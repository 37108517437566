import {
  SET_OPERATIONS_DATA,
  SET_CURRENT_PAGE_OPERATION_IDS,
  SET_CURRENT_SORT,

  ADD_OPERATION_TO_OPTIMIZE,
  REMOVE_OPERATION_TO_OPTIMIZE,

  ADD_MULTIPLE_OPERATION_TO_OPTIMIZE,
  REMOVE_MULTIPLE_OPERATION_TO_OPTIMIZE,

  SET_OPERATIONS_TO_OPTIMIZE,
  RESET_OPERATIONS_TO_OPTIMIZE,
} from '../mutation_types'

const state = {
  operationsData: [],
  currentPageOperationIds: [],
  operationsToOptimize: [],
  currentSort: null,
  currentSortDirection: 'desc',
}

const getters = {
  operationsToOptimizeCount: (state) => {
    return state.operationsToOptimize.length
  },
  areAllOperationsToOptimizeChecked: (state) => {
    return state.currentPageOperationIds.every(id => state.operationsToOptimize.includes(id))
  },
}

const actions = {
  setOperationsData ({ commit }, operationsData) {
    commit(SET_OPERATIONS_DATA, operationsData)
  },
  setCurrentPageOperationIds ({ commit }, operationsData) {
    commit(SET_CURRENT_PAGE_OPERATION_IDS, operationsData)
  },
  setCurrentSort ({ commit }, sortAndDirection) {
    commit(SET_CURRENT_SORT, sortAndDirection)
  },


  toggleOperationToOptimize ({ state, commit }, operation) {
    if (state.operationsToOptimize.includes(operation.id)) {
      commit(REMOVE_OPERATION_TO_OPTIMIZE, operation)
    } else {
      commit(ADD_OPERATION_TO_OPTIMIZE, operation.id)
    }
  },
  toggleAllOperationsToOptimize ({ state, getters, commit }) {
    commit(SET_OPERATIONS_TO_OPTIMIZE, state.operationsToOptimize || [])
    if (getters.areAllOperationsToOptimizeChecked) {
      commit(REMOVE_MULTIPLE_OPERATION_TO_OPTIMIZE, state.currentPageOperationIds)
    } else {
      commit(ADD_MULTIPLE_OPERATION_TO_OPTIMIZE, state.currentPageOperationIds)
    }
  },
  resetOperationsToOptimize ({ commit }) {
    commit(RESET_OPERATIONS_TO_OPTIMIZE)
  },
}

const mutations = {
  [SET_OPERATIONS_DATA] (state, operationsData) {
    state.operationsData = operationsData
  },
  [SET_CURRENT_PAGE_OPERATION_IDS] (state, operationsData) {
    state.currentPageOperationIds = operationsData.map(o => o.id)
  },
  [SET_CURRENT_SORT] (state, {sort, direction}) {
    state.currentSort = sort
    state.currentSortDirection = direction
  },


  [ADD_OPERATION_TO_OPTIMIZE] (state, operationId) {
    state.operationsToOptimize.push(operationId)
  },
  [REMOVE_OPERATION_TO_OPTIMIZE] (state, operation) {
    state.operationsToOptimize = state.operationsToOptimize.filter(operationsId => operationsId !== operation.id)
  },
  [ADD_MULTIPLE_OPERATION_TO_OPTIMIZE] (state, operations) {
    state.operationsToOptimize = Array.from(new Set(state.operationsToOptimize.concat(operations)))
  },
  [REMOVE_MULTIPLE_OPERATION_TO_OPTIMIZE] (state, operations) {
    state.operationsToOptimize = state.operationsToOptimize.filter(operationsId => !operations.includes(operationsId))
  },
  [SET_OPERATIONS_TO_OPTIMIZE] (state, operationsToOptimize) {
    state.operationsToOptimize = operationsToOptimize
  },
  [RESET_OPERATIONS_TO_OPTIMIZE] (state) {
    state.operationsToOptimize = []
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
