const PLANTS_STORAGE_KEY = 'PLANTS_STORAGE'

export const getPlantsStorage = () => {
  const rawValue = localStorage.getItem(PLANTS_STORAGE_KEY) || '{}'
  return JSON.parse(rawValue)
}

export const setPlantsStorage = (value) => {
  const rawValue = JSON.stringify(value)
  localStorage.setItem(PLANTS_STORAGE_KEY, rawValue)
}

export const setPlantStorage = (plantId, value) => {
  const newValues = {
    ...getPlantsStorage(),
    [plantId.toString()]: value,
  }

  setPlantsStorage(newValues)
}

export const getPlantStorage = (plantId, value) => {
  return getPlantsStorage()[plantId.toString()] || {}
}

export default {
  getPlantsStorage,
  setPlantsStorage,
  getPlantStorage,
  setPlantStorage,
}
