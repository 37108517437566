export function queryStringToJSON(qs) {
  qs = qs.startsWith('?') ? qs.substring(1) : qs

  const pairs = qs.split('&')
  const result = {}
  pairs.forEach(function (p) {
    const pair = p.split('=')
    const key = pair[0]
    let content = pair[1] || ''
    content =
      typeof content === 'string' ? content.replace(/\+/g, ' ') : content

    const value = decodeURIComponent(content)

    if (result[key]) {
      if (Object.prototype.toString.call(result[key]) === '[object Array]') {
        result[key].push(value)
      } else {
        result[key] = [result[key], value]
      }
    } else {
      result[key] = value
    }
  })

  return JSON.parse(JSON.stringify(result))
}