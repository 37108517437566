<template lang="pug">
.train-timeline-row-group(v-if="railroads", :style="cssVars")
  PureRowGroupHeader(v-if="rowGroupHeaderEnabled", :width="width", :height="rowGroupHeaderHeight")
  TrainsTimelineRow(
    v-for="railroad in railroads",
    :key="railroad.id",
    :railroad="railroad"
    :showOptimizationMethod="showOptimizationMethod"
  )
</template>

<script>
import TrainsTimelineRow from './TrainsTimelineRow'
import PureRowGroupHeader from '@/trains-timeline/PureRowGroupHeader'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'TrainsTimelineRowGroup',
  components: {
    TrainsTimelineRow,
    PureRowGroupHeader,
  },
  props: {
    railroadGroup: {
      type: Object,
      default: undefined,
    },
    width: {
      type: Number,
      default: 0,
    },
    showOptimizationMethod: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('trainsTimeline', [
      'rowGroupHeaderEnabled',
      'rowGroupHeaderHeight',
    ]),
    ...mapGetters('trainsTimeline', [
      'getRailroadsByIds',
    ]),
    cssVars() {
      return {
        '--width': `${this.width}px`,
      }
    },
    railroads() {
      return this.getRailroadsByIds(this.railroadGroup.railroadIds)
    },
  },

}
</script>

<style lang="sass" scoped>
@import '@styles/components/trains_timeline/train_timeline_row_group.sass'
</style>
