<template lang="pug">
.fields-form
  PlantSelect
</template>

<script>
import PlantSelect from './filters/PlantSelect.vue'

export default {
  name: 'FiltersForm',
  components: {
    PlantSelect,
  },
}
</script>

<style>
</style>
