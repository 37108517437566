import {
  NEXT_PAGE,
  PREVIOUS_PAGE,
  SET_PAGE,
  SET_PAGINATION_INFO,
  ENABLE_PAGINATION_LINKS,
  DISABLE_PAGINATION_LINKS,
} from './mutation_types'

const state = {
  currentPage: 1,
  disablePaginationLinks: false,
  // TODO check: seems that paginationInfo.page is duplicating same information of 'currentPage'
  paginationInfo: {},
}

const getters = {
}

const actions = {
  nextPage ({ state, commit }) {
    if (state.paginationInfo.next && !state.disablePaginationLinks) {
      commit(NEXT_PAGE)
    }
  },
  previousPage ({ state, commit }) {
    if (state.paginationInfo.prev && !state.disablePaginationLinks) {
      commit(PREVIOUS_PAGE)
    }
  },
  setPage ({ state, commit }, page) {
    if (page > 0 && page <= state.paginationInfo.pages && !state.disablePaginationLinks) {
      commit(SET_PAGE, page)
    }
  },
  setPaginationInfo ({ commit }, paginationInfo) {
    commit(SET_PAGINATION_INFO, paginationInfo)
  },
}

const mutations = {
  [NEXT_PAGE] (state) {
    ++state.currentPage
  },
  [PREVIOUS_PAGE] (state) {
    --state.currentPage
  },
  [SET_PAGE] (state, page) {
    state.currentPage = page
  },
  [SET_PAGINATION_INFO] (state, paginationInfo) {
    state.paginationInfo = paginationInfo
  },
  [ENABLE_PAGINATION_LINKS] (state) {
    state.disablePaginationLinks = false
  },
  [DISABLE_PAGINATION_LINKS] (state) {
    state.disablePaginationLinks = true
  },
}

export const paginationModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
