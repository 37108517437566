<template lang="pug">
.row-group-header(:style="cssVars")
  .row-header-group__name {{ name }}
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 15,
    },
  },
  computed: {
    cssVars() {
      return {
        '--width': `${this.width}px`,
        '--height': `${this.height}px`,
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.row-group-header
  height: var(--height)

.row-header-group__name
  width: max-content
  max-width: var(--width)
  width: var(--width)
  height: var(--height)
  line-height: var(--height)
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  font-size: 11px
  letter-spacing: 0.6px
  font-weight: 600
  padding-left: 15px
  text-transform: uppercase
</style>
