<template lang='pug'>
  .add-holidays
    p.help-text
      | {{ $t('holidays') }}
      | {{ holidaysList }}
    p.help-text(v-if='!withInterval')
      strong {{ $t('holidays_without_interval') }}
    .fields(v-if='withInterval')
      .field
        label
          | {{ $t('from') }}
        Datepicker(
          v-model='from'
          v-bind:language='datePickerLocale'
          v-bind:monday-first='true'
          v-bind:full-month-name='true'
          v-bind:disabledDates='disabledDates'
          v-bind:open-date='rangeStart'
          format='dd/MM/yyyy'
        )
    .fields(v-if='withInterval')
      .field
        label
          | {{ $t('to') }}
        Datepicker(
          v-model='to'
          v-bind:language='datePickerLocale'
          v-bind:monday-first='true'
          v-bind:full-month-name='true'
          v-bind:disabledDates='disabledDates'
          v-bind:open-date='rangeStart'
          format='dd/MM/yyyy'
        )

    .equal.width.inline.fields.days-categories
      .field.boolean
        label(for='holiday') {{ $t('days.holiday') }}
        input(type='checkbox' id='holiday' v-model='holiday')
      .field.boolean
        label(for='preholiday') {{ $t('days.preholiday') }}
        input(type='checkbox' id='preholiday' v-model='preholiday')

    .ui.red.message(v-if='errorMessage')
      | {{ errorMessage }}
    .ui.buttons
      .ui.tiny.button(v-on:click='cancel')
        | {{ $t('actions.rollback') }}
      .ui.green.button(v-on:click='confirm' v-if='valid')
        | {{ $t('actions.update') }}
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
import Holidays from 'date-holidays'

export default {
  components: {
    Datepicker,
  },

  props: {
    withInterval: Boolean,
    disabledDates: Object,
    rangeStart: Date,
    rangeEnd: Date,
    hasOperationsPlanInterval: Boolean,
    weekDays: Object,
    datePickerLocale: Object,
  },

  data() {
    return {
      from: null,
      to: null,
      holiday: true,
      preholiday: true,
    }
  },

  computed: {
    valid() {
      return this.atLeastOneCategorySelected &&
        (this.withInterval || this.hasOperationsPlanInterval) &&
        (!this.withInterval || (this.areToAndFromPresent && this.areToAfterFrom))
    },
    areToAndFromPresent() {
      return this.from !== null && this.to !== null
    },
    areToAfterFrom() {
      return this.to > this.from
    },
    atLeastOneCategorySelected() {
      return this.holiday || this.preholiday
    },
    errorMessage() {
      if (this.valid) {
        return
      } else if (!this.withInterval && !this.hasOperationsPlanInterval) {
        return this.$t('errors.operations_plan_must_has_an_interval')
      } else if (!this.atLeastOneCategorySelected) {
        return this.$t('errors.at_least_one_category_selected')
      } else if (this.areToAndFromPresent && this.withInterval && !this.areToAfterFrom) {
        return this.$t('errors.to_must_be_after_from')
      }
    },
    holidaysHolder() {
      // holidays can be selected for other countries (FR, UK)
      // evaluate with customer if a different locale means a different country
      return new Holidays('IT')
    },
    holidaysList() {
      return this.holidaysHolder.getHolidays(this.currentYear).map(function(holiday) {
        if (holiday.type === 'public') {
          return holiday.name
        }
      }).filter(Boolean).join(', ')
    },
    currentYear() {
      return parseInt(moment().format('YYYY'))
    },
    daysToAdd() {
      if (!this.valid) return []

      let daysToAdd = []
      let addHoliday = this.holiday
      let addPreHoliday = this.preholiday
      let from
      let to

      // set hours to 12: hack to have consistency through midnight/timezone and dates from input or from datepicker
      if (this.withInterval) {
        from = moment(this.from).hours(12)
        to = moment(this.to).hours(12)
      } else {
        from = moment(this.rangeStart).hours(12)
        to = moment(this.rangeEnd).hours(12)
      }

      let currentDay = from.clone()
      let dayToAdd
      let dayOfTheWeekIndex

      while (currentDay <= to) {
        let isHoliday = this.holidaysHolder.isHoliday(new Date(currentDay))
        dayOfTheWeekIndex = currentDay.isoWeekday()

        if (isHoliday && isHoliday.type === 'public') {
          if (addHoliday && (
            (dayOfTheWeekIndex === 1 && this.weekDays.monday) ||
            (dayOfTheWeekIndex === 2 && this.weekDays.tuesday) ||
            (dayOfTheWeekIndex === 3 && this.weekDays.wednesday) ||
            (dayOfTheWeekIndex === 4 && this.weekDays.thursday) ||
            (dayOfTheWeekIndex === 5 && this.weekDays.friday) ||
            (dayOfTheWeekIndex === 6 && this.weekDays.saturday) ||
            (dayOfTheWeekIndex === 7 && this.weekDays.sunday))) {

            daysToAdd.push(currentDay.format())
          }
          if (addPreHoliday) {
            dayToAdd = currentDay.clone().subtract(1, 'd')
            dayOfTheWeekIndex = dayToAdd.isoWeekday()
            if (dayToAdd.isSameOrAfter(from) && dayToAdd.isSameOrBefore(to) && (
              (dayOfTheWeekIndex === 1 && this.weekDays.monday) ||
              (dayOfTheWeekIndex === 2 && this.weekDays.tuesday) ||
              (dayOfTheWeekIndex === 3 && this.weekDays.wednesday) ||
              (dayOfTheWeekIndex === 4 && this.weekDays.thursday) ||
              (dayOfTheWeekIndex === 5 && this.weekDays.friday) ||
              (dayOfTheWeekIndex === 6 && this.weekDays.saturday) ||
              (dayOfTheWeekIndex === 7 && this.weekDays.sunday))) {

              // pre holiday is inside interval, add it
              daysToAdd.push(dayToAdd.format())
            }
          }
        }
        currentDay = currentDay.clone().add(1, 'd')
      }

      return daysToAdd
    },
  },

  methods: {
    cancel() {
      this.$emit('closeOption')
    },
    confirm() {
      this.$emit('addDaysToExcluded', this.daysToAdd)
      this.$emit('closeOption')
    },
  },
}
</script>

<style lang='sass'>
  .add-holidays
    margin-top: 15px
    .help-text
      font-size: 12px
      font-style: italic
    .ui.buttons
      margin-top: 10px

  .ui.form .inline.fields.days-categories .field > label
    display: block
    margin: 0 !important
</style>
