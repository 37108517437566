<template lang='pug'>
  .EditableCode(v-bind:class='containerClass' v-on:click='handleClick')
    .actions__label(
      :title='carList.name'
      v-if='showLabel'
      v-on:click='handleLabelClick'
    ) {{ carListName }}

    .ui.form(v-if='showForm' style="z-index: 100")
      .ui.action.input
        input(
          type='text'
          v-model='code'
          ref='code'
          :disabled='disabledInput'
          @keydown.esc="resetState"
          @keydown.enter="handleSubmit"
        )
        button.ui.icon.button.green(
          v-on:click='handleSubmit'
          v-bind:class='buttonClass'
        )
          i.check.icon

</template>

<script>
import { mapState } from 'vuex'
import { updateCarList } from '../../api/car_lists'

export default {
  props: {
    carList: Object,
    actionsCount: Number,
  },

  data() {
    return {
      isLoading: false,
      isEditing: false,
      code: null,
    }
  },

  methods: {
    handleClick() {
      if(this.isEditing) {
        this.$nextTick().then(() => {
          this.$refs.code.focus()
        })
      }
    },
    handleLabelClick() {
      this.isEditing = true
      this.$nextTick().then(() => {
        this.$refs.code.focus()
      })
    },
    resetState() {
      if (this.isLoading) { return }

      this.code = this.carList.code
      this.isEditing = false
    },
    handleSubmit() {
      this.isLoading = true

      const code = this.code
      const carListId = this.carList.id
      const attributes = { code }

      updateCarList({
        apiToken: this.apiToken,
        carListId,
        attributes,
      }).then((data) => {
        this.$store.commit('updateCarList', {
          carList: this.carList,
          attributes: {
            code,
            name: this.code,
          },
        })

        this.isLoading = false
        this.isEditing = false
      })

    },
  },

  computed: {
    ...mapState({
      apiToken: 'apiToken',
      tenant: 'tenant',
      settings: 'settings',
    }),
    showLabel() {
      return this.isEditing === false
    },
    showForm() {
      return !this.showLabel
    },
    containerClass() {
      return ''
    },
    buttonClass() {
      return this.isLoading ? 'loading' : ''
    },
    disabledInput() {
      return this.isLoading
    },
    carListName() {
      return this.settings.enable_parent_operation_done_on_car_list
        ? [this.carList.parent_operation_done_on, this.carList.name].filter(x => x).join(' - ')
        : this.carList.name
    },
  },

  mounted() {
    this.$nextTick().then(() => {
      this.code = this.carList.code
    })
  },
}
</script>

