import Vue from 'vue'

import {
  SET_OPTIMIZED_TIMES_DATA,
  SET_CURRENT_PAGE_OPTIMIZED_TIME_IDS,

  ADD_OPTIMIZED_TIME_TO_SAVE,
  REMOVE_OPTIMIZED_TIME_TO_SAVE,
  TOGGLE_OPTIMIZED_TIME_TO_SAVE,

  RESET_OPTIMIZED_TIMES_TO_SAVE,

  SET_OPTIMIZED_TIMES_TO_SAVE_ARRAY,
} from '../mutation_types'

const state = {
  optimizedTimesData: [],
  currentPageOptimizedTimeIds: [],
  optimizedTimesToSave: {},
  defaultsToSave: [],
  allDefaultsToSave: false,
}

const getters = {
  optimizedTimesToSaveCount: (state) => {
    return Object.values(state.optimizedTimesToSave).reduce((acc, arr) => acc += arr.length, 0)
  },
  optimizedTimesToSaveRowsCount: (state) => {
    return Object.values(state.optimizedTimesToSave).filter(arr => arr.length).length
  },

  optimizedTimeById: (state) => (optimizedTimeId) => {
    return state.optimizedTimesData.find(ot => ot.id === optimizedTimeId)
  },
  isOptimizedTimeToBeSaved: (state) => (optimizedTime, day) => {
    return state.optimizedTimesToSave[optimizedTime.id]
      && state.optimizedTimesToSave[optimizedTime.id].includes(day)
  },

  areDefaultsToSaveChecked: (state, getters) => (optimizedTime) => {
    return Object.keys(optimizedTime.circulation_days).every(day => {
      return getters.isOptimizedTimeToBeSaved(optimizedTime, day)
    })
  },

  areAllDefaultsToSaveChecked: (state, getters) => {
    return state.currentPageOptimizedTimeIds.every(id => {
      const optimizedTime = getters.optimizedTimeById(id)
      return getters.areDefaultsToSaveChecked(optimizedTime)
    })
  },
}

const actions = {
  setOptimizedTimesData ({ commit }, optimizedTimesData) {
    commit(SET_OPTIMIZED_TIMES_DATA, optimizedTimesData)
  },
  setCurrentPageOptimizedTimeIds ({ commit }, optimizedTimesData) {
    commit(SET_CURRENT_PAGE_OPTIMIZED_TIME_IDS, optimizedTimesData)
  },
  toggleOptimizedTimeToSave ({ getters, commit }, { optimizedTime, day }) {
    const checked = getters.isOptimizedTimeToBeSaved(optimizedTime, day)
    commit(TOGGLE_OPTIMIZED_TIME_TO_SAVE, { optimizedTime, day, checked })
  },
  toggleDefaultsToSave ({ getters, commit }, optimizedTime) {
    const checked = getters.areDefaultsToSaveChecked(optimizedTime)
    Object.keys(optimizedTime.circulation_days).forEach(day => {
      commit(TOGGLE_OPTIMIZED_TIME_TO_SAVE, { optimizedTime, day, checked })
    })
  },
  toggleAllDefaultsToSave ({ state, commit, getters }) {
    const checked = getters.areAllDefaultsToSaveChecked
    state.currentPageOptimizedTimeIds.forEach(optimizedTimeId => {
      const optimizedTime = getters.optimizedTimeById(optimizedTimeId)
      Object.keys(optimizedTime.circulation_days).forEach(day => {
        commit(TOGGLE_OPTIMIZED_TIME_TO_SAVE, { optimizedTime, day, checked })
      })
    })
  },
  setOptimizedTimesToSaveArrays ({ state, commit }) {
    state.optimizedTimesData.forEach(optimizedTime => {
      if (!state.optimizedTimesToSave[optimizedTime.id]) {
        commit(SET_OPTIMIZED_TIMES_TO_SAVE_ARRAY, { optimizedTime, arr: [] })
      }
    })
  },
  resetOptimizedTimesToSaveArrays ({ commit, dispatch }) {
    commit(RESET_OPTIMIZED_TIMES_TO_SAVE)
    dispatch('setOptimizedTimesToSaveArrays')
  },
}

const mutations = {
  [SET_OPTIMIZED_TIMES_DATA] (state, optimizedTimesData) {
    state.optimizedTimesData = optimizedTimesData
  },
  [SET_CURRENT_PAGE_OPTIMIZED_TIME_IDS] (state, optimizedTimesData) {
    state.currentPageOptimizedTimeIds = optimizedTimesData.map(ot => ot.id)
  },

  [ADD_OPTIMIZED_TIME_TO_SAVE] (state, { optimizedTime, day }) {
    state.optimizedTimesToSave[optimizedTime.id].push(day)
  },
  [REMOVE_OPTIMIZED_TIME_TO_SAVE] (state, { optimizedTime, day }) {
    state.optimizedTimesToSave[optimizedTime.id] = state.optimizedTimesToSave[optimizedTime.id].filter(d => d !== day)
  },

  [TOGGLE_OPTIMIZED_TIME_TO_SAVE] (state, { optimizedTime, day, checked }) {
    checked
      ? this.commit(`operationsPlansOptimizer/${REMOVE_OPTIMIZED_TIME_TO_SAVE}`, { optimizedTime, day })
      : this.commit(`operationsPlansOptimizer/${ADD_OPTIMIZED_TIME_TO_SAVE}`, { optimizedTime, day })
  },

  [RESET_OPTIMIZED_TIMES_TO_SAVE] (state) {
    state.optimizedTimesToSave = {}
  },

  [SET_OPTIMIZED_TIMES_TO_SAVE_ARRAY] (state, { optimizedTime, arr = [] }) {
    Vue.set(state.optimizedTimesToSave, [optimizedTime.id], arr)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
