<template lang='pug'>
  .field.days-input
    label(v-bind:for='id')
      | {{ $t('labels.suppression_days') }}
    .ui.action.input
      input(
        v-bind:name='previewName'
        v-bind:id='id'
        v-bind:value='excludedDaysPreviewValue'
        v-bind:disabled='true'
        type='text'
      )
      .ui.yellow.right.labeled.icon.button(
        v-on:click='showExcludedDaysModal'
      )
        i.edit.icon
        | {{ $t('actions.edit') }}

    input(
      v-if='noExcludedDaysSelected'
      v-bind:name='name'
      v-bind:value='null'
      type='hidden'
    )
    input(
      v-for='day in excludedDays'
      v-bind:name='name'
      v-bind:value='day'
      type='hidden'
    )
</template>

<script>
import moment from 'moment'

export default {
  props: {
    model: String,
    fieldName: String,
    excludedDays: Array,
  },

  computed: {
    id() {
      return this.model + '_' + this.fieldName
    },
    name() {
      return this.model + '[' + this.fieldName + '][]'
    },
    previewName() {
      return this.model + '[' + this.fieldName + '_preview]'
    },
    excludedDaysPreviewValue() {
      if (this.noExcludedDaysSelected) {
        return this.$t('no_days_selected')
      }

      let daysLimit = 3
      let preview = this.excludedDays.slice(0, daysLimit).map(function(day) {
        return moment(day).format('DD/MM/YYYY')
      }).join(', ')
      if (this.excludedDays.length > daysLimit) {
        preview += '  ' + this.$t('more_days', { count: this.excludedDays.length - daysLimit })
      }

      return preview
    },
    noExcludedDaysSelected() {
      return this.excludedDays.length === 0
    },
  },

  methods: {
    showExcludedDaysModal() {
      this.$emit('showExcludedDaysModal')
    },
  },
}
</script>

<style lang='sass'>
  .days-input
    .ui.action.input input
      pointer-events: none
  .ui.form .days-input.field :disabled
      opacity: 1
</style>
