import Axios from 'axios'

export const fetchStations = ({ apiToken, params = {} }) => {
  return Axios.get(`/v1/stations.json`, {
    headers: { 'X-AUTH-TOKEN': apiToken },
    params,
  }).then((response) => {
    if (response.status === 200) { return Promise.resolve(response.data) }

    return Promise.reject(new Error(`Page status ${response.status}`))
  })
}

export default {
  fetchStations,
}
