<template lang="pug">
.plant-component.plant-component--dashboard
  .h3(v-if="!plantId" style="text-align: center")
    | {{ $t('no_plant_selected') }}
  .h3(v-if="plantId && railroadGroupsRows.length == 0" style="text-align: center")
    | {{ $t('loading') }}
  masonry(:cols="3", :gutter="8")
    template(v-for="(row, rowIndex) in railroadGroupsRows")
      template(v-for="(railroadGroup, groupIndex) in row.railroad_groups")
        .railroad-group(v-if="railroadGroup.railroads.length > 0")
          .railroad-group__title(:style="titleStyleObject(row.title)") {{ railroadGroupTitle(railroadGroup) }}
          .railroad-group__body
            .railroad(
              @mouseenter="handleMouseEnter(index)"
              @mouseleave="handleMouseLeave()"
              :class="{ 'railroad--empty': railroad.car_lists.length === 0 }"
              v-for="(railroad, index) in railroadGroup.railroads",
              :key="railroad.id", :style="{zIndex: hoveredRailroadIndex === index ? 2 : 1}"
            )
              .railroad__name {{ railroad.name }}
              .railroad__car-list
                ReadonlyCarList(
                  v-for='carList in railroad.car_lists'
                  :key='carList.uuid'
                  :carList='carList'
                  :enableCompanyColorAsDefaultInPlant='settings.enable_company_color_as_default_in_plant'
                )
              .railroad__counter
                RailroadCounter(
                  :railroad='railroad'
                  :showWeight='false'
                  :showCount='false'
                  :compact="true"
                )
</template>

<script>
import { mapState } from 'vuex'
import ReadonlyCarList from '../plant/components/readonly-car-list.vue'
import RailroadCounter from '../plant/components/railroad-counter.vue'
import { minutesToMilliseconds } from '@/helpers/functions'

const DEFAULT_RAILGROUP_HEADER_BG = '#eee'
const REFRESH_RATE_IN_MINUTES = 5 // refresh only data
const REFRESH_PAGE_IN_MINUTES = 359 // refresh entire page, avoid multiples of the REFRESH_RATE_IN_MINUTES

export default {
  name: 'PlantDashboard',
  components: {
    ReadonlyCarList,
    RailroadCounter,
  },
  props: {
    // railroadGroupsRows: {
    //   type: Array,
    //   required: true,
    // },
    plantId: Number,
    plantName: String,
    // plantUpdateToken: String,
    // railroadGroups: Array,
    // operations: Array,
    // currentOperation: Object,
    // currentAddonable: Object,
    // otherPlants: Array,
    // manualTrainSelect: Boolean,
    // releaseToAnotherPlant: Boolean,
    // apiToken: String,
    currentDate: String,
    // plantBracketsCount: Number,
    // plantBracketsAvailableCount: Number,
    settings: Object,
    currentUser: Object,
    tenant: String,
    // bracketsUrl: String,
    // carListId: Number,
    // plantInsertFromLabels: Object,
    // canToggleRailroadActive: Boolean,
    // carTypologies: Array,
    dataUrl: String,
  },
  data() {
    return {
      hoveredRailroadIndex: null,
      railroadGroupsRows: [],
    }
  },
  computed: {
    ...mapState({
      plantNameFromStore: 'plantName',
    }),
  },
  methods: {
    handleMouseEnter(index) {
      this.hoveredRailroadIndex = index
    },
    handleMouseLeave() {
      this.hoveredRailroadIndex = null
    },
    titleStyleObject(stringInput) {
      return { backgroundColor: backgroundColor(stringInput) }
    },
    railroadGroupCarsCount(railroadGroup) {
      return railroadGroup.railroads.reduce((railroadGroupAcc, railroad) => {
        const railroadCarsCount = railroad.car_lists.reduce((railroadAcc, carList) => railroadAcc + carList.cars.length, 0)
        return railroadGroupAcc + railroadCarsCount
      }, 0)
    },
    railroadGroupTitle(railroadGroup) {
      const railroadGroupCarsCount = this.railroadGroupCarsCount(railroadGroup)
      return [ railroadGroup.dashboard_name, this.$t('cars_in_title', { cars_count: railroadGroupCarsCount})]
        .filter(x => x)
        .join(' – ')
    },
    loadData() {
      if (!this.plantId) {
        return
      }

      fetch(this.dataUrl)
        .then(res => res.json())
        .then(data => {
          const state = {}
          state.plantId = data.plantId
          state.plantName = data.plantName
          state.currentDate = data.currentDate
          state.originalDate = data.originalDate
          state.settings = data.settings
          state.tenant = data.tenant
          state.currentUser = data.currentUser
          this.$store.dispatch('loadInitialState', state)

          this.railroadGroupsRows = data.railroadGroupsRows

          setTimeout(
            () => { this.loadData() },
            minutesToMilliseconds(REFRESH_RATE_IN_MINUTES)
          )
        })
        .catch(error => console.error('error loading data', error))
    },
  },
  mounted() {
    const initialState = {
      plantId: this.plantId,
      plantName: this.plantName,
      // plantUpdateToken: this.plantUpdateToken,
      // railroadGroups: this.railroadGroups,
      // operations: this.operations,
      // isOperationsDropdownShown: !!this.currentOperation,
      // currentOperation: this.currentOperation,
      // currentAddonable: this.currentAddonable,
      // otherPlants: this.otherPlants,
      // manualTrainSelect: this.manualTrainSelect,
      // releaseToAnotherPlant: this.releaseToAnotherPlant,
      // apiToken: this.apiToken,
      currentDate: this.currentDate,
      originalDate: this.currentDate,
      // plantBracketsCount: this.plantBracketsCount,
      // plantBracketsAvailableCount: this.plantBracketsAvailableCount,
      settings: this.settings,
      tenant: this.tenant,
      // bracketsUrl: this.bracketsUrl,
      currentUser: this.currentUser,
      // plantInsertFromLabels: this.plantInsertFromLabels,
      // canToggleRailroadActive: this.canToggleRailroadActive
    }

    this.$store.dispatch('loadInitialState', initialState)

    // avoid session expiring: reload entire page after a long time
    setTimeout(
      () => { location.reload() },
      minutesToMilliseconds(REFRESH_PAGE_IN_MINUTES)
    )

    this.loadData()
  },
}

function backgroundColor(stringInput) {
  if(!stringInput) { return DEFAULT_RAILGROUP_HEADER_BG }

  const stringUniqueHash = [...stringInput].reduce((acc, char) => {
    acc = char.charCodeAt(0) + ((acc << 6) - acc)
    return acc & acc
  }, 0)
  return `hsl(${stringUniqueHash % 360}, 40%, 75%)`
}
</script>

<style lang="sass">
.plant-component.plant-component--dashboard
  --car-height: 12px
  --car-icon-height: 10px

  .railroad
    display: flex
    align-items: center
    justify-content: flex-start
    min-height: unset
    position: relative

    &.railroad--empty
      font-size: 8px
      line-height: 1

    .railroad__counter
      margin-left: auto

    .railroad__name
      font-size: 10px
      padding: 0 5px

  .railroad-group__title
    text-align: center
    height: 20px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 4px 4px 0px 0px

  .railroad-group__body
    background: #fafafa
    margin-bottom: 10px

  .car-list-wrapper.with-sticky-name
    height: auto
    display: inline-flex
    padding: 0
    margin: 10px 2px 2px

    .car-list__sticky-name
      font-size: 8px
      top: -10px
      height: 10px
      line-height: 10px
      border-radius: 3px 3px 0px 0px

  .car
    width: var(--car-height)
    position: relative

    > i.icon
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      line-height: var(--car-icon-height)
      height: auto

  .railroad-counter
    font-size: 10px
</style>
