import { $toast } from 'vue/types/umd'
<template lang="pug">
div
  .ui.grid.inputs
    .three.wide.column
      vSelect(
        class='ui-vue-select'
        :options='plantsOptions'
        :multiple='true'
        v-model='selectedPlants'
        v-bind:placeholder='$t("plant")'
      )
    .three.wide.column
      .ui.input.operations
        input(type="text" :placeholder='$t("operation")' v-model="operation")
    .three.wide.column
      .ui.input.addonable-stop
        input(type="text" :placeholder='$t("addonable_stop")' v-model="addonable")
    .three.wide.column
      .ui.buttons
        .ui.tiny.button.blue(v-on:click='getOptimizations(optimizationsPage)')
          | {{ $t('search') }}
        .ui.tiny.button.green(v-on:click='publishOptimizations')
          | {{ $t('publish') }}
          .ui.grid
  .ui.grid
    .row.padded
      .sixteen.wide.column
        OptimizationsTable(
          :optimizations='optimizations'
          :operationFilter ='operation'
          :addonableFilter ='addonable'
          v-on:notifyUpdatePeriodicity='updatePeriodicity'
          v-on:sendSelectedOptimizations='saveSelectedOptimizations'
        )
        Pagination(
          :page='optimizationsPage'
          v-on:pageChanged='getOptimizations'
        )

</template>

<script>
import OptimizationsTable from './OptimizationsTable.vue'
import Pagination from '../operations-pairing/Pagination.vue'
import { error as errNotify, success as succNotify } from '../utils/notify'
import vSelect from 'vue-select'

export default {
  components: {
    OptimizationsTable,
    Pagination,
    vSelect,
  },
  data() {
    return {
      selectedOptimizations: [],
      optimizations: [],
      optimizationsPage: 1,
      plantsOptions: [],
      selectedPlants: [],
      operation: '',
      addonable: '',
    }
  },
  mounted() {
    this.setPlantsOptions()
    this.getOptimizations(this.optimizationsPage)
  },
  methods: {
    getSelectedPlantsIds() {
      var selectedIds = []
      this.selectedPlants.forEach((plant) => {
        selectedIds.push(plant['code'])
      })
      return selectedIds
    },
    setPlantsOptions() {
      this.$http.get('/plants/list_for_select.json').then(response => {
        response.data.plants.forEach((plant) => {
          this.plantsOptions.push({ label: plant.name, code: plant.value })
        })
      })
    },
    publishOptimizations() {
      if (this.selectedOptimizations.length > 0) {
        this.$http.post(
          `/operations_pairings_optimizer/publish_optimizations`,
          {
            optimizations: this.selectedOptimizations,
          }
        ).then(response => {
          if (response.data['success']){
            succNotify({ title: this.$t('publication_in_progress') })
          } else {
            errNotify({ title: response.data['message'] })
          }
        })
      } else {
        errNotify({ title: this.$t('select_optimizations') })
      }
    },
    saveSelectedOptimizations(optimizations) {
      this.selectedOptimizations = optimizations
    },
    getOptimizations(page) {
      var query = {
        plant_ids: this.getSelectedPlantsIds(),
      }
      this.$http.get(
        `/operations_pairings_optimizer/optimizations_collection?page=${page}`,
        { params: query }
      ).then(response => {
        this.optimizationsPage = page
        this.optimizations = response.data
      })
    },
    updatePeriodicity(data) {
      this.$http.put(
        `/operations_pairings_optimizer/update_periodicity`,
        {
          optimizationId: data['optimizationId'],
          periodicity: data['periodicity'],
        }
      ).then(response => {
        if (response.data['success'])
          succNotify({ title: this.$t('saved') })
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.inputs
  display: flex
  align-items: center
.operations, .addonable-stop
  width: 230px
</style>
