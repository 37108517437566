<template lang="pug">
div
  .ui.grid
    .three.wide.column
      vSelect(
        class='ui-vue-select'
        :options='plantsOptions'
        v-model='selectedPlant'
        v-bind:placeholder='"Impianto"'
        v-on:input='getOptimizedShifts()'
      )
  .table-container-scrollable
    table.ui.compact.table.unstackable.max-content
      thead
        th
        template(v-for="day in days")
          template(v-for="(item, index) in shifts")
            th
              div(v-if='index === 0')
                | {{ $t(day) }}

      tbody
        tr
          td
            | Inizio - Fine
          template(v-for="(optimizedShift, index) in optimizedShiftsData")
            td
              | {{optimizedShift.start_time}} - {{optimizedShift.end_time}}
        tr
          td
            | Capacità Team
          template(v-for="(optimizedShift, index) in optimizedShiftsData")
            td
              input(
                v-model="optimizedShift.team_capacity"
                v-on:change="handleUpdate(optimizedShift)"
              )
        tr
          td
            | Capacità Piloti
          template(v-for="(optimizedShift, index) in optimizedShiftsData")
            td
              input(
                v-model="optimizedShift.pilots_capacity"
                v-on:change="handleUpdate(optimizedShift)"
              )
</template>

<script>
import DatePicker from 'vue2-datepicker'
import vSelect from 'vue-select'


export default {
  components: {
    DatePicker,
    vSelect,
  },
  data() {
    return {
      plantsOptions: [],
      selectedPlant: null,
      optimizedShiftsData: [],
      shiftsInDay: 0,
      days: [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
      ],
      hoursToUpdate: [],
      pageCounter: 0,
    }
  },
  computed: {
    shifts() {
      return Array(this.shiftsInDay).fill(null)
    },
  },
  mounted() {
    this.setPlantsOptions()
    this.getOptimizedShifts()
  },
  methods: {
    getOptimizedShifts() {
      if(this.selectedPlant) {
        var query = {
          plant_id_eq: this.selectedPlant.code,
        }
      }
      this.$http.get(
        `/optimized_shifts/optimized_shifts_collection`,
        { params: { q: query } }
      ).then(response => {
        this.optimizedShiftsData = response.data
        this.shiftsInDay = this.optimizedShiftsData.length / 7
      })
    },
    setPlantsOptions() {
      this.$http.get('/plants/list_for_select.json').then(response => {
        response.data.plants.forEach((plant) => {
          this.plantsOptions.push({ label: plant.name, code: plant.value })
        })
      })
    },
    handleUpdate(optimizedShift) {
      $.ajax({
        url: `/optimized_shifts/${optimizedShift.id}`,
        data: {
          team_capacity: optimizedShift.team_capacity,
          pilots_capacity: optimizedShift.pilots_capacity,
        },
        method: 'put',
      })
    },
  },
}
</script>

<style lang="sass">
.max-content
  width: max-content !important
</style>
