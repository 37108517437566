<template lang="pug">
.ui.active.dimmer
  .ui.negative.message
    .header {{ $t('error_unable_to_retrieve_data') }}
    br
    .ui.button.primary(@click="$emit('retry-button:click', $event)")
      i.icon.redo
      | {{ $t('retry') }}
</template>

<script>

export default {
  name: 'TrainsTimelineErrorAlert',
}
</script>

<style>
</style>
