<template lang="pug">
  button.ui.button.small.blue.right.floated(v-on:click='handleClick')
    | {{ $t('collapse_railroad_group_button') }}
</template>
<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([
      'isRailRoadGroupCollapsed',
    ]),
  },
  methods: {
    handleClick(e) {
      e.preventDefault()
      this.$store.dispatch('updateIsRailRoadGroupCollapsed')
    },
  },
}
</script>
