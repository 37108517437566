<template lang="pug">
.editable-datetime(:class="{ 'editable-datetime--readonly': readonly }")
  .text-label(v-if="!editing", @click="startEditing") {{ formattedDatetime }}
  date-picker(
    v-if="editing",
    v-bind="datePickerProps",
    :value="datetime",
    @change="handleDatetimeChange($event)"
  )
  .editable-datetime__actions.ui.icon.buttons.mini
    .ui.button.mini.icon.green(
      @click="handleSaveButtonClick",
      v-if="editing",
      data-tooltip="Salva modifica",
      data-inverted
    )
      i.icon.check
    .ui.button.mini.icon.yellow(
      @click="handleClearDatetime",
      v-if="editing",
      data-tooltip="Cancella valore",
      data-inverted
    )
      i.icon.erase
    .ui.button.mini.icon(
      @click="handleCancelButtonClick",
      v-if="editing",
      data-tooltip="Annulla modifica",
      data-inverted
    )
      i.icon.times
</template>

<script>
import DatePicker from 'vue2-datepicker'
import { mapActions } from 'vuex'
import moment from 'moment'
const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm'

export default {
  data() {
    return {
      originalDatetime: this.storedDatetime(),
      editing: false,
      datePickerProps: {
        'class': 'wide',
        'lang': this.$i18n.locale.substring(0,2),
        'format': DATETIME_FORMAT,
        'clearable': false,
        'type': 'datetime',
        'placeholder': '',
        'time-picker-options': {start: '00:00', step: '00:30', end: '23:30'},
        'append-to-body': true,
      },
    }
  },
  components: {
    DatePicker,
  },
  props: {
    optimizedTime: {
      type: Object,
      default: null,
    },
    field: {
      type: String,
      default: null,
    },
  },
  computed:{
    datetime() {
      return this.storedDatetime()
    },
    formattedDatetime(){
      return this.datetime && moment(this.datetime).format(DATETIME_FORMAT)
    },
    readonly() {
      return !this.optimizedTime.time_editable
    },
    doneOn(){
      return this.optimizedTime.done_on
    },
  },
  methods: {
    ...mapActions('operationsOptimizer', [
      'setOptimizedTimePeriodicityActivityTime',
    ]),
    storedDatetime () {
      const datetime = this.optimizedTime.periodicity?.[this.field]
      if (!datetime) { return }

      if (datetime instanceof Date) { return datetime }

      return this.$dayjs(datetime).toDate()
    },
    handleDatetimeChange(datetime) {
      this.setOptimizedTimePeriodicityActivityTime({
        field: this.field,
        optimizedTimeId: this.optimizedTime.id,
        datetime: datetime,
      })
    },
    handleSaveButtonClick(){
      this.stopEditing()
      this.remoteUpdate(this.optimizedTime)
      this.originalDatetime = this.storedDatetime()
    },
    handleCancelButtonClick(){
      this.stopEditing()
      this.setOptimizedTimePeriodicityActivityTime({
        field: this.field,
        optimizedTimeId: this.optimizedTime.id,
        datetime: this.originalDatetime,
      })
    },
    handleClearDatetime() {
      this.setOptimizedTimePeriodicityActivityTime({
        field: this.field,
        optimizedTimeId: this.optimizedTime.id,
        datetime: null,
      })
    },
    remoteUpdate(optimizedTime) {
      this.$http.put(`/optimized_times/${optimizedTime.id}`, {
        period: optimizedTime.periodicity,
      })
    },
    startEditing() {
      if(!this.optimizedTime.time_editable) { return }

      this.editing = true
    },
    stopEditing(){
      this.editing = false
    },
  },
}
</script>

<style lang="sass" scoped>
.editable-datetime
  display: flex
  align-items: center

  .ui.icon.button
    min-height: 30px

  .text-label
    display: flex
    align-items: center
    min-height: 30px
    width: 100%
    padding-left: 8px
    padding-right: 8px

  .editable-datetime__actions.ui.buttons
    margin-left: 5px

.editable-datetime
  &:not(.editable-datetime--readonly)
    .text-label
      cursor: pointer
      border-radius: 4px
      background: rgba(0, 0, 0, 0.025)

      &:hover
        background: rgba(0, 0, 0, 0.06)
</style>

<style lang="sass">
.editable-datetime
  .mx-datepicker
    .mx-input-wrapper
      input.mx-input
        padding: 0px
        padding-left: 8px
        height: 30px
        font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif
</style>
