import Vue from 'vue'
import Vuex from 'vuex'

import {
  state as plantState,
  getters as plantGetters,
  actions as plantActions,
  mutations as plantMutations,
} from '../plant/store/index'
// TODO: refactor plants to be a store module

import { operationsPlansOptimizerModule } from '../operations-plans-optimizer/store/index'
import { operationsOptimizerModule } from '../operations-optimizer/store/index'
import { invoiceAddonModule } from '../invoice-addon/store/index'
import { trainsTimelineModule } from '@/trains-timeline/store/index'
import { paginationModule } from '../pagination/store/index'

Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'

const state = { ...plantState }
const getters = { ...plantGetters }
const actions = { ...plantActions }
const mutations = {
  ...plantMutations,
}

export default new Vuex.Store({
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
  modules: {
    operationsPlansOptimizer: operationsPlansOptimizerModule,
    operationsOptimizer: operationsOptimizerModule,
    invoiceAddon: invoiceAddonModule,
    pagination: paginationModule,
    trainsTimeline: trainsTimelineModule,
  },
  strict: debug,
})
