<template lang="pug">
  div(v-bind:class='cssClass')
    .ui.icon.buttons
      button.ui.red.button(v-if='canPublish' v-on:click='handleClick')
        i.check.icon
        | &nbsp;{{ $t('publish') }}

      button.ui.button.disabled(v-if='cannotPublish')
        i.check.icon
        | &nbsp;{{ $t('publish') }}

    PublishModal(
      v-if='publishingCarList'
      v-on:closed='onClosed'
      v-bind:railroad='railroad'
      v-bind:railroads='railroads'
      v-bind:stations='stations'
      v-bind:sourceStationName='sourceStationName'
      v-bind:operationTitle='operationTitle'
      v-bind:operationId='operationId'
      v-bind:operationDoneOn='operationDoneOn'
      v-bind:factualArrivalHour='factualArrivalHour'
    )
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PublishModal from './components/modals/car-list-publish-modal'

export default {
  components: {
    PublishModal,
  },

  props: {
    apiToken: String,
    dataUrl: String,
    factualArrivalHour: String,
  },

  data() {
    return {
      carList: undefined,
      railroad: undefined,
      railroads: [],
      stations: [],
      settings: undefined,
      sourceStationName: undefined, // String,
      operationTitle: undefined, // String,
      operationId: undefined, // Number,
      operationDoneOn: undefined, // String,
      showModal: false,
    }
  },

  computed: {
    ...mapState({
      publishingCarList: 'publishingCarList',
    }),
    cssClass() {
      return 'car-list-publish-button'
    },
    canPublish() {
      return this.carList && this.carList.publish_preconditions
    },
    cannotPublish() {
      return !this.canPublish
    },
  },

  methods: {
    carListId() {
      const domId = 'operation_addonable_car_list_id'
      const el = document.getElementById(domId)

      return el.options[el.selectedIndex].value
    },

    handleClick(e) {
      e.preventDefault()
      document.body.classList.add('overflow-hidden')
      this.$store.dispatch('showPublishingModal', this.carList)
    },

    onClosed() {
      document.body.classList.remove('overflow-hidden')
      this.$store.dispatch('hidePublishingModal')
    },

    loadData() {
      fetch(this.dataUrl)
        .then((res) => res.json())
        .then((data) => {

          // assign each key to component 'data'
          this.carList = data.carList
          this.insert_from_left_label = data.insert_from_left_label
          this.operationDoneOn = data.operationDoneOn
          this.operationId = data.operationId
          this.operationTitle = data.operationTitle
          this.plantId = data.plantId
          this.plantName = data.plantName
          this.railroad = data.railroad
          this.railroads = data.railroads
          this.settings = data.settings
          this.showModal = data.showModal
          this.sourceStationName = data.sourceStationName
          this.stations = data.stations

          const initialStoreState = {
            operations: this.operations,
            apiToken: this.apiToken,
            plantId: this.plantId,
            plantName: this.plantName,
            railroadGroups: this.railroadGroups,
            settings: this.settings,
            otherPlants: this.otherPlants,
          }

          this.$store.dispatch('loadInitialState', initialStoreState)
        })
        .catch((error) => console.error('error loading data', error))
    },
  },

  mounted() {
    this.$nextTick().then(() => {
      if (this.showModal) {
        this.$store.dispatch('showPublishingModal', this.carList)
      }

      //   this.carListSelectedId = this.carListId()
      //   $(document).off('change', '#operation_addonable_car_list_id')
      //   $(document).on('change', '#operation_addonable_car_list_id',
      //     this.handleFromRailroadChange);
    })

    this.loadData()
  },

  beforeDestroy() {
    // $(document).off('change', '#operation_addonable_car_list_id')
  },
}
</script>

<style lang="sass">
  .overflow-hidden
    overflow: hidden
</style>
