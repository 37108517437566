<template lang="pug">
.request-optimization-table
  .table-container-scrollable
    table.ui.compact.table.unstackable.max-content
      thead
        th: input#all-defaults-to-optimize-toggler(
          type="checkbox",
          :checked="areAllDefaultsToOptimizeChecked",
          @change="toggleAllDefaultsToOptimize()"
        )
        th Traccia
        th Orario
        th A/P
        th Terminal
        th Luogo
        th Giorni di Circolazione
        th.column--day(v-for="day in days") {{ $t(day) }}
      tbody
        tr(v-for="(operationsPlan, index) in operationsPlansData")
          td: input(
            type="checkbox",
            :id="operationsPlan.id",
            :value="operationsPlan.id",
            :checked="areDefaultsToOptimizeChecked(operationsPlan)",
            @change="toggleDefaultsToOptimize(operationsPlan)"
          )
          td {{ operationsPlan.train }}
          td {{ formatPeriod(operationsPlan.programmed_hour) }}
          td {{ operationsPlan.typology }}
          td {{ operationsPlan.terminal }}
          td {{ operationsPlan.station }}
          td: ActiveWeekDays(:optimized-time="operationsPlan")
          td.column--day(v-for="day in days")
            input(
              type="checkbox",
              :id="`${operationsPlan.id}_${day}`",
              :checked="isOperationsPlanToBeOptimized(operationsPlan, day)",
              @change="toggleOperationsPlanToOptimize({ operationsPlan, day })"
            )
      tfoot.full-width
  Pagination(@pagination:page:changed="handlePageChanged")
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { DAYS } from '../modules/calendar_constants'
import { formatPeriod } from './utils'
import ActiveWeekDays from'./ActiveWeekDays.vue'
import Pagination from'../pagination/Pagination.vue'

export default {
  data() {
    return {
      days: DAYS,
    }
  },
  components: {
    ActiveWeekDays,
    Pagination,
  },
  computed: {
    ...mapState('operationsPlansOptimizer', [
      'operationsPlansData',
    ]),
    ...mapGetters('operationsPlansOptimizer', [
      'isOperationsPlanToBeOptimized',
      'areDefaultsToOptimizeChecked',
      'areAllDefaultsToOptimizeChecked',
    ]),
  },
  created() {
    this.setOperationsPlansToOptimizeArrays()
  },
  methods: {
    ...mapActions('operationsPlansOptimizer', [
      'setOperationsPlansToOptimizeArrays',
      'toggleOperationsPlanToOptimize',
      'toggleDefaultsToOptimize',
      'toggleAllDefaultsToOptimize',
      'callIndex',
    ]),
    formatPeriod,
    async handlePageChanged() {
      await this.callIndex()
    },
  },
}
</script>

<style lang="sass" scoped>
.request-optimization-table
  .max-content
    width: max-content !important

  .ui.table thead .column--day,
  .ui.table tbody .column--day
    min-width: 84px
    text-align: center

  .table-container-scrollable
    max-height: 79vh
</style>
