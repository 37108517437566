<template lang='pug'>
  modal(name='car-list-publish-modal' class='vue-modal' width='900px' height='auto' v-bind:adaptive='true' v-on:closed='onClosed')
    .ui.modal.visible.active.car-list-publish-modal(style="overflow: auto; max-height: 100vh;")
      div(slot='top-right')
        i.close.icon(v-on:click='closeModal')
      .header
        | {{ $t('publish_modal.title') }}
        | "{{ carList.name }}"
      .content
        FormFields(
          v-bind:formLoading='formLoading'
          v-bind:railroads='railroads'
          v-bind:railroad='railroad'
          v-bind:carList='carList'
          v-bind:stations='stations'
          v-bind:sourceStationName='sourceStationName'
          v-bind:operationTitle='operationTitle'
          v-bind:operationId='operationId'
          v-bind:operationDoneOn='operationDoneOn'
          v-bind:factualArrivalHour='factualArrivalHour'
          v-model='publishFields'
        )

      .actions
        .ui.cancel.button(v-on:click='closeModal')
          | {{ $t('cancel') }}

        button.ui.red.button(v-if='canConfirm' v-on:click='handleConfirm')
          i.check.icon
          | &nbsp;{{ confirmLabel }}

        button.ui.red.button(v-if='canPublish' v-on:click='handleSubmit')
          i.check.icon
          | &nbsp;{{ submitLabel }}

        button.ui.button.disabled(v-if='cannotPublish')
          i.check.icon
          | &nbsp;{{ submitLabel }}
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import vSelect from 'vue-select'
import moment from 'moment'
import FormFields from './car-list-publish-form-fields'

export default {
  components: {
    DatePicker,
    vSelect,
    FormFields,
  },

  props: {
    railroad: Object,
    railroads: Array,
    stations: Array,
    sourceStationName: String,
    operationTitle: String,
    operationId: Number,
    operationDoneOn: String,
    factualArrivalHour: String,
  },

  data() {
    return {
      publishFields: {
        publishDate: null,
        currentRailroad: null,
        insertFrom: null,
        rename: false,
        internalCurrentOperation: null,
      },

      formLoading: false,
      showConfirmMessage: false,
    }
  },

  computed: {
    ...mapState({
      carList: 'publishingCarList',
      operations: 'operations',
      settings: 'settings',
    }),
    internalCurrentOperation() {
      return this.publishFields.internalCurrentOperation
    },
    insertFrom() {
      return this.publishFields.insertFrom
    },
    rename() {
      return this.publishFields.rename
    },
    publishDate() {
      return this.publishFields.publishDate
    },
    currentRailroad() {
      return this.publishFields.currentRailroad
    },
    currentOperation: {
      get() {
        return this.internalCurrentOperation
      },
      set(value) {
        if (value && value.typology === 'arrival' && value.factual_arrives_at) {
          const values = value.factual_arrives_at.split('T')[1].split(':')
          this.publishDate.setHours(Number(values[0]))
          this.publishDate.setMinutes((values[1]))
          this.publishDate = new Date(this.publishDate)
        }
        this.internalCurrentOperation = value
      },
    },
    displayConfirmMessage() {
      if (this.formLoading) { return false }

      return this.showConfirmMessage
    },
    confirmationRequired() {
      if (!this.currentOperation) { return false }
      if (!this.currentOperation.railway_company_id) { return false }

      let result = false
      this.carList.cars.forEach((car) => {
        if (car.railway_company_id && car.railway_company_id !=
          this.currentOperation.railway_company_id) {
          result = true
        }
      })

      return result
    },
    submitLabel() {
      return this.$t('publish_modal.submit')
    },
    confirmLabel() {
      return this.$t('publish_modal.confirm')
    },
    cssFormClass() {
      if (this.formLoading) {
        return 'loading car-list-publish-form'
      }

      if (this.showConfirmMessage) { return 'hide' }

      return 'car-list-publish-form'
    },
    canConfirm() {
      if (this.formLoading) { return false }

      return this.showConfirmMessage
    },
    publishConditions() {
      return this.publishDate && this.currentRailroad && this.insertFrom
    },
    operationConditions() {
      if (!this.settings.mandatory_operation_for_manoeuvres) { return true }

      return !!this.currentOperation
    },
    canPublish() {
      if (this.formLoading) { return false }
      if (this.showConfirmMessage) { return false }

      return (
        this.carList.publish_preconditions
        && this.publishConditions
        && this.operationConditions
      )
    },
    cannotPublish() {
      if (this.formLoading) { return true }
      if (this.showConfirmMessage) { return false }

      return !this.canPublish
    },
    railroadId() {
      return this.currentRailroad && this.currentRailroad.id
    },
    datePickerLocale() {
      return this[this.$i18n.locale]
    },
    insertFromOptions() {
      const options = []

      if (!this.currentRailroad) { return options }
      if (this.currentRailroad.insert_from !== 'left') {
        options.push({
          value: 'right',
          label: this.currentRailroad.insert_from_right_label,
        })
      }
      if (this.currentRailroad.insert_from !== 'right') {
        options.push({
          value: 'left',
          label: this.currentRailroad.insert_from_left_label,
        })
      }

      return options
    },
  },

  mounted() {
    this.$nextTick().then(() => {
      this.$modal.show('car-list-publish-modal')
      // this.currentRailroad = this.railroad
      // this.publishDate = new Date()
      // this.operationsDate = new Date()
      // this.resetInsertFrom()
    })
  },

  methods: {
    closeModal() {
      this.$modal.hide('car-list-publish-modal')
    },
    onClosed() {
      this.$emit('closed')
      this.$store.dispatch('hidePublishingModal')
    },
    performSubmit() {
      this.formLoading = true
      const currentOperationId = this.currentOperation
        ? this.currentOperation.id
        : null

      const data = {
        railroad_id: this.currentRailroad.id,
        insert_from: this.insertFrom.value,
        rename: this.rename,
        publish_date: moment(this.publishDate).format('YYYY-MM-DD HH:mm'),
        operation_id: currentOperationId,
      }
      var self = this
      $.ajax({
        url: `/car-lists/${this.carList.id}/publish.js`,
        data: { car_list: data, _method: 'patch' },
        dataType: 'script',
        method: 'post',
      }).always(() => {
        self.closeModal()
      })
    },
    handleConfirm(e) {
      e.preventDefault()

      this.performSubmit()
    },
    handleSubmit(e) {
      e.preventDefault()

      if (this.confirmationRequired) {
        this.showConfirmMessage = true
      } else {
        this.performSubmit()
      }
    },
  },
}
</script>
