import _ from 'underscore'

// Sort and decorate car lists
export const sortCarLists = (carLists) => {
  let sortedCarLists = _.sortBy(carLists, (carList) => (carList.index))

  sortedCarLists.map((carList, index, array) => {
    if (array.length === 1) { carList.position = 'unique' }
    else if (index === 0) { carList.position = 'first' }
    else if (index === array.length - 1) { carList.position = 'last' }

    if (index !== 0) {
      carList.prevCarList = Object.assign({}, array[index - 1] || {}, { nextCarList: undefined, prevCarList: undefined })
      carList.prevCarListUuid = array[index - 1].uuid
    }

    if (index !== sortedCarLists.length - 1) {
      carList.nextCarList = Object.assign({}, array[index + 1] || {}, { nextCarList: undefined, prevCarList: undefined })
      carList.nextCarListUuid = array[index + 1].uuid
    }

    return carList
  })

  return sortedCarLists
}

export function findSegment(carLists, carId, carListId) {
  const carList = carLists.find(cl => cl.id === carListId).cars

  if (carId === 'START') {
    const firstLockedIndex = carList.findIndex(car => car.locked)
    return { start: 0, end: firstLockedIndex >= 0 ? firstLockedIndex - 1 : carList.length - 1 }
  }
  let lockedIndexes = carList.map((car, index) => car.locked ? index : -1).filter(index => index !== -1)
  let carIndex = carList.findIndex(car => car.id == carId)

  let end = carList.length - 1
  for (let i = lockedIndexes.length - 1; i >= 0; i--) {
    if (carIndex < lockedIndexes[i]) {
      end = lockedIndexes[i] - 1
    } else {
      break
    }
  }

  // knowing the end and the locked indexes you can find the start indexes
  let start = 0
  for (let lockedIndex of lockedIndexes) {
    if (lockedIndex <= end && (end - lockedIndex) < (end - start)) {
      start = lockedIndex + 1
    }
  }

  return { start, end }
}

