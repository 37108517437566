<template lang='pug'>
  vSelect(
    class='dropdown-wrapper operation-select__select'
    v-bind:options='operationTypes'
    v-model='currentOperationType'
    v-bind:value='currentOperationType'
  )
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import vSelect from 'vue-select'


export default {
  components: { vSelect },

  computed: {
    operationTypes: {
      get() {
        return [
          {label: this.$t('typology.type_0'), code: 0}, // 0 is arrival
          {label: this.$t('typology.type_1'), code: 1},  // 1 is departure
        ]
      },
    },
    currentOperationType: {
      get() {
        if(this.$store.state.currentOperationType || this.$store.state.currentOperationType === 0){
          return this.$t(`typology.type_${this.$store.state.currentOperationType}`)}
        else
          return null
      },
      set(value) {
        if(value)
          this.$store.commit('updateOperationType', value['code'])
        else
          this.$store.commit('updateOperationType', null)
        this.$store.commit('unloadOperation', null)
      },
    },
  },
}
</script>

<style lang='sass'>
  .operation-select-wrapper
    display: inline-block

  .operation-select__select
    display: inline-block
    width: 200px

    .vs__selected
      position: relative
      font-weight: bold
      font-size: 15px

      &::after
        content: ''
        width: 30px
        height: 22px
        top: 0
        right: 0
        position: absolute
        background: linear-gradient(to right, rgba(255, 255, 255, 0), white)

    .vs__selected
      width: 330px
      white-space: nowrap
      overflow: hidden

</style>