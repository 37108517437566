<template lang='pug'>
  modal(name='hazard-modal' class='vue-modal' width='900px' height='auto' v-bind:adaptive='true' v-on:closed='onClosed')
    .ui.modal.visible.active.hazard-modal(style="overflow: auto; max-height: 100vh;")
      div(slot='top-right')
        i.close.icon(v-on:click='closeModal')

      .header
        | {{ $t('hazard_modal.title') }}

      .content
        table.ui.celled.table
          thead
            tr
              th {{ $t('hazard_modal.message_title') }}
              th {{ $t('hazard_modal.created_at') }}
          tbody
            tr(v-for='hazardMessage in hazardMessagesArray')
              td
                | {{ $t('hazard_modal.message', {ctr_number: hazardMessage.ctr_number, status: hazardMessage.status, car: hazardMessage.car, station: hazardMessage.station}) }}
              td
                | {{ formatDate(hazardMessage.created_at) }}

</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import _ from 'lodash'

export default {

  data() {
    return {
      hazardMessagesArray: this.hazardMessages,
    }
  },

  props: {
    hazardMessages: Array,
  },

  computed: {
    ...mapState({
      plantId: 'plantId',
    }),
  },

  created() {
    moment.locale(this.$i18n.locale)
  },

  mounted() {
    this.$modal.show('hazard-modal')
  },

  methods: {
    formatDate(date) {
      return moment(String(date)).format('MM/DD/YYYY hh:mm')
    },
    closeModal() {
      this.$modal.hide('hazard-modal')
    },
    onClosed() {
      this.$emit('closed')
      this.$store.dispatch('hideHazardModal')
    },
  },
}
</script>
