<template lang="pug">
.ui.active.inverted.dimmer
  .ui.loader
</template>

<script>
export default {
  name: 'TrainsTimelineLoader',
}
</script>

<style>
</style>
