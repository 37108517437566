<template lang='pug'>
  modal(name='railroad-car-lists-merge-modal' class='vue-modal' width='900px' height='auto' v-bind:adaptive='true' v-on:closed='onClosed')
    .ui.modal.visible.active.railroad-car-lists-merge-modal(style="overflow: auto; max-height: 100vh;")
      div(slot='top-right')
        i.close.icon(v-on:click='closeModal')

      .header
        | {{ $t('railroad_car_lists_merge_modal.title') }}
        | &nbsp;
        small
          | "{{ railroad.name }}"

      .content
        .ui.red.message.text-center(v-if='anyClampedCarLists')
          strong
            | {{ $t('railroad_car_lists_merge_modal.clamped_car_lists') }}

        .car-lists.text-center
          div.car-list-item(v-for='carList in carLists')
            CarList(
              v-bind:key='carList.uuid'
              v-bind:carList='carList'
              v-bind:railroadId='railroad.id'
              v-bind:railroad='railroad'
              disabledActions=true
            )
            .car-list-separator
              i.icon.plus

        RailroadMergeFields(
          v-bind:railroad='railroad'
          v-model='mergeFields'
          v-if='canMerge'
        )

      .actions
        .ui.cancel.button(v-on:click='closeModal')
          | {{ $t('cancel') }}

        .ui.button.positive(v-if='canSubmit' v-on:click='merge')
          | {{ $t('merge_modal.submit') }}
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import _ from 'lodash'

import CarList from '../car-list'
import Car from '../car'
import RailroadMergeFields from './railroad-merge-fields'

export default {
  components: {
    CarList,
    Car,
    vSelect,
    RailroadMergeFields,
  },

  data() {
    return {
      mergeFields: {
        code: null,
        train: null,
      },
    }
  },

  computed: {
    ...mapState({
      railroad: 'bulkRailroadCarListsMerge',
    }),
    ...mapGetters([
      'manipulationDisabled',
    ]),
    carLists() {
      return this.railroad.car_lists
    },
    code() {
      return this.mergeFields.code
    },
    currentTrain() {
      return this.mergeFields.train
    },
    trainId() {
      return this.currentTrain ? this.currentTrain.id : undefined
    },
    canMerge() {
      if (this.anyClampedCarLists) { return false }

      return true
    },
    canSubmit() {
      return this.canMerge
    },
    railroadId() {
      return this.railroad.id
    },
    anyClampedCarLists() {
      return _.includes(this.carLists.map(cl => cl.clamps), true)
    },
  },

  mounted() {
    this.$modal.show('railroad-car-lists-merge-modal')
  },

  methods: {
    closeModal() {
      this.$modal.hide('railroad-car-lists-merge-modal')
    },
    onClosed() {
      this.$emit('closed')
      this.$store.dispatch('hideRailroadCarListsMergeModal')
    },
    merge() {
      this.$store.dispatch('railroadCarListsMerge', {
        railroadId: this.railroadId,
        carListCode: this.code,
        trainId: this.trainId,
        beforeDeselection: () => { this.$emit('closed') },
      })
    },
  },
}
</script>
