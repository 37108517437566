<template lang="pug">
.timeline-times
  PureTimelineTime(
    v-for="(time, index) in times",
    :key="index",
    :time="time",
    :showMinutes="$dayjs(time).minute() != 0",
    :width="columnWidth"
  )
</template>

<script>
import PureTimelineTime from '@/trains-timeline/PureTimelineTime.vue'
import { MINUTES_IN_A_DAY, MINUTES_IN_AN_HOUR } from '@/helpers/constants'
import { roundToAllowedValues } from '@/helpers/functions'

export default {
  name: 'PureTimelineTimes',
  components: {
    PureTimelineTime,
  },
  props: {
    granularityInMinutes: {
      type: Number,
      default: 15,
    },
    startFromHour: {
      type: Number,
      default: 0,
    },
    columnWidth: {
      type: Number,
      default: 40,
    },
    allowedGranularitiesInMinutes: {
      type: Array,
      default: () => [1, 5, 10, 15, 20, 30, 60],
    },
  },
  computed: {
    roundedGranularityInMinutes() {
      return roundToAllowedValues(this.allowedGranularitiesInMinutes, this.granularityInMinutes)
    },
    count() {
      return Math.ceil(MINUTES_IN_A_DAY / (this.roundedGranularityInMinutes > 0 ? this.roundedGranularityInMinutes : 1))
    },
    offsetInMinutes() {
      return Math.floor(this.startFromHour) * MINUTES_IN_AN_HOUR
    },
    times() {
      return Array.from({length: this.count}, (_, index) => {
        return this.$dayjs()
          .startOf('day')
          .add(this.offsetInMinutes + index * this.roundedGranularityInMinutes, 'minute')
          .toDate()
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.timeline-times
  display: flex
  align-items: center
  flex: 1 0 auto
  width: 100%
</style>
