<template lang='pug'>
  modal(name='car-list-upload-modal' class='vue-modal' width='900px' height='auto' v-bind:adaptive='true' v-on:closed='onClosed')
    .ui.modal.visible.active.car-list-upload-modal(style="overflow: auto; max-height: 100vh;")
      div(slot='top-right')
        i.close.icon(v-on:click='closeModal')
      .header
        | {{ $t('upload_modal.title') }}
        | "{{ carList.name }}"

      .content
        vue-dropzone(ref="myVueDropzone"
                     id="dropzone"
                     :options="dropzoneOptions"
                     @vdropzone-file-added="handleFileAdded"
                     @vdropzone-complete="handleFileCompleted"
                     useCustomSlot=true)
          .dropzone-custom-content
            h3.uploadModal__text {{ uploadText }}

      .actions
        .ui.cancel.button(v-on:click='closeModal')
          | {{ $t('cancel') }}

        .ui.cancel.button.green.disabled.loading(v-if='isUploading')
          | {{ $t('confirm') }}

        .ui.cancel.button.green(v-on:click='closeModal' v-else)
          | {{ $t('confirm') }}
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },

  props: {
  },

  data() {
    return {
      pendingFilesCounter: 0,
    }
  },

  computed: {
    ...mapState({
      carList: 'uploadingCarList',
    }),
    dropzoneOptions() {
      return {
        url: `/car-lists/${this.carList.id}/assets.json`,
        thumbnailWidth: 200,
        maxFilesize: 10,
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
      }
    },
    uploadText() {
      return this.$t('upload_modal.text')
    },
    cssFormClass() {
      if (this.formLoading) {
        return 'loading car-list-upload-form'
      }

      return 'car-list-upload-form'
    },
    canUpload() {
      return true
    },
    cannotUpload() {
      return !this.canUpload
    },
    isUploading() {
      return this.pendingFilesCounter > 0
    },
  },

  mounted() {
    this.$nextTick().then(() => {
      this.$modal.show('car-list-upload-modal')
    })
  },

  methods: {
    closeModal() {
      this.$modal.hide('car-list-upload-modal')
    },
    onClosed() {
      this.$emit('closed')
      this.$store.dispatch('hideUploadingModal')
      window.location.href = window.location.href
    },
    handleFileAdded(file) {
      console.log('handleFileAdded', file)
      this.pendingFilesCounter += 1
    },
    handleFileCompleted(response) {
      console.log('handleFileCompleted', response)
      this.pendingFilesCounter -= 1
    },
  },
}
</script>
