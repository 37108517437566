import {
  SET_COMPANY_ID,
  SET_OPERATION_ADDONABLES_DATA,
  SET_CURRENT_SORT,
  SET_DATE_RANGE,
  REMOVE_ADDONABLE_TO_INVOICE,
  ADD_ADDONABLE_TO_INVOICE,
  SET_ADDONABLEs_TO_INVOICE,
  REMOVE_MULTIPLE_ADDONABLE_TO_INVOICE,
  ADD_MULTIPLE_ADDONABLE_TO_INVOICE,
  SET_CURRENT_PAGE_ADDONABLE_IDS,
  SET_LAST_GENERATED_INVOICE,
  UNSET_LAST_GENERATED_INVOICE,
} from './mutation_types'

import {
  SET_PAGINATION_INFO,
  ENABLE_PAGINATION_LINKS,
} from '../../pagination/store/mutation_types'

const state = {
  operationAddonablesData: [],
  fromDate: null,
  toDate: null,
  companyId: null,
  currentSort: null,
  currentSortDirection: 'desc',
  currentPageAddonableIds: [],
  addonablesToInvoice: [],
  lastGeneratedInvoice: null,
}


const getters = {
  addonablesToInvoice: (state) => {
    return state.addonablesToInvoice.length
  },
  areAllAddonablesToInvoiceChecked: (state) => {
    return state.currentPageAddonableIds.every(id => state.addonablesToInvoice.includes(id))
  },
}
const actions = {
  callIndex ({ state, commit, rootState}, { page, sort, direction }) {
    return this.$http.get(
      '/invoice-addon/operations',
      {
        params: {
          from_date: state.fromDate,
          to_date: state.toDate,
          company_id: state.companyId,
          page: page || rootState.pagination.currentPage,
          sort: sort && sort.split(' ').map(k => `${k} ${direction || 'desc'}`),
        },
      }).then(response => {
      commit(SET_OPERATION_ADDONABLES_DATA, response.data.operation_addonables)
      const responseSortKeys = response.data.sort.map(x => x.split(' ')[0]).join(' ')
      const responseSortDirection = response.data.sort[0].split(' ')[1]
      commit(SET_CURRENT_SORT, { sort: responseSortKeys, direction: responseSortDirection})
      commit(`pagination/${SET_PAGINATION_INFO}`, response.data.pagination, { root: true })
      commit(`pagination/${ENABLE_PAGINATION_LINKS}`, null, { root: true })
    })
  },
  callGenerateInvoice({ commit, dispatch }, { addonableIds, onSuccess, onFailure }) {
    commit(UNSET_LAST_GENERATED_INVOICE)
    this.$http.post('invoice-addon/generate_invoice', {
      addonable_ids: addonableIds.map((id) => {return {id: id}}),
      from_date: state.fromDate,
      to_date: state.toDate,
      company_id: state.companyId,
    }).then((response) => {
      const responseData = response.data
      if (responseData.status === 'ok') {
        commit(SET_LAST_GENERATED_INVOICE, {lastGeneratedInvoiceLink: responseData.invoice_link, lastGeneratedInvoiceNumber: responseData.invoice.number})
        onSuccess(responseData.invoice.number)
        dispatch('callIndex', {})
      } else {
        onFailure(responseData.errors)
      }
    }).catch(err => {
      console.log(err)
      onFailure(err)
    })
  },

  setCompanyId({ commit }, { companyId }) {
    commit(SET_COMPANY_ID, { companyId })
  },
  setDateRange ({ commit }, { fromDate, toDate }) {
    commit(SET_DATE_RANGE, { fromDate, toDate })
  },
  setCurrentSort ({ commit }, sortAndDirection) {
    commit(SET_CURRENT_SORT, sortAndDirection)
  },
  toggleAddonableToInvoice ({ state, commit }, addonable) {
    if (state.addonablesToInvoice.includes(addonable.id)) {
      commit(REMOVE_ADDONABLE_TO_INVOICE, addonable)
    } else {
      commit(ADD_ADDONABLE_TO_INVOICE, addonable.id)
    }
  },
  toggleAllAddonablesToInvoice ({ state, getters, commit }) {
    commit(SET_ADDONABLEs_TO_INVOICE, state.addonablesToInvoice || [])
    if (getters.areAllAddonablesToInvoiceChecked) {
      commit(REMOVE_MULTIPLE_ADDONABLE_TO_INVOICE, state.currentPageAddonableIds)
    } else {
      commit(ADD_MULTIPLE_ADDONABLE_TO_INVOICE, state.currentPageAddonableIds)
    }
  },
}

const mutations = {
  [SET_OPERATION_ADDONABLES_DATA] (state, operationAddonablesData) {
    state.operationAddonablesData = operationAddonablesData
  },
  [SET_DATE_RANGE] (state, { fromDate, toDate }) {
    state.fromDate = fromDate
    state.toDate = toDate
  },
  [SET_COMPANY_ID] (state, { companyId }) {
    state.companyId = companyId
  },
  [SET_CURRENT_SORT] (state, {sort, direction}) {
    state.currentSort = sort
    state.currentSortDirection = direction
  },
  [SET_CURRENT_PAGE_ADDONABLE_IDS] (state, operationsData) {
    state.currentPageAddonableIds = operationsData.map(o => o.id)
  },
  [SET_LAST_GENERATED_INVOICE] (state, {lastGeneratedInvoiceLink, lastGeneratedInvoiceNumber}) {
    state.lastGeneratedInvoice = {
      link: lastGeneratedInvoiceLink,
      number: lastGeneratedInvoiceNumber,
    }
  },
  [UNSET_LAST_GENERATED_INVOICE] (state) {
    state.lastGeneratedInvoice = null
  },
}

export const invoiceAddonModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
