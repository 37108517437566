<template lang='pug'>
.operation-select-wrapper(v-if='canBeShowed')
    div(v-if='isOperationsDropdownShown' class='select-container')
      SelectOperationType
      vSelect(
        class='dropdown-wrapper operation-select__select'
        v-bind:options='filteredOperations'
        v-model='currentOperation'
        v-bind:value='currentOperation'
        v-bind:placeholder='selectOperationsPlaceholder'
      )
      button.ui.button.primary.small(v-on:click='handleDeselectOperation').wider-button
        i.sign.out.icon
        | {{ $t('deselect_operations') }}
      OperationActions
    div(v-else)
      button.ui.button.primary.small(v-on:click='handleOperationsDropdownShow')
        i.sign.in.icon
        | {{ $t('select_operations') }}

</template>
<script>
import SelectOperationType from './select-operation-type'

import { mapState, mapActions, mapGetters } from 'vuex'
import OperationActions from './operation-actions'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    OperationActions,
    SelectOperationType,
  },

  computed: {
    ...mapState([
      'operations',
      'currentUser',
      'tenant',
    ]),
    ...mapGetters([
      'isOperationSelected',
      'isOperationsDropdownShown',
    ]),
    currentOperation: {
      get() {
        return this.$store.state.currentOperation
      },
      set(value) {
        if (value) {
          $('#addonable-collection-button').prop('disabled', false)
          this.$store.dispatch('selectOperation', value)
        } else {
          $('#addonable-collection-button').prop('disabled', true)
          this.$store.dispatch('deselectOperation')
        }
      },
    },
    currentOperationType: {
      get() {
        return this.$store.state.currentOperationType
      },
    },
    canBeShowed() {
      return this.hasOperations && (this.tenant != 'adriafer' || !this.currentUser.external)
    },
    hasOperations() {
      return this.operations !== undefined && this.operations.length > 0
    },
    selectOperationsPlaceholder() {
      return this.$t('select_operations')
    },
    filteredOperations() {
      if(this.currentOperationType === null) {
        return this.operations
      } else {
        var typology = ['arrival', 'departure'][this.currentOperationType]
        return this.operations.filter(op => op.typology === typology)
      }
    },
  },

  methods: {
    ...mapActions([
      'selectFirstOperation',
      'deselectOperation',
    ]),
    handleOperationsDropdownShow() {
      $('#addonable-collection-button').prop('disabled', false)
      this.$store.dispatch('showOperationsDropdown')
    },
    // handleSelectFirstOperation() {
    //   $('#addonable-collection-button').prop('disabled', false)
    //   this.$store.dispatch('selectFirstOperation')
    // },
    handleDeselectOperation() {
      $('#addonable-collection-button').prop('disabled', true)
      this.$store.dispatch('deselectOperation')
    },
  },
}
</script>
<style lang='sass'>
  .wider-button
    width: max-content

  .operation-select-wrapper
    display: inline-block

  .select-container
    display: flex
    justify-content: space-between
    gap: 20px
    z-index: 1000
    position: relative

  .operation-select__select
    display: inline-block
    width: 400px
    margin-bottom: 5px

    .vs__selected
      position: relative
      font-weight: bold
      font-size: 15px

      &::after
        content: ''
        width: 30px
        height: 22px
        top: 0
        right: 0
        position: absolute
        background: linear-gradient(to right, rgba(255, 255, 255, 0), white)

    .vs__selected
      width: 330px
      white-space: nowrap
      overflow: hidden

</style>

