import Vue from 'vue'

import {
  SET_OPERATIONS_PLANS_DATA,
  SET_CURRENT_PAGE_OPERATIONS_PLAN_IDS,

  ADD_OPERATIONS_PLAN_TO_OPTIMIZE,
  REMOVE_OPERATIONS_PLAN_TO_OPTIMIZE,
  TOGGLE_OPERATIONS_PLAN_TO_OPTIMIZE,

  RESET_OPERATIONS_PLANS_TO_OPTIMIZE,

  SET_OPERATIONS_PLANS_TO_OPTIMIZE_ARRAY,

  SET_PLANT_ID_FILTER,
} from '../mutation_types'

const state = {
  operationsPlansData: [],
  currentPageOperationsPlanIds: [],
  operationsPlansToOptimize: {},
  filters: {
    plantId: null,
  },
}

const getters = {
  operationsPlansToOptimizeCount: (state) => {
    return Object.values(state.operationsPlansToOptimize).reduce((acc, arr) => acc += arr.length, 0)
  },
  operationsPlansToOptimizeRowsCount: (state) => {
    return Object.values(state.operationsPlansToOptimize).filter(arr => arr.length).length
  },

  operationsPlanById: (state) => (operationsPlanId) => {
    return state.operationsPlansData.find(ot => ot.id === operationsPlanId)
  },
  isOperationsPlanToBeOptimized: (state) => (operationsPlan, day) => {
    return state.operationsPlansToOptimize[operationsPlan.id]
      && state.operationsPlansToOptimize[operationsPlan.id].includes(day)
  },

  areDefaultsToOptimizeChecked: (state, getters) => (operationsPlan) => {
    return Object.keys(operationsPlan.circulation_days).every(day => {
      return getters.isOperationsPlanToBeOptimized(operationsPlan, day)
    })
  },

  areAllDefaultsToOptimizeChecked: (state, getters) => {
    return state.currentPageOperationsPlanIds.every(id => {
      const operationsPlan = getters.operationsPlanById(id)
      return getters.areDefaultsToOptimizeChecked(operationsPlan)
    })
  },
}

const actions = {
  setOperationsPlansData ({ commit }, operationsPlansData) {
    commit(SET_OPERATIONS_PLANS_DATA, operationsPlansData)
  },
  setCurrentPageOperationsPlanIds ({ commit }, operationsPlansData) {
    commit(SET_CURRENT_PAGE_OPERATIONS_PLAN_IDS, operationsPlansData)
  },

  toggleOperationsPlanToOptimize ({ getters, commit }, { operationsPlan, day }) {
    const checked = getters.isOperationsPlanToBeOptimized(operationsPlan, day)
    commit(TOGGLE_OPERATIONS_PLAN_TO_OPTIMIZE, { operationsPlan, day, checked })
  },
  toggleDefaultsToOptimize ({ getters, commit }, operationsPlan) {
    const checked = getters.areDefaultsToOptimizeChecked(operationsPlan)
    Object.keys(operationsPlan.circulation_days).forEach(day => {
      commit(TOGGLE_OPERATIONS_PLAN_TO_OPTIMIZE, { operationsPlan, day, checked })
    })
  },
  toggleAllDefaultsToOptimize ({ state, commit, getters }) {
    const checked = getters.areAllDefaultsToOptimizeChecked
    state.currentPageOperationsPlanIds.forEach(operationsPlanId => {
      const operationsPlan = getters.operationsPlanById(operationsPlanId)
      Object.keys(operationsPlan.circulation_days).forEach(day => {
        commit(TOGGLE_OPERATIONS_PLAN_TO_OPTIMIZE, { operationsPlan, day, checked })
      })
    })
  },
  setOperationsPlansToOptimizeArrays ({ state, commit }) {
    state.operationsPlansData.forEach(operationsPlan => {
      if (!state.operationsPlansToOptimize[operationsPlan.id]) {
        commit(SET_OPERATIONS_PLANS_TO_OPTIMIZE_ARRAY, { operationsPlan, arr: [] })
      }
    })
  },
  resetOperationsPlansToOptimizeArrays ({ commit, dispatch }) {
    commit(RESET_OPERATIONS_PLANS_TO_OPTIMIZE)
    dispatch('setOperationsPlansToOptimizeArrays')
  },

  setPlantIdFilter ({ commit }, plantId) {
    commit(SET_PLANT_ID_FILTER, plantId)
  },
}

const mutations = {
  [SET_OPERATIONS_PLANS_DATA] (state, operationsPlansData) {
    state.operationsPlansData = operationsPlansData
  },
  [SET_CURRENT_PAGE_OPERATIONS_PLAN_IDS] (state, operationsPlansData) {
    state.currentPageOperationsPlanIds = operationsPlansData.map(ot => ot.id)
  },

  [ADD_OPERATIONS_PLAN_TO_OPTIMIZE] (state, { operationsPlan, day }) {
    state.operationsPlansToOptimize[operationsPlan.id].push(day)
  },
  [REMOVE_OPERATIONS_PLAN_TO_OPTIMIZE] (state, { operationsPlan, day }) {
    state.operationsPlansToOptimize[operationsPlan.id] = state.operationsPlansToOptimize[operationsPlan.id].filter(d => d !== day)
  },
  [TOGGLE_OPERATIONS_PLAN_TO_OPTIMIZE] (state, { operationsPlan, day, checked }) {
    checked
      ? this.commit(`operationsPlansOptimizer/${REMOVE_OPERATIONS_PLAN_TO_OPTIMIZE}`, { operationsPlan, day })
      : this.commit(`operationsPlansOptimizer/${ADD_OPERATIONS_PLAN_TO_OPTIMIZE}`, { operationsPlan, day })
  },

  [RESET_OPERATIONS_PLANS_TO_OPTIMIZE] (state) {
    state.operationsPlansToOptimize = {}
  },

  [SET_OPERATIONS_PLANS_TO_OPTIMIZE_ARRAY] (state, { operationsPlan, arr = [] }) {
    Vue.set(state.operationsPlansToOptimize, [operationsPlan.id], arr)
  },

  [SET_PLANT_ID_FILTER] (state, plantId) {
    Vue.set(state.filters, 'plantId', plantId)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
