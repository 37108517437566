<template lang='pug'>
  div.addonable-renderer
    CollectionForm
    EditForm
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CollectionForm from './collection-form'
import EditForm from './edit-form'

export default {
  components: {
    CollectionForm,
    EditForm,
  },
  computed: {
    ...mapGetters([
      'isAddonableSelected',
    ]),
    ...mapState([
      'currentAddonable',
      'currentOperation',
    ]),
    ...mapState({
      addonablesFormShown: 'addonablesFormShown',
    }),
  },
  methods: {
    renderForms() {
      const operation = this.currentOperation
      const addonable = this.currentAddonable

      if (operation) {
        this.$store.dispatch('loadAddonablesCollectionForm', operation)

        if (addonable) {
          this.$store.dispatch('loadAddonablesForm', {
            operationId: operation.id,
            addonableId: addonable.id,
          })
        }
      }
    },
  },

  mounted() {
    this.$nextTick().then(() => {
      this.renderForms()
    })
  },
}
</script>

