<template lang="pug">
.month-and-week {{ formattedWeek }}
</template>

<script>
// import DatePicker from 'vue2-datepicker'

export default {
  name: 'PureWeekSelector',
  props: {
    date: {
      type: [Date, Object, String],
      default: () => new Date(),
    },
  },
  computed: {
    month() {
      const month = this.$dayjs(this.date).format('MMMM')
      return month.charAt(0).toUpperCase() + month.slice(1)
    },
    yearAndWeek() {
      return this.$dayjs(this.date).format('YYYY - [w]w')
    },
    formattedWeek() {
      return `${this.month} ${this.yearAndWeek}`
    },
  },
}
</script>

<style lang="sass" scoped>
.month-and-week
  font-size: 18px
  font-weight: bold
  text-align: center
</style>
