<template lang="pug">
.train-timeline-body(v-if="railroadGroups", :style="cssVars")
  .body-timeline
    .corner
      PureRowHeader(:width="rowHeaderWidth")
    .timeline-header-wrapper(
      ref="timelineHeaderWrapper",
      @scroll.passive="handleScrollTimelineHeaderScroll($event)"
    )
      HeaderTimeline(
        :height="secondRowHeight",
        :width="timelineWidth",
        :shiftsDurations="shiftsDurations"
        :shiftsOffset="shiftsOffset"
      )
  .body-content(:key="Number(currentDate)")
    .header-rows(
      ref="headerRows",
      @scroll.passive="handleScrollHeaderRowsScroll($event)"
    )
      TrainsTimelineRowHeaderGroup(
        v-for="railroadGroup in railroadGroups",
        :key="`${railroadGroup.id}-header`",
        :width="rowHeaderWidth",
        :railroadGroup="getRailroadGroupById(railroadGroup.id)"
      )
    .content-rows(
      ref="contentRows",
      @scroll.passive="handleScrollContentRowsScroll($event)"
    )
      TrainsTimelineRowGroup(
        v-for="railroadGroup in railroadGroups",
        :key="railroadGroup.id",
        :width="timelineWidth",
        :railroadGroup="getRailroadGroupById(railroadGroup.id)"
        :showOptimizationMethod="showOptimizationMethod"
      )
</template>

<script>
import PureRowHeader from './PureRowHeader.vue'
import HeaderTimeline from './HeaderTimeline.vue'
import TrainsTimelineRowGroup from './TrainsTimelineRowGroup.vue'
import TrainsTimelineRowHeaderGroup from './TrainsTimelineRowHeaderGroup.vue'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'TrainsTimelineBody',
  components: {
    PureRowHeader,
    HeaderTimeline,
    TrainsTimelineRowGroup,
    TrainsTimelineRowHeaderGroup,
  },
  props:{
    railroadGroups: {
      type: Array,
      default: () => [],
    },
    secondRowHeight: {
      type: Number,
      default: 50,
    },
    rowHeaderWidth: {
      type: Number,
      default: 200,
    },
    shiftsOffset: {
      type: Number,
      default: 0,
    },
    shiftsDurations: {
      type: Array,
      default: () => [6, 6, 6, 6],
    },
    showOptimizationMethod: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scrollLeft: 0,
      scrollTop: 0,
    }
  },
  computed: {
    ...mapState('trainsTimeline', [
      'currentDate',
    ]),
    ...mapGetters('trainsTimeline', [
      'timelineWidth',
      'getRailroadGroupById',
    ]),
    cssVars() {
      return {
        '--row-header-width': `${this.rowHeaderWidth}px`,
        '--second-row-height': `${this.secondRowHeight}px`,
      }
    },
  },
  methods: {
    handleScrollTimelineHeaderScroll(event) {
      this.scrollLeft = event?.srcElement?.scrollLeft
    },
    handleScrollHeaderRowsScroll(event) {
      this.scrollTop = event?.srcElement?.scrollTop
    },
    handleScrollContentRowsScroll(event) {
      this.scrollLeft = event?.srcElement?.scrollLeft
      this.scrollTop = event?.srcElement?.scrollTop
    },
  },
  watch: {
    currentDate (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$refs.timelineHeaderWrapper.scrollLeft = 0
        this.$refs.contentRows.scrollLeft = 0
      }
    },
    scrollLeft(newVal) {
      this.$refs.timelineHeaderWrapper.scrollLeft = newVal
      this.$refs.contentRows.scrollLeft = newVal
    },
    scrollTop(newVal) {
      this.$refs.headerRows.scrollTop = newVal
      this.$refs.contentRows.scrollTop = newVal
    },
  },
}
</script>

<style lang="sass" scoped>
.train-timeline-body
  height: 100%
  border: 1px solid #bbb
  overflow: hidden
  border-radius: 6px

  .body-timeline
    display: flex

    .row-header
      border-width: 0px 1px 1px 0px
      height: var(--second-row-height)
      z-index: 1

    .corner
      z-index: 2

    .timeline-header-wrapper
      box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.03)
      z-index: 1
      padding-right: 10px
      overflow-x: auto
      scrollbar-width: none // Firefox
      -ms-overflow-style: none // Internet Explorer 10+

      &::-webkit-scrollbar // WebKit
        width: 0
        height: 0

      .header-timeline
        border-bottom: 1px solid #bbb

  .body-content
    display: flex
    height: calc(100% - var(--second-row-height))
    background: #ddd

    .header-rows
      flex: 1 0 auto
      width: var(--row-header-width)
      border-right: 1px solid #bbb
      background: #eee
      padding-bottom: 110px
      box-shadow: 10px 0px 16px 0px rgba(0, 0, 0, 0.03)
      z-index: 1
      overflow-y: auto
      scrollbar-width: none // Firefox
      -ms-overflow-style: none // Internet Explorer 10+

      &::-webkit-scrollbar // WebKit
        width: 0
        height: 0

    .content-rows
      overflow: auto
      position: relative
      padding-bottom: 100px

  ::v-deep
    .train-timeline-row-header-group:first-child,
    .train-timeline-row-group:first-child
      border-top: 0px solid #bbb

    .train-timeline-row-header-group:last-child,
    .train-timeline-row-group:last-child
      // border-bottom: 1px solid #bbb

    .train-timeline-row-header-group + .train-timeline-row-header-group,
    .train-timeline-row-group + .train-timeline-row-group
      border-top: none
</style>
