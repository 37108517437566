<template lang="pug">
.pairing-table
  .table-container-scrollable
    table.ui.compact.table.unstackable.max-content
      thead
        th
        th {{ $t('train') }}
        th {{ $t('from_to') }}
        th {{ $t('mto') }}
        th {{ $t('if') }}
        th {{ $t('hour') }}
        th {{ $t('date') }}
        th {{ $t('notes') }}
      tbody
        tr(v-if='!operationsData.length')
          td(colspan=8)
            .text-center
              | {{ $t('no_data_found') }}
        tr(v-for="(operation, index) in operationsData")
          td: input(
            type="checkbox",
            :id="operation.id",
            :value="operation.id",
            v-model="checkedOperation",
            @change="clearOtherOperations(operation.id, $event.target.checked)"
          )
          td {{ operation.train }}
          td {{ operation.station }}
          td {{ operation.mto_name }}
          td {{ operation.railway_company_name }}
          td {{ formatProgrammedHour(operation.programmed_hour) }}
          td {{ formatDoneOn(operation.done_on) }}
          td {{ operation.notes }}

      tfoot.full-width
</template>

<script>
import Pagination from '../pagination/Pagination.vue'
import moment from 'moment'

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      operationsData: [],
      checkedOperation: [],
    }
  },
  props: {
    operations: Array,
  },
  methods: {
    clearOtherOperations(operationId, value) {
      if (value) {
        this.checkedOperation = [operationId]
      } else {
        this.checkedOperation = []
      }
    },
    formatProgrammedHour(programmedHour) {
      if (programmedHour) return `${moment(programmedHour).format('HH:mm')}`
    },
    formatDoneOn(doneOn) {
      if (doneOn) return `${moment(doneOn).format('DD/MM/YYYY')}`
    },
  },
  mounted() {
    // at this point this.operations is empty
    this.operationsData = [...this.operations]
  },
  watch: {
    operations(newOperations) {
      this.checkedOperation = []
      // operations can be updated from outside
      this.operationsData = [...newOperations]
    },
    checkedOperation(newOperation, oldOperation) {
      this.$emit('toggleOperationToPair', {
        newOperationId: newOperation[0],
        oldOperationId: oldOperation[0],
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.pairing-table
  .max-content
    width: max-content !important

  .table-container-scrollable
    max-height: 79vh
</style>
