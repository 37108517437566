import Vue from 'vue'
import dayjs from 'dayjs'

const state = {
  loadingBodyData: false,
  inBlockingErrorState: false,
  currentDate: new Date(),
  currentPlantId: null,
  currentOptimizationMethod: null,
  railroadGroups: [],
  railroads: [],
  trainsTimelineUnits: [],
  shiftsDurations: [],
  shiftsOffset: 0,
  columnWidth: 60,
  rowHeight: 40,
  timelineUnitHeight: 28,
  minutesPerColumn: 15,
  rowHeaderWidth: 200,
  topHeaderHeight: 50,
  rowGroupHeaderEnabled: true,
  rowGroupHeaderHeight: 15,
  tenant: null,
}
import { MINUTES_IN_A_DAY, ISO_DATE_FORMAT } from '@/helpers/constants'
import { roundToAllowedValues } from '@/helpers/functions'

const ALLOWED_GRANULARITIES_IN_MINUTES = [1, 5, 10, 15, 20, 30, 60]

const getters = {
  formattedCurrentDate (state) {
    return Vue.prototype.$dayjs(state.currentDate).format(ISO_DATE_FORMAT)
  },
  roundedMinutesPerColumn (state) {
    return roundToAllowedValues(ALLOWED_GRANULARITIES_IN_MINUTES, state.minutesPerColumn)
  },
  columnsCount (state, getters) {
    return MINUTES_IN_A_DAY / getters.roundedMinutesPerColumn
  },
  timelineWidth (state, getters) {
    return state.columnWidth * getters.columnsCount
  },
  getRailroadGroupById: state => id => {
    return state.railroadGroups.find(railroadGroup => railroadGroup.id === id)
  },
  getRailroadById: state => id => {
    return state.railroads.find(railroad => railroad.id === id)
  },
  getTrainsTimelineUnitById: state => id => {
    return state.trainsTimelineUnits.find(unit => unit.id === id)
  },
  getRailroadsByIds: state => ids => {
    return state.railroads.filter(railroad => ids.includes(railroad.id))
  },
  getTrainsTimelineUnitByIds: state => ids => {
    return state.trainsTimelineUnits.filter(unit => ids.includes(unit.id))
  },
  getSubRowByIndex: (_state, getters) => (railroadId, subRowIndex) => {
    const railroad = getters.getRailroadById(railroadId)
    return railroad?.subRows?.find(subRow => subRow.index === subRowIndex)
  },
  findFirstAvailableSubRow: (_state, getters) => (railroadId, newUnitStart) => {
    const railroad = getters.getRailroadById(railroadId)
    if (!railroad?.subRows) { return null }

    return railroad.subRows.find(subRow => subRow.end.isSameOrBefore(newUnitStart))
  },
  getNextSubRowIndex: (_state, getters) => railroadId => {
    const railroad = getters.getRailroadById(railroadId)
    if (!railroad?.subRows) { return 0 }

    const maxIndex = Math.max(...railroad.subRows.map(subRow => subRow.index))
    return Math.max(0, maxIndex + 1)
  },
  getSubRowsCount: (_state, getters) => railroadId => {
    return getters.getNextSubRowIndex(railroadId)
  },
  subRowHeight (state) {
    return state.timelineUnitHeight + 1
  },
}

const actions = {
  setRailroadGroups ({ commit }, railroadGroups) { commit('SET_RAILROAD_GROUPS', railroadGroups) },
  setRailroads ({ commit }, railroads) { commit('SET_RAILROADS', railroads) },
  setTenant ({ commit }, tenant) { commit('SET_TENANT', tenant) },
  setTrainsTimelineUnits ({ commit }, trainsTimelineUnits) { commit('SET_TRAIN_TIMELINE_UNITS', trainsTimelineUnits) },
  setShiftsDurations ({ commit }, shiftsDurations) { commit('SET_SHIFTS_DURATIONS', shiftsDurations) },
  setShiftsOffset ({ commit }, shiftsOffset) { commit('SET_SHIFTS_OFFSET', shiftsOffset) },
  setCurrentDate ({ commit }, currentDate) { commit('SET_CURRENT_DATE', currentDate) },
  setCurrentPlantId ({ commit }, currenPlantId) { commit('SET_CURRENT_PLANT_ID', currenPlantId) },
  setCurrentOptimizationMethod ({ commit }, currentOptimizationMethod) { commit('SET_CURRENT_OPTIMIZATION_METHOD', currentOptimizationMethod) },
  setColumnWidth ({ commit }, { columnWidth }) { commit('SET_COLUMN_WIDTH', { columnWidth }) },
  setRowHeight ({ commit }, { rowHeight }) { commit('SET_ROW_HEIGHT  ', { rowHeight }) },
  setMinutesPerColumn ({ commit }, { minutesPerColumn }) { commit('SET_MINUTES_PER_COLUMN', { minutesPerColumn }) },
  setLoadingBodyData ({ commit }, bool) { commit('SET_LOADING_BODY_DATA', bool) },
  setBlockingErrorState ({ commit }, bool) { commit('SET_BLOCKING_ERROR_STATE', bool) },
  setRowGroupHeaderEnabled ({ commit }, bool) { commit('ROW_GROUP_HEADER_ENABLED', bool) },
  upsertRailroadSubRow ({ commit, getters }, { railroadId, index, end }) {
    const subRow = getters.getSubRowByIndex(railroadId, index)
    const railroad = getters.getRailroadById(railroadId)
    subRow
      ? commit('UPDATE_RAILROAD_SUB_ROW_END', { subRow, end: dayjs(end) })
      : commit('PUSH_RAILROAD_SUB_ROW', { railroad, subRow: { index, end: dayjs(end) } })
  },
  resetRailroadSubRows ({ commit, getters }, railroadId) {
    const railroad = getters.getRailroadById(railroadId)
    commit('RESET_RAILROAD_SUB_ROWS', { railroad })
  },
}

const mutations = {
  SET_RAILROAD_GROUPS (state, railroadGroups) { state.railroadGroups = railroadGroups },
  SET_RAILROADS (state, railroads) { state.railroads = railroads },
  SET_TENANT (state, tenant) { state.tenant = tenant },
  SET_TRAIN_TIMELINE_UNITS (state, trainsTimelineUnits) { state.trainsTimelineUnits = trainsTimelineUnits },
  SET_SHIFTS_DURATIONS (state, shiftsDurations) { state.shiftsDurations = shiftsDurations },
  SET_SHIFTS_OFFSET (state, shiftsOffset) { state.shiftsOffset = shiftsOffset },
  SET_CURRENT_DATE (state, currentDate) { state.currentDate = currentDate },
  SET_CURRENT_PLANT_ID(state, currentPlantId) { state.currentPlantId = currentPlantId },
  SET_CURRENT_OPTIMIZATION_METHOD(state, currentOptimizationMethod) { state.currentOptimizationMethod = currentOptimizationMethod },
  SET_COLUMN_WIDTH (state, { columnWidth }) { state.columnWidth = columnWidth },
  SET_ROW_HEIGHT (state, { rowHeight }) { state.rowHeight = rowHeight },
  SET_MINUTES_PER_COLUMN (state, { minutesPerColumn }) { state.minutesPerColumn = minutesPerColumn },
  UPDATE_RAILROAD_SUB_ROW_END (_state, { subRow, end }) { Vue.set(subRow, 'end', end) },
  PUSH_RAILROAD_SUB_ROW (_state, { railroad, subRow }) {
    if (railroad.subRows) {
      railroad.subRows.push(subRow)
    } else {
      Vue.set(railroad, 'subRows', [subRow])
    }
  },
  RESET_RAILROAD_SUB_ROWS (_state, { railroad }) {
    Vue.set(railroad, 'subRows', [])
  },
  SET_LOADING_BODY_DATA (state, bool) { state.loadingBodyData = bool },
  SET_BLOCKING_ERROR_STATE (state, bool) { state.inBlockingErrorState = bool },
  ROW_GROUP_HEADER_ENABLED (state, bool) { state.rowGroupHeaderEnabled = bool },
}

export const trainsTimelineModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
