<template lang="pug">
.timeline-span(:style="cssVars")
  .timeline-span__label {{ label }}
</template>

<script>
export default {
  name: 'PureTimelineSpan',
  props: {
    label: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 40,
    },
  },
  computed: {
    cssVars() {
      return {
        '--width': `${this.width}px`,
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.timeline-span
  display: flex
  align-items: center
  justify-content: left
  padding: 0px 15px
  font-size: 12px
  flex: 1 1 auto
  height: 100%
  width: var(--width)
  text-transform: uppercase

  .timeline-span__label
    position: sticky
    left: 15px
</style>
