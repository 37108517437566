<template lang='pug'>
  div(v-bind:class='cssClass')
    .ui.icon.buttons
      button.ui.small.labeled.icon.button.orange(v-if='canUpload' v-on:click='handleClick')
        i.upload.cloud.icon
        | &nbsp;{{ $t('upload_assets') }}

      button.ui.small.labeled.icon.button.orange(v-if='cannotUpload')
        i.upload.cloud.icon
        | &nbsp;{{ $t('upload_assets') }}

    UploadModal(
      v-if='uploadingCarList'
      v-on:closed='onClosed'
    )
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import UploadModal from './components/modals/car-list-upload-modal'

export default {
  components: {
    UploadModal,
  },

  props: {
    apiToken: String,
    carList: Object,
  },

  computed: {
    ...mapState({
      uploadingCarList: 'uploadingCarList',
    }),
    cssClass() {
      return 'car-list-upload-button'
    },
    canUpload() {
      return this.carList
    },
    cannotUpload() {
      return !this.canUpload
    },
  },

  methods: {
    carListId() {
      const domId = 'operation_addonable_car_list_id'
      const el = document.getElementById(domId)

      return el.options[el.selectedIndex].value
    },

    handleClick(e) {
      e.preventDefault()
      this.$store.dispatch('showUploadingModal', this.carList)
    },

    onClosed() {
      this.$store.dispatch('hideUploadingModal')
    },
  },

  created() {
    const initialState = {
      operations: this.operations,
      apiToken: this.apiToken,
      plantId: this.plantId,
      plantName: this.plantName,
      railroadGroups: this.railroadGroups,
      otherPlants: this.otherPlants,
    }

    this.$store.dispatch('loadInitialState', initialState)
  },


  mounted() {
    this.$nextTick().then(() => {
      //   this.carListSelectedId = this.carListId()
      //   $(document).off('change', '#operation_addonable_car_list_id')
      //   $(document).on('change', '#operation_addonable_car_list_id',
      //     this.handleFromRailroadChange);
    })
  },

  beforeDestroy() {
    // $(document).off('change', '#operation_addonable_car_list_id')
  },
}
</script>
