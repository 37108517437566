<template lang="pug">
.train-timeline-header(:style="cssVars")
  .week-selector-wrapper
    PureWeekSelector(
      v-if="!isLongPeriod"
      :date="currentDate"
    )
    .typical-week-label(v-else)
      | {{ $t('typical_week') }}
  .selects-container
    .plant-select(v-if="showPlantSelect")
      vSelect(
        class='ui-vue-select'
        :options='plantsOptions'
        :multiple='false'
        :clearable="false"
        v-model='selectedPlant'
        @input='handlePlantChanged($event)'
      )
    .optimization-method-select(v-if="enableOptimizationMethodSelect")
      vSelect(
        class='ui-vue-select'
        :options='optimizationMethodOptions'
        :multiple='false'
        :clearable='false'
        v-model='selectedOptimizationMethod'
        @input='handleOptimizationMethodChanged($event)'
      )
  .week-day-selector-wrapper
    WeekDaySelector(
      :hideNavigation="hideNavigation"
      :showDates="showDates"
    )
  .ui.buttons(v-if='enableExport')
    .ui.tiny.button.green(v-on:click='exportTrains')
      | {{ $t('export') }}
    a.ui.tiny.button.grey(v-if='showExportButton' :href='exportUrl' :download='filename')
      | {{ $t('download') }}
</template>

<script>
import PureWeekSelector from '@/trains-timeline/PureWeekSelector.vue'
import WeekDaySelector from '@/trains-timeline/WeekDaySelector.vue'
import { mapState, mapActions } from 'vuex'
import vSelect from 'vue-select'

export default {
  name: 'TrainsTimelineHeader',
  components: {
    PureWeekSelector,
    WeekDaySelector,
    vSelect,
  },
  props: {
    enablePlantSelect: {
      type: Boolean,
      default: false,
    },
    enableOptimizationMethodSelect: {
      type: Boolean,
      default: false,
    },
    enableExport: {
      type: Boolean,
      default: false,
    },
    hideNavigation: {
      type: Boolean,
      default: false,
    },
    showDates: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('trainsTimeline', [
      'currentDate',
      'rowHeaderWidth',
      'tenant',
    ]),
    cssVars() {
      return {
        '--row-header-width': `${this.rowHeaderWidth}px`,
      }
    },
    showPlantSelect() {
      return this.enablePlantSelect && this.plantsOptions.length > 0
    },
    isLongPeriod () {
      return this.hideNavigation && !this.showDates
    },
  },
  data() {
    return {
      showExportButton: false,
      exportUrl: null,
      filename: null,
      plantsOptions: [],
      optimizationMethodOptions: [],
      selectedPlant: null,
      selectedOptimizationMethod: null,
    }
  },
  created() {
    Manovre.ws?.on('trains_timeline_export.completed', this.showExportFileButton)
    Manovre.ws?.on('trains_timeline_export.failed', this.showExportError)
    this.setPlantsOptions()
    this.setOptimizationMethodOptions()
  },
  methods: {
    ...mapActions('trainsTimeline', [
      'setCurrentPlantId',
      'setCurrentOptimizationMethod',
    ]),
    setOptimizationMethodOptions () {
      this.optimizationMethodOptions = [
        { code: 'all', label: this.$t('all') },
        { code: 'relaxed', label: this.$t('optimization_method.relaxed') },
        { code: 'optimized', label: this.$t('optimization_method.optimized') },
      ]
      this.selectedOptimizationMethod = this.optimizationMethodOptions[0]
      this.setCurrentOptimizationMethod('all')
    },
    handlePlantChanged (ev){
      this.setCurrentPlantId(ev.code)
    },
    handleOptimizationMethodChanged (ev){
      this.setCurrentOptimizationMethod(ev.code)
    },
    setPlantsOptions() {
      this.$http.get('/plants/list_for_select.json').then(response => {
        var firstPlant = response.data.plants[0]
        this.selectedPlant = {label: firstPlant.name, code: firstPlant.value}
        this.setCurrentPlantId(this.selectedPlant.code)
        response.data.plants.forEach((plant) => {
          this.plantsOptions.push({ label: plant.name, code: plant.value })
        })
      })
    },
    exportTrains() {
      $('#global-loader').dimmer('show')
      this.$http.get('/optimized_times/export_times.xlsx', {
        params: {
          currentDate: this.currentDate,
        },
      }).then(response => {
        Manovre.job_id = response.data.job_id
      })
    },
    showExportFileButton(message) {
      if (Manovre.job_id !== message.jid) { return }

      $('#global-loader').dimmer('hide')
      this.showExportButton = true
      this.exportUrl = message.url
      this.filename = message.filename
    },
    showExportError(message) {
      if (Manovre.job_id !== message.jid) { return }

      $('#global-loader').dimmer('hide')
      Manovre.notifyStatus(message.error)
    },
  },
}
</script>

<style lang="sass" scoped>
.train-timeline-header
  display: grid
  grid-template-columns: var(--row-header-width) min-content minmax(0, 1fr) min-content
  align-items: center
  grid-template-rows: 110px
  width: 100%

  .selects-container
    display: flex
    flex-direction: column
    row-gap: 10px
    align-items: center
    justify-content: center
    max-width: 250px

    > [class*='select']
      min-width: max-content
      width: 100%

  .week-day-selector-wrapper
    width: 100%

  .typical-week-label
    font-size: 18px
    font-weight: bold
    text-align: center

  ::v-deep
    .week-day-selector
      max-width: 100%
      margin: 0 auto

    // .row-header + .header-timeline
    //   border-left: none

    // .header-timeline
    //   align-self: stretch
</style>
