import Vue from 'vue'
import toOptimize from './to_optimize'
import toSave from './to_save'
import dayjs from 'dayjs'

import {
  SHOW_OPTIMIZED_TIMES,
  HIDE_OPTIMIZED_TIMES,

  SET_OPERATIONS_PLANS_DATA,
  SET_CURRENT_PAGE_OPERATIONS_PLAN_IDS,

  SET_OPTIMIZED_TIMES_DATA,
  SET_CURRENT_PAGE_OPTIMIZED_TIME_IDS,

  SET_OPTIMIZED_TIME_PERIODICITY_ACTIVITY_TIME,
} from './mutation_types'

import {
  SET_PAGINATION_INFO,
  ENABLE_PAGINATION_LINKS,
  DISABLE_PAGINATION_LINKS,
} from '../../pagination/store/mutation_types'

const state = {
  ...toOptimize.state,
  ...toSave.state,

  showOptimizedTimes: false,
}

const getters = {
  ...toOptimize.getters,
  ...toSave.getters,
}

const actions = {
  ...toOptimize.actions,
  ...toSave.actions,

  callIndex ({ state, commit, dispatch, rootState }, page) {
    if (state.showOptimizedTimes) { commit(HIDE_OPTIMIZED_TIMES) }
    commit(`pagination/${DISABLE_PAGINATION_LINKS}`, null, { root: true })

    const params = {
      params: {
        page: page || rootState.pagination.currentPage,
        filters: {
          plant_id: state.filters.plantId,
        },
      },
    }

    return this.$http.get('/operations_plans_optimizer', params).then(response => {
      commit(SET_OPERATIONS_PLANS_DATA, response.data.operationsPlans)
      commit(SET_CURRENT_PAGE_OPERATIONS_PLAN_IDS, response.data.operationsPlans)
      commit(`pagination/${SET_PAGINATION_INFO}`, response.data.pagination, { root: true })

      commit(`pagination/${ENABLE_PAGINATION_LINKS}`, null, { root: true })
      dispatch('setOperationsPlansToOptimizeArrays')
    }).catch(err => {
      commit(`pagination/${ENABLE_PAGINATION_LINKS}`, null, { root: true })
    })
  },
  showLastOptimized ({ commit, dispatch, rootState }, page) {
    commit(SHOW_OPTIMIZED_TIMES)
    commit(`pagination/${DISABLE_PAGINATION_LINKS}`, null, { root: true })

    return this.$http.get('/operations_plans_optimizer', {
      params: {
        get_last_optimized: true,
        page: page || rootState.pagination.currentPage,
        filters: {
          plant_id: state.filters.plantId,
        },
      },
    }).then(function (response) {
      commit(SET_OPTIMIZED_TIMES_DATA, response.data.optimizedTimes)
      commit(SET_CURRENT_PAGE_OPTIMIZED_TIME_IDS, response.data.optimizedTimes)
      commit(`pagination/${SET_PAGINATION_INFO}`, response.data.pagination, { root: true })
      commit(`pagination/${ENABLE_PAGINATION_LINKS}`, null, { root: true })
      dispatch('setOptimizedTimesToSaveArrays')
    })
  },

  setOptimizedTimePeriodicityActivityTime ({ commit }, { field, day, optimizedTimeId, datetime }) {
    commit(SET_OPTIMIZED_TIME_PERIODICITY_ACTIVITY_TIME, { field, day, optimizedTimeId, datetime })
  },
}

const mutations = {
  ...toOptimize.mutations,
  ...toSave.mutations,

  [SHOW_OPTIMIZED_TIMES] (state) {
    state.showOptimizedTimes = true
  },
  [HIDE_OPTIMIZED_TIMES] (state) {
    state.showOptimizedTimes = false
  },

  [SET_OPTIMIZED_TIME_PERIODICITY_ACTIVITY_TIME] (state, { field, day, optimizedTimeId, datetime }) {
    const optimizedTime = state.optimizedTimesData.find(ot => ot.id === optimizedTimeId)
    if (!optimizedTime.periodicity) {
      optimizedTime.periodicity = {}
    }
    if (!optimizedTime.periodicity[day]) {
      Vue.set(optimizedTime.periodicity, day, {})
    }
    const serializedDatetime = datetime ? dayjs(datetime).format('YYYY-MM-DDTHH:mm:ss.000+0000') : null
    Vue.set(optimizedTime.periodicity[day], field, serializedDatetime)
  },
}

export const operationsPlansOptimizerModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
