<template lang="pug">
.date-ranges-form
  .label Filtra per data
  DatePicker(
    v-bind="datePickerProps",
    @change="handleDatePickerChange($event)"
  )
</template>

<script>
import DatePicker from 'vue2-datepicker'
import moment from 'moment'
const DATE_FORMAT = 'DD/MM/YYYY'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      dates: null,
      weekRangeFormatter: {
        stringify:() => {
          return `w${this.weekNumber}: ${this.fromDateDisplay} – ${this.toDateDisplay}`
        },
      },
      datepickerShortcuts: [
        { text: 'Questa settimana', onClick: () => new Date() },
        { text: 'Settimana prossima', onClick: () => {
          return moment(new Date()).add(1, 'week').toDate()
        }},
      ],
    }
  },
  created() {
    moment.locale(this.locale)
  },
  computed: {
    locale() {
      return this.$i18n.locale.substring(0,2)
    },
    ...mapState('operationsOptimizer', [
      'fromDate',
      'toDate',
    ]),

    datePickerProps() {
      return {
        class: 'wide',
        lang: this.locale,
        value: this.fromDate,
        formatter: this.weekRangeFormatter,
        range: false,
        clearable: false,
        type: 'week',
        placeholder: '',
        confirm: false,
        shortcuts: this.datepickerShortcuts,
        'popup-class': 'date-ranges-form-popup',
        'show-week-number': true,
        'append-to-body': true,
      }
    },
    weekNumber() {
      return moment(this.fromDate).format('w')
    },
    fromDateDisplay() {
      return moment(this.fromDate).format(DATE_FORMAT)
    },
    toDateDisplay() {
      return moment(this.toDate).format(DATE_FORMAT)
    },
  },
  methods: {
    ...mapActions('operationsOptimizer', [
      'resetOperationsToOptimize',
      'setDateRange',
      'callIndex',
    ]),
    ...mapActions('pagination', [
      'setPage',
    ]),
    handleDatePickerChange(date){
      const momentDate = moment(date)
      const monday = momentDate.clone().startOf('week').toDate()
      const sunday = momentDate.clone().endOf('week').toDate()
      this.resetOperationsToOptimize()
      this.setPage(1)
      this.setDateRange({ fromDate: monday, toDate: sunday })
      this.callIndex({})
    },
  },
}
</script>

<style lang="sass" scoped>
.date-ranges-form
  display: inline-flex
  align-items: center
  margin-left: 10px

  .label
    margin-right: 8px
    white-space: nowrap

  .from + .to
    margin-left: 10px
</style>

<style lang="sass">
.date-ranges-form
  .mx-datepicker .mx-input-wrapper
    width: 240px

.date-ranges-form-popup
  $datepicker-popup-sidebar-width: 150px

  .mx-datepicker-sidebar
    width: $datepicker-popup-sidebar-width

    & + .mx-datepicker-content
      margin-left: $datepicker-popup-sidebar-width
</style>
