<template lang="pug">
div
  OTHeader
  RequestOptimizationsTable(v-if="!showOptimizedTimes")
  SaveOptimizationsTable(v-if="showOptimizedTimes")
</template>

<script>
import OTHeader from'./OTHeader.vue'
import RequestOptimizationsTable from'./RequestOptimizationsTable.vue'
import SaveOptimizationsTable from'./SaveOptimizationsTable.vue'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    OTHeader,
    RequestOptimizationsTable,
    SaveOptimizationsTable,
  },
  props: {
    operations: {
      type: Array,
      default: () => [],
    },
    optimizedTimes: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object,
      default: null,
    },
    fromDate: {
      type: String,
      default: null,
    },
    toDate: {
      type: String,
      default: null,
    },
    initialSort: {
      type: String,
      default: null,
    },
  },
  created() {
    if(this.operations) {
      this.setOperationsData([...this.operations])
      this.setCurrentPageOperationIds([...this.operations])
      this.setCurrentSort({sort: this.initialSort, direction: 'desc'})
    }
    if(this.optimizedTimes) {
      this.setOptimizedTimesData([...this.optimizedTimes])
      this.setCurrentPageOptimizedTimeIds([...this.optimizedTimes])
    }
    this.setPaginationInfo(this.pagination)
    this.setDateRange({ fromDate: new Date(this.fromDate), toDate: new Date(this.toDate)})
  },
  computed: {
    ...mapState('operationsOptimizer', [
      'showOptimizedTimes',
      'operationsData',
      'currentSort',
    ]),
  },
  methods: {
    ...mapActions('operationsOptimizer', [
      'setOperationsData',
      'setCurrentPageOperationIds',
      'setCurrentSort',

      'setOptimizedTimesData',
      'setCurrentPageOptimizedTimeIds',

      'setDateRange',
    ]),

    ...mapActions('pagination', [
      'setPaginationInfo',
    ]),

  },
}
</script>
