<template lang="pug">
.train-timeline(:style="cssVars")
  TrainsTimelineHeader(
    :showDates="showDates"
    :hideNavigation="hideNavigation"
    :weekDayNumber="computedWeekDayNumber"
    :enableOptimizationMethodSelect="enableOptimizationMethodSelect"
    :enablePlantSelect="enablePlantSelect"
    :enableExport="enableExport"
  )
  .train-timeline__body-wrapper
    TrainsTimelineLoader(v-if="loadingBodyData")
    TrainsTimelineErrorAlert(
      v-if="inBlockingErrorState",
      @retry-button:click="handleRetryButtonClick()"
    )
    TrainsTimelineBody(
      :railroadGroups="railroadGroups",
      :railroads="railroads",
      :trainsTimelineUnits="trainsTimelineUnits",
      :rowHeaderWidth="rowHeaderWidth",
      :shiftsDurations="shiftsDurations"
      :shiftsOffset="shiftsOffset"
      :showOptimizationMethod="enableOptimizationMethodSelect"
    )
</template>

<script>
import TrainsTimelineHeader from './TrainsTimelineHeader.vue'
import TrainsTimelineLoader from './TrainsTimelineLoader.vue'
import TrainsTimelineErrorAlert from './TrainsTimelineErrorAlert.vue'
import TrainsTimelineBody from './TrainsTimelineBody.vue'
import { MILLISECONDS_IN_A_SECOND } from '@/helpers/constants.js'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'TrainsTimeline',
  components: {
    TrainsTimelineHeader,
    TrainsTimelineLoader,
    TrainsTimelineErrorAlert,
    TrainsTimelineBody,
  },
  props: {
    date: {
      type: [Date, String],
      default: () => new Date(),
    },
    dataUrl: {
      type: String,
      default: undefined,
    },
    tenant: {
      type: String,
      default: undefined,
    },
    isLongPeriod: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      firstLoad: true,
      url: new URL(window.location),
    }
  },
  created () {
    this.setTenant(this.tenant)

    const date = this.url.searchParams.get('date') || this.date
    this.setCurrentDate(date)
  },
  computed: {
    ...mapState('trainsTimeline', [
      'currentDate',
      'currentPlantId',
      'currentOptimizationMethod',
      'rowHeaderWidth',
      'topHeaderHeight',
      'railroadGroups',
      'railroads',
      'trainsTimelineUnits',
      'shiftsDurations',
      'shiftsOffset',
      'loadingBodyData',
      'inBlockingErrorState',
    ]),
    ...mapGetters('trainsTimeline', [
      'formattedCurrentDate',
    ]),
    cssVars(){
      return {
        '--horizontal-header-width': `${this.rowHeaderWidth}px`,
        '--top-header-height': `${this.topHeaderHeight}px`,
      }
    },
    enablePlantSelect() {
      return ['ati'].includes(this.tenant)
    },
    enableOptimizationMethodSelect() {
      return ['ati'].includes(this.tenant)
    },
    enableExport() {
      return ['fuorimuro'].includes(this.tenant)
    },
    showDates () {
      return !this.isLongPeriod
    },
    hideNavigation () {
      return this.isLongPeriod
    },
    computedWeekDayNumber () {
      if (this.showDates) { return }

      return this.$dayjs(this.currentDate).day()
    },
  },
  watch: {
    currentDate: {
      immediate: false,
      handler: function (newVal, oldVal) {
        if (!this.firstLoad
          && oldVal
          && this.$dayjs(newVal).isSame(oldVal, 'day')) { return }

        this.updateDateURLParam()
        this.asyncLoadData()
      },
    },
    currentPlantId: {
      immediate: false,
      handler: function (newVal, oldVal) {
        if (!this.firstLoad
          && oldVal
          && newVal == oldVal) { return }

        this.asyncLoadData()
      },
    },
    currentOptimizationMethod: {
      immediate: false,
      handler: function (newVal, oldVal) {
        if (!this.firstLoad
          && oldVal
          && newVal == oldVal) { return }

        this.asyncLoadData()
      },
    },
  },
  methods: {
    ...mapActions('trainsTimeline', [
      'setCurrentDate',
      'setRailroadGroups',
      'setRailroads',
      'setTrainsTimelineUnits',
      'setShiftsDurations',
      'setShiftsOffset',
      'setLoadingBodyData',
      'setBlockingErrorState',
      'setTenant',
    ]),
    async asyncLoadData() {
      this.setBlockingErrorState(false)
      this.setLoadingBodyData(true)
      try {
        if (!this.currentPlantId){
          return
        }
        const params = {
          date: this.formattedCurrentDate,
          week_day_number: this.computedWeekDayNumber,
          plant_id: this.currentPlantId,
          optimization_method: this.currentOptimizationMethod,
        }
        const { data } = await this.$http.get(this.dataUrl,
                                              { params, timeout: 30 * MILLISECONDS_IN_A_SECOND })
        this.firstLoad = false
        this.setCurrentDate(data.currentDate)
        this.setRailroadGroups(data.railroadGroups)
        this.setRailroads(data.railroads)
        this.setTrainsTimelineUnits(data.trainsTimelineUnits)
        this.setShiftsDurations(data.shiftsDurations)
        this.setShiftsOffset(data.shiftsOffset)
        if(data.errors?.length) { this.$toast.error(this.$t('error_some_units_were_not_drawn'))}
      } catch(err){
        console.error('asyncLoadData Error:', err)
        this.setBlockingErrorState(true)
      }
      this.setLoadingBodyData(false)
    },
    handleRetryButtonClick() {
      this.setBlockingErrorState(false)
      this.asyncLoadData()
    },
    updateDateURLParam() {
      this.showDates
        ? this.url.searchParams.set('date', this.formattedCurrentDate)
        : this.url.searchParams.set('weekDayNumber', this.computedWeekDayNumber)
      window.history.pushState({}, '', this.url)
    },
  },


}
</script>

<style lang="sass" scoped>
.train-timeline
  display: grid
  grid-template-columns: minmax(0px, 1fr)
  grid-template-rows: 110px minmax(0px, 1fr)
  height: 90vh
  position: relative
  overflow: hidden

.train-timeline__body-wrapper
  position: relative
</style>
