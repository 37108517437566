<template lang="pug">
modal.vue-modal(
  name="send-times-modal",
  width="900px",
  height="500px",
  v-bind:adaptive="true"
)
  .ui.modal.visible.active.send-times-modal(
    style="overflow: auto; max-height: 100vh"
  )
    div(slot="top-right")
      i.close.icon(v-on:click="closeModal")
    .content(style="height: 400px")
      | Seleziona un range temporale:
      .ui.grid
        .eight.wide.column
          date-picker.wide(
            lang="datePickerLocale",
            v-model="startPlanningTime",
            format="DD/MM/YYYY",
            clearable=false,
            type="date",
            placeholder="Inizio",
            :time-picker-options="{ start: '00:00', step: '00:30', end: '23:30' }",
            value-type="date"
          )
        .eight.wide.column
          date-picker.wide(
            lang="datePickerLocale",
            v-model="endPlanningTime",
            format="DD/MM/YYYY",
            clearable=false,
            type="date",
            placeholder="Fine",
            :time-picker-options="{ start: '00:00', step: '00:30', end: '23:30' }",
            value-type="date"
          )
    .actions
      .ui.cancel.button(v-on:click="closeModal")
        | {{ $t('cancel') }}

      .ui.cancel.button.green(v-on:click="updateOperations")
        | {{ $t('confirm') }}
</template>

<script>
import DatePicker from 'vue2-datepicker'
import { error as errNotify, success as succNotify } from '../utils/notify'
import { hashOfArraysToArrayPairs } from './utils'
import { mapState } from 'vuex'


export default {
  data() {
    return {
      startPlanningTime: '',
      endPlanningTime: '',
    }
  },
  components: {
    DatePicker,
  },
  computed: {
    ...mapState('operationsPlansOptimizer', [
      'optimizedTimesToSave',
    ]),
  },
  methods: {
    closeModal() {
      this.$modal.hide('send-times-modal')
    },
    updateOperations() {
      $.ajax({
        url: '/operations_plans_optimizer/update_operations',
        data: {
          days_to_optimize: hashOfArraysToArrayPairs(this.optimizedTimesToSave),
          start_planning_time: this.startPlanningTime,
          end_planning_time: this.endPlanningTime,
        },
        method: 'post',
      }).always((result) => {
        if (result.status === 'ok') {
          succNotify({ title: 'Aggiornamento concluso con successo' })
          this.$modal.hide('send-times-modal')
        } else {
          errNotify({ title: 'Errore: qualcosa è andato storto' })
        }
      })
    },
  },
}
</script>
