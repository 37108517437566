<template lang="pug">
.paired-car-list-table
  .table-container-scrollable
    table.ui.compact.table.unstackable.max-content
      thead
        th
        th
        th {{ $t('car_list_code') }}
        th {{ $t('car_list_id') }}
        th {{ $t('car_list_parent_operation_id') }}
        th {{ $t('car_list_parent_operation_done_on') }}
        th {{ $t('car_list_pair_id') }}
        th(v-for="(addonableName) in addonableNames")
          | {{ addonableName }}
      tbody
        tr(v-if='!carListsData.length')
          td(colspan=7)
            .text-center
              | {{ $t('no_data_found') }}
        tr(v-for="(carList) in filteredRows")
          td(v-if="isEditable(carList.id)")
            .ui.tiny.icon.button.red(v-on:click='removeRowFromEditables(carList.id)')
              i.icon.remove
          td(v-else)
            .ui.tiny.icon.button(v-on:click='addRowToEditables(carList.id)')
              i.icon.pencil
          td: input(
            type="checkbox",
            :id="carList.id",
            :value="carList.id",
            v-model="checkedCarLists"
          )
          td {{ carList.code }}
          td {{ carList.id }}
          td {{ carList.parent_operation_id }}
          td {{ carList.parent_operation_done_on }}
          td {{ carList.pair_id }}
          td(v-for="(addonableName) in addonableNames")
            .ui.grid(v-if='carList.addonables_setup[addonableName]')
              .row.padded.centered
                input(
                  v-if="isEditable(carList.id)"
                  type="checkbox",
                  v-model="carList.addonables_setup[addonableName].required"
                  v-on:change="notifyUpdateAddonablesSetup(carList.id, carList.addonables_setup)"
                )
                div(v-else)
                  | {{ humanRequired(carList.addonables_setup[addonableName].required) }}
              .row.padded.centered
                .ui.input
                  input(
                    v-if="isEditable(carList.id)"
                    v-model.number="carList.addonables_setup[addonableName].duration"
                    v-on:change="notifyUpdateAddonablesSetup(carList.id, carList.addonables_setup)"
                  )
                  div(v-else)
                    | {{ `${$t('duration')}: ${carList.addonables_setup[addonableName].duration} min` }}
      tfoot.full-width
</template>

<script>
import Pagination from '../pagination/Pagination.vue'

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      editableRows: [],
      carListsData: [],
      plantIdData: null,
      checkedCarLists: [],
      addonableNames: [],
    }
  },
  props: {
    carLists: Array,
    plantId: Number,
    codeFilter: String,
    idFilter: String,
    startDate: String,
    endDate: String,
  },
  methods: {
    notifyUpdateAddonablesSetup(carListId, carListAddonablesSetup) {
      this.$emit('notifyUpdateAddonablesSetup', { carListId, carListAddonablesSetup })
    },
    getAddonableNames() {
      this.$http.get(
        `/operations_pairings_optimizer/optimizer_standard_operations`,
        {
          params:
            {
              plantId: this.plantIdData,
            },
        }
      ).then(response => {
        this.addonableNames = response.data.operation_names
      })
    },
    isEditable(carListId) {
      return this.editableRows.includes(carListId)
    },
    addRowToEditables(carListId) {
      this.editableRows.push(carListId)
    },
    removeRowFromEditables(carListId) {
      this.editableRows = this.editableRows.filter(carList => carList !== carListId)
    },
    humanRequired(required) {
      return required ? this.$t('required') : this.$t('not_required')
    },
  },
  mounted() {
    this.carListsData = this.carLists
    this.getAddonableNames()
  },
  watch: {
    carLists(newCarLists) {
      this.carListsData = newCarLists
    },
    plantId(newPlantId) {
      this.plantIdData = newPlantId
      this.getAddonableNames()
    },
    checkedCarLists(newCarLists) {
      this.$emit('sendSelectedCarLists', newCarLists)
    },
  },
  computed: {
    filteredRows() {
      return this.carListsData.filter(carList => {
        const codeSearchTerm = this.codeFilter.toLowerCase()
        const idSearchTerm = this.idFilter.toLowerCase()
        const startDate = this.$dayjs(this.startDate, 'DD/MM/YYYY')
        const endDate = this.$dayjs(this.endDate, 'DD/MM/YYYY')
        const codes = carList.code.toLowerCase()
        const carlistIds = carList.id.toLowerCase()
        const date = new Date(carList.parent_operation_done_on)

        if(!startDate.isValid() || !endDate.isValid()){
          return codes.includes(codeSearchTerm) &&
            carlistIds.includes(idSearchTerm)
        }else{
          return codes.includes(codeSearchTerm) &&
            carlistIds.includes(idSearchTerm) &&
            startDate <= date &&
            date <= endDate
        }
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.paired-car-list-table
  .max-content
    width: max-content !important

  .table-container-scrollable
    max-height: 79vh
</style>
