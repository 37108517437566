<template lang="pug">
.train-timeline-unit-peek-view
  h3.train {{ $t('train') }} {{ trainsTimelineUnit.train }}
  .entry.entry--time
    .entry__label {{ $t('time') }}
    .entry__value
      TimeRange(
        :start-time="trainsTimelineUnit.startsAt",
        :end-time="trainsTimelineUnit.endsAt"
      )
    .entry__method(v-if="showOptimizationMethod")
      | {{ $t(`optimization_method.${trainsTimelineUnit.optimizationMethod}`) }}
  .entry.entry--station(v-if="trainsTimelineUnit.station")
    .entry__label {{ $t('station') }}
    .entry__value {{ trainsTimelineUnit.station }}
</template>

<script>
import TimeRange from './TimeRange.vue'

export default {
  name: 'TrainsTimelineUnitPeekView',
  components: {
    TimeRange,
  },
  props: {
    trainsTimelineUnit: {
      type: Object,
      default: undefined,
    },
    showOptimizationMethod: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="sass" scoped>
.train-timeline-unit-peek-view
  display: flex
  flex-direction: column

  .entry
    .entry__label
      font-weight: 600

  .entry + .entry
    margin-top: 5px
</style>
