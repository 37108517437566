<template lang="pug">
multiselect(
  class="car-or-locomotive-remote-select"
  ref="carOrLocomotiveSelectRef",
  v-model="selectedCarOrLocomotive",
  :options="unpublishedCarsAndLocomotives",
  label="name",
  :placeholder="$t('car_or_locomotive_remote_select.placeholder')",
  :loading="isCarSelectLoading",
  name="car_position[car_id]",
  :custom-label="nameWithType",
  :internal-search="false",
  :clear-on-select="false",
  :show-labels="false",
  track-by="name"
  @open="handleOpen()",
  @search-change="asyncFindCarAndLocomotives($event)",
  @select="handleSelect($event)"
  @remove="handleRemove($event)"
)
</template>

<script>
import Axios from '@frontend/utils/axios_defaults.js'
import { debounce } from '@frontend/utils/helpers.js'
import Multiselect from 'vue-multiselect'
import { mapState } from 'vuex'

export default {
  name: 'CarOrLocomotiveRemoteSelect',
  components: {
    Multiselect,
  },
  data() {
    return {
      unpublishedCarsAndLocomotives: [],
      selectedCarOrLocomotive: {},
      isCarSelectLoading: false,
    }
  },
  computed: {
    ...mapState({
      mode: 'wagonsManagementModalMode',
    }),
  },
  methods: {
    asyncFindCarAndLocomotives: debounce(function (query = '') {
      this.isCarSelectLoading = true
      Axios.get(
        '/unpublished_cars_and_locomotives_index.json',
        { params: { q: query } }
      ).then(response => {
        this.unpublishedCarsAndLocomotives = response.data.results
        this.isCarSelectLoading = false
      })
    }, 300),
    handleSelect(selected){
      this.$refs.carOrLocomotiveSelectRef.search = selected.name

      this.$emit('select', selected)
    },
    handleRemove(removed) {
      this.$emit('remove', removed)
    },
    handleOpen() {
      if(!this.selectedCarOrLocomotive) { return }

      this.$refs.carOrLocomotiveSelectRef.search = this.selectedCarOrLocomotive.name
    },
    nameWithType({ name, type }) {
      if(!name || ! type) { return  }

      return `${name} (${this.$t('car_or_locomotive_remote_select.' + type)})`
    },
  },
  mounted() {
    this.asyncFindCarAndLocomotives()
  },
}
</script>

<style lang="sass">
.car-or-locomotive-remote-select
  .multiselect__content-wrapper
    max-height: 200px !important
</style>
