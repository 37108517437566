<template lang='pug'>
  div(name='car-list-merge-fields')
    .two.fields
      // new merged car list code
      .ui.form.field.string
        label {{ $t('merge_modal.target_code') }}
        input.car-list-merge-modal__code-input(
          type='text'
          v-model='mergeCode'
          :placeholder='newCarListNamePlaceholder'
          @input="handleUpdate()"
        )

      // new merged car list train
      .ui.form.field
        label {{ $t('merge_modal.target_train') }}

        vSelect(
          class='ui-vue-select'
          label='name'
          v-on:search='fetchTrains'
          v-model='currentTrain'
          v-bind:options='trainOptions'
          v-bind:filterable='false'
          v-bind:value='currentTrain'
          v-bind:placeholder='$t("merge_modal.train_placeholder")'
          @input="handleUpdate()"
        )
          div(slot='no-options') {{ $t('merge_modal.no_trains_found') }}
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import trainsApi from '../../api/trains'
import _ from 'lodash'

export default {
  components: {
    vSelect,
  },

  props: ['value', 'carList'],

  data() {
    return {
      mergeCode: '',
      currentTrain: null,
      trainOptions: [],
    }
  },

  computed: {
    ...mapState({
      mergeDirection: 'mergingCarListDirection',
      apiToken: 'apiToken',
    }),
    ...mapGetters([
      'manipulationDisabled',
      'carListUnavailable',
    ]),
    defaultTrainOptions() {
      const trains = [originalTrain, targetOriginalTrain]

      const options = trains.map((train) => {
        if (!train) { return }

        const trainOption = Object.assign({}, train)
        trainOption.id = parseInt(trainOption.id, 10)

        return trainOption
      })

      return _.compact(options)
    },
    targetOriginalTrain() {
      if (!this.targetCarList) { return }
      if (!this.targetCarList.train) { return }

      const train = Object.assign({}, this.targetCarList.train)
      train.id = parseInt(train.id, 10)

      return train
    },
    targetOriginalTrainId() {
      return this.targetOriginalTrain
        ? parseInt(this.targetOriginalTrain.id, 10)
        : undefined
    },
    originalTrain() {
      if (!this.carList.train) { return }

      const train = Object.assign({}, this.carList.train)
      train.id = parseInt(train.id, 10)

      return train
    },
    originalTrainId() {
      return this.originalTrain
        ? parseInt(this.originalTrain.id, 10)
        : undefined
    },
    trainId() {
      return this.currentTrain ? this.currentTrain.id : undefined
    },
    newCarListNamePlaceholder() {
      return this.$t('edit_modal.new_car_list_name_placeholder')
    },
    targetCarList() {
      if (this.mergeDirection === 'left') {
        return this.carList.prevCarList
      } else {
        return this.carList.nextCarList
      }
    },
  },

  mounted() {
    this.mergeCode = this.carList.code
    this.currentTrain = this.carList.train
    this.fetchTrains('', () => {})
    this.handleUpdate()
  },

  methods: {
    handleUpdate() {
      const model = {
        code: this.mergeCode,
        train: this.currentTrain,
      }

      this.$emit('input', model)
    },
    fetchTrains(search, loading) {
      loading(true)
      trainsApi.fetch(this.apiToken, search, this.trainDate,
                      data => {
                        const options = data.trains
                        const trainIds = data.trains.map((x) => (x.id))

                        if (this.originalTrainId && !_.includes(trainIds, this.originalTrainId)) {
                          options.push(this.originalTrain)
                        }

                        if (this.targetOriginalTrainId && !_.includes(trainIds, this.targetOriginalTrainId)) {
                          options.push(this.targetOriginalTrain)
                        }

                        this.trainOptions = options
                        loading(false)
                      },
                      error => {
                        console.log(error)
                        loading(false)
                      }
      )
    },
  },
}
</script>
