import Axios from 'axios'

export const fetchCarList = ({ apiToken, carListId }) => {
  return Axios.get(`/v1/car_lists/${carListId}`, {
    headers: { 'X-AUTH-TOKEN': apiToken },
  }).then((response) => {
    if (response.status === 200) { return Promise.resolve(response.data) }

    return Promise.reject(new Error(`Page status ${response.status}`))
  })
}

export const fetchCarLists = ({ apiToken, params = {} }) => {
  return Axios.get(`/v1/car_lists.json`, {
    headers: { 'X-AUTH-TOKEN': apiToken },
    params,
  }).then((response) => {
    if (response.status === 200) { return Promise.resolve(response.data) }

    return Promise.reject(new Error(`Page status ${response.status}`))
  })
}

export const checkCarListsExistence = ({ apiToken, params = {} }) => {
  return Axios.get(`/v1/car_lists/found_by_params.json`, {
    headers: { 'X-AUTH-TOKEN': apiToken },
    params,
  }).then((response) => {
    if (response.status === 200) { return Promise.resolve(response.data) }

    return Promise.reject(new Error(`Page status ${response.status}`))
  })
}

export const updateCarList = ({ apiToken, carListId, attributes = {} }) => {
  return Axios.put(`/v1/car_lists/${carListId}.json`, {
    car_list: attributes,
  }, {
    headers: { 'X-AUTH-TOKEN': apiToken },
  }).then((response) => {
    if (response.status === 200) { return Promise.resolve(response.data) }

    return Promise.reject(new Error(`Page status ${response.status}`))
  })
}

export default {
  fetchCarList,
  fetchCarLists,
  updateCarList,
}
