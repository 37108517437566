<template lang="pug">
.active-week-days
  template(v-for="(day, index) in days")
    span.ui.mini.green.circular.label(v-if="optimizedTime.circulation_days[day]")
      | {{ index + 1 }}
    span.ui.mini.gray.circular.label(v-else)
      | {{ index + 1 }}
</template>

<script>
import { DAYS } from '../modules/calendar_constants'

export default {
  data() {
    return {
      days: DAYS,
    }
  },
  props: {
    optimizedTime: {
      type: Object,
      default: null,
    },
  },
}
</script>
