const SELECTORS = {
  DROPDOWN: '.ui.dropdown',
  SELECT: 'select',
}

const CLASSES = {
  DROPDOWN_HIGHLIGHTED: 'dropdown--highlighted',
}

class DropdownHighlighter {
  constructor({ dropdown, originalValue }) {
    this.dropdown = dropdown
    this.originalValue = originalValue
  }

  start () {
    this.bindElements()
    this.bindEvents()
    this.setOriginalValue()
    if (this.originalValue !== null || typeof this.originalValue !== 'undefined') {
      this.highlight()
    }
  }

  bindElements () {
    this.dropdown = this.dropdown.matches(SELECTORS.DROPDOWN) && this.dropdown || this.dropdown.querySelector(SELECTORS.DROPDOWN)
    this.select = this.dropdown.querySelector('select')
  }

  setOriginalValue () {
    if (this.originalValue === null || typeof this.originalValue === 'undefined') {
      this.originalValue = this.select.value
    }
  }

  bindEvents () {
    $(this.selector).on('change', this.handleDropdownChange.bind(this))
    $(this.dropdown).on('change', this.handleDropdownChange.bind(this))
  }

  handleDropdownChange (e) {
    this.highlight()
  }

  highlight () {
    if (this.originalValue != this.select.value) {
      this.dropdown.classList.add(CLASSES.DROPDOWN_HIGHLIGHTED)
    } else {
      this.dropdown.classList.remove(CLASSES.DROPDOWN_HIGHLIGHTED)
    }
  }
}

export default DropdownHighlighter
