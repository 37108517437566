<template lang='pug'>
  .car-list-wrapper(
    v-bind:class='cssClass'
    v-on:ontouchstart='activateActions'
    v-on:mouseover='activateActions'
    v-on:mouseleave='deactivateActions'
    v-on:click='handleClick'
    v-bind:data-car-list-uuid='carList.uuid'
    :style="customStyleColor.wrapper"
  )

    .car-list__sticky-name(
      v-if='isStickyNameEnabled'
      :style="customStyleColor.sticky"
    )
      span(:title='carList.name') {{ carListStickyName }}

    .clamp(v-if='hasClamps')

    .car-list
      Car(
        v-for='car in carList.cars'
        v-bind:key='car.id'
        v-bind:car='car'
        v-bind:carList='carList'
        v-bind:searchedCarCode='searchedCarCode'
        v-bind:searchedFullCarCodes='searchedFullCarCodes'
        v-bind:searchedCarTypology='searchedCarTypology'
        v-bind:searchedMaintenanceStatus='searchedMaintenanceStatus'
      )

    .clamp(v-if='hasClamps')

    .actions-unavailable(v-if='unavailable' v-bind:class='cssClassForUnavailable')
      span.actions__label(:title='carList.name') {{ unavailableCarListName }}
      .plant-link(v-on:click='handleUnavailableClick')
        span {{ viewAddonableLabel }}
        i.arrow.right.icon.blue

    .actions(v-if='showActions' v-bind:class='cssClassForActionsCounter')
      EditableCode(
        v-bind:carList='carList'
        v-bind:actionsCount='actionsCount'
      )
      .car-list-actions-container
        i.edit.icon.green(
          v-if='canOpenParentOperationPopUp' v-on:click='openParentOperationPopUp'
          v-bind:class='cssClassForSingleAction'
        )
        i.angle.double.left.icon.green(
          v-if='canMergeLeft' v-on:click='mergeLeft'
          v-bind:class='cssClassForSingleAction'
        )
        i.write.icon.green(
          v-if='canEdit' v-on:click='edit'
          v-bind:class='cssClassForSingleAction'
        )
        i.info.icon.green(
          v-if='canInfo' v-on:click='info'
          v-bind:class='cssClassForSingleAction'
        )
        i.share.icon.green(
          v-if='canRelease' v-on:click='release'
          v-bind:class='cssClassForSingleAction'
        )
        i.exchange.icon.green(
          v-if='canInvert' v-on:click='invert'
          v-bind:class='cssClassForSingleAction'
        )
        i.checkmark.icon.no-anchor(
          v-if='inverted'
          v-bind:class='cssClassForSingleAction'
        )
        i.upload.icon.cloud.green(
          v-if='canSendH30' v-on:click='sendH30OutboundMessage'
          v-bind:class='cssClassForSingleAction'
        )
        i.angle.double.right.icon.green(
          v-if='canMergeRight' v-on:click='mergeRight'
          v-bind:class='cssClassForSingleAction'
        )
        i.double.cut.icon.green(
          v-if='canCut' v-on:click='handleCutAction'
          v-bind:class='cssClassForSingleAction'
        )
        i.check.circle.icon.green(
          v-if='canManageRat' v-on:click='handleRatConfirmation'
          v-bind:class='cssClassForSingleAction'
        )
        input(
          :id="`${this.carList.id}`"
          type='text'
          style="display: none;"
        )
    i.refresh.icon.processing(v-if='processing && !disableInteraction')
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import Car from './car'
import confirmable from '../mixins/confirmable'
import EditableCode from './car_lists/editable-code'
import { error as errNotify, success as succNotify } from '../../utils/notify'

export default {
  components: {
    Car,
    EditableCode,
  },

  props: {
    carList: Object,
    railroad: Object,
    railroadId: String,
    disabledActions: Boolean,
    searchedCarCode: String,
    searchedFullCarCodes: Array,
    searchedCarTypology : Object,
    searchedMaintenanceStatus: Object,
    disableInteraction: {
      type: Boolean,
      default: false,
    },
    readOnlyPlant: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [confirmable],

  data() {
    return {
      focus: false,
      inverted: false,
      bgColor: '',
    }
  },
  mounted() {
    const carListColorPickerSetups = {
      allowEmpty: true,
      preferredFormat: 'hex',
      showPalette: true,
      showPaletteOnly: true,
      hideAfterPaletteSelect: true,
      color: this.customStyleColor.sticky['background-color'],
      palette: [
        ['#000','#444','#666','#999','#ccc','#eee','#f3f3f3','#fff'],
        ['#f00','#f90','#ff0','#0f0','#0ff','#00f','#90f','#f0f'],
        ['#f4cccc','#fce5cd','#fff2cc','#d9ead3','#d0e0e3','#cfe2f3','#d9d2e9','#ead1dc'],
        ['#ea9999','#f9cb9c','#ffe599','#b6d7a8','#a2c4c9','#9fc5e8','#b4a7d6','#d5a6bd'],
        ['#e06666','#f6b26b','#ffd966','#93c47d','#76a5af','#6fa8dc','#8e7cc3','#c27ba0'],
        ['#c00','#e69138','#f1c232','#6aa84f','#45818e','#3d85c6','#674ea7','#a64d79'],
        ['#900','#b45f06','#bf9000','#38761d','#134f5c','#0b5394','#351c75','#741b47'],
        ['#600','#783f04','#7f6000','#274e13','#0c343d','#073763','#20124d','#4c1130'],
      ],
      change: (color) => {
        $.ajax({
          url: `/car-lists/${this.carList.id}`,
          method: 'put',
          data: {
            car_list: {
              sticky_color: color ? color.toHexString() : '',
            },
          },
          headers: {
            accept : 'application/json',
          },
        }).always((result) => {
          if (result.success) {
            succNotify({ title: this.$t('success') })
            this.bgColor = color.toHexString()
          } else {
            errNotify({ title: this.$t('error') })
          }
        })
      },
    }
    $(`#${this.carList.id}`).spectrum(carListColorPickerSetups)
  },

  computed: {
    ...mapState([
      'currentOperation',
      'currentCarList',
      'highlightedCarList',
      'dragging',
      'settings',
      'tenant',
      'currentUser',
      'canWriteRailroad',
      'canWriteCarPosition',
    ]),
    ...mapGetters([
      'isOperationSelected',
      'manipulationDisabled',
      'carListUnavailable',
      'currentPlantId',
      'isAddonableSelected',
      'isStickyCarListNameEnabled',
      'isCarListViewable',
      'hasClosedOperation',
    ]),
    isRailroadActive() {
      return this.railroad.active
    },
    isStickyNameEnabled() {
      return this.isStickyCarListNameEnabled
    },
    isClamped() {
      return this.carList.clamps === true
    },
    unavailableCarListName() {
      var length = 4 * 4
      if (this.carList.name.length <= length) { return this.carList.name }

      return this.carList.name.substring(0, length) + '...'
    },
    carListStickyName() {
      const charsPerCar = 2.5
      const cars = this.carList.cars.length
      const label = this.carList.name || this.carList.uuid
      if (label.length <= (cars * charsPerCar)) { return label }

      return label.substring(0, (cars * charsPerCar)) + '...'
    },
    viewAddonableLabel() {
      return this.$t('view')
    },
    viewAddonableUrl() {
      const addonable = this.carList.current_addonable

      return `/plants/${this.currentPlantId}/operation/${addonable.operation_id}/addon/${addonable.id}`
    },
    hasAvailableActions() {
      return this.actionsCount > 0
    },
    showActions() {
      if (this.unavailable) { return false }
      if (this.disabledActions === true) { return false }

      return this.hasAvailableActions
    },
    canManipulate() {
      if (this.readOnlyPlant) { return false }
      if (!this.isViewable) { return false }
      if (this.currentUser.external && this.tenant === 'adriafer') { return false }

      return !this.manipulationDisabled && !this.unavailable
    },
    canEdit() {
      return this.canManipulate && this.isRailroadActive && this.canWriteCarPosition && !this.readOnlyPlant
    },
    canOpenParentOperationPopUp() {
      return (this.carList.parent_operation_id && !this.readOnlyPlant) ? true : false
    },
    canMergeLeft() {
      return (
        !this.isClamped
        && this.isRailroadActive
        && this.canManipulate
        && this.carList.prevCarListUuid !== undefined
        && this.carList.prevCarList.clamps !== true
        && !this.carListUnavailable(this.carList.prevCarList)
        && this.canWriteCarPosition
        && !this.readOnlyPlant
      )
    },
    canMergeRight() {
      return (
        !this.isClamped
        && this.isRailroadActive
        && this.canManipulate
        && this.carList.nextCarListUuid !== undefined
        && this.carList.nextCarList.clamps !== true
        && !this.carListUnavailable(this.carList.nextCarList)
        && this.canWriteCarPosition
        && !this.readOnlyPlant
      )
    },
    canReleaseWithClamps() {
      if (this.readOnlyPlant) { return false }
      if (!this.isRailroadActive) { return false }
      if (this.settings.allow_bracked_car_list_release) { return true }

      return !this.hasClamps
    },
    canReleaseWithoutAnOperation() {
      if (this.readOnlyPlant) { return false }
      if (!this.isRailroadActive) { return false }

      return !this.settings.mandatory_operation_for_manoeuvres
    },
    canRelease() {
      return (
        this.canManipulate
        && (this.isOperationSelected || this.canReleaseWithoutAnOperation)
        && this.canReleaseWithClamps
        && this.railroad.accepts_departures
        && this.isRailroadActive
        && this.canWriteCarPosition
        && !this.readOnlyPlant
      )
    },
    canInfo() {
      return this.tenant !== 'sncfr'
    },
    canInvert() {
      return this.hasCars &&
        this.canManipulate &&
        !this.inverted &&
        this.isRailroadActive &&
        this.canWriteCarPosition &&
        !this.readOnlyPlant
    },
    canSendH30() {
      return (
        this.settings.enable_verform_sync
        && this.canManipulate
        && this.isRailroadActive
        && (this.isOperationSelected || this.canReleaseWithoutAnOperation)
        && this.canWriteRailroad
        && !this.readOnlyPlant
      )
    },
    canCut() {
      return this.hasCars &&
        this.cars.length > 2 &&
        this.canManipulate &&
        !this.hasClamps &&
        this.canWriteCarPosition &&
        !this.readOnlyPlant
    },
    canManageRat() {
      return this.tenant === 'sncfr' && this.canWriteRailroad && !this.readOnlyPlant
    },
    cars() {
      return this.carList.cars
    },
    hasCars() {
      return this.cars.length > 1
    },
    hasClamps() {
      return this.carList.clamps
    },
    processing() {
      return this.carList.processing
    },
    isCurrentCarList() {
      if (!this.currentOperation) { return false }
      if (!this.isAddonableSelected) { return false }

      return this.currentCarList && Number(this.currentCarList.id) === Number(this.carList.id)
    },
    isCarListHighlighted() {
      if (this.isCurrentCarList) { return false }

      return this.highlightedCarList
        && Number(this.highlightedCarList.id) === Number(this.carList.id)
    },
    cssClass() {
      let cssClass = ''

      if (this.carList.position !== undefined) {
        cssClass += this.carList.position
      }

      if (!this.isCurrentCarList && this.unavailable) {
        cssClass += ' unavailable'
      }

      if (this.focus && !this.dragging) {
        cssClass += ' focus'
      }
      if (this.isCurrentCarList) { cssClass += ' active' }
      if (this.isCarListHighlighted) { cssClass += ' highlight' }
      if (this.isStickyNameEnabled) { cssClass += ' with-sticky-name' }
      if (!this.isViewable && this.currentUser.external) { cssClass += ' not-viewable' }
      if (this.hasClosedOperation) { cssClass += ' closed-operation' }

      return cssClass
    },
    customStyleColor() {
      const bgColor = this.bgColor || this.carList.sticky_color
      const highPriorityColorNeeded = this.isCurrentCarList || this.isCarListHighlighted
      const companyColor = this.settings.enable_company_color_as_default_in_plant &&
        !highPriorityColorNeeded &&
        this.carList?.railway_company_color
      return {
        wrapper: companyColor ? {'border-color': companyColor} : null,
        sticky: companyColor ? {'background-color': companyColor} : {'background-color': bgColor},
      }
    },
    actionsCount() {
      let actionsCounter = 0
      if (this.canInfo) actionsCounter += 1
      if (this.canEdit) actionsCounter += 1
      if (this.canMergeLeft) actionsCounter += 1
      if (this.canRelease) actionsCounter += 1
      if (this.canInvert || this.inverted) actionsCounter += 1
      if (this.canMergeRight) actionsCounter += 1
      if (this.canSendH30) actionsCounter += 1
      if (this.canCut) actionsCounter += 1
      if (this.canManageRat) actionsCounter += 1

      return actionsCounter
    },
    cssClassForUnavailable() {
      return 'actions-counter-5'
    },
    //TODO improve css, now using a different class for different number of icons, can be improved to use a single class
    cssClassForActionsCounter() {
      let count = this.actionsCount + 2
      if (count < 4) { count = 4 }

      return 'actions-counter-' + count
    },
    cssClassForSingleAction() {
      const classes = ['car-list-action']

      if (this.actionsCount === 1) {
        classes.push('width-2x')
      }

      return classes.join(' ')
    },
    isViewable() {
      return this.isCarListViewable(this.carList)
    },
    hasClosedOperation() {
      return this.carList.has_closed_operation
    },
    unavailable() {
      if (!this.isViewable) { return false }

      return this.carListUnavailable(this.carList)
    },
  },

  methods: {
    handleUnavailableClick() {
      window.location.href = this.viewAddonableUrl
    },
    handleClick() {
      if (!this.unavailable) {
        this.$store.dispatch('setAndUpdateAddonableCarList', this.carList)
      }
    },
    activateActions() {
      if(this.disableInteraction) { return }

      this.focus = true
      this.inverted = false
    },
    deactivateActions() {
      this.focus = false
      this.inverted = false
    },
    openParentOperationPopUp() {
      window.location.href = `/plants/${this.currentPlantId}/operation/${this.carList.parent_operation_id}`
    },
    mergeLeft(e) {
      e.stopPropagation()

      this
        .confirmMovement()
        .then(confirmed => {
          if (confirmed) {
            this.$store.dispatch('carListsMergeModal', {
              carList: this.carList,
              direction: 'left',
            })
          }
        })
    },
    mergeRight(e) {
      e.stopPropagation()

      this
        .confirmMovement()
        .then(confirmed => {
          if (confirmed) {
            this.$store.dispatch('carListsMergeModal', {
              carList: this.carList,
              direction: 'right',
            })
          }
        })
    },
    handleCutAction(e) {
      e.stopPropagation()

      this
        .confirmMovement()
        .then(confirmed => {
          if (confirmed) {
            this.$store.dispatch('showCarListSplitAtModal', this.carList)
          }
        })
    },
    handleRatConfirmation(e) {
      e.stopPropagation()

      this
        .confirmMovement()
        .then(confirmed => {
          if (confirmed) {
            this.$store.dispatch('showRatConfirmatorModal', this.carList)
          }
        })
    },
    info(e) {
      e.stopPropagation()

      const currentOperationId = this.isOperationSelected
        ? this.currentOperation.id
        : ''
      Manovre.openCarListPreview(this.carList.id, currentOperationId)
    },
    invert(e) {
      e.stopPropagation()
      this
        .confirmMovement()
        .then(confirmed => {
          if (confirmed) {
            this.inverted = true
            this.$store.dispatch('carListInvert', {
              carListUuid: this.carList.uuid,
              railroadId: this.railroadId,
            })
          }
        })
    },
    edit(e) {
      e.stopPropagation()

      this
        .confirmMovement()
        .then(confirmed => {
          if (confirmed) {
            this.$store.commit('editingCarList', this.carList)
          }
        })
    },
    release(e) {
      e.stopPropagation()

      this
        .confirmMovement()
        .then(confirmed => {
          if (confirmed) {
            this.$store.commit('releasingCarList', this.carList)
          }
        })
    },
    sendH30OutboundMessage(e) {
      e.stopPropagation()

      this
        .confirmMovement()
        .then(confirmed => {
          if (!confirmed) { return }

          const currentOperationId = this.isOperationSelected
            ? this.currentOperation.id
            : 'missing'
          const { update_token } = this.railroad
          const url = `/car-lists/${this.carList.id}/h30_outbound_messages/new?update_token=${update_token}`

          $.ajax({
            url: url,
            data: {operation_id: currentOperationId},
            dataType: 'script',
            method: 'get',
          })
        })
    },
  },
}
</script>
