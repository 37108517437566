export const SHOW_OPTIMIZED_TIMES = 'SHOW_OPTIMIZED_TIMES'
export const HIDE_OPTIMIZED_TIMES = 'HIDE_OPTIMIZED_TIMES'

export const SET_OPERATIONS_DATA = 'SET_OPERATIONS_DATA'
export const SET_CURRENT_PAGE_OPERATION_IDS = 'SET_CURRENT_PAGE_OPERATION_IDS'
export const SET_CURRENT_SORT = 'SET_CURRENT_SORT'

export const ADD_OPERATION_TO_OPTIMIZE = 'ADD_OPERATION_TO_OPTIMIZE'
export const REMOVE_OPERATION_TO_OPTIMIZE = 'REMOVE_OPERATION_TO_OPTIMIZE'
export const ADD_MULTIPLE_OPERATION_TO_OPTIMIZE = 'ADD_MULTIPLE_OPERATION_TO_OPTIMIZE'
export const REMOVE_MULTIPLE_OPERATION_TO_OPTIMIZE = 'REMOVE_MULTIPLE_OPERATION_TO_OPTIMIZE'
export const SET_OPERATIONS_TO_OPTIMIZE = 'SET_OPERATIONS_TO_OPTIMIZE'
export const RESET_OPERATIONS_TO_OPTIMIZE = 'RESET_OPERATIONS_TO_OPTIMIZE'

export const SET_OPTIMIZED_TIMES_DATA = 'SET_OPTIMIZED_TIMES_DATA'
export const SET_CURRENT_PAGE_OPTIMIZED_TIME_IDS = 'SET_CURRENT_PAGE_OPTIMIZED_TIME_IDS'

export const ADD_OPTIMIZED_TIME_TO_SAVE = 'ADD_OPTIMIZED_TIME_TO_SAVE'
export const REMOVE_OPTIMIZED_TIME_TO_SAVE = 'REMOVE_OPTIMIZED_TIME_TO_SAVE'
export const ADD_MULTIPLE_OPTIMIZED_TIME_TO_SAVE = 'ADD_MULTIPLE_OPTIMIZED_TIME_TO_SAVE'
export const REMOVE_MULTIPLE_OPTIMIZED_TIME_TO_SAVE = 'REMOVE_MULTIPLE_OPTIMIZED_TIME_TO_SAVE'
export const SET_OPTIMIZED_TIMES_TO_SAVE = 'SET_OPTIMIZED_TIMES_TO_SAVE'
export const RESET_OPTIMIZED_TIMES_TO_SAVE = 'RESET_OPTIMIZED_TIMES_TO_SAVE'

export const SET_OPTIMIZED_TIME_PERIODICITY_ACTIVITY_TIME = 'SET_OPTIMIZED_TIME_PERIODICITY_ACTIVITY_TIME'

export const SET_DATE_RANGE = 'SET_DATE_RANGE'
