<template lang='pug'>
  .add-days
    .fields
      .field
        label
          | {{ $t('from') }}
        Datepicker(
          v-model='from'
          v-bind:language='datePickerLocale'
          v-bind:monday-first='true'
          v-bind:full-month-name='true'
          v-bind:disabledDates='disabledDates'
          v-bind:open-date='rangeStart'
          format='dd/MM/yyyy'
        )
    .fields
      .field
        label
          | {{ $t('to') }}
        Datepicker(
          v-model='to'
          v-bind:language='datePickerLocale'
          v-bind:monday-first='true'
          v-bind:full-month-name='true'
          v-bind:disabledDates='disabledDates'
          v-bind:open-date='rangeStart'
          format='dd/MM/yyyy'
        )

    .equal.width.inline.fields.days-of-the-week(v-if='withDayOfTheWeek')
      .field.boolean
        label(for='monday') {{ $t('days.monday') }}
        input(type='checkbox' id='monday' v-model='monday')
      .field.boolean
        label(for='tuesday') {{ $t('days.tuesday') }}
        input(type='checkbox' id='tuesday' v-model='tuesday')
      .field.boolean
        label(for='wednesday') {{ $t('days.wednesday') }}
        input(type='checkbox' id='wednesday' v-model='wednesday')
      .field.boolean
        label(for='thursday') {{ $t('days.thursday') }}
        input(type='checkbox' id='thursday' v-model='thursday')
      .field.boolean
        label(for='friday') {{ $t('days.friday') }}
        input(type='checkbox' id='friday' v-model='friday')
      .field.boolean
        label(for='saturday') {{ $t('days.saturday') }}
        input(type='checkbox' id='saturday' v-model='saturday')
      .field.boolean
        label(for='sunday') {{ $t('days.sunday') }}
        input(type='checkbox' id='sunday' v-model='sunday')

    .ui.red.message(v-if='errorMessage')
      | {{ errorMessage }}
    .ui.buttons
      .ui.tiny.button(v-on:click='cancel')
        | {{ $t('actions.rollback') }}
      .ui.green.button(v-on:click='confirm' v-if='valid')
        | {{ $t('actions.update') }}
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'

export default {
  components: {
    Datepicker,
  },

  props: {
    withDayOfTheWeek: Boolean,
    disabledDates: Object,
    rangeStart: Date,
    weekDays: Object,
    datePickerLocale: Object,
  },

  data() {
    return {
      from: null,
      to: null,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    }
  },

  computed: {
    valid() {
      return this.areToAndFromPresent &&
        this.areToAfterFrom &&
        (!this.withDayOfTheWeek || this.atLeastOneWeekdaySelected)
    },
    areToAndFromPresent() {
      return this.from !== null && this.to !== null
    },
    areToAfterFrom() {
      return this.to > this.from
    },
    atLeastOneWeekdaySelected() {
      return this.monday || this.tuesday || this.wednesday ||
        this.thursday || this.friday || this.saturday || this.sunday
    },
    errorMessage() {
      if (this.valid) {
        return
      } else if (this.areToAndFromPresent && !this.areToAfterFrom) {
        return this.$t('errors.to_must_be_after_from')
      } else if (this.withDayOfTheWeek && !this.atLeastOneWeekdaySelected) {
        return this.$t('errors.at_least_one_weekday_selected')
      }
    },
    daysToAdd() {
      if (!this.valid) return []

      let daysToAdd = []
      let from = moment(this.from).hours(12)
      let to = moment(this.to).hours(12)
      let dayOfTheWeekIndex

      while (from <= to) {
        dayOfTheWeekIndex = moment(from).isoWeekday()

        if (this.withDayOfTheWeek) {
          if ((dayOfTheWeekIndex === 1 && this.monday && this.weekDays.monday) ||
            (dayOfTheWeekIndex === 2 && this.tuesday && this.weekDays.tuesday) ||
            (dayOfTheWeekIndex === 3 && this.wednesday && this.weekDays.wednesday) ||
            (dayOfTheWeekIndex === 4 && this.thursday && this.weekDays.thursday) ||
            (dayOfTheWeekIndex === 5 && this.friday && this.weekDays.friday) ||
            (dayOfTheWeekIndex === 6 && this.saturday && this.weekDays.saturday) ||
            (dayOfTheWeekIndex === 7 && this.sunday && this.weekDays.sunday)) {

            daysToAdd.push(moment(from).format())
          }
        } else {
          if ((dayOfTheWeekIndex === 1 && this.weekDays.monday) ||
            (dayOfTheWeekIndex === 2 && this.weekDays.tuesday) ||
            (dayOfTheWeekIndex === 3 && this.weekDays.wednesday) ||
            (dayOfTheWeekIndex === 4 && this.weekDays.thursday) ||
            (dayOfTheWeekIndex === 5 && this.weekDays.friday) ||
            (dayOfTheWeekIndex === 6 && this.weekDays.saturday) ||
            (dayOfTheWeekIndex === 7 && this.weekDays.sunday)) {

            daysToAdd.push(moment(from).format())
          }
        }
        from = from.clone().add(1, 'd')
      }

      return daysToAdd
    },
  },

  methods: {
    cancel() {
      this.$emit('closeOption')
    },
    confirm() {
      this.$emit('addDaysToExcluded', this.daysToAdd)
      this.$emit('closeOption')
    },
  },
}
</script>

<style lang='sass'>
  .add-days
    margin-top: 15px
    .ui.buttons
      margin-top: 10px

  .ui.form .inline.fields.days-of-the-week .field > label
    display: block
    margin: 0 !important
</style>
