<template lang='pug'>
  button(v-if='isPlannedShuntingOperation' v-on:click='handleClick' v-bind:class='cssClass')
    i.random.icon
    | {{ label }}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'isPlannedShuntingOperation',
      'currentOperationId',
    ]),
    cssClass() {
      return 'ui button small teal'
    },
    label() {
      return this.$t('planned_shunting')
    },
  },
  methods: {
    handleClick() {
      Manovre.openPlannedShuntingPreview(this.currentOperationId)
    },
  },
}
</script>
