import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState([
      'currentOperation',
    ]),

    ...mapGetters([
      'isConfirmabledManovreWithoutOperationEnabled',
    ]),

    isMovementConfirmationRequired() {
      if (this.currentOperation) { return false }

      return this.isConfirmabledManovreWithoutOperationEnabled
    },
  },

  methods: {
    confirm({ message }) {
      return new Promise((resolve, _reject) => {
        this.$confirm({
          message,
          button: {
            yes: this.$t('confirmable.confirm'),
            no:  this.$t('confirmable.cancel'),
          },
          callback: resolve,
        })
      })
    },

    // Without a current operation a confirmation message is required
    confirmMovement() {
      if (!this.isMovementConfirmationRequired) {
        return Promise.resolve(true)
      }

      return this.confirm({
        message: this.$t('movements.confirmation_message'),
      })
    },
  },
}
