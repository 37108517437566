<template lang="pug">
.row-content(:style="cssVars")
  TrainsTimelineUnit(
    v-for="timelineUnit in filteredAndSortedTrainsTimelineUnits",
    :key="timelineUnit.id",
    :trainsTimelineUnit="timelineUnit",
    :topOffset="topOffset(timelineUnit)",
    :showOptimizationMethod="showOptimizationMethod",
    v-once
  )
</template>

<script>
import TrainsTimelineUnit from './TrainsTimelineUnit.vue'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'RowContent',
  components: {
    TrainsTimelineUnit,
  },
  props: {
    railroad: {
      type: Object,
      default: undefined,
    },
    showOptimizationMethod: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.resetRailroadSubRows(this.railroad.id)
  },
  emits: ['sub-row-count:changed'],
  computed: {
    ...mapState('trainsTimeline', [
      'rowHeight',
      'currentDate',
      'columnWidth',
    ]),
    ...mapGetters('trainsTimeline', [
      'getTrainsTimelineUnitByIds',
      'getSubRowByIndex',
      'findFirstAvailableSubRow',
      'getNextSubRowIndex',
      'getSubRowsCount',
      'subRowHeight',
    ]),
    filteredAndSortedTrainsTimelineUnits() {
      if (!this.railroad) { return [] }
      const trainsTimelineUnits = this.getTrainsTimelineUnitByIds(this.railroad.trainsTimelineUnitIds)
        .filter(unit => {
          const startsAt = this.$dayjs(unit.startsAt)
          const endsAt = this.$dayjs(unit.endsAt)
          const currentDate = this.$dayjs(this.currentDate)
          return startsAt
            .isBetween(
              currentDate.startOf('day'),
              currentDate.endOf('day')
            )
            || endsAt
              .isBetween(
                currentDate.startOf('day'),
                currentDate.endOf('day')
              )
            || currentDate.startOf('day')
              .isBetween(startsAt, endsAt)
        })
      return [...trainsTimelineUnits].sort((a, b) => this.$dayjs(a.startsAt) - this.$dayjs(b.startsAt))
    },
    adaptedRowHeight() {
      return (this.rowHeight - this.subRowHeight) + this.subRowHeight * this.getSubRowsCount(this.railroad.id)
    },
    cssVars() {
      return {
        '--min-height': `${this.rowHeight}px`,
        '--height': `${this.adaptedRowHeight}px`,
        '--column-width': `${this.columnWidth}px`,
      }
    },
  },
  methods: {
    ...mapActions('trainsTimeline', [
      'upsertRailroadSubRow',
      'resetRailroadSubRows',
    ]),
    topOffset(trainsTimelineUnit) {
      let subRowIndex = this.findFirstAvailableSubRow(this.railroad.id, trainsTimelineUnit.startsAt)?.index

      if (typeof subRowIndex === 'undefined' || subRowIndex === null || subRowIndex < 0) {
        subRowIndex = this.getNextSubRowIndex(this.railroad.id)
      }

      this.upsertRailroadSubRow({ railroadId: this.railroad.id, index: subRowIndex, end: trainsTimelineUnit.endsAt })
      return subRowIndex * this.subRowHeight
    },
  },
}
</script>

<style lang="sass" scoped>
.row-content
  display: flex
  align-items: center
  position: relative
  width: 100%
  min-height: var(--min-height)
  height: var(--height)
  background-color: rgba(255, 255, 255, 0.9)
  background-image: linear-gradient(90deg, transparent 0%, transparent calc(100% - 2px), #ddd calc(100% - 2px), #ddd 100%), linear-gradient(90deg, transparent 0%, transparent calc(100% - 1px), #eee calc(100% - 1px), #eee 100%)
  background-size: calc(var(--column-width) * 4), var(--column-width) // IMPROVEMENT: change dynamically based on current granularity
</style>
