<template lang="pug">
div
  .ui.buttons
    .ui.tiny.button(v-on:click='previousPage')
      i.icon.chevron.left
    .ui.tiny.button.blue
      | {{ pageData }}
    .ui.tiny.button(v-on:click='nextPage')
      i.icon.chevron.right

</template>

<script>
export default {
  components: {},
  props: {
    page: Number,
  },
  data() {
    return {
      pageData: 1,
    }
  },
  methods: {
    previousPage() {
      if(this.pageData > 1){
        this.pageData -= 1
        this.$emit('pageChanged', this.pageData)
      }
    },
    nextPage() {
      this.pageData += 1
      this.$emit('pageChanged', this.pageData)
    },
  },
}
</script>
