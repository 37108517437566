<template lang='pug'>
  date-picker(
    :lang='currentLocale'
    :clearable='false'
    v-model='currentDate'
    format='DD/MM/YYYY'
    value-type='format'
    @input='dateChanged'
  )
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import DatePicker from 'vue2-datepicker'

function localize(date) {
  if (!date || date.toString().length === 0) { return }

  return `${date.substring(8, 10)}/${date.substring(5, 7)}/${date.substring(0, 4)}`
}

function delocalize(date) {
  if (!date || date.toString().length === 0) { return }

  return `${date.substring(6, 10)}-${date.substring(3, 5)}-${date.substring(0, 2)}`
}

export default {
  components: { DatePicker },

  computed: {
    ...mapState([
      'currentDate',
      'currentOperationType',
      'plantId',
    ]),
    currentDate: {
      get() {
        return localize(this.$store.state.currentDate)
      },
      set(value) {
        this.$store.commit('updateDateFromPicker', delocalize(value))
      },
    },
    currentLocale() {
      return gon.locale
    },
  },
  methods: {
    ...mapMutations([
      'setLoading',
    ]),
    dateChanged() {
      this.reset()
    },
    reset() {
      this.setLoading()
      let url = ''
      if(this.currentOperationType || this.currentOperationType === 0)
        url = `/plants/${this.plantId}/?q[done_on_eq]=${this.currentDate}&q[typology_eq]=${this.currentOperationType}`
      else
        url = `/plants/${this.plantId}/?q[done_on_eq]=${this.currentDate}`
      window.location.href = url
    },
  },
}
</script>
