import { updateAddonableCarList as updateWithCarList } from '../../api/addonables'
import OpApi from '../../api/operations'

export default {
  setAndUpdateAddonableCarList(context, carList) {
    const oldCarList = context.getters.currentCarList
    context.dispatch('setCarList', carList)
    const { currentAddonable, currentOperation } = context.state
    if (!currentAddonable || !currentOperation) { return }

    context.commit('addonablesFormLoading', true)

    context
      .dispatch('updateAddonableCarList', carList)
      .then(() => {
        context.commit('resetCarListCurrentAddonable', oldCarList)
        context.dispatch('loadAddonablesForm', {
          operationId: currentOperation.id,
          addonableId: currentAddonable.id,
        })
      })
  },

  updateAddonableCarList(context, carList) {
    const addonable = context.state.currentAddonable
    if (!addonable) { return Promise.reject() }

    return updateWithCarList({
      apiToken: context.state.apiToken,
      addonableId: addonable.id,
      carListId: carList.id,
    }).catch((error) => { console.error(error) })
  },

  selectFirstAddonable(context) {
    if (context.state.addonables.length === 0) { return }

    context.commit('setAddonable', context.state.addonables[0])
  },

  deselectAddonable(context) {
    context.commit('unloadAddonable')
  },

  fetchAddonables(context, operation) {
    if (!operation) {
      context.commit('setAddonables', [])
      return
    }

    return OpApi
      .fetchAddonables({ apiToken: context.state.apiToken, operationId: operation.id })
      .then((result) => { context.commit('setAddonables', result.data)  })
      .catch((error) => { console.error(error) })
  },

  fetchAddonable(context, addonableId) {
    return OpApi
      .fetchAddonable({ apiToken: context.state.apiToken, addonableId })
      .then((result) => {
        context.commit('setAddonable', result.data.operation_addonable)
        context.dispatch('setCarList', result.data.operation_addonable.car_list)
      })
      .catch((error) => { console.error(error) })
  },

  loadAddonablesCollectionForm(context, operation) {
    context.commit('addonablesFormShown', false)
    context.commit('unloadAddonable')

    context.commit('addonablesCollectionFormLoading', true)
    const operationId = operation.id

    OpApi.getAddonablesCollectionForm({ operationId }).done((result) => {
      context.commit('addonablesCollectionFormContent', result.content)
      context.commit('addonablesCollectionFormLoading', false)
    })
  },

  loadAddonablesForm(context, { operationId, addonableId }) {
    context.commit('addonablesFormLoading', true)
    context.commit('addonablesFormShown', true)

    const { id } = context.state.currentAddonable || {}
    if (!id || Number(id) !== Number(addonableId)) {
      context.commit('resetAddonablesFormDirtyState')
    }
    // Also load current addonable
    context.dispatch('fetchAddonable', addonableId)

    OpApi.getAddonablesEditForm({ operationId, addonableId }).done((result) => {
      context.commit('addonablesFormContent', result.content)
      context.commit('addonablesFormLoading', false)
    })
  },

  reloadAddonablesForm(context) {
    const { currentOperation, currentAddonable, addonablesFormIsShown } = context.state
    if (!currentOperation || !currentAddonable || !addonablesFormIsShown) { return }

    context.dispatch('loadAddonablesForm', {
      addonableId: currentAddonable.id, operationId: currentOperation.id,
    })
  },
}
