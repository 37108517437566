<template lang="pug">
.save-optimization-table
  .table-container-scrollable
    table.ui.compact.table.unstackable.max-content
      thead
        th: input#all-defaults-to-save-toggler(
          type="checkbox",
          :checked="areAllDefaultsToSaveChecked",
          @change="toggleAllDefaultsToSave()"
        )
        th Traccia
        th Orario
        th A/P
        th Terminal
        th Luogo
        th Giorni di Circolazione
        template(v-for="day in days")
          th.column--day {{ $t(day) }}
          th.column--datetime Pilotaggio Inizio
          th.column--datetime Pilotaggio Fine
          th.column--datetime Secondaria Inizio
          th.column--datetime Secondaria Fine
      tbody
        tr(v-for="(optimizedTime, index) in optimizedTimesData")
          td: input(
            type="checkbox",
            :id="optimizedTime.id",
            :value="optimizedTime.id",
            :checked="areDefaultsToSaveChecked(optimizedTime)",
            @change="toggleDefaultsToSave(optimizedTime)"
          )
          td {{ optimizedTime.train }}
          td {{ formatPeriod(optimizedTime.programmed_hour) }}
          td {{ optimizedTime.typology }}
          td {{ optimizedTime.terminal }}
          td {{ optimizedTime.station }}
          td: ActiveWeekDays(:optimized-time="optimizedTime")
          template(v-for="(day, index) in days")
            td.column--day(:class="{ 'column--odd': isOdd(index + 1) }")
              input(
                type="checkbox",
                :id="`${optimizedTime.id}_${day}`",
                :checked="isOptimizedTimeToBeSaved(optimizedTime, day)",
                @change="toggleOptimizedTimeToSave({ optimizedTime, day })"
              )

            td.column--datetime(:class="{ 'column--odd': isOdd(index + 1) }")
              EditableDatetime(
                :optimized-time="optimizedTime",
                :day="day",
                field="piloting_activity_starts_at"
              )
            td.column--datetime(:class="{ 'column--odd': isOdd(index + 1) }")
              EditableDatetime(
                :optimized-time="optimizedTime",
                :day="day",
                field="piloting_activity_ceases_at"
              )
            td.column--datetime(:class="{ 'column--odd': isOdd(index + 1) }")
              EditableDatetime(
                :optimized-time="optimizedTime",
                :day="day",
                field="secondary_activity_starts_at"
              )
            td.column--datetime(:class="{ 'column--odd': isOdd(index + 1) }")
              EditableDatetime(
                :optimized-time="optimizedTime",
                :day="day",
                field="secondary_activity_ceases_at"
              )

      tfoot.full-width
  Pagination(@pagination:page:changed="handlePageChanged")
</template>

<script>
import ActiveWeekDays from'./ActiveWeekDays.vue'
import EditableDatetime from'./EditableDatetime.vue'
import Pagination from'../pagination/Pagination.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import { DAYS } from '../modules/calendar_constants'
import { formatPeriod, isOdd } from './utils'

export default {
  data() {
    return {
      days: DAYS,
    }
  },
  components: {
    ActiveWeekDays,
    EditableDatetime,
    Pagination,
  },
  computed: {
    ...mapState('operationsPlansOptimizer', [
      'optimizedTimesData',
    ]),
    ...mapGetters('operationsPlansOptimizer', [
      'isOptimizedTimeToBeSaved',
      'areDefaultsToSaveChecked',
      'areAllDefaultsToSaveChecked',
    ]),
  },
  methods: {
    ...mapActions('operationsPlansOptimizer', [
      'setOptimizedTimesToSaveArrays',
      'toggleOptimizedTimeToSave',
      'toggleDefaultsToSave',
      'toggleAllDefaultsToSave',
      'showLastOptimized',
    ]),
    formatPeriod,
    isOdd,
    async handlePageChanged() {
      await this.showLastOptimized()
      this.setOptimizedTimesToSaveArrays()
      this.setAllDefaultsToSave(false)
    },
  },
}
</script>

<style lang="sass" scoped>
.save-optimization-table
  .max-content
    width: max-content !important

  .ui.table thead .column--day,
  .ui.table tbody .column--day
    min-width: 84px
    text-align: center

  .ui.table thead .column--datetime,
  .ui.table tbody .column--datetime
    min-width: 170px

  .ui.table thead .column--odd,
  .ui.table tbody .column--odd
    background: #fafafa

  .table-container-scrollable
    max-height: 79vh
</style>
