<template lang="pug">
.timeline-time(:style="cssVars")
  .time__hours(v-if="showHours") {{ hours }}
  .time__minutes(v-show="internalShowMinutes") {{ minutes }}
</template>

<script>
export default {
  name: 'PureTimelineTime',
  props: {
    time: {
      type: Date,
      default: () => new Date(),
    },
    showMinutes: {
      type: Boolean,
      default: () => true,
    },
    onlyOClockHours: {
      type: Boolean,
      default: () => true,
    },
    width: {
      type: Number,
      default: 40,
    },
  },
  computed: {
    hours() {
      return this.$dayjs(this.time).format('HH')
    },
    minutes() {
      return this.$dayjs(this.time).format('mm')
    },
    showHours() {
      if(!this.onlyOClockHours) { return true }
      return this.isOClock
    },
    isOClock() {
      return this.minutes === '00'
    },
    internalShowMinutes() {
      return this.showMinutes || !this.showHours
    },
    cssVars() {
      return {
        '--width': `${this.width}px`,
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.timeline-time
  display: flex
  align-items: center
  justify-content: flex-start
  flex: 0 0 auto
  font-family: var(--monospace-font-family)
  width: var(--width)

  .time__hours
    font-size: 14px

  .time__minutes
    font-size: 11px
    color: #999

  .time__hours + .time__minutes
    margin-left: 2px
</style>
