import Axios from 'axios'

export const updateAddonableCarList = ({ apiToken, addonableId, carListId }) => {
  return Axios.put(`/v1/addonables/${addonableId}/update_car_list`, {
    car_list_id: carListId,
  }, {
    headers: { 'X-AUTH-TOKEN': apiToken },
  }).then((response) => {
    if (response.status === 200) { return Promise.resolve(response.data) }

    return Promise.reject(new Error(`Page status ${response.status}`))
  })
}

export default {
  updateAddonableCarList,
}
