export default {
  isMergingRailroadCarLists(state) {
    return state.bulkRailroadCarListsMerge !== false
  },

  railroads(state) {
    return state.railroadGroups.flatMap((rrg) => (
      (rrg.railroads || []).map((rr) => ({
        railroadGroup: rrg,
        label: [rrg.name, rr.name].join(' / '),
        ...rr,
      }))
    ))
  },
}

