<template lang="pug">
.paired-car-list-table
  .table-container-scrollable
    table.ui.compact.table.unstackable.max-content
      thead
        th
        th
        th {{ $t('operation') }}
        th {{ $t('addonable_stop') }}
        th {{ $t('start') }}
        th {{ $t('end') }}
        th {{ $t('plant') }}
        th {{ $t('railroad_group') }}
        th {{ $t('railroad') }}

      tbody
        tr(v-for="(row) in filteredRows")
          td(v-if="isEditable(addonablesKeyId(row.optimizationId, row.opId || row.stopId))")
            .ui.tiny.icon.button.red(v-on:click='removeRowFromEditables(addonablesKeyId(row.optimizationId, row.opId || row.stopId))')
              i.icon.remove
          td(v-else)
            .ui.tiny.icon.button(v-on:click='addRowToEditables(addonablesKeyId(row.optimizationId, row.opId || row.stopId))')
              i.icon.pencil
          td: input(
            type="checkbox",
            :id="addonablesKeyId(row.optimizationId, row.opId || row.stopId)",
            :value="addonablesKeyId(row.optimizationId, row.opId || row.stopId)",
            v-model="checkedOptimizations"
          )
          td {{ row.optimizationTitle }}
          td {{ row.opId || row.stopId }}
          td(v-if="isEditable(addonablesKeyId(row.optimizationId, row.opId || row.stopId))")
            DatePicker(
              v-bind="datePickerProps",
              v-model="row.startDate",
              v-on:close="notifyUpdatePeriodicity(row, 'dataOraInizio', row.startDate)"
            )
          td(v-else)
            | {{ row.startDate }}

          td(v-if="isEditable(addonablesKeyId(row.optimizationId, row.opId || row.stopId))")
            DatePicker(
              v-bind="datePickerProps",
              v-model="row.finishDate",
              v-on:close="notifyUpdatePeriodicity(row, 'dataOraFine', row.finishDate)"
            )
          td(v-else)
            | {{ row.finishDate }}

          td(v-if="isEditable(addonablesKeyId(row.optimizationId, row.stopId))")
            vSelect(
              class='ui-vue-select'
              :options='plantsOptions'
              v-model='row.stopPlant'
              :reduce="plant => plant.label"
              v-bind:placeholder='$t("plant")'
              v-on:input="notifyUpdatePeriodicity(row, 'impianto', row.stopPlant)"
            )
          td(v-else)
            | {{ row.stopPlant }}

          td(v-if="isEditable(addonablesKeyId(row.optimizationId, row.stopId))")
            vSelect(
              class='ui-vue-select'
              :options='railroadGroupsOptions[row.stopPlant]'
              v-model='row.stopBinaryGroup'
              :reduce="railroad_group => railroad_group.label"
              v-bind:placeholder='$t("railroad_group")'
              v-on:input="notifyUpdatePeriodicity(row, 'gruppoBinario', row.stopBinaryGroup)"
            )
          td(v-else)
            | {{ row.stopBinaryGroup }}

          td(v-if="isEditable(addonablesKeyId(row.optimizationId, row.stopId))")
            vSelect(
              class='ui-vue-select'
              :options='railroadsOptions[row.stopBinaryGroup]'
              v-model='row.stopBinaryName'
              :reduce="railroad => railroad.label"
              v-bind:placeholder='$t("railroad")'
              v-on:input="notifyUpdatePeriodicity(row, 'nomeBinario', row.stopBinaryName)"
            )
          td(v-else)
            | {{ row.stopBinaryName }}



      tfoot.full-width
</template>

<script>
import Pagination from '../pagination/Pagination.vue'
import DatePicker from 'vue2-datepicker'
import vSelect from 'vue-select'
const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm'

export default {
  components: {
    Pagination,
    DatePicker,
    vSelect,
  },
  data() {
    return {
      checkedOptimizations: [],
      editableRows: [],
      plantsOptions: [],
      railroadGroupsOptions: [],
      railroadsOptions: [],
      optimizationsData: [],
      datePickerProps: {
        'class': 'wide',
        'lang': this.$i18n.locale.substring(0, 2),
        'format': DATETIME_FORMAT,
        'value-type': DATETIME_FORMAT,
        'clearable': false,
        'type': 'datetime',
        'placeholder': '',
        'time-picker-options': { start: '00:00', step: '00:30', end: '23:30' },
      },
    }
  },
  props: {
    optimizations: Array,
    operationFilter: String,
    addonableFilter: String,
  },
  methods: {
    notifyUpdatePeriodicity(row, key, value) {
      this.modifyPeriodicity(row, key, value)
      const optimizationId = row.optimizationId
      const periodicity = row.periodicity
      this.$emit('notifyUpdatePeriodicity', { optimizationId, periodicity })
      this.$forceUpdate()
    },
    setPlantsOptions() {
      this.$http.get('/plants/list_for_select.json').then(response => {
        response.data.plants.forEach((plant) => {
          this.plantsOptions.push({ label: plant.name, code: plant.value })
        })
      })
    },
    setRailroadGroupsOptions() {
      this.$http.get('/admin/railroad_groups/list_for_select.json').then(response => {
        this.railroadGroupsOptions = response.data.railroad_groups
      })
    },
    setRailroadsOptions() {
      this.$http.get('/railroads/list_for_select.json').then(response => {
        this.railroadsOptions = response.data.railroads
      })
    },
    isEditable(optimizationId) {
      return this.editableRows.includes(optimizationId)
    },
    addRowToEditables(optimizationId) {
      this.editableRows.push(optimizationId)
    },
    removeRowFromEditables(optimizationId) {
      this.editableRows = this.editableRows.filter(optimization => optimization !== optimizationId)
    },
    addonablesKeyId(optimizationId, operationOpID) {
      return `${optimizationId}-${operationOpID}`
    },
    modifyPeriodicity(row, key, value) {
      const typeKey = row.type === 'ops' ? 'operations' : 'soste'
      const idKey = row.type === 'ops' ? 'opID' : 'id'
      const rowId = row.opId || row.stopId

      const periodicityItem = row.periodicity[typeKey].find(
        (property) => property[idKey] === rowId
      )
      if (periodicityItem) {
        periodicityItem[key] = value
      }
    },
  },
  mounted() {
    this.optimizationsData = this.optimizations
    this.setPlantsOptions()
    this.setRailroadGroupsOptions()
    this.setRailroadsOptions()
  },
  watch: {
    optimizations(newOptimizations) {
      this.optimizationsData = newOptimizations
    },
    checkedOptimizations(newOptimizations) {
      this.$emit('sendSelectedOptimizations', newOptimizations)
    },
  },
  computed: {
    filteredRows() {
      return this.expandedData.filter(row => {
        const opSearchTerm = this.operationFilter.toLowerCase()
        const idSearchTerm = this.addonableFilter.toLowerCase()
        const title = row.optimizationTitle.toLowerCase()
        const id = (row.opId || row.stopId || '').toLowerCase()
        return title.includes(opSearchTerm) && id.includes(idSearchTerm)
      })
    },
    expandedData() {
      let data = []
      Object.values(this.optimizationsData).forEach((optimization) => {
        Object.values(optimization.periodicity.operations).forEach((operation) => {
          data.push({
            opId: operation.opID,
            optimizationId: optimization.id,
            optimizationTitle: optimization.optimizable.title,
            startDate: operation.dataOraInizio,
            finishDate: operation.dataOraFine,
            periodicity: optimization.periodicity,
            type: 'ops',
          })
        })
        Object.values(optimization.periodicity.soste).forEach((stopover) => {
          data.push({
            stopId: stopover.id,
            optimizationId: optimization.id,
            optimizationTitle: optimization.optimizable.title,
            startDate: stopover.dataOraInizio,
            finishDate: stopover.dataOraFine,
            periodicity: optimization.periodicity,
            stopPlant: stopover.impianto,
            stopBinaryGroup: stopover.gruppoBinario,
            stopBinaryName: stopover.nomeBinario,
            type: 'stop',
          })
        })
      })
      return data
    },
  },
}
</script>

<style lang="sass" scoped>
.paired-car-list-table
  .max-content
    width: max-content !important

  .table-container-scrollable
    max-height: 79vh
</style>
