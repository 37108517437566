<template lang='pug'>
  date-picker(
    :lang='currentLocale'
    :clearable='false'
    v-model='currentDate'
    :disabled='disabled'
    type='datetime'
    format='DD/MM/YYYY HH:mm:ss'
    value-type='date'
    v-on:change='dateChanged'
  )
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import DatePicker from 'vue2-datepicker'

export default {
  components: { DatePicker },

  props: ['date', 'disabled', 'id'],

  data () {
    return {
      currentDate: this.date,
    }
  },

  watch: {
    date: function(newVal, oldVal) {
      this.currentDate = newVal
    },
  },

  computed: {
    ...mapState([
    ]),
    currentLocale() {
      return gon.locale
    },
  },

  methods: {
    dateChanged: function() {
      this.$emit('dateChanged', this.currentDate, this.id)
    },
  },
}
</script>
