<template lang="pug">
.operations-plans-optimizer-header
  .ui.button.gray(@click="callIndex({})", v-if="showOptimizedTimes")
    | Mostra tutti
  .ui.button(@click="showLastOptimized(1)", v-if="!showOptimizedTimes")
    | Mostra ultimi ottimizzati
  .ui.button.blue(
    @click="optimizeSelectedWithGuard",
    v-if="!showOptimizedTimes && operationsPlansToOptimizeCount > 0"
  )
    | Ottimizza Selezionati ({{ operationsPlansToOptimizeCount }} in {{ operationsPlansToOptimizeRowsCount }} righe)
  .ui.button.yellow(
    v-if="!showOptimizedTimes && operationsPlansToOptimizeCount > 0",
    @click="resetOperationsPlansToOptimizeArrays"
  )
    | Deseleziona tutti
  .ui.button.green(
    @click="showSendTimesModal",
    v-if="showOptimizedTimes && optimizedTimesToSaveCount > 0"
  )
    | Salva selezionati ({{ optimizedTimesToSaveCount }} in {{ optimizedTimesToSaveRowsCount }} righe)
  .ui.button.yellow(
    v-if="showOptimizedTimes && optimizedTimesToSaveCount > 0",
    @click="resetOptimizedTimesToSaveArrays"
  )
    | Deseleziona tutti
  FiltersForm
</template>

<script>
import { error as errNotify, success as succNotify } from '../utils/notify'
import { hashOfArraysToArrayPairs } from './utils'
import { mapState, mapGetters, mapActions } from 'vuex'
import FiltersForm from './FiltersForm.vue'

export default {
  components: {
    FiltersForm,
  },
  computed: {
    ...mapState('operationsPlansOptimizer', [
      'showOptimizedTimes',
      'optimizedTimesData',
      'operationsPlansToOptimize',
    ]),
    ...mapGetters('operationsPlansOptimizer', [
      'operationsPlansToOptimizeCount',
      'operationsPlansToOptimizeRowsCount',
      'optimizedTimesToSaveCount',
      'optimizedTimesToSaveRowsCount',
    ]),
  },
  methods: {
    ...mapActions('operationsPlansOptimizer', [
      'callIndex',
      'setOptimizedTimesData',
      'showLastOptimized',
      'resetOperationsPlansToOptimizeArrays',
      'resetOptimizedTimesToSaveArrays',
    ]),
    optimizeSelectedWithGuard() {
      this.$http.get('/operations_plans_optimizer/is_optimizer_busy')
        .then(response => {
          const isOptimizerBusy = response.data.isOptimizerBusy
          if(!isOptimizerBusy || confirm(`Ci sono elaborazioni in corso. Procedere comunque?`)) {
            this.optimizeSelected()
          }
        }).catch(err => {
          errNotify({ title: 'Errore: qualcosa è andato storto' })
        })
    },
    optimizeSelected() {
      this.$http.post('/operations_plans_optimizer/shuntopt', {
        days_to_optimize: hashOfArraysToArrayPairs(this.operationsPlansToOptimize),
      }).then((response) => {
        const parsedResponse = response.data
        if (parsedResponse.status === 'ok') {
          succNotify({ title: 'Richiesta inviata con successo' })
        } else {
          errNotify({ title: 'Errore: qualcosa è andato storto' })
        }
      }).catch(err => {
        errNotify({ title: 'Errore: qualcosa è andato storto' })
      })
    },
    showSendTimesModal() {
      this.$modal.show('send-times-modal')
    },
  },
}
</script>

<style lang="sass">
.operations-plans-optimizer-header
  display: flex
  align-items: center
  margin-bottom: 13px
</style>
