<template lang='pug'>
  .locomotive-brackets-form
    .ui.dividing.header
      small
        i.train.icon
        | {{ $t('locomotive') }}:
        | &nbsp;
        | {{ locomotiveLabel }}

    .ui.grid.centered
      .three.wide.column
        .ui.toggle.checkbox.spaced
          input(
            type='checkbox'
            v-model='clamps'
            v-on:change='handleClampsChange(); checkClampsInputState();'
          )
          label {{ $t('edit_modal.clamps') }}

      .six.wide.column
        vSelect(
          :disabled='!isClamped'
          @search='fetchBracketsOptions'
          @search:focus='fetchBracketsOptions'
          :options='leftBracketsOptions'
          v-model='leftBracket'
          v-bind:value='leftBracket'
          v-on:input='updateBrackets'
          @input="checkClampsInputState()"
        )

      .six.wide.column
        vSelect(
          :disabled='!isClamped'
          @search='fetchBracketsOptions'
          @search:focus='fetchBracketsOptions'
          :options='rightBracketsOptions'
          v-model='rightBracket'
          v-bind:value='rightBracket'
          v-on:input='updateBrackets'
          @input="checkClampsInputState()"
        )
      .three.wide.column

      .six.wide.column
        label {{ $t('edit_modal.left_car_position') }}
        vSelect(
          :disabled='!isClamped || !this.leftBracketCode'
          :options='carOptions'
          :reduce="car => car.code"
          v-model='leftCarPosition'
          v-bind:value='leftCarPosition'
          v-on:input='updateBrackets'
        )
      .six.wide.column
        label {{ $t('edit_modal.right_car_position') }}
        vSelect(
          :disabled='!isClamped || !this.rightBracketCode'
          :options='carOptions'
          :reduce="car => car.code"
          v-model='rightCarPosition'
          v-bind:value='rightCarPosition'
          v-on:input='updateBrackets'
        )

</template>

<script>
import { mapState } from 'vuex'
import { difference } from 'underscore'
import vSelect from 'vue-select'

export default {
  props: {
    locomotive: Object,
    carList: Object,
    onChange: Function,
  },

  components: {
    vSelect,
  },

  data() {
    return {
      clamps: false,
      bracketsOptions: [],
      leftBracket: null,
      rightBracket: null,
      leftCarPosition: null,
      rightCarPosition: null,
    }
  },

  mounted() {
    const { locomotive } = this
    const { left_bracket, right_bracket, left_car_position_id, right_car_position_id } = locomotive

    this.leftBracket = left_bracket
    this.rightBracket = right_bracket
    this.clamps = !!(left_bracket || right_bracket)
    this.leftCarPosition = left_car_position_id
    this.rightCarPosition = right_car_position_id
  },

  computed: {
    ...mapState({
      plantId: 'plantId',
    }),
    carOptions() {
      var carOptions = []
      this.carList.cars.forEach((car) => {
        carOptions.push({ label: this.carId(car), code: car.id })
      })
      return carOptions
    },
    locomotiveLabel() {
      return this.locomotive.formatted_code
    },
    isClamped() {
      return this.clamps === true
    },
    leftBracketCode() {
      return this.leftBracket ? this.leftBracket.code : null
    },
    rightBracketCode() {
      return this.rightBracket ? this.rightBracket.code : null
    },
    leftBracketsOptions() {
      if (!this.rightBracket) {
        return this.bracketsOptions
      }

      return this.bracketsOptions.filter((b) => (b.code !== this.rightBracketCode))
    },
    rightBracketsOptions() {
      if (!this.leftBracket) {
        return this.bracketsOptions
      }

      return this.bracketsOptions.filter((b) => (b.code !== this.leftBracketCode))
    },
    railroadGroupId() {
      return this.carList.railroad_group_id
    },
  },

  methods: {
    carId(car) {
      const carInfo = [
        this.tenant == 'sncfr' && car.resource_type == 'car' ? car.code : null,
        this.tenant != 'sncfr' && car.code ? car.code.slice(-4) : null,
        this.tenant == 'sncfr' && car.resource_type == 'locomotive' ? car.serial : null,
      ].filter(Boolean)

      return carInfo.join('\n')
    },
    handleClampsChange() {
      if (this.isClamped) { return }

      this.leftBracket = null
      this.rightBracket = null

      this.updateBrackets()
    },
    updateBrackets() {
      const {
        locomotive, isClamped, leftBracketCode, rightBracketCode,
      } = this

      if (this.leftBracketCode === this.rightBracketCode) {
        this.rightBracket = null
      }

      // We need both brackets before update them
      if (this.clamps && (!leftBracketCode || !rightBracketCode)) {
        return
      }

      this.$store.dispatch('clampsLocomotive', {
        locomotive,
        clampsValue: isClamped,
        leftBracketId: (isClamped ? leftBracketCode : null),
        rightBracketId: (isClamped ? rightBracketCode : null),
        leftCarPositionId: (isClamped ? this.leftCarPosition : null),
        rightCarPositionId: (isClamped ? this.rightCarPosition : null),
      }).then(() => {
        this.$store.dispatch('reloadAddonablesForm')
        this.$emit('updated', { locomotive, isClamped })

        if (!isClamped) {
          this.leftBracket = null
          this.rightBracket = null
          this.$store.commit('updateLocomotiveBrackets', {
            locomotive,
            leftBracket: null,
            rightBracket: null,
          })
        } else {
          this.$store.commit('updateLocomotiveBrackets', {
            locomotive,
            leftBracket: this.leftBracket,
            rightBracket: this.rightBracket,
            leftCarPosition: this.leftCarPosition,
            rightCarPosition: this.rightCarPosition,
          })
        }
      })
    },
    fetchBrackets(search = '', loading = () => {}) {
      const plantId = this.plantId
      const data = {
        q: { name_or_code_cont: search },
        railroad_group_id: this.railroadGroupId,
        target: 'Locomotive',
      }

      return $.ajax({
        url: `/admin/plants/${plantId}/brackets`,
        method: 'GET',
        dataType: 'json',
        data,
        beforeSend: () => { loading(true) },
        complete: () => { loading(false) },
      })
    },
    fetchBracketsOptions(search = '', loading = () => {}) {
      this.setBracketsOptions([])

      return this
        .fetchBrackets(search, loading)
        .done((data) => {
          this.setBracketsOptions(data.brackets)
        })
    },
    setBracketsOptions(brackets) {
      this.bracketsOptions = brackets
    },
    checkClampsInputState(event) {
      let saneState = true
      const {
        locomotive, clamps, leftBracketCode, rightBracketCode,
      } = this

      if (clamps && (!leftBracketCode || !rightBracketCode)) {
        saneState = false
      }

      this.$emit('changed', { locomotive, saneState })
    },
  },
}
</script>
