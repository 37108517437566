import { sortCarLists } from '../../utils/car-lists'

export default {
  updateLocomotiveBrackets(state, { locomotive, leftBracket, rightBracket, leftCarPosition, rightCarPosition }) {
    const { resource_id } = locomotive;

    (state.railroadGroups || []).forEach(rrg => {
      (rrg.railroads || []).forEach(rr => {
        (rr.car_lists || []).forEach(cr => {
          (cr.cars || []).forEach(c => {
            if (c.resource_type === 'locomotive' && c.resource_id === resource_id) {
              c.left_bracket = leftBracket
              c.right_bracket = rightBracket
              c.left_car_position = leftCarPosition
              c.right_car_position = rightCarPosition
            }
          })
        })
      })
    })

  },

  editingRailroadNote(state, railroad) {
    state.editingCarList = null
    state.splittingCarList = null
    state.uploadingCarList = null
    state.publishingCarList = null
    state.releasingCarList = null
    state.mergingCarList = null
    state.mergingCarListDirection = null
    state.ratConfirmatorModal = null
    state.removingCarFromList = null
    state.removingCarFromListRailroad = null
    state.editRailroadNote = railroad
  },

  removingCarFromList(state, args) {
    state.editingCarList = null
    state.splittingCarList = null
    state.uploadingCarList = null
    state.publishingCarList = null
    state.releasingCarList = null
    state.mergingCarList = null
    state.mergingCarListDirection = null
    state.ratConfirmatorModal = null
    state.removingCarFromList = args.carLists
    state.removingCarFromListRailroad = args.railroad
    state.editRailroadNote = null
  },

  uploadingCarList(state, carList) {
    state.uploadingCarList = carList
    state.publishingCarList = null
    state.editingCarList = null
    state.splittingCarList = null
    state.releasingCarList = null
    state.mergingCarList = null
    state.mergingCarListDirection = null
    state.ratConfirmatorModal = null
    state.removingCarFromList = null
    state.removingCarFromListRailroad = null
    state.editRailroadNote = null
  },

  publishingCarList(state, carList) {
    state.uploadingCarList = null
    state.publishingCarList = carList
    state.editingCarList = null
    state.splittingCarList = null
    state.releasingCarList = null
    state.mergingCarList = null
    state.mergingCarListDirection = null
    state.ratConfirmatorModal = null
    state.removingCarFromList = null
    state.removingCarFromListRailroad = null
    state.editRailroadNote = null
  },

  editingCarList(state, carList) {
    state.editingCarList = carList
    state.splittingCarList = null
    state.uploadingCarList = null
    state.publishingCarList = null
    state.releasingCarList = null
    state.mergingCarList = null
    state.mergingCarListDirection = null
    state.ratConfirmatorModal = null
    state.removingCarFromList = null
    state.removingCarFromListRailroad = null
    state.editRailroadNote = null
  },

  releasingCarList(state, carList) {
    state.releasingCarList = carList
    state.splittingCarList = null
    state.publishingCarList = null
    state.editingCarList = null
    state.mergingCarList = null
    state.mergingCarListDirection = null
    state.ratConfirmatorModal = null
    state.removingCarFromList = null
    state.removingCarFromListRailroad = null
    state.editRailroadNote = null
  },

  mergingCarList(state, { carList, direction }) {
    state.releasingCarList = null
    state.publishingCarList = null
    state.splittingCarList = null
    state.editingCarList = null
    state.mergingCarList = carList
    state.mergingCarListDirection = direction
    state.ratConfirmatorModal = null
    state.removingCarFromList = null
    state.removingCarFromListRailroad = null
    state.editRailroadNote = null
  },

  splittingCarList(state, carList) {
    state.splittingCarList = carList
    state.releasingCarList = null
    state.publishingCarList = null
    state.editingCarList = null
    state.mergingCarList = null
    state.ratConfirmatorModal = null
    state.removingCarFromList = null
    state.removingCarFromListRailroad = null
    state.editRailroadNote = null
  },

  ratConfirmatorModal(state, carList) {
    state.splittingCarList = null
    state.releasingCarList = null
    state.publishingCarList = null
    state.editingCarList = null
    state.mergingCarList = null
    state.ratConfirmatorModal = carList
    state.removingCarFromList = null
    state.removingCarFromListRailroad = null
    state.editRailroadNote = null
  },

  closeMergingModal(state) {
    state.mergingCarList = null
    state.mergingCarListDirection = null
  },

  highlightCarList(state, carList) {
    state.highlightedCarList = carList
  },

  setCarList(state, carList) {
    // state.highlightedCarList = null;
    state.currentCarList = carList
  },

  resetCarListCurrentAddonable(state, oldCarList) {
    if (!oldCarList) { return }

    (state.railroadGroups || []).forEach(rrg => {
      (rrg.railroads || []).forEach(rr => {
        (rr.car_lists || []).forEach(cr => {
          if (cr.uuid == oldCarList.uuid) {
            cr.current_addonable_id = null
            cr.current_addonable = null
          }
        })
      })
    })
  },

  sortRailroadsCarlists(state) {
    (state.railroadGroups || []).forEach(rrg => {
      (rrg.railroads || []).forEach(rr => {
        rr.car_lists = sortCarLists(rr.car_lists)
      })
    })
  },

  showPlantCarListPublishingModal(state) {
    state.showPlantCarListPublishingModal = true
  },

  hidePlantCarListPublishingModal(state) {
    state.showPlantCarListPublishingModal = false
  },

  showHazardModal(state) {
    state.showHazardModal = true
  },

  hideHazardModal(state) {
    state.showHazardModal = false
  },

  updateCarList(state, { carList, attributes = {} }) {
    const newRailroadGroups = (state.railroadGroups || []).map(railroadGroup => {
      const railroads = (railroadGroup.railroads || []).map(railroad => {
        const car_lists = (railroad.car_lists || []).map(currentCarList => {
          const currentAttrs = currentCarList.uuid === carList.uuid
            ? attributes
            : {}

          return {
            ...currentCarList,
            ...currentAttrs,
          }
        })

        return {
          ...railroad,
          car_lists,
        }
      })

      return {
        ...railroadGroup,
        railroads,
      }
    })

    state.railroadGroups = newRailroadGroups
  },

  setWagonsManagementModalMode (state, mode) {
    state.wagonsManagementModalMode = mode
  },
}
