<template lang='pug'>
  modal(name='plant-car-list-publishing-modal' class='vue-modal' width='900px' height='auto' v-bind:adaptive='true' v-on:closed='onClosed')
    .ui.modal.visible.active.plant-car-list-publishing-modal(style="overflow: auto; max-height: 100vh;")
      div(slot='top-right')
        i.close.icon(v-on:click='closeModal')

      .header
        | {{ $t('publish_modal.title') }}

      .content
        .search-car-list.ui.form
          .fields.three
            .four.wide.field
              date-picker(
                :lang='currentLocale'
                :clearable='false'
                v-model='trainDate'
                format='DD/MM/YYYY'
                value-type='format'
                @input='handleTrainDateChange'
              )

            .eight.wide.field
              vSelect(
                class='dropdown-wrapper wide'
                @search='fetchCarListsOptions'
                @search:focus='fetchCarListsOptions'
                v-bind:options='decoratedCarListsOptions'
                v-model='carList'
                v-bind:value='carList'
                label='label'
                @input='handleCarListChange'
              )
                template(v-slot:no-options="{ search, searching }")
                  em {{ $t('no_results') }}

            .four.wide.field
              button.ui.button.right.floated(
                v-if='carList'
                v-on:click='exportPublishableCarList'
              )
                i.icon.file.excel.outline(style="margin: auto")

        .car-list-details(v-if="showPublishForm")
          h3 {{ carList.status }}

          .ui.divider

          .table-container-scrollable(style="max-height: 250px; overflow: auto;")
            table.ui.compact.table.unstackable
              thead
                tr
                  th {{ $t('cars.code') }}
                  th {{ $t('cars.car_faults') }}
                  th {{ $t('cars.arrival_station') }}
                  th {{ $t('cars.transport_characteristics') }}

              tbody
                tr(v-for='car in cars' v-bind:key='car.id')
                  td {{ car.formatted_code }}
                  td {{ car.car_fault_codes.join(', ') }}
                  td {{ car.arrival_station && car.arrival_station.name }}
                  td {{ car.transport_characteristics }}

          .ui.divider

          FormFields(
            v-bind:formLoading='formLoading'
            v-bind:labels='labels'
            v-bind:railroads='railroadsOptions'
            v-bind:railroad='railroad'
            v-bind:carList='carList'
            v-bind:stations='stations'
            v-model='publishFields'
          )

      .actions
        .ui.cancel.button(v-on:click='closeModal')
          | {{ $t('cancel') }}

        button.ui.red.button(v-if='canUpdateCarList' v-on:click='handleUpdateCarList')
          i.check.icon
          | &nbsp;{{ $t('publish_modal.car_list.update_action') }}

        button.ui.red.button(v-if='canConfirm' v-on:click='handleConfirm')
          i.check.icon
          | &nbsp;{{ confirmLabel }}

        button.ui.red.button(v-if='canPublish' v-on:click='handleSubmit')
          i.check.icon
          | &nbsp;{{ submitLabel }}

        button.ui.button.disabled(v-if='cannotPublish')
          i.check.icon
          | &nbsp;{{ submitLabel }}
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import vSelect from 'vue-select'
import moment from 'moment'
import FormFields from './car-list-publish-form-fields'
import { fetchCarLists } from '../../api/car_lists'
import { fetchArrivalsRailroads } from '../../api/railroads'
import { error as errNotify, success as succNotify } from '../../../utils/notify'

export default {
  components: {
    DatePicker,
    vSelect,
    FormFields,
  },

  props: {
    stations: Array,
  },

  data() {
    return {
      publishFields: {
        publishDate: null,
        currentRailroad: null,
        insertFrom: null,
        rename: false,
        internalCurrentOperation: null,
      },

      carList: null,
      carListsOptions: [],
      railroadsOptions: [],
      trainDate: null,
      formLoading: false,
      showConfirmMessage: false,
    }
  },

  computed: {
    ...mapGetters({
    }),
    ...mapState({
      operations: 'operations',
      apiToken: 'apiToken',
      plantId: 'plantId',
      settings: 'settings',
    }),
    decoratedCarListsOptions() {
      return this.carListsOptions.map((cl) => ({
        ...cl,
        label: `[${cl.train.number}] - ${(cl.plant || {}).name} - ${cl.code}`,
      }))
    },
    railroad() {
      return this.carList.railroad
    },
    cars() {
      if (!this.carList) { return [] }

      return this.carList.cars
    },
    currentLocale() {
      return gon.locale
    },
    showPublishForm() {
      return !!this.carList
    },
    internalCurrentOperation() {
      return this.publishFields.internalCurrentOperation
    },
    insertFrom() {
      return this.publishFields.insertFrom
    },
    rename() {
      return this.publishFields.rename
    },
    publishDate() {
      return this.publishFields.publishDate
    },
    currentRailroad() {
      return this.publishFields.currentRailroad
    },
    currentOperation: {
      get() {
        return this.internalCurrentOperation
      },
      set(value) {
        if (value && value.typology === 'arrival' && value.factual_arrives_at) {
          const values = value.factual_arrives_at.split('T')[1].split(':')
          this.publishDate.setHours(Number(values[0]))
          this.publishDate.setMinutes((values[1]))
          this.publishDate = new Date(this.publishDate)
        }
        this.internalCurrentOperation = value
      },
    },
    displayConfirmMessage() {
      if (this.formLoading) { return false }

      return this.showConfirmMessage
    },
    confirmationRequired() {
      if (!this.currentOperation) { return false }
      if (!this.currentOperation.railway_company_id) { return false }

      let result = false
      this.carList.cars.forEach((car) => {
        if (car.railway_company_id && car.railway_company_id !=
          this.currentOperation.railway_company_id) {
          result = true
        }
      })

      return result
    },
    submitLabel() {
      return this.$t('publish_modal.submit')
    },
    confirmLabel() {
      return this.$t('publish_modal.confirm')
    },
    cssFormClass() {
      if (this.formLoading) {
        return 'loading car-list-publish-form'
      }

      if (this.showConfirmMessage) { return 'hide' }

      return 'car-list-publish-form'
    },
    canConfirm() {
      if (this.formLoading) { return false }

      return this.showConfirmMessage
    },
    canUpdateCarList() {
      return this.carList
    },
    publishConditions() {
      return this.publishDate && this.currentRailroad && this.insertFrom
    },
    operationConditions() {
      if (!this.settings.mandatory_operation_for_manoeuvres) { return true }

      return !!this.currentOperation
    },
    canPublish() {
      if (this.formLoading) { return false }
      if (this.showConfirmMessage) { return false } // TBD
      if (!this.carList) { return false }

      return (
        this.carList
        && this.carList.publish_preconditions
        && this.publishConditions
        && this.operationConditions
      )
    },
    cannotPublish() {
      if (this.formLoading) { return true }
      if (this.showConfirmMessage) { return false }

      return !this.canPublish
    },
    railroadId() {
      return this.currentRailroad && this.currentRailroad.id
    },
    datePickerLocale() {
      return this[this.$i18n.locale]
    },
    insertFromOptions() {
      const options = []

      if (!this.currentRailroad) { return options }
      if (this.currentRailroad.insert_from !== 'left') {
        options.push({
          value: 'right',
          label: this.labels.insert_from_right_label,
        })
      }
      if (this.currentRailroad.insert_from !== 'right') {
        options.push({
          value: 'left',
          label: this.labels.insert_from_left_label,
        })
      }

      return options
    },
    plant() {
      return (this.carList || {}).plant
    },
    labels() {
      if (!this.plant) { return {} }

      const {
        insert_from_left_label,
        insert_from_right_label,
      } = this.plant

      return {
        insert_from_left_label,
        insert_from_right_label,
      }
    },
  },

  mounted() {
    this.$nextTick().then(() => {
      this.$modal.show('plant-car-list-publishing-modal')
      this.fetchCarListsOptions()
    })
  },

  methods: {
    handleCarListChange() {
      this.fetchRailroadsOptions()
    },
    handleTrainDateChange() {
      this.carList = null
      this.fetchCarListsOptions()
    },
    fetchCarListsOptions(search = '', loading = () => {}) {
      loading(true)
      this.formLoading = true

      const params = {
        q: {
          code_or_notes_or_train_number_cont: search,
          status_eq: 4, // Draft
          train_id_null: false,
          cached_plant_id_eq: this.plantId,
        },
      }

      if (this.trainDate) {
        params.q.train_train_date_eq = this.trainDate

        fetchCarLists({ params, apiToken: this.apiToken })
          .then((data) => {
            this.carListsOptions = data
            this.formLoading = false
            loading(false)
          })
      }

    },
    fetchRailroadsOptions(search = '', loading = () => {}) {
      loading(true)
      this.formLoading = true

      const params = {}
      const plantId = this.carList.plant.id

      fetchArrivalsRailroads({ plantId, params, apiToken: this.apiToken })
        .then((data) => {
          this.railroadsOptions = data
          this.formLoading = false
          loading(false)
        })
    },
    closeModal() {
      this.$modal.hide('plant-car-list-publishing-modal')
    },
    onClosed() {
      this.$emit('closed')
      this.$store.dispatch('hidePlantCarListPublishingModal')
    },
    performSubmit() {
      this.formLoading = true
      const currentOperationId = this.currentOperation
        ? this.currentOperation.id
        : null

      const data = {
        railroad_id: this.currentRailroad.id,
        insert_from: this.insertFrom.value,
        rename: this.rename,
        publish_date: moment(this.publishDate).format('YYYY-MM-DD HH:mm'),
        operation_id: currentOperationId,
      }

      $.ajax({
        url: `/car-lists/${this.carList.id}/publish.js`,
        data: { car_list: data, _method: 'patch' },
        dataType: 'script',
        method: 'post',
      })
    },
    handleConfirm(e) {
      e.preventDefault()

      this.performSubmit()
    },
    handleUpdateCarList(e){
      e.preventDefault()

      const data = {
        railroad_id: this.currentRailroad.id,
      }

      $.ajax({
        url: `/v1/car_lists/${this.carList.id}`,
        data: { car_list: data, _method: 'patch' },
        dataType: 'script',
        method: 'patch',
      }).always(() => {
        succNotify({ title: 'Lot mis à jour avec succès' })
      })
    },
    handleSubmit(e) {
      e.preventDefault()

      if (this.confirmationRequired) {
        this.showConfirmMessage = true
      } else {
        this.performSubmit()
      }
    },

    exportPublishableCarList() {
      $('#global-loader').dimmer('show')
      this.$http.get(`/car-lists/${this.carList.id}/publishable_car_list_export`).then(response => {
        Manovre.job_id = response.data.job_id
        // subscription to channel must be done before ws init
        // so it's done on plant-car-list-publishing-modal-button if you search it
      })
    },
  },
}
</script>
