<template lang="pug">
FullCalendar(
  :options="calendarOptions"
  ref="cc"
)
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import itLocale from '@fullcalendar/core/locales/it'

//import interactionPlugin from '@fullcalendar/interaction'

export default {
  components: {
    FullCalendar,
  },
  data() {
    return {
      currentDate: undefined,
      calendarOptions: {
        plugins: [ dayGridPlugin ],
        initialView: 'dayGridMonth',
        locale: itLocale,
        datesSet: this.reloadData,
        eventClick: this.openOperationsPlansOptimizerPage,
        events: [
        ],
      },
    }
  },
  methods: {
    openOperationsPlansOptimizerPage(e) {
      let terminalName = e.event.title.split('(')[0].trim()
      window.open(`/operations_plans_optimizer?terminal=${terminalName}&date=${e.event.startStr}`, '_blank').focus()
    },
    reloadData() {
      const calendarApi = this.$refs.cc.getApi()
      if(this.currentDate?.getMonth() !== calendarApi.getDate().getMonth()){
        this.currentDate = calendarApi.getDate()
        this.loadMonthData(this.currentDate)
      }
    },
    loadMonthData(currentDate){
      this.$http.get('/trains_timeline/long_period_calendar', {
        params: {
          currentDate: currentDate.toLocaleString('sv').replace(' ', 'T'), // https://stackoverflow.com/a/65758103
        },
      }).then(response => {
        this.calendarOptions.events = []
        response.data.operations_plans.forEach((operations_plan) => {
          this.calendarOptions.events.push(
            {
              id: `${operations_plan.id}-${operations_plan.day}`,
              title: `${operations_plan.terminal || 'Nessun terminal'} (${operations_plan.count})`,
              start: operations_plan.day,
              backgroundColor: operations_plan.color || '#3788d8',
              borderColor: operations_plan.color || '#3788d8',
              className: 'text-border-black',
            })
        })
      })
    },
  },
}
</script>

<style>
  .text-border-black {
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  }
</style>
