<template lang='pug'>
  modal(name='car-list-release' class='vue-modal' width='900px' height='auto' v-bind:adaptive='true' v-on:closed='onClosed')
    .ui.modal.visible.active.car-list-release(style="overflow: auto; max-height: 100vh;")
      div(slot='top-right')
        i.close.icon(v-on:click='closeModal')
      .header
        | {{ $t('release_modal.title') }}
        | {{ carList.code }}
      .content
        form.ui.form.car-list-release-form
          .fields.two
            .ui.form.field
              label {{ $t('release_modal.date') }}
              date-picker(
                class="wide"
                lang="datePickerLocale"
                v-model="trainDate"
                format="DD/MM/YYYY"
                clearable=false
                type="date"
                :placeholder='datePlaceholder'
              )

            .ui.form.field
              label {{ $t('release_modal.time') }}
              date-picker(
                class="wide"
                lang="datePickerLocale"
                v-model="trainTime"
                format="HH:mm"
                clearable=false
                type="time"
                :placeholder='timePlaceholder'
                :time-picker-options="{start: '00:00', step: '00:30', end: '23:30'}"
              )

          .ui.form.field(v-if='manualTrainSelect')
            label {{ $t('release_modal.train_number') }}
            input(
              v-model='manualTrainNumber'
              v-bind:class='manualTrainNumberCssClass'
              type='text'
              name='manual-train-number')

          .field(v-else)
            label {{ $t('release_modal.train_number') }}
            vSelect(
              class='ui-vue-select'
              label='number'
              v-on:search='fetchTrains'
              v-model='trainId'
              v-bind:disabled='!trainDateSelected'
              v-bind:options='trainOptions'
              v-bind:filterable='false'
              v-bind:value='trainId'
              v-bind:placeholder='$t("release_modal.train_placeholder")'
            )
              div(slot='no-options') {{ $t('release_modal.no_trains_found') }}

          .ui.form.field(v-if='this.settings.enable_car_list_release_on_odm_close')
            label {{ $t('release_modal.notes') }}
            input(
              v-model='notes'
              type='text')

          .field(v-if='showOtherPlantsField')
            label {{ $t('release_modal.other_plant') }}
            vSelect(
              class='ui-vue-select'
              label='name'
              v-bind:options='otherPlants'
              v-model='otherPlant'
              v-bind:value='otherPlant'
            )
              div(slot='no-options') {{ $t('no_results_found') }}

      .actions
        .ui.cancel.button(v-on:click='closeModal')
          | {{ $t('cancel') }}
        .ui.button.positive(v-if='canSubmit' v-on:click='release')
          | {{ $t('release_modal.submit') }}
</template>

<script>
import { mapState } from 'vuex'
import DatePicker from 'vue2-datepicker'
import vSelect from 'vue-select'
import moment from 'moment'
import trainsApi from '../../api/trains'

export default {
  components: {
    DatePicker,
    vSelect,
  },

  data() {
    return {
      trainDate: null,
      trainTime: null,
      manualTrainNumber: null,
      trainId: null,
      trainOptions: [],
      otherPlant: null,
      notes: null,
    }
  },

  computed: {
    ...mapState({
      manualTrainSelect: 'manualTrainSelect',
      releaseToAnotherPlant: 'releaseToAnotherPlant',
      otherPlants: 'otherPlants',
      carList: 'releasingCarList',
      apiToken: 'apiToken',
      currentOperation: 'currentOperation',
      settings: 'settings',
    }),
    timePlaceholder() {
      return this.$t('release_modal.time')
    },
    datePlaceholder() {
      return this.$t('release_modal.date')
    },
    datePickerLocale() {
      return this[this.$i18n.locale]
    },
    trainDateSelected() {
      return (
        this.trainDate !== null && this.trainDate !== undefined && this.trainDate !== '' &&
        this.trainTime !== null && this.trainTime !== undefined && this.trainTime !== ''
      )
    },
    trainSelected() {
      return this.trainId !== null && this.trainId !== undefined && this.trainId !== ''
    },
    manualTrainSelected() {
      return this.manualTrainNumber !== null && this.manualTrainNumber !== undefined && this.manualTrainNumber !== ''
    },
    manualTrainNumberCssClass() {
      return this.trainDateSelected ? '' : 'disabled-input'
    },
    showOtherPlantsField() {
      return this.releaseToAnotherPlant && this.otherPlants !== undefined && this.otherPlants.length > 0
    },
    canSubmit() {
      return this.trainDateSelected && (this.trainSelected || this.manualTrainSelected)
    },
    trainDateTime() {
      return `${moment(this.trainDate).format('YYYY-MM-DD')} ${moment(this.trainTime).format('HH:mm')}`
    },
  },

  mounted() {
    this.$nextTick().then(() => {
      this.trainDate = new Date()
      this.trainTime = new Date()
      this.manualTrainNumber = (this.currentOperation || {}).train
      this.$modal.show('car-list-release')
    })
  },

  methods: {
    closeModal() {
      this.$modal.hide('car-list-release')
    },
    onClosed() {
      this.$store.commit('releasingCarList', null)
    },
    resetTrains() {
      this.trainOptions = []
      this.trainId = null
    },
    fetchTrains(search, loading) {
      if (search.length >= 3) {
        loading(true)
        trainsApi.fetch(this.apiToken, search, this.trainDate,
                        data => {
                          this.trainOptions = data.trains
                          loading(false)
                        },
                        error => {
                          console.log(error)
                          loading(false)
                        }
        )
      }
    },
    release() {
      if (this.canSubmit) {
        this.$store.dispatch('carListRelease', {
          carListUuid: this.carList.uuid,
          trainId: this.trainId,
          manualTrainNumber: this.manualTrainNumber,
          trainDate: moment(this.trainDate).format('YYYY-MM-DD'),
          trainTime: moment(this.trainTime).format('HH:mm'),
          otherPlant: this.otherPlant,
          notes: this.notes,
        })
      }
    },
  },
}
</script>
