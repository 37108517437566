<template lang="pug">
.selectors
  PureMonthAndWeekSelector(
    v-if="!hideNavigation && showDates"
    :selected-date="currentDate",
    @date:changed="handleDateChanged($event)",
    @week:next="handleDateNext($event)"
    @week:prev="handleDatePrev($event)"
    :disableNavigation="loadingBodyData"
  )
  PureWeekDaySelector(
    :hide-navigation="hideNavigation",
    :show-dates="showDates",
    :selected-date="currentDate",
    @date:changed="handleDateChanged($event)",
    @date:next="handleDateNext($event)"
    @date:prev="handleDatePrev($event)"
    :disableNavigation="loadingBodyData"
  )
</template>

<script>
import PureWeekDaySelector from './PureWeekDaySelector.vue'
import PureMonthAndWeekSelector from './PureMonthAndWeekSelector.vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'WeekDaySelector',
  components: { PureWeekDaySelector, PureMonthAndWeekSelector },
  props: {
    hideNavigation: {
      type: Boolean,
      default: false,
    },
    showDates: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('trainsTimeline', [
      'currentDate',
      'loadingBodyData',
    ]),
  },
  methods: {
    ...mapActions('trainsTimeline', [
      'setCurrentDate',
    ]),
    handleDateChanged({ date }) {
      this.setCurrentDate(this.$dayjs(date).toDate())
    },
    handleDatePrev(days) {
      this.setCurrentDate(this.$dayjs(this.currentDate).subtract(days, 'day'))
    },
    handleDateNext(days) {
      this.setCurrentDate(this.$dayjs(this.currentDate).add(days, 'day'))
    },
  },
}
</script>

<style lang="sass" scoped>
.selectors
  display: flex
  flex-direction: column
  align-items: center
  gap: 6px
</style>
