<template lang="pug">
.train-timeline-unit(v-if="trainsTimelineUnit")
  PureTimelineUnit(
    :width="width",
    :height="adaptedHeight",
    :left-offset="leftOffset",
    :top-offset="adaptedTopOffset",
    :groupId="trainsTimelineUnit.train"
    :optimizationMethod="trainsTimelineUnit.optimizationMethod"
  )
    template(slot="leadingText") {{ trainsTimelineUnit.train }}
    template(slot="trailingText")
      TimeRange(:start-time="startTime", :end-time="endTime", :timeOnly="true")
    template(slot="popover")
      TrainsTimelineUnitPeekView(
        :trainsTimelineUnit="trainsTimelineUnit"
        :showOptimizationMethod="showOptimizationMethod"
      )
</template>

<script>
import PureTimelineUnit from './PureTimelineUnit.vue'
import TimeRange from './TimeRange.vue'
import TrainsTimelineUnitPeekView from './TrainsTimelineUnitPeekView.vue'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'TrainsTimelineUnit',
  components: {
    PureTimelineUnit,
    TrainsTimelineUnitPeekView,
    TimeRange,
  },
  props: {
    trainsTimelineUnit: {
      type: Object,
      default: () => {},
    },
    topOffset: {
      type: Number,
      default: 0,
    },
    showOptimizationMethod: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('trainsTimeline', [
      'currentDate',
      'columnWidth',
      'rowHeight',
      'timelineUnitHeight',
    ]),
    ...mapGetters('trainsTimeline', [
      'roundedMinutesPerColumn',
      'timelineWidth',
    ]),
    startTime() {
      return this.$dayjs(this.trainsTimelineUnit.startsAt)
    },
    endTime() {
      return this.$dayjs(this.trainsTimelineUnit.endsAt)
    },
    durationInMinutes() {
      return this.endTime.diff(this.startTime, 'minute')
    },
    width() {
      return this.durationInMinutes / this.roundedMinutesPerColumn * this.columnWidth
    },
    adaptedHeight() {
      return Math.min(this.rowHeight, this.timelineUnitHeight)
    },
    leftOffset() {
      return this.startTime.diff(this.$dayjs(this.currentDate).startOf('day'), 'minute') / this.roundedMinutesPerColumn * this.columnWidth
    },
    adaptedTopOffset() {
      return this.topOffset + (this.rowHeight - this.timelineUnitHeight) / 2
    },
  },
  methods: {
    formatTime(time) {
      return this.$dayjs(time).format('HH:mm')
    },
  },
}
</script>

<style lang="sass" scoped>

</style>
