import Axios from 'axios'

export const fetchAddonables = ({ apiToken, operationId }) => {
  return Axios.get(`/v1/operations/${operationId}/addonables`, {
    headers: { 'X-AUTH-TOKEN': apiToken },
  }).then((response) => {
    if (response.status === 200) { return Promise.resolve(response) }

    return Promise.reject(new Error(`Page status ${response.status}`))
  })
}

export const fetchAddonable = ({ apiToken, addonableId }) => {
  return Axios.get(`/v1/addonables/${addonableId}`, {
    headers: { 'X-AUTH-TOKEN': apiToken },
  }).then((response) => {
    if (response.status === 200) { return Promise.resolve(response) }

    return Promise.reject(new Error(`Page status ${response.status}`))
  })
}

export const getAddonablesCollectionForm = ({ operationId }) => {
  const url = `/operations/${operationId}/addons/collection_form`
  return $.get(url)
}

export const getAddonablesEditForm = ({ operationId, addonableId }) => {
  const url = `/operations/${operationId}/addons/${addonableId}/edit_form`
  return $.get(url)
}

export default {
  fetchAddonables,
  fetchAddonable,
  getAddonablesCollectionForm,
  getAddonablesEditForm,
}
