<template lang='pug'>
  span
    button.ui.button.small.yellow(
      v-if='showHazardButton()'
      v-on:click='handleClick'
    )
      | !

    HazardModal(
      :hazardMessages='hazardMessages'
      v-if='showHazardModal'
      v-on:closed='onClosed'
    )
</template>

<script>
import { mapState } from 'vuex'
import HazardModal from '../modals/hazard-modal'

export default {
  components: {
    HazardModal,
  },
  computed: {
    ...mapState([
      'showHazardModal',
      'hideHazardModal',
    ]),
  },
  props: {
    hazardMessages: Array,
  },
  methods: {
    showHazardButton() {
      return this.hazardMessages.length != 0
    },
    handleClick(e) {
      e.preventDefault()
      this.$store.commit('showHazardModal')
    },

    onClosed() {
      this.$store.commit('hideHazardModal')
    },
  },
}
</script>
