<template lang='pug'>
  modal(name='car-list-edit' class='vue-modal' width='900px' height='auto' v-bind:adaptive='true' v-on:closed='closeModal')
    .ui.modal.visible.active.car-remover-modal
      div(slot='top-right')
        i.close.icon(v-on:click='closeModal')
      .header
        | {{ mode === 'remove' ? $t('car_adder_remover.remove_title') : (mode === 'addCarList' ? $t('car_adder_remover.new_car_list') : `${$t('car_adder_remover.add_title')} - ${this.addModeList.name}`) }}

      .content
        div(style="display: flex; align-items: center; padding-bottom: 50px;" v-if="mode === 'remove'")
          button.ui.icon.button.green(v-on:click='switchToAddCarListMode()')
            i.share.icon.enqueue-car-icon
          button.ui.icon.button.red(style='margin-left: auto; margin-right: 20px;' v-if="selectedCars.length > 1" v-on:click='deleteCars(selectedCars)')
            i.trash.icon
          vSelect(
              class='option-select'
              style='margin-left: 20px; margin-right: 20px; flex-grow: 1;'
              v-model='searchedFullCarCodes'
              label="code"
              v-bind:options='carRemoverCarsOptions'
              v-bind:placeholder='fullCarCodePlaceholder'
              v-bind:searchable='true'
              :multiple='true'
            )
          button.ui.icon.button.yellow(style='margin-left: 20px;' @click='toggleAllCarListsLocks' v-if="tenant=='sncfr'")
            i.icon(:class='areAllLocked ? "lock" : "unlock"')
        .ui.stackable.grid(v-if="mode==='remove'")
          .sixteen.wide.column.car-lists-container(v-for='carList in sortedCarLists')
            .car-list-container
              .car-container.ui.stackable.grid
                .eight.wide.mobile.column(:id="`draggableCar-${carList.id}-START-0`" draggable="false")
                  h5
                    | {{ carList.name }}
                .eight.wide.mobile.column
                  .car-container_actions(style='float: right')
                    button.ui.icon.button.mini.yellow(@click='toggleCarListLock(carList.id)' v-if="tenant=='sncfr'")
                      i.icon(:class='isCarListLocked(carList.id) ? "lock" : "unlock"')
                    button.ui.icon.button.green.compact.mini.enqueue-car-button(v-on:click='switchToAddMode(carList, 0)')
                      i.share.icon.enqueue-car-icon
              .car-container.ui.stackable.grid(v-show="dropPositionId === `draggableCar-${carList.id}-START-0`" style="background-color: blue")
              template(v-for='(car, index) in unremovedCars(carList)')
                .car-container.ui.stackable.grid(:key="car.code" :class='highlightFoundCar(car.code)')
                  .one.wide.mobile.column
                    .ui.checkbox.fitted
                      input(type="checkbox", @change="selectCar(car, carList)", :value="isCarSelected(car)")
                      label
                  .nine.wide.mobile.column(:id="`draggableCar-${carList.id}-${car.id}-${index}`" :draggable="!moving" :style="!moving ? 'cursor: move;' : ''" :class="{ 'locked-car': car.locked }")
                    span(style='margin-right: 10px; float: left;')
                      i.th.icon(v-show="!moving")
                      | &nbsp;
                      | {{ index + 1 }}
                    Car(
                      v-bind:key='car.id'
                      v-bind:car='car'
                      v-bind:carList='carList'
                    )
                    span(style='margin-left: 10px')
                      | {{ car.code }}
                    span(v-if='car.ctr' style='margin-left: 10px')
                      | CTR: {{ car.ctr }}
                  .six.wide.mobile.column
                    .car-container_actions(style='float: right')
                      button.ui.icon.button.red.compact.mini(v-on:click='deleteCar(car, carList)' v-if="selectedCars.length <= 1")
                        i.trash.icon
                      button.ui.icon.button.green.compact.mini(v-on:click='switchToAddMode(carList, index + 1)')
                        i.share.icon.enqueue-car-icon
                      button.ui.icon.button.mini.yellow(@click='toggleCarLock(car)' v-if="tenant=='sncfr'")
                        i.icon(:class='car.locked ? "lock" : "unlock"')
                .car-container.ui.stackable.grid(v-show="dropPositionId === `draggableCar-${carList.id}-${car.id}-${index}`" style="background-color: blue")


        form.ui.form(v-if="mode==='add'" ref="addModeForm")
          .fields
            .field.six.wide
              div(class="field select required car_position_car_id")
                label(class="select optional" for="car_position_car_id") {{ $t('car_adder_remover.car_or_locomotive') }}
                car-or-locomotive-remote-select(
                  @select="handleCarOrLocomotiveSelect($event)"
                  @remove="handleCarOrLocomotiveRemoved($event)"
                  name="car_position[car_id]"
                  id="car_position_car_id"
                )
          .field.boolean
            .ui.checkbox
              input(type="checkbox" v-model="usePreviousProperty" id="car_position_use_previous_property" name="car_position[use_previous_property]" value="false")
              label.boolean.optional(for="car_position_use_previous_property")
                | {{ $t('car_adder_remover.use_previous_property') }}
        form.ui.form(v-if="mode==='addCarList'" ref="addModeCarListForm")
          .fields
            .field.six.wide
              div(class="field select required car_list_car_position_id")
                label(class="select" for="car_list_car_position_id") {{ $t('car_adder_remover.car_or_locomotive') }}
                car-or-locomotive-remote-select(
                  @select="handleCarOrLocomotiveSelect($event)"
                  name="car_list[car_id]"
                  id="car_list_car_id"
                )
            .field.four.wide
              div(class="field required")
                label {{ $t('car_adder_remover.car_list_code') }}
                input(
                  v-model="newCarListCode"
                  type='text'
                  id="car_list_code"
                  name='car_list[code]')

            .field.four.wide(v-if="this.tenant!='sncfr'")
              label {{ $t('car_adder_remover.railway_company') }}
              div(class="field select optional car_list_railway_company_id")
                label(class="select optional" for="car_list_railway_company_id")
                select(
                  v-model="selectedRailwayCompanyId"
                  class="ui dropdown search select optional clearable remote-dropdown"
                  :data-remote-url="`/companies.json?q%5Bname_i_cont%5D={query}&q%5Brailway_company_eq%5D=true`"
                  name="car_list[railway_company_id]"
                  id="car_list_railway_company_id"
                  ref="selectCarListRailwayCompanyId"
                )

            .field.four.wide(v-if="this.tenant!='sncfr'")
              label {{ $t('car_adder_remover.terminal') }}
              div(class="field select optional car_list_terminal_id")
                label(class="select optional" for="car_list_terminal_id")
                select(
                  v-model="selectedTerminalId"
                  class="ui dropdown search select optional clearable remote-dropdown"
                  :data-remote-url="`/companies.json?q%5Bname_i_cont%5D={query}&q%5Bterminal_eq%5D=true`"
                  name="car_list[terminal_id]"
                  id="car_list_terminal_id"
                  ref="selectCarListTerminalId"
                )

            .field.four.wide(v-if="this.tenant=='adriafer'")
              label {{ $t('car_adder_remover.mto') }}
              div(class="field select optional car_list_mto_id")
                label(class="select optional" for="car_list_mto_id")
                select(
                  v-model="selectedMtoId"
                  class="ui dropdown search select optional clearable remote-dropdown"
                  :data-remote-url="`/companies.json?q%5Bname_i_cont%5D={query}&q%5Bmto_eq%5D=true`"
                  name="car_list[mto_id]"
                  id="car_list_mto_id"
                  ref="selectCarListMtoId"
                )


      .actions
        .ui.cancel.button(v-if="mode === 'remove'" v-on:click='closeModal')
          | {{ $t('close') }}
        .ui.cancel.button(v-if="mode === 'add' || mode === 'addCarList'" v-on:click='switchToRemoveMode')
          | {{ $t('car_adder_remover.back_to_remove_mode') }}
        .ui.blue.button(
          v-if="mode === 'add'"
          v-on:click='addModeFormSubmit'
          :disabled="!addModeFormValid"
          :class="{ 'disabled': !addModeFormValid }"
        )
          | {{ $t('save') }}
        .ui.blue.button(
          v-if="mode === 'addCarList'"
          v-on:click='addModeCarListFormSubmit'
          :disabled="!addModeCarListFormValid"
          :class="{ 'disabled': !addModeCarListFormValid }"
        )
          | {{ $t('save') }}
</template>

<script>
import { mapState, mapGetters, mapActions} from 'vuex'
import _ from 'lodash'
import 'vue-slider-component/theme/antd.css'
import Car from '../car'
import { error as errNotify, success as succNotify } from '../../../utils/notify'
import Axios from '@frontend/utils/axios_defaults.js'
import CarOrLocomotiveRemoteSelect from './car-or-locomotive-remote-select.vue'
import vSelect from 'vue-select'
import { findSegment as findSegmentUtil } from '../../utils/car-lists'

export default {
  components: {
    Car,
    CarOrLocomotiveRemoteSelect,
    vSelect,
  },

  data() {
    return {
      removedCars: [],
      carOptions: [],
      addModeList: null,
      addModeIndex: null,
      sortedCarLists: [],
      movingCarId: null,
      dropPositionId: null,
      selectedCar: null,
      selectedLocomotive: null,
      selectedType: null,
      usePreviousProperty: false,
      newCarListCode: null,
      moving: false,
      selectedRailwayCompanyId: null,
      selectedTerminalId: null,
      selectedMtoId: null,
      touchDragging: false,
      selectedCars: [],
      lockedCarLists: [],
      lockedCars: [],
      searchedFullCarCodes: [],
    }
  },

  computed: {
    ...mapState({
      carLists: 'removingCarFromList',
      railroad: 'removingCarFromListRailroad',
      tenant: 'tenant',
      apiToken: 'apiToken',
      mode: 'wagonsManagementModalMode',
    }),
    ...mapGetters([
    ]),

    carOrLocomotive() {
      return this.selectedType === 'car'
        ? this.selectedCar
        : this.selectedLocomotive
    },
    addModeFormValid() {
      return this.carOrLocomotive && this.carOrLocomotive.value
    },
    addModeCarListFormValid() {
      let valid = this.carOrLocomotive
        && this.carOrLocomotive.value
        && this.newCarListCode

      if(this.tenant != 'sncfr' && this.tenant != 'adriafer') {
        valid = valid && this.selectedRailwayCompanyId && this.selectedTerminalId
      }

      if(this.tenant == 'adriafer') {
        valid = valid && this.selectedRailwayCompanyId && this.selectedTerminalId && this.selectedMtoId
      }

      return valid
    },
    areAllLocked() {
      return this.sortedCarLists.length === this.lockedCarLists.length
    },
    fullCarCodePlaceholder() {
      return this.$t('cars.code')
    },
    carRemoverCarsOptions() {
      if (this.sortedCarLists) {
        return this.sortedCarLists.reduce((carSelectOptions, currentList) => {
          if (currentList && currentList.cars) {
            return carSelectOptions.concat(currentList.cars.map(car => ({ id: car.id, code: car.code })))
          } else {
            return carSelectOptions
          }
        }, [])
      } else {
        return []
      }
    },
  },

  mounted() {
    this.sortedCarLists = this.processCarLists(this.carLists)
    this.$modal.show('car-list-edit')
    document.addEventListener('dragstart', this.onDragStart)
    document.addEventListener('dragover',  this.onDragOver)
    document.addEventListener('dragleave', this.onDragLeave)
    document.addEventListener('drop', this.onDrop)

    document.addEventListener('touchstart', this.onDragStart, { passive: false })
    document.addEventListener('touchmove', this.onDragOver, { passive: false })
    document.addEventListener('touchend', this.onDrop, { passive: false })
  },

  updated() {
    if (this.mode === 'add' || this.mode === 'addCarList') {
      Manovre.initRemoteDropdown($('.remote-dropdown'))
    }
  },
  methods: {
    ...mapActions(['setWagonsManagementModalMode']),
    unremovedCars(carList) {
      return carList.cars.filter(car => !(this.removedCars && this.removedCars.includes(car.id)))
    },
    carDescription(car) {
      return `${car.code}${car.ctr ? ' CTR:' + car.ctr : ''}`
    },
    selectCar(car, carList) {
      const carAlreadySelected = this.selectedCars.find(c => c.code === car.code)
      if(carAlreadySelected) {
        this.selectedCars = this.selectedCars.filter(c => c.code !== car.code)
      }else {
        car['carList'] = carList
        this.selectedCars.push(car)
      }
    },
    isCarSelected(car) {
      this.selectedCars.find(c => c.code === car.code)
    },
    deleteCar(car, carList) {
      const confirmText = [
        this.$t('car_adder_remover.you_are_removing', {target: this.carDescription(car)}),
        carList.cars.length === 1 ? this.$t('car_adder_remover.removing_even_car_list') : null,
        this.$t('are_you_sure'),
      ]
      const sure = confirm(confirmText.filter(txt => !!txt).join('\n'))

      if (sure) {
        $.ajax({
          url: `/car-lists/${carList.id}/car-positions/${car.id}`,
          method: 'delete',
          headers: {
            accept : 'application/json',
          },
        }).always((result) => {
          if (result.status === 'ok') {
            this.removedCars.push(car.id)
            this.switchToRemoveMode()
            succNotify({ title: this.$t('success') })
            this.$store.dispatch('refreshRailroads', [result.railroad])
          } else {
            errNotify({ title: result.error })
          }
        })
      }
    },

    deleteCars(cars) {
      const carDescriptions = cars.map(car => this.carDescription(car)).join(', ')
      const removingCarList = cars.some(car => car.carList.cars.length === cars.length)
      const confirmText = [
        this.$t('car_adder_remover.you_are_removing', { target: carDescriptions }),
        removingCarList ? this.$t('car_adder_remover.removing_even_car_list') : null,
        this.$t('are_you_sure'),
      ]
      const sure = confirm(confirmText.filter(txt => !!txt).join('\n'))

      if (sure) {
        const payload = {
          car_position_ids: cars.map(car => car.id),
        }
        $.ajax({
          url: `/car-lists/car-positions/delete_multiple`,
          dataType: 'json',
          contentType: 'application/json',
          method: 'delete',
          headers: {
            accept : 'application/json',
          },
          data: JSON.stringify(payload),
        }).always((result) => {
          if (result.status === 'ok') {
            this.removedCars.push(...cars.map(car => car.id))
            this.switchToRemoveMode()
            succNotify({ title: this.$t('success') })
            var railroads = result.railroads
            this.$store.dispatch('refreshRailroads', railroads)
          } else {
            errNotify({ title: result.error })
          }
        })
      }
    },
    isCarListLocked(id) {
      return this.lockedCarLists.includes(id)
    },
    isCarLocked(id) {
      return this.lockedCars.includes(id)
    },
    toggleCarListLock(id) {
      if (this.isCarListLocked(id)) {
        this.lockedCarLists = this.lockedCarLists.filter((item) => item !== id)
      } else {
        this.lockedCarLists.push(id)
      }
    },
    toggleCarLock(car){
      if (this.isCarLocked(String(car.id))) {
        this.lockedCars = this.lockedCars.filter((item) => item !== String(car.id))
      } else {
        this.lockedCars.push(String(car.id))
      }
      car.locked = !car.locked
    },
    lockAll() {
      this.lockedCarLists = this.sortedCarLists.map((carList) => carList.id)
    },
    toggleAllCarListsLocks() {
      if (this.areAllLocked) {
        this.lockedCarLists = []
      } else {
        this.lockAll()
      }
    },
    getCarListIdFromEventId(id) {
      const match = id.match(/draggableCar-([0-9]+)/)
      if (match && match.length > 1) {
        return match[1]
      }
      return null
    },
    switchToAddMode(list, index){
      this.resetValues()
      this.addModeList = list
      this.addModeIndex = index
      this.setWagonsManagementModalMode('add')
    },
    switchToAddCarListMode(){
      this.resetValues()
      this.setWagonsManagementModalMode('addCarList')
    },
    switchToRemoveMode(){
      this.resetValues()
      this.setWagonsManagementModalMode('remove')
      this.addModeList = null
      this.addModeIndex = null
    },
    handleCarOrLocomotiveSelect(selectedCarOrLocomotive) {
      if(selectedCarOrLocomotive.type === 'car') {
        this.selectedCar = selectedCarOrLocomotive
        this.selectedLocomotive = null
      } else if(selectedCarOrLocomotive.type === 'locomotive') {
        this.selectedCar = null
        this.selectedLocomotive = selectedCarOrLocomotive
      }
      this.selectedType = selectedCarOrLocomotive.type
    },
    handleCarOrLocomotiveRemoved() {
      this.resetValues()
    },
    resetValues() {
      this.selectedCar = null
      this.selectedLocomotive = null
      this.selectedType = null
      this.selectedCars = []
    },
    addModeFormSubmit() {
      const carOrLocomotiveId = this.carOrLocomotive && this.carOrLocomotive.value
      const params = {
        car_position: {
          index: this.addModeIndex,
          [`${this.selectedType}_id`]: carOrLocomotiveId,
          use_previous_property: this.usePreviousProperty,
        },
      }

      Axios.post(
        `/car-lists/${this.addModeList.id}/${this.selectedType}-positions.json`,
        params
      ).then(response => {
        const result = response.data
        if (result.status === 'ok') {
          this.switchToRemoveMode()
          succNotify({ title: this.$t('success') })
          this.$store.dispatch('refreshRailroads', [result.railroad])
          this.sortedCarLists.forEach(carList => {
            if(result.car_list.id === carList.id)
              carList.cars = result.car_list.cars
          })
        } else {
          errNotify({ title: result.error })
        }
      })
    },
    addModeCarListFormSubmit() {
      const carOrLocomotiveId = this.carOrLocomotive && this.carOrLocomotive.value
      const params = {
        car_list: {
          railroad_id: this.railroad.id,
          [`${this.selectedType}_id`]: carOrLocomotiveId,
          code: this.newCarListCode,
          railway_company_id: this.selectedRailwayCompanyId,
          terminal_id: this.selectedTerminalId,
          mto_id: this.selectedMtoId,
          cars_number: 0,
          locomotives_number: 0,
        },
      }
      const url = this.selectedType === 'car'
        ? '/v1/car_lists/create_and_publish'
        : '/v1/car_lists/create_and_publish_with_locomotive'

      const config = {
        headers: { 'X-AUTH-TOKEN': this.apiToken },
      }
      Axios.post(url, params, config).then((response) => {
        const result = response.data
        if (response.status === 200) {
          this.$store.dispatch('refreshRailroads', [result.railroad])
          var newCarLists = [result.car_list].concat(this.sortedCarLists)
          this.switchToRemoveMode()
          succNotify({ title: this.$t('success') })
          this.$store.dispatch('showCarRemoverModal', {carLists: newCarLists, railroad: result.railroad})
          this.sortedCarLists = JSON.parse(JSON.stringify(newCarLists))
        } else {
          errNotify({ title: result.error })
        }
      }).catch((error) => {
        if (error.response) {
          errNotify({ title: (error.response.data.error || this.$t('error'))})
        }
      })
    },
    closeModal() {
      this.$emit('closed')
      this.$store.dispatch('hideCarRemoverModal')
      this.$modal.hide('car-list-edit')
      this.switchToRemoveMode()
      document.removeEventListener('dragstart', this.onDragStart)
      document.removeEventListener('touchstart', this.onDragStart)
      document.removeEventListener('dragover', this.onDragOver)
      document.removeEventListener('touchmove', this.onDragOver)
      document.removeEventListener('dragleave', this.onDragLeave)
      document.removeEventListener('touchend', this.onDrop)
      document.removeEventListener('drop', this.onDrop)
    },
    onDragStart(event) {
      const startId = event.target.id
      if (startId && startId.match(/draggableCar-[0-9]+-[0-9]+-[0-9]+/)) {
        const carListId = this.getCarListIdFromEventId(startId)
        if (this.isCarListLocked(carListId)) {
          event.preventDefault()
          return
        }
        this.touchDragging = true
        this.movingCarId = event.target.id
      }
    },
    onDragOver(event) {
      if (!this.touchDragging)
        return
      event.preventDefault()
      _.throttle(
        () => {
          let dropElement = null
          if (event.type == 'touchmove') {
            const touches = event.changedTouches
            const pageX = touches[0].clientX
            const pageY = touches[0].clientY
            let target = document.elementFromPoint(pageX, pageY)
            dropElement = target.closest('div[draggable]')
          } else if (event.type == 'dragover') {
            dropElement = event.target.closest('div[draggable]')
          }
          const dropId = dropElement && dropElement.id
          // avoid doing changes when not needed: Vue is too slow for this!
          if (dropId !== this.dropPositionId) {
            this.dropPositionId = dropId
          }
        },
        600
      )()
    },
    onDragLeave() {
    },
    onDrop(event) {
      if(!this.touchDragging)
        return
      this.touchDragging = false
      event.preventDefault()
      const draggingCarId = this.movingCarId
      this.movingCarId = false // remove the id since the drag is ended
      let dropElement = null
      if (event.type == 'touchend') {
        const touches = event.changedTouches
        const pageX = touches[0].clientX
        const pageY = touches[0].clientY
        let target = document.elementFromPoint(pageX, pageY)
        dropElement = target.closest('div[draggable]')
      } else if (event.type == 'drop') {
        dropElement = event.target.closest('div[draggable]')
      }
      const dropId = dropElement && dropElement.id
      let dropMatch
      if (draggingCarId && dropId && (dropMatch = dropId.match(/draggableCar-([0-9]+)-([0-9]+|START)-([0-9]+)/))) {
        this.moving = true
        const startMatch = draggingCarId.match(/draggableCar-([0-9]+)-([0-9]+)-([0-9]+)/)
        const startCarListId = startMatch[1]
        const startCurrentIndex = parseInt(startMatch[3])
        const dropCarListId = dropMatch[1]
        const dropCarId = dropMatch[2]

        const sourceSegment = this.findSegment(startMatch[2], startCarListId)
        const targetSegment = this.findSegment(dropCarId, dropCarListId)
        const dropIndex = this.calculateDropIndex(dropCarId, dropCarListId)

        if (this.isCarLocked(startMatch[2])) {
          this.abortMove()
          return
        }

        if (!this.validMove(sourceSegment, targetSegment, startCarListId, dropCarListId, dropIndex)) {
          this.abortMove()
          return
        }
        // car list title has "START" as CarId (since it isn't a Car)
        let dropCurrentIndex = dropCarId === 'START' ? -1 : parseInt(dropMatch[3])

        // TODO those cases could become custom functions
        // CASE 1: moving to another carList
        if (startCarListId !== dropCarListId) {
          const oldCarListIndex = this.sortedCarLists.findIndex(cl => cl.id === startCarListId)
          const oldCarListCars = [...this.sortedCarLists[oldCarListIndex].cars]

          const movedCar = oldCarListCars[startCurrentIndex]

          const newCarListIndex = this.sortedCarLists.findIndex(cl => cl.id === dropCarListId)
          let newCarListCars = [...this.sortedCarLists[newCarListIndex].cars]

          // add car in the correct position in new carlist
          newCarListCars.splice(dropCurrentIndex + 1, 0, movedCar)
          // add car from old carlist
          oldCarListCars.splice(startCurrentIndex, 1)

          Axios.post(
            `/car-lists/${startCarListId}/car-positions/${parseInt(startMatch[2])}/car_list_update`,
            {
              'new_car_list_id': dropCarListId,
              'old_sorted_ids': oldCarListCars.map(car => car.id),
              'new_sorted_ids': newCarListCars.map(car => car.id),
              'new_car_index': parseInt(dropMatch[3]),
            }
          ).then(response => {
            const result = response.data
            if (result.status === 'ok') {
              // you can now show result in frontend
              this.sortedCarLists[oldCarListIndex].cars = oldCarListCars
              this.sortedCarLists[newCarListIndex].cars = newCarListCars

              succNotify({ title: this.$t('success') })
              this.$store.dispatch('refreshRailroads', [result.railroad])
            } else {
              errNotify({ title: result.error })
            }
            this.moving = false
          })

        } else { // CASE 2: moving inside the same carList
          const currentCarListIndex = this.sortedCarLists.findIndex(cl => cl.id === startMatch[1])
          const currentCarListCars = [...this.sortedCarLists[currentCarListIndex].cars]

          const draggedCar = currentCarListCars[startCurrentIndex]

          // remove drag starting element
          currentCarListCars.splice(startCurrentIndex, 1)
          // re add element after the one on drag end
          if (startCurrentIndex > dropCurrentIndex) {
            dropCurrentIndex++
          }
          currentCarListCars.splice(dropCurrentIndex, 0, draggedCar)

          // id order to send to /sort
          const cars = currentCarListCars.map(car => car.id)

          $.ajax({
            url: `/car-lists/${startCarListId}/car-positions/sort`,
            data: {sorted_ids: cars},
            method: 'post',
            headers: {
              accept: 'application/json',
            },
          }).always((result) => {
            if (result.status === 'ok') {
              // you can now show result in frontend
              this.sortedCarLists[currentCarListIndex].cars = currentCarListCars

              succNotify({ title: this.$t('success') })
              this.$store.dispatch('refreshRailroads', [result.railroad])
            } else {
              errNotify({ title: result.error })
            }
            this.moving = false
          })
        }
      }
      this.dropPositionId = null
    },
    highlightFoundCar(carCode) {
      const isCarCodeSelected = this.searchedFullCarCodes.some(item => item.code === carCode)
      return isCarCodeSelected ? 'found-light' : null
    },
    processCarLists(carLists) {
      let sortedCarLists = JSON.parse(JSON.stringify(carLists))
      sortedCarLists.forEach(carList => {
        carList.cars.forEach(car => {
          if (!('locked' in car)) {
            car.locked = false
          }
        })
      })
      return sortedCarLists
    },
    findSegment(carId, carListId){
      return findSegmentUtil(this.sortedCarLists, carId, carListId)
    },
    abortMove() {
      this.dropPositionId = null
      this.moving = false
      errNotify({ title: this.$t('car_adder_remover.blocked_car_movement') })
    },
    calculateDropIndex(dropCarId, dropCarListId) {
      if (dropCarId === 'START') return 0

      const targetList = this.sortedCarLists.find(cl => cl.id === dropCarListId).cars
      const index = targetList.findIndex(car => car.id === dropCarId)
      return index === -1 ? targetList.length - 1 : index
    },
    validMove(sourceSegment, targetSegment, startCarListId, dropCarListId, dropIndex) {
      return startCarListId === dropCarListId
        ? sourceSegment.start === targetSegment.start && sourceSegment.end === targetSegment.end
        : dropIndex >= targetSegment.start && dropIndex <= targetSegment.end
    },
  },
}
</script>

<style lang="sass" scoped>
.vue-modal
  .content
    min-height: 30vh

.locked-car
  background-color: #f7dc9c
</style>
