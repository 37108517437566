<template lang="pug">
.operations-optimizer-header
  .ui.button.gray(@click="callIndex({page: 1})", v-if="showOptimizedTimes")
    | Mostra tutti
  .ui.button(@click="showLastOptimized(1)", v-if="!showOptimizedTimes")
    | Mostra ultimi ottimizzati
  .ui.button.blue(
    @click="handleOptimizeSelectedButtonClick",
    v-if="!showOptimizedTimes && operationsToOptimizeCount > 0"
  )
    | Ottimizza Selezionati ({{ operationsToOptimizeCount }})
  .ui.button.yellow(
    v-if="!showOptimizedTimes && operationsToOptimizeCount > 0",
    @click="resetOperationsToOptimize"
  )
    | Deseleziona tutti
  .ui.button.green(
    @click="handleSaveButtonClick",
    v-if="showOptimizedTimes && optimizedTimesToSaveCount > 0"
  )
    | Salva selezionati ({{ optimizedTimesToSaveCount }})
  .ui.button.yellow(
    v-if="showOptimizedTimes && optimizedTimesToSaveCount > 0",
    @click="resetOptimizedTimesToSave"
  )
    | Deseleziona tutti
  DateRangeForm(v-if="!showOptimizedTimes")
</template>

<script>
import DateRangeForm from './DateRangeForm.vue'
import { error as errNotify, success as succNotify } from '../utils/notify'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  components: {
    DateRangeForm,
  },
  computed: {
    ...mapState('operationsOptimizer', [
      'showOptimizedTimes',
      'operationsData',
      'operationsToOptimize',
      'optimizedTimesToSave',
    ]),
    ...mapGetters('operationsOptimizer', [
      'operationsToOptimizeCount',
      'optimizedTimesToSaveCount',
    ]),
  },
  created() {
    // load data in async
    this.callIndex({})
  },
  methods: {
    ...mapActions('operationsOptimizer', [
      'callIndex',
      'setOperationsData',
      'showLastOptimized',
      'resetOperationsToOptimize',
      'resetOptimizedTimesToSave',
    ]),
    handleOptimizeSelectedButtonClick() {
      this.optimizeSelectedWithGuard()
    },
    handleSaveButtonClick() {
      this.updateOperations()
    },
    optimizeSelectedWithGuard() {
      this.$http.get('/operations_optimizer/is_optimizer_busy')
        .then(response => {
          const isOptimizerBusy = response.data.isOptimizerBusy
          if(!isOptimizerBusy || confirm(`Ci sono elaborazioni in corso. Procedere comunque?`)) {
            this.optimizeSelected()
          }
        }).catch(err => {
          errNotify({ title: 'Errore: qualcosa è andato storto' })
        })
    },
    optimizeSelected() {
      this.$http.post('/operations_optimizer/shuntopt', {
        operations_to_optimize_ids: this.operationsToOptimize,
      }).then((response) => {
        const responseData = response.data
        if (responseData.status === 'ok') {
          succNotify({ title: 'Richiesta inviata con successo' })
        } else {
          errNotify({ title: 'Errore: qualcosa è andato storto' })
        }
      }).catch(err => {
        errNotify({ title: 'Errore: qualcosa è andato storto' })
      })
    },
    updateOperations() {
      this.$http.post('/operations_optimizer/update_operations', {
        optimized_time_ids: this.optimizedTimesToSave,
      }).then((response) => {
        const responseData = response.data
        if (responseData.status === 'ok') {
          succNotify({ title: 'Aggiornamento concluso con successo' })
          this.$modal.hide('send-times-modal')
        } else {
          errNotify({ title: 'Errore: qualcosa è andato storto' })
        }
      }).catch(err => {
        errNotify({ title: 'Errore: qualcosa è andato storto' })
      })
    },
  },
}
</script>

<style lang="sass">
.operations-optimizer-header
  display: flex
  align-items: center
  margin-bottom: 13px
</style>
