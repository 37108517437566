<template lang="pug">
div
  OTHeader
  SendTimesModal
  RequestOptimizationsTable(v-if="!showOptimizedTimes")
  SaveOptimizationsTable(v-if="showOptimizedTimes")
</template>

<script>
import OTHeader from'./OTHeader.vue'
import RequestOptimizationsTable from'./RequestOptimizationsTable.vue'
import SaveOptimizationsTable from'./SaveOptimizationsTable.vue'
import SendTimesModal from'./SendTimesModal.vue'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    OTHeader,
    RequestOptimizationsTable,
    SaveOptimizationsTable,
    SendTimesModal,
  },
  props: {
    operationsPlans: {
      type: Array,
      default: () => [],
    },
    optimizedTimes: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object,
      default: null,
    },
  },
  created() {
    if(this.operationsPlans) {
      this.setOperationsPlansData([...this.operationsPlans])
      this.setCurrentPageOperationsPlanIds([...this.operationsPlans])
    }
    if(this.optimizedTimes) {
      this.setOptimizedTimesData([...this.optimizedTimes])
      this.setCurrentPageOptimizedTimeIds([...this.optimizedTimes])
    }

    this.setPaginationInfo(this.pagination)
  },
  computed: {
    ...mapState('operationsPlansOptimizer', [
      'showOptimizedTimes',
      'optimizedTimesData',
    ]),

  },
  methods: {
    ...mapActions('operationsPlansOptimizer', [
      'setOperationsPlansData',
      'setCurrentPageOperationsPlanIds',

      'setOptimizedTimesData',
      'setCurrentPageOptimizedTimeIds',
    ]),

    ...mapActions('pagination', [
      'setPaginationInfo',
    ]),

  },
}
</script>
