<template lang='pug'>
  .ui.grid.days-selector
    .eight.wide.column
      Range(
        v-bind:model='model'
        v-bind:rangeStartInputName='rangeStartInputName'
        v-bind:rangeStart='rangeStart'
        v-bind:rangeEndInputName='rangeEndInputName'
        v-bind:rangeEnd='rangeEnd'
        v-bind:datePickerLocale='datePickerLocale'
        v-bind:disabledRangeStartInput='disabledRangeStartInput'
        v-bind:disabledRangeEndInput='disabledRangeEndInput'
        v-bind:errors='errors'
        v-on:updateRangeStart='updateRangeStart'
        v-on:updateRangeEnd='updateRangeEnd'
      )
      WeekDays(
        v-bind:model='model'
        v-bind:fieldName='weekDaysInputName'
        v-bind:weekDays='weekDays'
        v-bind:errors='errors'
        v-on:updateWeekDays='updateWeekDays'
      )
      ExcludedDays(
        v-bind:model='model'
        v-bind:fieldName='excludedDaysInputName'
        v-bind:excludedDays='orderedExcludedDays'
        v-on:showExcludedDaysModal='showExcludedDaysModal'
      )
    .eight.wide.column
      .fields
        .field
          label {{ $t('labels.preview') }}
          Calendar(
            v-bind:rangeStart='rangeStart'
            v-bind:rangeEnd='rangeEnd'
            v-bind:weekDays='weekDays'
            v-bind:excludedDays='orderedExcludedDays'
            v-bind:datePickerLocale='datePickerLocale'
            v-on:submitExcludedDays='submitExcludedDays'
          )
    ExcludedDaysModal(
      v-bind:excludedDays='orderedExcludedDays'
      v-bind:rangeStart='rangeStart'
      v-bind:rangeEnd='rangeEnd'
      v-bind:weekDays='weekDays'
      v-bind:datePickerLocale='datePickerLocale'
      v-on:hideExcludedDaysModal='hideExcludedDaysModal'
      v-on:submitExcludedDays='submitExcludedDays'
    )
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import { en, fr, it } from 'vuejs-datepicker/dist/locale'
import Range from './components/range'
import Calendar from './components/calendar'
import WeekDays from './components/week-days'
import ExcludedDays from './components/excluded-days'
import ExcludedDaysModal from './components/excluded-days-modal'

export default {
  components: {
    Range,
    Calendar,
    WeekDays,
    ExcludedDays,
    ExcludedDaysModal,
  },

  props: {
    model: String,
    rangeStartInputName: String,
    rangeStartInputValue: String,
    rangeEndInputName: String,
    rangeEndInputValue: String,
    weekDaysInputName: String,
    weekDaysInputValue: Object,
    excludedDaysInputName: String,
    excludedDaysInputValue: Array,
    errors: Object,
    disabledRangeStartInput: Boolean,
    disabledRangeEndInput: Boolean,
  },

  data() {
    return {
      rangeStart: this.parseDate(this.rangeStartInputValue),
      rangeEnd: this.parseDate(this.rangeEndInputValue),
      weekDays: this.parsedWeekDays(),
      datepickerLocales: {
        en: en,
        fr: fr,
        it: it,
      },
      excludedDays: Vue.util.extend([], this.excludedDaysInputValue),
    }
  },

  computed: {
    datePickerLocale() {
      return this.datepickerLocales[this.$i18n.locale]
    },
    orderedExcludedDays() {
      // uniq values ES6 way, sorted & without empty strings
      return [...new Set(this.excludedDays.filter(Boolean))].sort()
    },
  },

  methods: {
    parseDate(date) {
      return this.normalizeDate(moment(date, 'DD/MM/YYYY', true).toDate())
    },
    normalizeDate(date) {
      return moment(date).hours(12).toDate()
    },
    parsedWeekDays() {
      return {
        monday: this.weekDaysInputValue.monday === '1',
        tuesday: this.weekDaysInputValue.tuesday === '1',
        wednesday: this.weekDaysInputValue.wednesday === '1',
        thursday: this.weekDaysInputValue.thursday === '1',
        friday: this.weekDaysInputValue.friday === '1',
        saturday: this.weekDaysInputValue.saturday === '1',
        sunday: this.weekDaysInputValue.sunday === '1',
      }
    },
    showExcludedDaysModal() {
      this.$modal.show('excluded-days')
    },
    hideExcludedDaysModal() {
      this.$modal.hide('excluded-days')
    },
    updateRangeStart(date) {
      this.rangeStart = this.normalizeDate(date)
    },
    updateRangeEnd(date) {
      this.rangeEnd = this.normalizeDate(date)
    },
    updateWeekDays(weekDays) {
      this.weekDays = weekDays
    },
    submitExcludedDays(selectedDays) {
      this.excludedDays = selectedDays
    },
  },

  created() {
    moment.locale(this.$i18n.locale)
    moment.defaultFormat = 'YYYY-MM-DD' // rails format
  },
}
</script>

<style lang='sass'>

  .days-selector
    margin-bottom: 15px
</style>
