<template lang='pug'>
  .railroad-counter
    .railroad-counter__length(v-if="showLength" v-bind:class='cssClass') {{ carsLength }}
    .railroad-counter__weight.car-counter(v-if="showWeight") {{ carsWeight }}
    .railroad-counter__count.car-counter(v-if="showCount") {{ carsCount }}
</template>

<script>

export default {
  props: {
    railroad: Object,
    showLength: {
      type: Boolean,
      default: true,
    },
    showWeight: {
      type: Boolean,
      default: true,
    },
    showCount: {
      type: Boolean,
      default: true,
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    cssClass() {
      let cssClass = 'car-counter'
      if (this.counters.limitExceeded) {
        cssClass += ' with-error'
      }
      return cssClass
    },
    carsWeight() {
      const weight = this.railroad.car_lists
        .map((e) => e.cars.map((e) => e.gross_weight_tons || 0))
        .flat()
        .reduce((a, b) => a + b, 0)

      return `${this.$t('weight')}: ${weight.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} t`
    },
    carsLength() {
      if(this.compact) { return `${Math.round(this.counters.carsLength)}/${Math.round(this.counters.railroadCapacity)}m`}

      return this.counters.carsLength + ' m / ' + this.counters.railroadCapacity + ' m'
    },
    carsCount() {
      const carsCount = this.railroad.car_lists.reduce((sum, cl) => sum + cl.cars.length, 0)
      return `${this.$t('cars_label')}: ${carsCount}`
    },
    counters() {
      let carsLength = 0
      let railroadCapacity = this.railroad.capacity

      this.railroad.car_lists.forEach(function(carList) {
        if (carList.cars !== undefined && Array.isArray(carList.cars)) {
          carList.cars.forEach(function(car) {
            carsLength += car.length
          }, carsLength)
        }
      }, carsLength)

      const limitExceeded = carsLength > railroadCapacity
      const counter = {
        limitExceeded: limitExceeded,
        carsLength: +(carsLength).toFixed(2),
        railroadCapacity: railroadCapacity,
      }

      return counter
    },
  },
}
</script>
