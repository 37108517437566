import { MILLISECONDS_IN_A_SECOND, SECONDS_IN_A_MINUTE } from './constants'

// round to closest allowed value
export const roundToAllowedValues = (allowedValues, input) => {
  if (allowedValues.includes(input)) { return input }

  return allowedValues.reduce((prev, curr) => {
    return Math.abs(curr - input) < Math.abs(prev - input) ? curr : prev
  })
}

export const minutesToMilliseconds = (minutes) =>
  minutes * SECONDS_IN_A_MINUTE * MILLISECONDS_IN_A_SECOND
