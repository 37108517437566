<template lang="pug">
.save-optimization-table
  .table-container-scrollable
    table.ui.compact.table.unstackable.max-content
      thead
        th: input#all-optimized-times-to-save-toggler(
          type="checkbox",
          :checked="areAllOptimizedTimesToSaveChecked",
          @change="toggleAllOptimizedTimesToSave()"
        )
        th Traccia
        th Orario
        th A/P
        th Terminal
        th Luogo
        th.column--datetime Pilotaggio Inizio
        th.column--datetime Pilotaggio Fine
        th.column--datetime Secondaria Inizio
        th.column--datetime Secondaria Fine
      tbody
        tr(v-for="(optimizedTime, index) in optimizedTimesData")
          td: input(
            type="checkbox",
            :id="optimizedTime.id",
            :value="optimizedTime.id",
            :checked="optimizedTimesToSave.includes(optimizedTime.id)",
            @change="toggleOptimizedTimeToSave(optimizedTime)"
          )
          td {{ optimizedTime.train }}
          td {{ formatProgrammedDatetime(optimizedTime) }}
          td {{ optimizedTime.typology }}
          td {{ optimizedTime.terminal }}
          td {{ optimizedTime.station }}
          td.column--datetime
            EditableDatetime(
              :optimized-time="optimizedTime",
              field="piloting_activity_starts_at"
            )
          td.column--datetime
            EditableDatetime(
              :optimized-time="optimizedTime",
              field="piloting_activity_ceases_at"
            )
          td.column--datetime
            EditableDatetime(
              :optimized-time="optimizedTime",
              field="secondary_activity_starts_at"
            )
          td.column--datetime
            EditableDatetime(
              :optimized-time="optimizedTime",
              field="secondary_activity_ceases_at"
            )

      tfoot.full-width
  Pagination(@pagination:page:changed="handlePageChanged")
</template>

<script>
import EditableDatetime from'./EditableDatetime.vue'
import Pagination from'../pagination/Pagination.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  components: {
    EditableDatetime,
    Pagination,
  },
  computed: {
    ...mapState('operationsOptimizer', [
      'optimizedTimesData',
      'optimizedTimesToSave',
    ]),
    ...mapGetters('operationsOptimizer', [
      'areAllOptimizedTimesToSaveChecked',
    ]),
  },
  methods: {
    ...mapActions('operationsOptimizer', [
      'toggleOptimizedTimeToSave',
      'toggleAllOptimizedTimesToSave',

      'showLastOptimized',
    ]),
    formatProgrammedDatetime(optimizedTime) {
      return `${moment(optimizedTime.done_on).format('DD/MM/YYYY')} ${moment(optimizedTime.programmed_hour).format('HH:mm')}`
    },
    async handlePageChanged() {
      await this.showLastOptimized()
    },
  },
}
</script>

<style lang="sass" scoped>
.save-optimization-table
  .max-content
    width: 100% !important

  .ui.table thead .column--day,
  .ui.table tbody .column--day
    min-width: 84px
    text-align: center

  .ui.table thead .column--datetime,
  .ui.table tbody .column--datetime
    min-width: 170px

  .ui.table thead .column--odd,
  .ui.table tbody .column--odd
    background: #fafafa

  .table-container-scrollable
    max-height: 79vh
</style>
