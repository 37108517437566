<template lang="pug">
.train-timeline-row(v-if="railroad")
  RowContent(
    :railroad="railroad"
    :showOptimizationMethod="showOptimizationMethod"
  )
</template>

<script>
import RowContent from '@/trains-timeline/RowContent'

export default {
  name: 'TrainsTimelineRow',
  components: {
    RowContent,
  },
  props: {
    railroad: {
      type: Object,
      default: () => {},
    },
    showOptimizationMethod: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="sass" scoped>
@import '@styles/components/trains_timeline/train_timeline_row.sass'
</style>
