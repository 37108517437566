<template lang="pug">
div
  .ui.divider.compact
  .operations-optimizer-header(v-if='sinfomarGateAuthorizationSync')
    .ui.button.blue(
      @click="requestSelected",
      :class="{ 'disabled': !isAnyOperationAddonableChecked }"
    )
      | Invia
    .ui.button.blue(
      @click="revokeSelected",
      :class="{ 'disabled': !isAnyOperationAddonableChecked }"
    )
      | Revoca
    .ui.button.blue(
      @click="refreshSelected",
      :class="{ 'disabled': !isAnyOperationAddonableChecked }"
    )
      i.icon.refresh
      | Refresh
  h4
    input#all-operation-addonables-toggler(
              type="checkbox",
              :checked="areAllOperationAddonablesChecked",
              @change="toggleAllOperationAddonable()"
            )
    label.select.optimal(for="all-operation-addonables-toggler")  
      span(v-if="areAllOperationAddonablesChecked")   {{ $t('deselect_all') }}
      span(v-else)   {{ $t('select_all') }}
  div(v-for="(operationAddonable, index) in addonables")
    .ui.segment
      div
        h4
          input(
                :disabled="!operationAddonable.really_editable",
                type="checkbox",
                :id="'checkbox' + operationAddonable.id",
                :value="operationAddonable.id",
                :checked="isOperationAddonableChecked(operationAddonable.id)",
                @change="toggleOperationAddonable(operationAddonable.id)"
              )
          label.select.optimal(:for="'checkbox' + operationAddonable.id")
            |    {{ operationAddonable.name }}
      .ui.divider.compact
      .six.wide.column.ui.grid(v-for="(operationAddonableGate, index_gate) in operationAddonable.operation_addonable_gates")
        .two.wide.column
          label(class="select optional" for="operation_addonable_gate.gate_id")
          vSelect(
            :disabled="!operationAddonable.really_editable"
            v-model='operationAddonableGate.gate_id'
            class='ui-vue-select'
            :options='gateCollection'
            :multiple='false'
            :clearable="false"
            :reduce="gate => gate.id"
            v-bind:placeholder='$t("gates")'
            @input='handleOperationAddonableGateChangedGate(operationAddonableGate.id, operationAddonable.id, $event)'
          )
        .two.wide.column
          date-picker(
            :disabled="!operationAddonable.really_editable"
            :lang='currentLocale'
            v-model='operationAddonableGate.hour'
            value-type='format'
            format="HH:mm"
            clearable=false
            @change="handleOperationAddonableGateChangedHour(operationAddonableGate.id, operationAddonable.id, $event)"
            type="time"
            :placeholder='timePlaceholder'
            :time-picker-options="{start: '00:00', step: '00:30', end: '23:30'}"
          )
        .one.wide.column
          label {{ $t(operationAddonableGate.gate_authorization) }}
        .one.wide.column
          button.ui.icon.button.mini.red(
            v-if="operationAddonable.really_editable === true && operationAddonableGate.id"
            @click="deleteOperationAddonableGate(operationAddonableGate.id)",
          )
            i.icon.trash
      
</template>

<script>
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker'
import moment from 'moment'
import Axios from '@frontend/utils/axios_defaults.js'
import { error as errNotify, success as succNotify } from '../utils/notify'

export default {
  name: 'OperationAddonableGates',
  components: {
    vSelect,
    DatePicker,
  },

  data() {
    var addonables = this.operationAddonables
    addonables.forEach(addonable => {
      addonable.operation_addonable_gates.push(this.emptyOperationAddonableGate())
    })
    return {
      addonables: addonables,
      selectedOperationAddonableIds: [],
    }
  },
  props: {
    operationAddonables: {
      type: Array,
      default: () => [],
    },
    gateCollection: {
      type: Array,
      default: () => [],
    },
    sinfomarGateAuthorizationSync: {
      type: Boolean,
      default: false,
    },
    requestUrl: {
      type: String,
      default: '',
    },
    cancelUrl: {
      type: String,
      default: '',
    },
    saveUrl: {
      type: String,
      default: '',
    },
    updateUrl: {
      type: String,
      default: '',
    },
  },
  computed: {
    currentLocale() {
      return gon.locale
    },
    isAnyOperationAddonableChecked: function() {
      return this.selectedOperationAddonableIds.length > 0
    },
    areAllOperationAddonablesChecked: function() {
      return this.selectedOperationAddonableIds.length == this.operationAddonables.filter(function(oa) { return oa.really_editable }).length
    },
    timePlaceholder() {
      return '__:__'
    },
  },
  methods: {
    isOperationAddonableChecked: function(operationAddonableId) {
      return this.selectedOperationAddonableIds.includes(operationAddonableId)
    },
    toggleOperationAddonable: function(operationAddonableId) {
      var index = this.selectedOperationAddonableIds.indexOf(operationAddonableId)

      if (index === -1) {
        this.selectedOperationAddonableIds.push(operationAddonableId)
      } else {
        this.selectedOperationAddonableIds.splice(index, 1)
      }
    },
    toggleAllOperationAddonable: function() {
      if(this.areAllOperationAddonablesChecked) {
        this.selectedOperationAddonableIds = []
      } else {
        var self = this
        this.operationAddonables.forEach  (function(operationAddonable) {
          if(operationAddonable.really_editable && self.selectedOperationAddonableIds.indexOf(operationAddonable.id) === -1) {
            self.selectedOperationAddonableIds.push(operationAddonable.id)
          }
        })
      }
    },
    handleOperationAddonableGateChangedGate: function(operationAddonableGateId, operationAddonableId, gateId) {
      this.saveOperationAddonableGate({
        operation_addonable_gate_id: operationAddonableGateId,
        operation_addonable_id: operationAddonableId,
        gate_id: gateId,
      })
    },
    handleOperationAddonableGateChangedHour: function(operationAddonableGateId, operationAddonableId, hour) {
      this.saveOperationAddonableGate({
        operation_addonable_gate_id: operationAddonableGateId,
        operation_addonable_id: operationAddonableId,
        hour: hour,
      })
    },
    saveOperationAddonableGate: function(params) {
      Axios.post(
        this.saveUrl,
        params
      ).then(response => {
        const responseData = response.data
        this.substituteAperationAddonables(responseData.addonables)
        if (responseData.success) {
          succNotify({ title: this.$t('saved') })
        } else {
          responseData.alerts.forEach(function(alert) {
            errNotify({ title: alert })
          })
        }
      }).catch(err => {
        errNotify({ title: this.$t('error') })
      })
    },
    formattedHour(operationAddonableGate) {
      return moment(operationAddonableGate.hour).format('HH:mm') 
    },
    deleteOperationAddonableGate: function(operationAddonableGateId) {
      Axios.delete(
        `/operation_addonable_gates/${operationAddonableGateId}`,
      ).then(() => {
        var self = this
        this.addonables.forEach(function(addonable, addonable_index) {
          addonable.operation_addonable_gates.forEach(function(operation_addonable_gate, addonable_gate_index) {
            if(operation_addonable_gate.id === operationAddonableGateId) {
              self.addonables[addonable_index].operation_addonable_gates.splice(addonable_gate_index, 1) 
              return
            }
          })
        })
        succNotify({ title: this.$t('success') })
      }).catch(err => {
        errNotify({ title: this.$t('error') })
      })
    },
    revokeSelected: function() {
      this.callAction(this.cancelUrl)
    },
    requestSelected: function() {
      this.callAction(this.requestUrl)
    },
    refreshSelected: function() {
      this.callAction(this.updateUrl)
    },
    callAction: function(url) {
      if(this.selectedOperationAddonableIds.length > 0 ) {
        const params = {
          operation_addonables_ids: this.selectedOperationAddonableIds,
        }

        Axios.post(
          url,
          params
        ).then(response => {
          const responseData = response.data
          this.substituteAperationAddonables(responseData.addonables)
          responseData.notices.forEach(function(notice) {
            succNotify({ title: notice })
          })
          responseData.alerts.forEach(function(alert) {
            errNotify({ title: alert })
          })
        }).catch(err => {
          errNotify({ title: this.$t('error') })
        })
      }
    },
    substituteAperationAddonables(new_addonables) {
      var self = this
      this.addonables.forEach(function(addonable, addonable_index) {
        new_addonables.forEach(function(response_addonable) {
          if(addonable.id === response_addonable.id) {
            response_addonable.operation_addonable_gates.push(self.emptyOperationAddonableGate())
            self.$set(self.addonables, addonable_index, response_addonable)
          }
        })
      })
    },
    emptyOperationAddonableGate() {
      return {id: undefined}
    },
  },
}
</script>
