import { contains, findWhere } from 'underscore'

const blackListedFormAttrs = [
  'utf8',
  '_method',
  'authenticity_token',
]
const filterBlacklistedFormAttrs = (newAttrs, value) => {
  if (contains(blackListedFormAttrs, value.name)) { return newAttrs }

  newAttrs.push(value)
  return newAttrs
}
const makeFilterInitialStateFormAttrs = (initialState) => (newAttrs, value) => {
  if (contains(blackListedFormAttrs, value.name)) { return newAttrs }

  const initialStateValue = findWhere(initialState || [], { name: value.name })
  if (initialStateValue && initialStateValue.value == value.value) { return newAttrs }

  newAttrs.push(value)
  return newAttrs
}

export default {
  unloadAddonable(state) {
    state.currentAddonable = null
  },

  setAddonable(state, addonable) {
    state.currentAddonable = addonable
  },

  setAddonables(state, addonables) {
    state.addonables = addonables
  },

  addonablesCollectionFormLoading(state, value) {
    state.addonablesCollectionFormIsLoading = value
  },

  addonablesCollectionFormContent(state, content) {
    state.addonablesCollectionFormContent = content
  },

  addonablesFormLoading(state, value) {
    state.addonablesFormIsLoading = value
  },

  addonablesFormShown(state, value) {
    state.addonablesFormIsShown = value
  },

  addonablesFormContent(state, content) {
    state.addonablesFormContent = content
  },

  trackAddonablesFormInitialState(state, serializedForm) {
    state.addonablesFormInitialState = serializedForm.reduce(filterBlacklistedFormAttrs, [])
  },

  trackAddonablesFormDirtyState(state, serializedForm) {
    state.addonablesFormDirtyState = serializedForm.reduce(makeFilterInitialStateFormAttrs(state.addonablesFormInitialState), [])
  },

  resetAddonablesFormDirtyState(state) {
    state.addonablesFormDirtyState = undefined
  },
}
