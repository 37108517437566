import {
  SET_OPTIMIZED_TIMES_DATA,
  SET_CURRENT_PAGE_OPTIMIZED_TIME_IDS,

  ADD_OPTIMIZED_TIME_TO_SAVE,
  REMOVE_OPTIMIZED_TIME_TO_SAVE,
  ADD_MULTIPLE_OPTIMIZED_TIME_TO_SAVE,
  REMOVE_MULTIPLE_OPTIMIZED_TIME_TO_SAVE,
  SET_OPTIMIZED_TIMES_TO_SAVE,
  RESET_OPTIMIZED_TIMES_TO_SAVE,

} from '../mutation_types'

const state = {
  optimizedTimesData: [],
  currentPageOptimizedTimeIds: [],
  optimizedTimesToSave: [],
}

const getters = {
  optimizedTimesToSaveCount: (state) => {
    return state.optimizedTimesToSave.length
  },
  areAllOptimizedTimesToSaveChecked: (state) => {
    return state.currentPageOptimizedTimeIds.every(id => state.optimizedTimesToSave.includes(id))
  },
}

const actions = {
  setOptimizedTimesData ({ commit }, optimizedTimesData) {
    commit(SET_OPTIMIZED_TIMES_DATA, optimizedTimesData)
  },
  setCurrentPageOptimizedTimeIds ({ commit }, optimizedTimesData) {
    commit(SET_CURRENT_PAGE_OPTIMIZED_TIME_IDS, optimizedTimesData)
  },


  toggleOptimizedTimeToSave ({ state, commit }, optimizedTime) {
    if (state.optimizedTimesToSave.includes(optimizedTime.id)) {
      commit(REMOVE_OPTIMIZED_TIME_TO_SAVE, optimizedTime)
    } else {
      commit(ADD_OPTIMIZED_TIME_TO_SAVE, optimizedTime.id)
    }
  },
  toggleAllOptimizedTimesToSave ({ state, getters, commit }) {
    commit(SET_OPTIMIZED_TIMES_TO_SAVE, state.optimizedTimesToSave || [])
    if (getters.areAllOptimizedTimesToSaveChecked) {
      commit(REMOVE_MULTIPLE_OPTIMIZED_TIME_TO_SAVE, state.currentPageOptimizedTimeIds)
    } else {
      commit(ADD_MULTIPLE_OPTIMIZED_TIME_TO_SAVE, state.currentPageOptimizedTimeIds)
    }
  },
  resetOptimizedTimesToSave ({ commit }) {
    commit(RESET_OPTIMIZED_TIMES_TO_SAVE)
    commit(RESET_OPTIMIZED_TIMES_TO_SAVE)
  },
}

const mutations = {
  [SET_OPTIMIZED_TIMES_DATA] (state, optimizedTimesData) {
    state.optimizedTimesData = optimizedTimesData
  },
  [SET_CURRENT_PAGE_OPTIMIZED_TIME_IDS] (state, optimizedTimesData) {
    state.currentPageOptimizedTimeIds = optimizedTimesData.map(ot => ot.id)
  },


  [ADD_OPTIMIZED_TIME_TO_SAVE] (state, optimizedTimeId) {
    state.optimizedTimesToSave.push(optimizedTimeId)
  },
  [REMOVE_OPTIMIZED_TIME_TO_SAVE] (state, optimizedTime) {
    state.optimizedTimesToSave = state.optimizedTimesToSave.filter(optimizedTimesId => optimizedTimesId !== optimizedTime.id)
  },
  [ADD_MULTIPLE_OPTIMIZED_TIME_TO_SAVE] (state, optimizedTimesToSave) {
    state.optimizedTimesToSave = Array.from(new Set(state.optimizedTimesToSave.concat(optimizedTimesToSave)))
  },
  [REMOVE_MULTIPLE_OPTIMIZED_TIME_TO_SAVE] (state, optimizedTimesToSave) {
    state.optimizedTimesToSave = state.optimizedTimesToSave.filter(optimizedTimesId => !optimizedTimesToSave.includes(optimizedTimesId))
  },
  [SET_OPTIMIZED_TIMES_TO_SAVE] (state, optimizedTimesToSave) {
    state.optimizedTimesToSave = optimizedTimesToSave
  },
  [RESET_OPTIMIZED_TIMES_TO_SAVE] (state) {
    state.optimizedTimesToSave = []
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
