<template lang='pug'>
  modal(name='excluded-days' class='vue-modal' width='900px' height='auto' v-bind:adaptive='true')
    .ui.modal.visible.active.edit-days-modal(style="overflow: auto; max-height: 100vh;")
      div(slot='top-right')
        i.close.icon(v-on:click='closeModal')
      .header
        h1 {{ $t('excluded_days_modal.title') }}
      .content
        .ui.grid
          .eight.wide.column
            p {{ $t('excluded_days_modal.manual_edit') }}
            Calendar(
              v-bind:rangeStart='rangeStart'
              v-bind:rangeEnd='rangeEnd'
              v-bind:weekDays='weekDays'
              v-bind:excludedDays='excludedDays'
              v-bind:datePickerLocale='datePickerLocale'
              v-on:submitExcludedDays='submitExcludedDays'
            )
            p
              br
              span(v-if='hasOperationsPlanInterval')
                | {{ $t('excluded_days_modal.operations_plan_interval', { start_on: this.prettyRangeStart, crease_on: this.prettyRangeEnd } ) }}
                br
              | {{ $t('excluded_days_modal.excluded_days_count') }}
              strong {{ this.excludedDays.length }}
            .ui.labeled.icon.red.mini.button.deselect-all-days-button(v-on:click='deselectAllDays' v-if='someDaysSelected')
              i.icon.trash
              | {{ $t('excluded_days_modal.deselect_all_days') }}
            .calendar-key
              .marker.disabled 1
              | {{ $t('calendar_key.disabled') }}
            .calendar-key
              .marker.selected 2
              | {{ $t('calendar_key.selected') }}
            .calendar-key
              .marker.excluded 3
              | {{ $t('calendar_key.excluded') }}

          .eight.wide.column
            p {{ $t('excluded_days_modal.automated_edit') }}
            vSelect(
              class='option-select'
              v-model='option'
              v-bind:options='options'
              v-bind:placeholder='$t("options.placeholder")'
              v-bind:searchable='false'
            )
            ExcludeDays(
              v-if='showExcludeDays'
              v-bind:withDayOfTheWeek='withDayOfTheWeek'
              v-bind:disabledDates='disabledDates'
              v-bind:rangeStart='rangeStart'
              v-bind:rangeEnd='rangeEnd'
              v-bind:weekDays='weekDays'
              v-bind:datePickerLocale='datePickerLocale'
              v-on:addDaysToExcluded='addDaysToExcluded'
              v-on:closeOption='closeOption'
            )
            ExcludeHolidays(
              v-if='showExcludeHolidays'
              v-bind:withInterval='withInterval'
              v-bind:disabledDates='disabledDates'
              v-bind:rangeStart='rangeStart'
              v-bind:rangeEnd='rangeEnd'
              v-bind:weekDays='weekDays'
              v-bind:hasOperationsPlanInterval='hasOperationsPlanInterval'
              v-bind:datePickerLocale='datePickerLocale'
              v-on:addDaysToExcluded='addDaysToExcluded'
              v-on:closeOption='closeOption'
            )
      .actions
        .ui.buttons
          .ui.green.button(v-on:click='closeModal')
            | {{ $t('actions.update') }}
</template>

<script>
import Vue from 'vue'
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'
import moment from 'moment'
import Calendar from './calendar'
import ExcludeDays from './exclude-days'
import ExcludeHolidays from './exclude-holidays'

export default {
  components: {
    Datepicker,
    vSelect,
    Calendar,
    ExcludeDays,
    ExcludeHolidays,
  },

  props: {
    excludedDays: Array,
    rangeStart: Date,
    rangeEnd: Date,
    weekDays: Object,
    datePickerLocale: Object,
  },

  data() {
    return {
      option: null,
    }
  },

  computed: {
    someDaysSelected() {
      return this.excludedDays.length > 0
    },
    prettyRangeStart() {
      return moment(this.rangeStart).format('DD/MM/YYYY')
    },
    prettyRangeEnd() {
      return moment(this.rangeEnd).format('DD/MM/YYYY')
    },
    hasOperationsPlanInterval() {
      return this.rangeStart !== undefined && this.rangeEnd !== undefined
    },
    disabledDates() {
      if (this.hasOperationsPlanInterval) {
        return {
          to: this.rangeStart,
          from: moment(this.rangeEnd).add(1, 'd').toDate(),
        }
      } else {
        return {}
      }
    },
    options() {
      return [
        { label: this.$t('options.days_interval'), code: 'days-interval' },
        { label: this.$t('options.week_days_interval'), code: 'week-days-interval' },
        { label: this.$t('options.holidays'), code: 'holidays' },
        { label: this.$t('options.holidays_interval'), code: 'holidays-interval' },
      ]
    },
    optionSelected() {
      return this.option !== null && this.option.code !== undefined
    },
    showExcludeDays() {
      return this.optionSelected && (this.option.code === 'days-interval' || this.option.code === 'week-days-interval')
    },
    showExcludeHolidays() {
      return this.optionSelected && (this.option.code === 'holidays' || this.option.code === 'holidays-interval')
    },
    withDayOfTheWeek() {
      return this.option.code === 'week-days-interval'
    },
    withInterval() {
      return this.option.code === 'holidays-interval'
    },
  },

  methods: {
    addDaysToExcluded(daysToAdd) {
      let daysJoin = this.excludedDays.concat(daysToAdd)
      this.$emit('submitExcludedDays', [...new Set(daysJoin.filter(Boolean))].sort())
    },
    closeOption() {
      this.option = null
    },
    deselectAllDays() {
      this.$emit('submitExcludedDays', [])
    },
    closeModal() {
      this.$emit('hideExcludedDaysModal')
    },
    submitExcludedDays(excludedDays) {
      this.$emit('submitExcludedDays', excludedDays)
    },
  },
}
</script>

<style lang='sass'>
  .ui.form .option-select
    width: 100%
    input[type='search'],
    input[type='search']:focus
      line-height: 1.4
      font-size: 1em
      border: 1px solid transparent
      border-left: none
      outline: none
      margin: 4px 0 0 0
      padding: 0 7px
      background: none
      box-shadow: none
      width: 0
      max-width: 100%
      flex-grow: 1
  .calendar-key
    margin-bottom: 3px
    .marker
      display: inline-block
      margin-right: 10px
      padding: 0 5px
      width: 30px
      height: 30px
      line-height: 30px
      text-align: center
      vertical-align: middle
      border: 1px solid #f7f7f7
      &.disabled
        color: #dddddd
      &.selected
        background: #cae5ed
      &.excluded
        background: #fdd86c
  .ui.button.deselect-all-days-button
    margin-bottom: 10px
</style>
