<template lang="pug">
.timeline-spans(:style="cssVars")
  PureTimelineSpan(
    v-for="(duration, index) in durationsWithOffset",
    :key="index",
    :label="shiftLabel(index)",
    :width="spanWidthByDuration(duration)"
  )
</template>

<script>
import PureTimelineSpan from '@/trains-timeline/PureTimelineSpan.vue'
import { HOURS_IN_A_DAY } from '@frontend/helpers/constants'

export default {
  name: 'PureTimelineSpans',
  components: {
    PureTimelineSpan,
  },
  props: {
    offset: {
      type: Number,
      default: 0,
    },
    durations: {
      type: Array,
      default: () => [8, 8, 8],
    },
    timelineSpanLabel: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 1000,
    },
  },
  computed: {
    cssVars() {
      return {
        '--width': `${this.width}px`,
      }
    },
    hasOffset() {
      return !!this.offset
    },
    durationsWithOffset() {
      if (!this.offset) { return this.durations }

      const durationsWithOffset = [...this.durations]
      durationsWithOffset.unshift(this.offset)
      durationsWithOffset[durationsWithOffset.length - 1] -= this.offset
      return durationsWithOffset
    },
  },
  methods: {
    spanWidthByDuration(duration) {
      return this.width * ( duration / HOURS_IN_A_DAY )
    },
    shiftLabel(index) {
      let indexWithOffset
      if (!this.hasOffset) {
        indexWithOffset = index
      } else {
        indexWithOffset = index - 1 >= 0 ? index - 1  : this.durations.length - 1
      }

      return `${this.timelineSpanLabel} ${indexWithOffset}`
    },
  },
}
</script>

<style lang="sass" scoped>
.timeline-spans
  display: flex
  align-items: center
  width: var(--width)
  flex: 1 0 auto

  ::v-deep
    .timeline-span + .timeline-span
      border-left: 1px solid #bbb
</style>
