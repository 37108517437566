<template lang='pug'>
  .fields.two
    .field.required(v-bind:class='rangeStartCssClass')
      label.required {{ $t('labels.range_start') }}
      Datepicker(
        v-model='rangeStartSelectedValue'
        v-on:selected='rangeStartSelected'
        v-bind:name='rangeStartName'
        v-bind:language='datePickerLocale'
        v-bind:monday-first='true'
        v-bind:full-month-name='true'
        v-bind:disabledDates='rangeStartDisabledDates'
        format='dd/MM/yyyy'
        :disabled='disabledRangeStartInput'
      )
      .ui.pointing.red.basic.label(v-if='rangeStartHasValidationError')
        | {{ rangeStartValidationError }}

    .field.required(v-bind:class='rangeEndCssClass')
      label.required {{ $t('labels.range_end') }}
      Datepicker(
        v-model='rangeEndSelectedValue'
        v-on:selected='rangeEndSelected'
        v-bind:name='rangeEndName'
        v-bind:language='datePickerLocale'
        v-bind:monday-first='true'
        v-bind:full-month-name='true'
        v-bind:disabledDates='rangeEndDisabledDates'
        format='dd/MM/yyyy'
        :disabled='disabledRangeEndInput'
      )
      .ui.pointing.red.basic.label(v-if='rangeEndHasValidationError')
        | {{ rangeEndValidationError }}
</template>

<script>
import Datepicker from 'vuejs-datepicker'

export default {
  components: {
    Datepicker,
  },

  props: {
    model: String,
    rangeStartInputName: String,
    rangeStart: Date,
    rangeEndInputName: String,
    rangeEnd: Date,
    datePickerLocale: Object,
    errors: Object,
    disabledRangeStartInput: Boolean,
    disabledRangeEndInput: Boolean,
  },

  data() {
    return {
      rangeStartSelectedValue: this.rangeStart,
      rangeEndSelectedValue: this.rangeEnd,
    }
  },

  computed: {
    rangeStartName() {
      return this.model + '[' + this.rangeStartInputName + ']'
    },
    rangeEndName() {
      return this.model + '[' + this.rangeEndInputName + ']'
    },
    rangeStartDisabledDates() {
      if (this.rangeEndSelectedValue) {
        return {
          from: this.rangeEndSelectedValue,
        }
      } else {
        return {}
      }
    },
    rangeEndDisabledDates() {
      if (this.rangeStartSelectedValue) {
        return {
          to: this.rangeStartSelectedValue,
        }
      } else {
        return {}
      }
    },
    rangeStartHasValidationError() {
      return this.errors !== undefined &&
        this.errors[this.rangeStartInputName] !== undefined &&
        this.errors[this.rangeStartInputName].length > 0
    },
    rangeEndHasValidationError() {
      return this.errors !== undefined &&
        this.errors[this.rangeEndInputName] !== undefined &&
        this.errors[this.rangeEndInputName].length > 0
    },
    rangeStartValidationError() {
      if (this.rangeStartHasValidationError) {
        return this.errors[this.rangeStartInputName].join(', ')
      }
    },
    rangeEndValidationError() {
      if (this.rangeEndHasValidationError) {
        return this.errors[this.rangeEndInputName].join(', ')
      }
    },
    rangeStartCssClass() {
      if (this.rangeStartHasValidationError) {
        return 'error'
      }
    },
    rangeEndCssClass() {
      if (this.rangeEndHasValidationError) {
        return 'error'
      }
    },
  },

  methods: {
    rangeStartSelected(day) {
      this.$emit('updateRangeStart', day)
    },
    rangeEndSelected(day) {
      this.$emit('updateRangeEnd', day)
    },
  },
}
</script>
