<template lang='pug'>
  .car-list-wrapper(
    v-bind:class='cssClass'
    v-bind:data-car-list-uuid='carList.uuid'
    :style="customStyleColor.wrapper"
  )

    .car-list__sticky-name(
      v-if='isStickyNameEnabled'
      :style="customStyleColor.sticky"
    )
      span(:title='carList.name') {{ carListStickyName }}

    .clamp(v-if='hasClamps')

    .car-list
      Car(
        v-for='car in carList.cars'
        v-bind:key='car.id'
        v-bind:car='car'
        v-bind:carList='carList'
        v-bind:searchedCarCode='searchedCarCode'
        v-bind:searchedCarTypology='searchedCarTypology'
        v-bind:searchedMaintenanceStatus='searchedMaintenanceStatus'
      )

    .clamp(v-if='hasClamps')

    .actions-unavailable(v-if='unavailable' v-bind:class='cssClassForUnavailable')
      span.actions__label(:title='carList.name') {{ unavailableCarListName }}
      .plant-link
        span {{ viewAddonableLabel }}
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import Car from './car'
import confirmable from '../mixins/confirmable'

export default {
  components: {
    Car,
  },

  props: {
    carList: Object,
    searchedCarCode: String,
    searchedCarTypology : Object,
    searchedMaintenanceStatus: Object,
    enableCompanyColorAsDefaultInPlant: Boolean,
  },

  mixins: [confirmable],

  data() {
    return {
      focus: false,
      inverted: false,
    }
  },

  computed: {
    ...mapState([
      'currentOperation',
      'currentCarList',
      'highlightedCarList',
      'dragging',
      'tenant',
      'currentUser',
    ]),
    ...mapGetters([
      'carListUnavailable',
      'isAddonableSelected',
      'isStickyCarListNameEnabled',
      'isCarListViewable',
      'hasClosedOperation',
    ]),
    isStickyNameEnabled() {
      return this.isStickyCarListNameEnabled
    },
    isClamped() {
      return this.carList.clamps === true
    },
    unavailableCarListName() {
      var length = 4 * 4
      if (this.carList.name.length <= length) { return this.carList.name }

      return this.carList.name.substring(0, length) + '...'
    },
    carListStickyName() {
      const charsPerCar = 2.5
      const cars = this.carList.cars.length
      const label = this.carList.name || this.carList.uuid
      if (label.length <= (cars * charsPerCar)) { return label }

      return label.substring(0, (cars * charsPerCar)) + '...'
    },
    viewAddonableLabel() {
      return this.$t('view')
    },
    canManageRat() {
      return this.tenant === 'sncfr'
    },
    cars() {
      return this.carList.cars
    },
    hasCars() {
      return this.cars.length > 1
    },
    hasClamps() {
      return this.carList.clamps
    },
    isCurrentCarList() {
      if (!this.currentOperation) { return false }
      if (!this.isAddonableSelected) { return false }

      return this.currentCarList && Number(this.currentCarList.id) === Number(this.carList.id)
    },
    isCarListHighlighted() {
      if (this.isCurrentCarList) { return false }

      return this.highlightedCarList
        && Number(this.highlightedCarList.id) === Number(this.carList.id)
    },
    cssClass() {
      let cssClass = ''

      if (this.carList.position !== undefined) {
        cssClass += this.carList.position
      }

      if (!this.isCurrentCarList && this.unavailable) {
        cssClass += ' unavailable'
      }

      if (this.focus && !this.dragging) {
        cssClass += ' focus'
      }
      if (this.isCurrentCarList) { cssClass += ' active' }
      if (this.isCarListHighlighted) { cssClass += ' highlight' }
      if (this.isStickyNameEnabled) { cssClass += ' with-sticky-name' }
      if (!this.isViewable && this.currentUser.external) { cssClass += ' not-viewable' }
      if (this.hasClosedOperation) { cssClass += ' closed-operation' }

      return cssClass
    },
    customStyleColor() {
      const bgColor = this.bgColor || this.carList.sticky_color
      const highPriorityColorNeeded = this.isCurrentCarList || this.isCarListHighlighted
      const companyColor = this.enableCompanyColorAsDefaultInPlant &&
        !highPriorityColorNeeded &&
        this.carList?.railway_company_color
      return {
        wrapper: companyColor ? {'border-color': companyColor} : null,
        sticky: companyColor ? {'background-color': companyColor} : {'background-color': bgColor},
      }
    },
    cssClassForUnavailable() {
      return 'actions-counter-5'
    },
    isViewable() {
      return this.isCarListViewable(this.carList)
    },
    hasClosedOperation() {
      return this.carList.has_closed_operation
    },
    unavailable() {
      if (!this.isViewable) { return false }

      return this.carListUnavailable(this.carList)
    },
  },
}
</script>
