<template lang="pug">
.plant-select.ui.field.select
  VSelect(
    v-model="selectedPlant",
    :options="options",
    @search="asyncFindPlants",
    :loading="isLoading",
    :filterable="false",
    :placeholder="$t('plant_select.placeholder')",
    @option:selected="handleSelect($event)",
    @option:deselected="handleDeselect($event)",
    @input="handleInput($event)",
    label="name"
  )
</template>

<script>
import VSelect from 'vue-select'
import { debounce } from '@frontend/utils/helpers.js'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'PlantSelect',
  components: {
    VSelect,
  },
  data() {
    return {
      isLoading: false,
      selectedPlant: null,
      options: [],
    }
  },
  mounted() {
    this.asyncFindPlants()
  },
  computed: {
    ...mapState('operationsPlansOptimizer', [
      'showOptimizedTimes',
    ]),
  },
  methods: {
    ...mapActions('operationsPlansOptimizer', [
      'setPlantIdFilter',
      'callIndex',
      'showLastOptimized',
    ]),

    asyncFindPlants: debounce(function (query = '') {
      this.isLoading = true
      this.$http.get(
        '/plants/list_for_select.json',
        { params: { q: query } }
      ).then(response => {
        this.options = response.data.plants
        this.isLoading = false
      })
    }, 300),

    async handleSelect(selectedOption) {
      if(!selectedOption || !selectedOption.value) { return }

      this.setPlantIdFilter(selectedOption.value)
      await this.asyncPageRequest()
    },

    async handleDeselect() {
      await this.clear()
    },

    async handleInput(query) {
      if(!query || query.length === 0) { await this.clear() }
    },

    async clear() {
      this.selectedPlant = null
      this.setPlantIdFilter(null)
      await this.asyncPageRequest()
    },

    async asyncPageRequest() {
      this.showOptimizedTimes ? await this.showLastOptimized() : await this.callIndex({})
    },
  },
}
</script>


<style lang="sass" scoped>
.plant-select::v-deep
  min-width: 200px

  .vs__dropdown-toggle
    height: 36px
</style>
