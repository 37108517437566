export const SHOW_OPTIMIZED_TIMES = 'SHOW_OPTIMIZED_TIMES'
export const HIDE_OPTIMIZED_TIMES = 'HIDE_OPTIMIZED_TIMES'

export const SET_OPERATIONS_PLANS_DATA = 'SET_OPERATIONS_PLANS_DATA'
export const SET_CURRENT_PAGE_OPERATIONS_PLAN_IDS = 'SET_CURRENT_PAGE_OPERATIONS_PLAN_IDS'

export const ADD_OPERATIONS_PLAN_TO_OPTIMIZE = 'ADD_OPERATIONS_PLAN_TO_OPTIMIZE'
export const REMOVE_OPERATIONS_PLAN_TO_OPTIMIZE = 'REMOVE_OPERATIONS_PLAN_TO_OPTIMIZE'
export const TOGGLE_OPERATIONS_PLAN_TO_OPTIMIZE = 'TOGGLE_OPERATIONS_PLAN_TO_OPTIMIZE'
export const SET_OPERATIONS_PLANS_TO_OPTIMIZE_ARRAY = 'SET_OPERATIONS_PLANS_TO_OPTIMIZE_ARRAY'
export const RESET_OPERATIONS_PLANS_TO_OPTIMIZE = 'RESET_OPERATIONS_PLANS_TO_OPTIMIZE'

export const SET_OPTIMIZED_TIMES_DATA = 'SET_OPTIMIZED_TIMES_DATA'
export const SET_CURRENT_PAGE_OPTIMIZED_TIME_IDS = 'SET_CURRENT_PAGE_OPTIMIZED_TIME_IDS'

export const ADD_OPTIMIZED_TIME_TO_SAVE = 'ADD_OPTIMIZED_TIME_TO_SAVE'
export const REMOVE_OPTIMIZED_TIME_TO_SAVE = 'REMOVE_OPTIMIZED_TIME_TO_SAVE'
export const TOGGLE_OPTIMIZED_TIME_TO_SAVE = 'TOGGLE_OPTIMIZED_TIME_TO_SAVE'
export const SET_OPTIMIZED_TIMES_TO_SAVE_ARRAY = 'SET_OPTIMIZED_TIMES_TO_SAVE_ARRAY'
export const RESET_OPTIMIZED_TIMES_TO_SAVE = 'RESET_OPTIMIZED_TIMES_TO_SAVE'

export const SET_OPTIMIZED_TIME_PERIODICITY_ACTIVITY_TIME = 'SET_OPTIMIZED_TIME_PERIODICITY_ACTIVITY_TIME'

export const SET_PLANT_ID_FILTER = 'SET_PLANT_ID_FILTER'
