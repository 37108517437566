<template lang="pug">
.ui.segment
  h4 {{ $t('select_operations_for_invoice') }}
  .ui.grid
    .four.wide.column
      DateRangeForm
    .two.wide.column
      label(class="select optional" for="company_id")
      vSelect(
        class='ui-vue-select'
        :options='companiesCollection'
        :multiple='false'
        :clearable="false"
        :reduce="company => company.id"
        v-bind:placeholder='$t("company")'
        @input='companyChanged($event)'
      )
    .two.wide.column
      .ui.buttons
        .ui.tiny.button.blue(v-on:click='reloadData' :class="{'disabled': !(companyId && fromDate && toDate)}")
          i.icon.search
          | {{ $t('search') }}

    .three.wide.column
    .one.wide.column
      .actions
        .ui.buttons
          .ui.tiny.button.green(v-on:click='generateInvoice' :class="{'disabled': disableGenerateInvoice()}")
            | {{ $t('generate_invoice') }}
          a.ui.tiny.button.blue(v-bind:href='lastGeneratedInvoice.link' target='_blank' v-if="lastGeneratedInvoice")
            | {{ $t('goto_invoice_number', {number: lastGeneratedInvoice.number}) }}
  .operations-for-invoice-table
    .table-container-scrollable
      table.ui.compact.table.unstackable.max-content
        thead
          th: input#all-operations-for-invoice-toggler(
            type="checkbox",
            :checked="areAllAddonablesToInvoiceChecked",
            @change="toggleAllAddonableToInvoice()"
          )
          th
            | {{ $t('operation_name') }}
          th
            a(@click="setSort('done_on')")
              | {{ sortableTitle($t('done_at'), 'done_on') }}
          th
            | {{ $t('addonable_name') }}
          th
            | {{ $t('operation_code_name') }}
          th
            | {{ $t('cost') }}
          th
            | {{ $t('internal_cost') }}
        tbody
          tr(v-for="(operation_addonable, index) in operationAddonablesData",
             :key="operation_addonable.id"
            )
            td: input(
              :checked="selectedOperationAddonableIds.includes(operation_addonable.id)",
              type="checkbox",
              :id="operation_addonable.id",
              :value="operation_addonable.id",
              @change="toggleAddonableToInvoice(operation_addonable.id)"
            )
            td
              a(v-bind:href='operationLink(operation_addonable.operation_id)'
                target='_blank')
               | {{ operation_addonable.operation_title }}
            td {{ operation_addonable.operation_full_date }}
            td
              a(v-bind:href='operationAddonablesLink(operation_addonable.operation_id)'
                target='_blank')
               | {{ operation_addonable.name }}
            td {{ operation_addonable.operations_code_label }}
            td {{ operation_addonable.operations_code_cost }}
            td {{ operation_addonable.operations_code_internal_cost }}
        tfoot.full-width
    Pagination(@pagination:page:changed="handlePageChanged")
</template>

<script>
import DateRangeForm from './DateRangeForm.vue'
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker'
import { mapState, mapActions } from 'vuex'
import Pagination from'../pagination/Pagination.vue'
import { error as errNotify, success as succNotify } from '../utils/notify'

export default {
  name: 'InvoiceAddon',
  components: {
    vSelect,
    DatePicker,
    DateRangeForm,
    Pagination,
  },

  data() {
    return {
      selectedOperationAddonableIds: [],
    }
  },
  props: {
    companiesCollection: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    currentLocale() {
      return gon.locale
    },
    ...mapState('pagination', [
      'currentPage',
      'paginationInfo',
    ],
    ),
    ...mapState('invoiceAddon', [
      'operationAddonablesData',
      'areAllAddonablesToInvoiceChecked',
      'currentSort',
      'currentSortDirection',
      'fromDate',
      'toDate',
      'companyId',
      'lastGeneratedInvoice',
    ]),
  },
  methods: {
    ...mapActions('invoiceAddon', [
      'callIndex',
      'setCompanyId',
      'callGenerateInvoice',
    ]),
    companyChanged(companyId) {
      this.setCompanyId({companyId: companyId})
    },
    reloadData() {
      this.selectedOperationAddonableIds = []
      this.callIndex({})
    },
    async handlePageChanged() {
      const page = this.currentPage || 1
      await this.callIndex({page, sort: this.currentSort, direction: this.currentSortDirection})
    },
    sortableTitle(title, key) {
      const currentSort = key === this.currentSort

      if (!currentSort) {
        return title
      }

      const arrow = this.currentSortDirection === 'desc' ? '▼' : '▲'
      return `${title} ${arrow}`
    },
    operationLink(operationId) {
      return `/operations/${operationId}`
    },
    operationAddonablesLink(operationId) {
      return `/operations/${operationId}/addons`
    },
    onGenerateInvoiceSuccess(number) {
      succNotify({ title: this.$t('generate_invoice_success', {number: number}) })
    },
    onGenerateInvoiceFailure(errors) {
      errNotify({ title: this.$t('generate_invoice_error'), text: errors })
    },
    generateInvoice() {
      if(this.selectedOperationAddonableIds) {
        this.callGenerateInvoice({addonableIds: this.selectedOperationAddonableIds, onSuccess: this.onGenerateInvoiceSuccess, onFailure: this.onGenerateInvoiceFailure})
        this.selectedOperationAddonableIds = []
      }
    },
    disableGenerateInvoice() {
      return !(this.companyId && this.fromDate && this.toDate && this.selectedOperationAddonableIds.length > 0)
    },
    toggleAddonableToInvoice: function(operationAddonableId) {
      var index = this.selectedOperationAddonableIds.indexOf(operationAddonableId)

      if (index === -1) {
        this.selectedOperationAddonableIds.push(operationAddonableId)
      } else {
        this.selectedOperationAddonableIds.splice(index, 1)
      }
    },
    toggleAllAddonableToInvoice: function() {
      if(this.areAllAddonablesToInvoiceChecked) {
        this.selectedOperationAddonableIds = []
      } else {
        var self = this
        this.operationAddonablesData.forEach  (function(operationAddonable) {
          if(self.selectedOperationAddonableIds.indexOf(operationAddonable.id) === -1) {
            self.selectedOperationAddonableIds.push(operationAddonable.id)
          }
        })
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.operations-for-invoice-table
  .max-content
    width: 100% !important

  .ui.table thead .column--day,
  .ui.table tbody .column--day
    min-width: 84px
    text-align: center

  .table-container-scrollable
    max-height: 79vh
</style>
