<template lang='pug'>
  div(v-bind:class='cssClass')
    button.ui.button.small.yellow(
      v-if='showPublishCarListButton'
      v-on:click='handleClick'
    )
      i.train.icon
      | {{ label }}

    PublishModal(
      v-if='showPlantCarListPublishingModal'
      v-on:closed='onClosed'
    )
    i.add.circle.icon.yellow(
      v-if='this.newCarListToPublish'
    )
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import PublishModal from '../modals/plant-car-list-publishing-modal'
import { checkCarListsExistence } from '../../api/car_lists'

export default {
  components: {
    PublishModal,
  },
  created () {
    Manovre.ws.on('plant_export.completed', this.autoDownloadExportFile)
    Manovre.ws.on('plant_export.failed', this.showExportError)
    if (this.showPublishCarListButton){
      const updateCheck = () => {
        this.checkCarListToPublish().then(() => {
          setTimeout(updateCheck, 30000)
        })
      }
      updateCheck()
    }
  },
  data() {
    return {
      newCarListToPublish: false,
    }
  },
  computed: {
    ...mapGetters([
      'showPublishCarListButton',
    ]),
    ...mapState([
      'showPlantCarListPublishingModal', 'apiToken', 'plantId',
    ]),
    cssClass() {
      return 'PlantCarListPublishingModalButton'
    },
    label() {
      return this.$t('publish_car_list')
    },
  },
  methods: {
    handleClick(e) {
      e.preventDefault()
      this.$store.commit('showPlantCarListPublishingModal')
    },

    onClosed() {
      this.$store.commit('hidePlantCarListPublishingModal')
    },
    checkCarListToPublish() {
      const today = new Date()
      today.setHours(today.getHours() - 3)
      const params = {
        q: {
          status_eq: 4, // Draft
          train_id_null: false,
          cached_plant_id_eq: this.plantId,
          created_at_gteq: today,
        },
      }

      return checkCarListsExistence({ params, apiToken: this.apiToken })
        .then((data) => {
          if (data.found) {
            this.newCarListToPublish = true
          } else {
            this.newCarListToPublish = false
          }
        })
    },

    autoDownloadExportFile(message) {
      if (Manovre.job_id !== message.jid) { return }

      $('#global-loader').dimmer('hide')
      Manovre.notifyStatus(this.$t('publish_modal.export.success'), true)
      const fakeDwButton = document.createElement('a')
      fakeDwButton.href = message.url
      fakeDwButton.download = message.filename
      fakeDwButton.click()
      fakeDwButton.remove()
    },
    showExportError(message) {
      if (Manovre.job_id !== message.jid) { return }

      $('#global-loader').dimmer('hide')
      Manovre.notifyStatus(message.error)
    },
  },
}
</script>
