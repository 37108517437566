<template lang="pug">
.week-day-selector
  .week-day-selector__nav.week-day-selector__nav--prev(
    v-if="!hideNavigation"
    @click="handlePrevButtonClick()"
  )
    i.chevron.circle.left.icon(:class="{ disabled: disableNavigation }")
  .week-day-selector__day(v-for="(day, index) in daysFormatted")
    .ui.button(
      :class="{ blue: isSelected(days[index]) }",
      @click="handleDayClick(days[index])"
    )
      | {{ day }}
  .week-day-selector__nav.week-day-selector__nav--next(
    v-if="!hideNavigation"
    @click="handleNextButtonClick()"
  )
    i.chevron.circle.right.icon(:class="{ disabled: disableNavigation }")
</template>

<script>
export default {
  name: 'PureWeekDaySelector',
  props: {
    selectedDate: {
      type: [Date, Object, Number, String],
      default: () => new Date(),
    },
    disableNavigation: {
      type: Boolean,
      default: false,
    },
    hideNavigation: {
      type: Boolean,
      default: false,
    },
    showDates: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['date:changed'],
  computed: {
    monday() {
      return this.$dayjs(this.selectedDate).startOf('week').toDate()
    },
    days() {
      const days = []
      for(let i = 0; i < 7; i++) {
        days.push(this.$dayjs(this.monday).add(i, 'days'))
      }
      return days
    },
    daysFormatted () {
      return this.showDates
        ? this.days.map(day => day.format('ddd D'))
        : this.days.map(day => day.format('ddd'))
    },
  },
  methods:{
    isSelected(day) {
      return this.$dayjs(day).isSame(this.selectedDate, 'day')
    },
    handleDayClick(date) {
      if (this.disableNavigation) {return }
      this.$emit('date:changed', { date })
    },
    handlePrevButtonClick() {
      if (this.disableNavigation) {return }
      this.$emit('date:prev', 1)
    },
    handleNextButtonClick() {
      if (this.disableNavigation) {return }
      this.$emit('date:next', 1)
    },
  },
}
</script>

<style lang="sass" scoped>
.week-day-selector
  display: flex

  .week-day-selector__day
    .button
      min-width: 90px

  .week-day-selector__nav
    display: flex
    align-items: center
    margin: 0px
    padding-bottom: 1px
    font-size: 16px
    cursor: pointer

    .icon:not(.disabled):hover
      color: #777
</style>
