<template lang="pug">
.time-range
  span.start-time {{ formatTime(startTime) }}
  span.range-separator(:class="{ 'range-separator--wide': !onSameDate }")
  span.end-time {{ formatTime(endTime) }}
</template>

<script>
export default {
  name: 'TimeRange',
  props: {
    startTime: {
      type: [Date, Object, String],
      default: undefined,
    },
    endTime: {
      type: [Date, Object, String],
      default: undefined,
    },
    timeOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    onSameDate() {
      return this.$dayjs(this.startTime).isSame(this.endTime, 'day')
    },
  },
  methods: {
    formatTime(time) {
      return this.onSameDate || this.timeOnly
        ? this.$dayjs(time).format('HH:mm')
        : this.$dayjs(time).format('DD/MM HH:mm')
    },
  },
}
</script>

<style lang="sass" scoped>
.time-range
  .range-separator::after
    content: '–'
    display: inline-flex
    padding: 0px 2px

  .range-separator--wide::after
    content: '—'
    padding: 0px 5px
</style>
