import { render, staticRenderFns } from "./PureMonthAndWeekSelector.vue?vue&type=template&id=6226f74e&scoped=true&lang=pug&"
import script from "./PureMonthAndWeekSelector.vue?vue&type=script&lang=js&"
export * from "./PureMonthAndWeekSelector.vue?vue&type=script&lang=js&"
import style0 from "./PureMonthAndWeekSelector.vue?vue&type=style&index=0&id=6226f74e&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6226f74e",
  null
  
)

export default component.exports