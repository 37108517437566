<template lang="pug">
.timeline-unit-wrapper(:style="cssVars")
  VDropdown(
    :boundary="boundary",
    placement="bottom-start",
    :disabled="!$slots.popover",
    :triggers="['hover']",
    :arrow-padding="8",
    :container="popoverContainer"
    popperClass="timeline-unit-popper"
  )
    .timeline-unit(:class="{ clickable: $slots.popover }")
      .text.text--leading(:class="{ 'text--ellipsable': width < 70 }")
        slot(name="leadingText") --
      .text.text--trailing.text--ellipsable(v-show="width > 100")
        slot(name="trailingText") --
    template(v-slot:popper, v-if="$slots.popover")
      slot(name="popover")
</template>

<script>
import { hsla, parseToHsla } from 'color2k'
import randomColor from 'randomcolor'

export default {
  name: 'PureTimelineUnit',
  props: {
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 40,
    },
    colorOverride: {
      type: String,
      default: undefined,
    },
    leftOffset: {
      type: Number,
      default: 0,
    },
    topOffset: {
      type: Number,
      default: 0,
    },
    groupId: {
      type: String,
      default: '',
    },
    optimizationMethod: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      color: '#ddd',
      boundary: null,
      popoverContainer: null,
    }
  },
  created() {
    if(this.colorOverride != null) {
      this.color = this.colorOverride
    } else {
      this.color = randomColor({seed: this.groupId})
    }
  },
  mounted() {
    this.boundary = document.querySelector('.content-rows')
    this.popoverContainer = document.querySelector('.content-rows')
  },
  computed: {
    cssVars() {
      return {
        '--bg-color': this.bgColor,
        '--border-color': this.borderColor,
        '--text-muted-color': this.textMutedColor,
        '--width': `${this.width}px`,
        '--height': `${this.height}px`,
        '--padding-x': this.width > 60 ? '15px' : '5px',
        '--leftOffset': `${this.leftOffset}px`,
        '--topOffset': `${this.topOffset}px`,
      }
    },
    bgColor() {
      const hslaArray = parseToHsla(this.color)
      if (this.optimizationMethod == 'relaxed')
        return hsla(hslaArray[0], hslaArray[1], 0.95, 0.2)
      else
        return hsla(hslaArray[0], hslaArray[1], 0.95, 1)
    },
    borderColor() {
      const hslaArray = parseToHsla(this.color)
      if (this.optimizationMethod == 'relaxed')
        return hsla(hslaArray[0], hslaArray[1], 0.40, 0.2)
      else
        return hsla(hslaArray[0], hslaArray[1], 0.40, 0.65)
    },
    textMutedColor() {
      const hslaArray = parseToHsla(this.color)
      return hsla(hslaArray[0], hslaArray[1], 0.26, 0.55)
    },
  },
}
</script>

<style lang="sass" scoped>
.timeline-unit-wrapper
  position: absolute
  left: var(--leftOffset)
  top: var(--topOffset)

.timeline-unit
  display: flex
  align-items: center
  min-height: 20px
  padding: 0px var(--padding-x)
  border-radius: 50px
  background: var(--bg-color)
  border: 3px solid var(--border-color)
  width: var(--width)
  height: var(--height)
  font-size: 12px
  font-family: var(--monospace-font-family)
  overflow: hidden
  .text--ellipsable
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis

  .text--leading
    margin-right: 15px
    font-weight: 700

  .text--trailing
    margin-left: auto
    font-weight: 500
    color: var(--text-muted-color)

.clickable
  cursor: pointer
</style>

<style lang="sass">
.timeline-unit-popper.v-popper__popper
  .v-popper__inner
    background: white
    box-shadow: 1px 5px 20px 0px rgba(0, 0, 0, 0.25)
    border-radius: 4px
    padding: 10px 15px
    z-index: 2000
    border: none

.timeline-unit-popper
  &.v-popper__popper,
  &.v-popper__wrapper,
  &.v-popper__inner
    &:focus-visible
      outline: none
</style>
