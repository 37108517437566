<template lang='pug'>
  div(v-if='showFlash' v-bind:class='cssClass')
    | {{ message }}
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState([
      'currentOperation',
      'currentUser',
      'tenant',
    ]),
    ...mapGetters([
      'isOperationSelected',
    ]),
    showFlash() {
      return !this.isOperationSelected && (this.tenant != 'adriafer' || !this.currentUser.external)
    },
    cssClass() {
      let cssClass = 'ui message plant-message'
      if (!this.isOperationSelected) {
        cssClass += ' red'
      }
      return cssClass
    },
    message() {
      if (!this.isOperationSelected) {
        return this.$t('missing_operation_warning')
      }
    },
  },
}
</script>

<style lang='sass' scoped>
  .plant-message
    top: -10px
</style>
