export default {
  useOperationAsCarListName(state, getters) {
    if (!getters.isOperationSelected) { return false }

    return state.settings.enable_operation_as_car_list_name
  },

  maintainCarListNameWhenSplitting(state) {
    return state.settings.maintain_car_list_name_when_splitting
  },

  isConfirmabledManovreWithoutOperationEnabled(state) {
    return state.settings.enable_confirmable_manovres_without_operation
  },

  isStickyCarListNameEnabled(state) {
    return state.settings.enable_sticky_car_list_name
  },

  isCollapsablePlantViewEnabled(state) {
    return state.settings.enable_collapsable_plant_view
  },

  showPublishCarListButton(state) {
    return state.settings.enable_plant_view_car_list_publish
  },
  isOperationRequired(state) {
    return state.settings.mandatory_operation_for_manoeuvres
  },
}
