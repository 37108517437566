<template lang="pug">
.header-timeline(:style="cssVars")
  PureTimelineSpans(
    :width="timelineWidth",
    :timelineSpanLabel="$t('shift')",
    :durations="shiftsDurations"
    :offset="shiftsOffset"
  )
  PureTimelineTimes(
    :granularityInMinutes="roundedMinutesPerColumn",
    :columnWidth="columnWidth"
  )
</template>

<script>
import PureTimelineSpans from '../trains-timeline/PureTimelineSpans.vue'
import PureTimelineTimes from '../trains-timeline/PureTimelineTimes.vue'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'HeaderTimeline',
  components: {
    PureTimelineSpans,
    PureTimelineTimes,
  },
  props: {
    height: {
      type: Number,
      default: 50,
    },
    shiftsOffset: {
      type: Number,
      default: 0,
    },
    shiftsDurations: {
      type: Array,
      default: () => [6, 6, 6, 6],
    },
  },
  computed: {
    ...mapState('trainsTimeline', [
      'columnWidth',
    ]),
    ...mapGetters('trainsTimeline', [
      'roundedMinutesPerColumn',
      'timelineWidth',
    ]),
    cssVars() {
      return {
        '--height': `${this.height}px`,
        '--width': `${this.timelineWidth}px`,
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.header-timeline
  display: flex
  flex-direction: column
  height: var(--height)
  width: var(--width)

  ::v-deep
    .timeline-spans + .timeline-times
      border-top: 1px solid #bbb
</style>
