<template lang='pug'>
  modal(name='car-list-merge-modal' class='vue-modal' width='900px' height='auto' v-bind:adaptive='true' v-on:closed='onClosed')
    .ui.modal.visible.active.car-list-merge-modal(style="overflow: auto; max-height: 100vh;")
      div(slot='top-right')
        i.close.icon(v-on:click='closeModal')
      .header
        | {{ $t('merge_modal.title') }}
        | "{{ carList.name }}"
      .content
        .merge-actions.ui.form
          p.actions
            .ui.button.positive(v-if='canMergeLeft' v-on:click='toggleMergeLeft')
              i.angle.double.left.icon
              | {{ $t('edit_modal.merge_on_left') }}

            .ui.button.positive(v-if='canMergeRight' v-on:click='toggleMergeRight')
              | {{ $t('edit_modal.merge_on_right') }}
              i.angle.double.right.icon

          .merge-dialog(v-bind:class='mergeLeftDialogCssClass')
            p.text-center
              | {{ $t('edit_modal.merge_left_carl_list') }}
              | &nbsp;
              strong {{ prevCarListName }}

            .car-list-merge-modal__car-lists
              .car-list-wrapper
                Car(v-for='car in prevCarListCars' v-bind:key='car.id' v-bind:car='car')
              span.car-list-merge-modal__plus +
              .car-list-wrapper
                Car(v-for='car in carListCars' v-bind:key='car.id' v-bind:car='car')

          .merge-dialog(v-bind:class='mergeRightDialogCssClass')
            p.text-center
              | {{ $t('edit_modal.merge_right_carl_list') }}
              | &nbsp;
              strong {{ nextCarListName }}

            .car-list-merge-modal__car-lists
              .car-list-wrapper
                Car(v-for='car in carListCars' v-bind:key='car.id' v-bind:car='car')
              span.car-list-merge-modal__plus +
              .car-list-wrapper
                Car(v-for='car in nextCarListCars' v-bind:key='car.id' v-bind:car='car')

          CarListsMergeFields(
            v-bind:carList='carList'
            v-model='mergeFields'
          )

      .actions
        .ui.cancel.button(v-on:click='closeModal')
          | {{ $t('cancel') }}
        .ui.button.positive(v-if='canSubmit' v-on:click='merge')
          | {{ $t('merge_modal.submit') }}
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import Car from '../car'
import CarListsMergeFields from './car-list-merge-fields'

export default {
  components: {
    Car,
    vSelect,
    CarListsMergeFields,
  },

  data() {
    return {
      mergeFields: {
        code: null,
        train: null,
      },
    }
  },

  computed: {
    ...mapState({
      carList: 'mergingCarList',
      mergeDialog: 'mergingCarListDirection',
    }),
    ...mapGetters([
      'manipulationDisabled',
      'carListUnavailable',
    ]),
    code() {
      return this.mergeFields.code
    },
    currentTrain() {
      return this.mergeFields.train
    },
    trainId() {
      return this.currentTrain ? this.currentTrain.id : undefined
    },
    prevCarListName() {
      if (!this.carList) { return }

      return (this.carList.prevCarList || {}).name
    },
    nextCarListName() {
      if (!this.carList) { return }

      return (this.carList.nextCarList || {}).name
    },
    carListCars() {
      if (!this.carList) { return }

      return (this.carList || {}).cars || []
    },
    prevCarListCars() {
      if (!this.carList) { return }

      return (this.carList.prevCarList || {}).cars || []
    },
    nextCarListCars() {
      if (!this.carList) { return }

      return (this.carList.nextCarList || {}).cars || []
    },
    mergeLeftDialogCssClass() {
      if (this.mergeDialog === 'left') { return '' }

      return 'hide'
    },
    mergeRightDialogCssClass() {
      if (this.mergeDialog === 'right') { return '' }

      return 'hide'
    },
    isClamped() {
      return this.clamps === true
    },
    canSplit() {
      return !this.isClamped
    },
    canMerge() {
      return (
        (this.mergeDialog === 'left' && this.canMergeLeft)
        || (this.mergeDialog === 'right' && this.canMergeRight)
      )
    },
    canSubmit() {
      return this.canMerge
    },
    canMergeLeft() {
      return (
        !this.isClamped
        && this.carList.prevCarListUuid !== undefined
        && this.carList.prevCarList.clamps !== true
        && !this.carListUnavailable(this.carList.prevCarList)
      )
    },
    canMergeRight() {
      return (
        !this.isClamped
        && this.carList.nextCarListUuid !== undefined
        && this.carList.nextCarList.clamps !== true
        && !this.carListUnavailable(this.carList.nextCarList)
      )
    },
    leftLength() {
      return '(' + this.calculareCarsLength(this.carList.cars.slice(0, this.splitIndex)) + 'm)'
    },
    leftCars() {
      let leftCars = []
      this.carList.cars.forEach(function(car, index) {
        if (index < this) {
          leftCars.push(car)
        }
      }, this.splitIndex)
      return leftCars
    },
    rightCounter() {
      return this.carList.cars.length - this.splitIndex
    },
    rightLength() {
      return '(' + this.calculareCarsLength(this.carList.cars.slice(this.splitIndex)) + 'm)'
    },
    rightCars() {
      let rightCars = []
      this.carList.cars.forEach(function(car, index) {
        if (index >= this) {
          rightCars.push(car)
        }
      }, this.splitIndex)
      return rightCars
    },
    hasCars() {
      return this.carList.cars.length > 1
    },
    railroadId() {
      return this.carList.railroad_id
    },
  },

  mounted() {
    this.$modal.show('car-list-merge-modal')
  },

  methods: {
    closeModal() {
      this.$modal.hide('car-list-merge-modal')
    },
    onClosed() {
      this.$emit('closed')
      this.$store.commit('closeMergingModal')
    },
    toggleMergeLeft() {
      if (this.canMergeLeft) {
        this.$store.commit('mergingCarList', {
          carList: this.carList,
          direction: 'left',
        })
      }
    },
    toggleMergeRight() {
      if (this.canMergeLeft) {
        this.$store.commit('mergingCarList', {
          carList: this.carList,
          direction: 'right',
        })
      }
    },
    mergeLeft() {
      this.$store.dispatch('carListsMerge', {
        leftCarListUuid: this.carList.prevCarListUuid,
        rightCarListUuid: this.carList.uuid,
        carListCode: this.code,
        trainId: this.trainId,
        beforeDeselection: () => { this.$emit('closed') },
      })
    },
    mergeRight() {
      this.$store.dispatch('carListsMerge', {
        leftCarListUuid: this.carList.uuid,
        rightCarListUuid: this.carList.nextCarListUuid,
        carListCode: this.code,
        trainId: this.trainId,
        beforeDeselection: () => { this.$emit('closed') },
      })
    },
    merge() {
      if (this.mergeDialog === 'left') {
        this.mergeLeft()
      } else {
        this.mergeRight()
      }
    },
  },
}
</script>
