import Axios from 'axios'

export default {
  show(apiToken, plantId, successCallback, errorHandling) {
    Axios.get('/v1/plants/' + plantId, {
      headers: { 'X-AUTH-TOKEN': apiToken },
    })
      .then(function (response) {
        if (response.status === 200) {
          successCallback(response.data)
        } else {
          errorHandling(error)
        }
      })
      .catch(function (error) {
        errorHandling(error)
      })
  },

  bulkProcessing(apiToken, plantId, processing, railroadsTokens, successCallback, errorHandling) {
    Axios.put('/v1/plants/' + plantId + '/bulk_processing', {
      processing: processing,
      railroads_tokens: railroadsTokens,
    }, {
      headers: { 'X-AUTH-TOKEN': apiToken },
    })
      .then(function (response) {
        if (response.status === 200) {
          successCallback(response.data)
        } else {
          errorHandling(error)
        }
      })
      .catch(function (error) {
        errorHandling(error)
      })
  },

  plantUpdated(params) {
    Axios.put('/v1/plants/' + params.plantId + '/updated', {
      apiToken: params.apiToken,
      plantId: params.plantId,
      plantUpdateToken: params.plantUpdateToken,
    }, {
      headers: { 'X-AUTH-TOKEN': params.apiToken },
    })
  },
}
