<template lang="pug">
.request-optimization-table
  .table-container-scrollable
    table.ui.compact.table.unstackable.max-content
      thead
        //- sortable column should be 'a', non-sortable 'span'
        th: input#all-operations-to-optimize-toggler(
          type="checkbox",
          :checked="areAllOperationsToOptimizeChecked",
          @change="toggleAllOperationsToOptimize()"
        )
        th
          a(@click="setSort('train')")
            | {{ sortableTitle('Traccia MA', 'train') }}
        th
          a(@click="setSort('done_on planned_arrives_at planned_departs_at')")
            | {{ sortableTitle('Orario', 'done_on planned_arrives_at planned_departs_at') }}
        th
          a(@click="setSort('typology')")
            | {{ sortableTitle('A/P', 'typology') }}
        th
          a(@click="setSort('railroad_group_name')")
            | {{ sortableTitle('Terminal', 'railroad_group_name') }}
        th
          a(@click="setSort('source_station_dium_code target_station_dium_code')")
            | {{ sortableTitle('Luogo', 'source_station_dium_code target_station_dium_code') }}
      tbody
        tr(v-for="(operation, index) in operationsData")
          td: input(
            type="checkbox",
            :id="operation.id",
            :value="operation.id",
            :checked="operationsToOptimize.includes(operation.id)",
            @change="toggleOperationToOptimize(operation)"
          )
          td {{ operation.train }}
          td {{ formatProgrammedDatetime(operation) }}
          td {{ operation.typology }}
          td {{ operation.terminal }}
          td {{ operation.station }}
      tfoot.full-width
  Pagination(@pagination:page:changed="handlePageChanged")
</template>

<script>
import Pagination from'../pagination/Pagination.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  components: {
    Pagination,
  },
  computed: {
    ...mapState('operationsOptimizer', [
      'operationsData',
      'operationsToOptimize',
      'allOperationsToOptimize',
      'currentSort',
      'currentSortDirection',
    ]),
    ...mapState('pagination', [
      'currentPage',
      'paginationInfo',
    ]
    ),
    ...mapGetters('operationsOptimizer', [
      'areAllOperationsToOptimizeChecked',
    ]),
  },
  methods: {
    ...mapActions('operationsOptimizer', [
      'toggleOperationToOptimize',
      'toggleAllOperationsToOptimize',

      'setCurrentSort',

      'callIndex',
    ]),
    formatProgrammedDatetime(operation) {
      return `${moment(operation.done_on).format('DD/MM/YYYY')} ${moment(operation.programmed_hour).format('HH:mm')}`
    },
    async handlePageChanged() {
      const page = this.currentPage || 1
      await this.callIndex({page, sort: this.currentSort, direction: this.currentSortDirection})
    },
    sortableTitle(title, key) {
      const currentSort = key === this.currentSort

      if (!currentSort) {
        return title
      }

      const arrow = this.currentSortDirection === 'desc' ? '▼' : '▲'
      return `${title} ${arrow}`
    },
    setSort(newKey) {
      const newPage = this.paginationInfo && this.paginationInfo.page || 1
      let newDirection = 'desc'

      if (this.currentSort === newKey) {
        const directionInversion = this.currentSortDirection === 'desc' ? 'asc' : 'desc'
        newDirection = directionInversion
      }

      this.callIndex({page: newPage, sort: newKey, direction: newDirection})
      this.setCurrentSort({sort: newKey, direction: newDirection})
    },
  },
}
</script>

<style lang="sass" scoped>
.request-optimization-table
  .max-content
    width: 100% !important

  .ui.table thead .column--day,
  .ui.table tbody .column--day
    min-width: 84px
    text-align: center

  .table-container-scrollable
    max-height: 79vh
</style>
