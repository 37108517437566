<template lang='pug'>
  modal(name='railroad-note-edit' class='vue-modal' width='900px' height='auto' v-bind:adaptive='true' v-on:closed='onClosed')
    .ui.modal.visible.active.car-remover-modal
      div(slot='top-right')
        i.close.icon(v-on:click='closeModal')
      .header
        | {{$t('railroad_note_editor.title')}}

      .content
        form.ui.form
          .fields
            .field.sixteen.wide
              div(class="field select optional")
                label(class="select optional" for="railroad_note")
                  | Note
                textarea(id="railroad_note" v-model="railroad_note" style="resize: none;")

      .actions
        .ui.cancel.button(v-on:click='closeModal')
          | {{ $t('close') }}
        .ui.blue.button(v-on:click='editRailroadNoteSubmit')
          | {{ $t('save') }}
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import _ from 'lodash'
import 'vue-slider-component/theme/antd.css'
import { error as errNotify, success as succNotify } from '../../../utils/notify'

export default {
  components: {},

  data() {
    return {
      railroad_note: null,
    }
  },

  computed: {
    ...mapState({
      railroad: 'editRailroadNote',
      tenant: 'tenant',
      apiToken: 'apiToken',
    }),
    ...mapGetters([
    ]),
  },

  beforeMount() {
    this.railroad_note = this.railroad.note
  },

  mounted() {
    this.$modal.show('railroad-note-edit')
  },

  methods: {
    editRailroadNoteSubmit() {
      const params = {note: this.railroad_note.trim()}

      $.ajax({
        url: `/railroads/${this.railroad.id}/edit_note`,
        data: params,
        method: 'put',
        headers: { 'X-AUTH-TOKEN': this.apiToken },
      }).always((result) => {
        if (result.status === 'ok') {
          this.$store.dispatch('refreshRailroads', [{...this.railroad, ...params}])
          succNotify({ title: this.$t('success') })
          this.onClosed()
        } else {
          errNotify({ title: parsedResult.error })
        }
      })
    },
    onClosed() {
      this.$emit('closed')
      this.$store.dispatch('hideRailroadNoteModal')
    },
    closeModal() {
      this.$modal.hide('railroad-note-edit')
    },
  },
}
</script>
