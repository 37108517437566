export const notify = ({ title, ...options }) => (
  new PNotify({
    title,
    text: options.text || '',
    type: options.type || 'success',
    delay: 5000,
    animate_speed: 'fast',
    history: false,
  })
)

export const success = ({ title, ...options }) => {
  notify({
    ...options,
    title,
    type: 'success',
  })
}

export const error = ({ title, ...options }) => {
  notify({
    ...options,
    title,
    type: 'error',
  })
}

export const notice = ({ title, ...options }) => {
  notify({
    ...options,
    title,
    type: 'notice',
  })
}

export const info = ({ title, ...options }) => {
  notify({
    ...options,
    title,
    type: 'info',
  })
}

export default info
