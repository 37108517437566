<template lang='pug'>
  div
    button.ui.button.small.teal(v-on:click='handleClick')
      | {{ $t('osv_import_modal.title') }}
    modal(name='osv-import-modal' class='vue-modal' v-bind:adaptive='true' @before-close="resetModal")
      form.ui.modal.visible.active.osv-import-modal(ref="osvForm" enctype="multipart/form-data")
        div(slot='top-right')
          i.close.icon(v-on:click='closeModal')
        .header
          | {{ $t('osv_import_modal.title') }}

        .content(v-if="!resultErrors")
          .ui.active.inverted.dimmer(v-if='loading')
            .ui.text.loader Loading

          h5| {{ $t('osv_import_modal.import_file') }}
          .sixteen.wide.column
            input(type="file" name="file" ref="osvFileImporter" @change="onFileChange")
            button.ui.icon.button.red.compact.mini(v-if="selectedFile" style='float: right' @click="removeFile")
              | {{ $t('osv_import_modal.remove_file') }}
          h5| {{ $t('osv_import_modal.select_plant') }}
          .sixteen.wide.column
            vSelect(
              class='dropdown-wrapper'
              v-bind:options='railroadOptions'
              v-model='targetRailroad'
              name="railroad_id"
            )

        .content(v-if="resultErrors")
          .sixteen.wide.column
            div(class="field select optional")
              label(class="select optional" for="railroad_note")
                h5|{{ $t('osv_import_modal.process_result') }}
              textarea(readonly id="resultErrors" v-model="resultErrors" style="resize: none; width: 100%; height: 220px;")

        .actions
          .ui.cancel.button(v-if="!resultErrors" v-on:click='closeModal')
            | {{ $t('cancel') }}
          .ui.cancel.button(v-if="resultErrors" v-on:click='closeModal')
            | {{ $t('close') }}
          .ui.cancel.button.green(v-on:click='runOsvImport' v-if="!resultErrors")
            | {{ $t('confirm') }}
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { error as errNotify, success as succNotify } from '../../../utils/notify'

export default {
  components: {
    vSelect,
  },
  data() {
    const railroadOptions = this.railroadGroups.reduce((acc, rg) => {
      return acc.concat(rg.railroads.map(rr => {return {label: rr.full_name, code: rr.id}}) || [])
    }, [])

    return {
      selectedFile: null,
      targetRailroad: null,
      resultErrors: null,
      loading: false,
      railroadOptions,
    }
  },
  props: {
    railroadGroups: Array,
    plantId: Number,
  },
  computed: {
    ...mapGetters([
    ]),
  },
  methods: {
    handleClick() {
      this.$modal.show('osv-import-modal')
    },
    closeModal() {
      this.$modal.hide('osv-import-modal')
    },
    resetModal() {
      this.removeFile()
      this.targetRailroad = null
      this.resultErrors = null
      this.loading = false
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length)
        return
      this.createFile(files[0])
    },
    createFile(file) {
      var reader = new FileReader()
      var vm = this

      reader.onload = function(e) {
        var data = e.target.result

        vm.selectedFile = data
      }
      reader.readAsDataURL(file)
    },
    removeFile: function() {
      this.selectedFile = null
      if (this.$refs.osvFileImporter) {
        this.$refs.osvFileImporter.value = null
      }
    },
    runOsvImport() {
      if (
        !this.selectedFile ||
        !this.plantId ||
        !this.targetRailroad ||
        !this.targetRailroad.code
      ) {
        errNotify({ title: 'Devi compilare tutti i campi' })
        return
      }

      const osvFormData = new FormData(this.$refs.osvForm)
      osvFormData.append('plant_id', this.plantId)
      osvFormData.append('railroad_id', this.targetRailroad.code)

      this.loading = true
      $.ajax({
        url: `/car-lists/osv_import`,
        mimeType: 'multipart/form-data',
        data: osvFormData,
        cache: false,
        contentType: false,
        processData: false,
        method: 'post',
      }).always((result) => {
        this.loading = false
        const parsedResult = JSON.parse(result)

        const carListErrors = Object.entries(parsedResult.errors.car_lists)
        const carErrors = Object.entries(parsedResult.errors.cars)

        this.resultErrors = ''
        if (carListErrors.length === 0 && carErrors .length === 0) {
          this.resultErrors = this.$t('osv_import_modal.completed_without_errors')
        } else {
          this.resultErrors = this.$t('osv_import_modal.completed_with_errors') + ':'
        }

        if (carListErrors.length > 0) {
          this.resultErrors += `\n\n${this.$t('osv_import_modal.car_list_errors')}:\n` +
            `${carListErrors.map(([k, v]) => {
              return `${k}: ${v}`
            }).join('\n')}`
        }

        if (carErrors.length > 0) {
          this.resultErrors += `\n\n${this.$t('osv_import_modal.car_errors')}:\n` +
            `${carErrors.map(([k, v]) => {
              return `${k}: ${v}`
            }).join('\n')}`
        }

        if (parsedResult.status === 'ok') {
          succNotify({ title: this.$t('success')})
          this.$store.dispatch('refreshRailroads', [parsedResult.railroad])
        } else {
          errNotify({ title: 'Error'})
        }
      })
    },
  },
}
</script>
