<template lang="pug">
.month-and-week
  .month-selector
    .month-selector__day(v-for="(month, index) in monthsFormatted")
      .ui.button(
        :class="{ blue: isSelected(months[index]) }",
        @click="handleMonthClick(months[index])"
      )
        | {{ month }}
  .week-selector
    .week-selector__nav.week-selector__nav--prev(
      @click="handlePrevWeekClick()"
    )
      i.chevron.circle.left.icon(:class="{ disabled: disableNavigation }")
    .week-selector__week
      | {{ currentWeek }}
    .week-selector__nav.week-selector__nav--next(
      @click="handleNextWeekClick()"
    )
      i.chevron.circle.right.icon(:class="{ disabled: disableNavigation }")
</template>

<script>
export default {
  name: 'PureMonthAndWeekSelector',
  props: {
    selectedDate: {
      type: [Date, Object, Number, String],
      default: () => new Date(),
    },
    disableNavigation: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['date:changed'],
  computed: {
    months() {
      const months = []
      for(let i = 0; i < 12; i++) {
        months.push(this.$dayjs().month(i))
      }
      return months
    },
    monthsFormatted() {
      return this.months.map(month => month.format('MMM'))
    },
    currentWeek() {
      return this.$dayjs(this.selectedDate).format(`[${this.$t('week')}] w`)
    }
  },
  methods:{
    isSelected(month) {
      return this.$dayjs(month).isSame(this.selectedDate, 'month')
    },
    handleMonthClick(date) {
      if (this.disableNavigation) {return }
      this.$emit('date:changed', { date })
    },
    handlePrevWeekClick() {
      if (this.disableNavigation) {return }
      this.$emit('week:prev', 7)
    },
    handleNextWeekClick() {
      if (this.disableNavigation) {return }
      this.$emit('week:next', 7)
    },
  },
}
</script>

<style lang="sass" scoped>
.month-and-week
  display: flex
  flex-direction: column
  align-items: center
  gap: 6px
.month-selector, .week-selector
  display: flex

.month-selector__day, .week-selector__week
  .button
    min-width: 30px

.month-selector__nav, .week-selector__nav
  display: flex
  align-items: center
  margin: 0px
  padding-bottom: 1px
  font-size: 16px
  cursor: pointer

  .icon:not(.disabled):hover
    color: #777
</style>
