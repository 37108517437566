export const hashOfArraysToArrayPairs = (obj) => {
  const arrayPairs = []
  Object.entries(obj).forEach(([key, arr]) => {
    arr.forEach(val => {
      arrayPairs.push([key, val])
    })
  })
  return arrayPairs
}

export const formatPeriod = (period) => {
  if (period) {
    let timestamp = new Date(Date.parse(period))
    const hours = timestamp.getHours()
    const minutes = '0' + timestamp.getMinutes()
    return hours + ':' + minutes.substr(-2)
  } else
    return ''
}

export const isOdd = (number) => {
  return number % 2 !== 0
}
