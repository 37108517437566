
export const SET_DATE_RANGE = 'SET_DATE_RANGE'
export const SET_COMPANY_ID = 'SET_COMPANY_ID'
export const SET_OPERATION_ADDONABLES_DATA = 'SET_OPERATION_ADDONABLES_DATA'
export const SET_CURRENT_SORT = 'SET_CURRENT_SORT'

export const REMOVE_ADDONABLE_TO_INVOICE = 'REMOVE_ADDONABLE_TO_INVOICE'
export const ADD_ADDONABLE_TO_INVOICE = 'ADD_ADDONABLE_TO_INVOICE'
export const SET_ADDONABLEs_TO_INVOICE = 'SET_ADDONABLEs_TO_INVOICE'
export const REMOVE_MULTIPLE_ADDONABLE_TO_INVOICE = 'REMOVE_MULTIPLE_ADDONABLE_TO_INVOICE'
export const ADD_MULTIPLE_ADDONABLE_TO_INVOICE = 'ADD_MULTIPLE_ADDONABLE_TO_INVOICE'
export const SET_CURRENT_PAGE_ADDONABLE_IDS = 'SET_CURRENT_PAGE_ADDONABLE_IDS'
export const SET_LAST_GENERATED_INVOICE = 'SET_LAST_GENERATED_INVOICE'
export const UNSET_LAST_GENERATED_INVOICE = 'UNSET_LAST_GENERATED_INVOICE'
