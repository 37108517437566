<template lang='pug'>
  .car-list-merge-fields.ui.form
    .two.fields
      // new merged car list code
      .ui.form.field.string
        label {{ $t('merge_modal.target_code') }}
        input.car-list-merge-modal__code-input(
          type='text'
          v-model='mergeCode'
          :placeholder='newCarListNamePlaceholder'
          @input="handleUpdate()"
        )

      // new merged car list train
      .ui.form.field
        label {{ $t('merge_modal.target_train') }}

        vSelect(
          class='ui-vue-select'
          label='name'
          v-on:search='fetchTrains'
          v-model='currentTrain'
          v-bind:options='trainOptions'
          v-bind:filterable='false'
          v-bind:value='currentTrain'
          v-bind:placeholder='$t("merge_modal.train_placeholder")'
          @input="handleUpdate()"
        )
          div(slot='no-options') {{ $t('merge_modal.no_trains_found') }}
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import trainsApi from '../../api/trains'
import _ from 'lodash'

export default {
  components: {
    vSelect,
  },

  props: ['value', 'railroad'],

  data() {
    return {
      mergeCode: '',
      currentTrain: null,
      trainOptions: [],
    }
  },

  computed: {
    ...mapState({
      apiToken: 'apiToken',
    }),
    newCarListNamePlaceholder() {
      return this.$t('edit_modal.new_car_list_name_placeholder')
    },
  },

  mounted() {
    this.fetchTrains('', () => {})
    this.handleUpdate()
  },

  methods: {
    handleUpdate() {
      const model = {
        code: this.mergeCode,
        train: this.currentTrain,
      }

      this.$emit('input', model)
    },
    fetchTrains(search, loading) {
      loading(true)
      trainsApi.fetch(this.apiToken, search, this.trainDate,
                      data => {
                        const options = data.trains
                        const trainIds = data.trains.map((x) => (x.id))

                        this.trainOptions = options
                        loading(false)
                      },
                      error => {
                        console.log(error)
                        loading(false)
                      }
      )
    },
  },
}
</script>
