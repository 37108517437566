<template lang="pug">
div
  .ui.grid.inputs
    .three.wide.column
      vSelect(
        class='ui-vue-select'
        :options='plantsOptions'
        v-model='selectedPlant'
        v-bind:placeholder='$t("plant_select.placeholder")'
        v-on:input='getPairedCarLists(pairedCarListsPage)'
      )
    .two.wide.column
      .ui.input.car-list-code-filter
        input(type="text" :placeholder='$t("car_list_code")' v-model="carListCodeFilter")
    .two.wide.column
      .ui.input.car-list-id-filter
        input(type="number" :placeholder='$t("car_list_id")' v-model="carListIdFilter")
    .two.wide.column
      .ui.input.operation-start-date
        DatePicker(
          lang="it",
          v-model="operationStartDate",
          format="DD/MM/YYYY",
          clearable=false,
          type="date",
          :placeholder="$t('from')",
          value-type="DD/MM/YYYY"
        )
    .two.wide.column
      .ui.input.operation-end-date
        DatePicker(
          lang="it",
          v-model="operationEndDate",
          format="DD/MM/YYYY",
          clearable=false,
          type="date",
          :placeholder="$t('to')",
          value-type="DD/MM/YYYY"
        )
    input(
      type="radio"
      v-model="optimizationType"
      :value="$t('optimized').toUpperCase()"
    )
    label.middle.aligned
      | {{ $t('optimized') }}
    input(
      type="radio"
      v-model="optimizationType"
      :value="$t('relaxed').toUpperCase()"
    )
    label.middle.aligned
      | {{ $t('relaxed') }}
    vue-confirm-dialog
    .two.wide.column
      .ui.tiny.button.green(v-on:click='confirmOptimization')
        | {{ $t('optimize') }}

  .ui.grid
    .row.padded
      .sixteen.wide.column
        PairedCarListTable(
          :carLists='pairedCarLists'
          :plantId='selectedPlant.code'
          :codeFilter='carListCodeFilter'
          :idFilter='carListIdFilter'
          :startDate='operationStartDate'
          :endDate='operationEndDate'
          v-on:notifyUpdateAddonablesSetup='updateAddonablesSetup'
          v-on:sendSelectedCarLists='saveSelectedCarLists'
        )
        Pagination(
          :page='pairedCarListsPage'
          v-on:pageChanged='getPairedCarLists'
        )

</template>

<script>
import PairedCarListTable from './PairedCarListTable.vue'
import { error as errNotify, success as succNotify } from '../utils/notify'
import Pagination from '../operations-pairing/Pagination.vue'
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker'

export default {
  components: {
    PairedCarListTable,
    Pagination,
    vSelect,
    DatePicker,
  },
  data() {
    return {
      optimizationType: 'OTTIMIZZATO',
      pairedCarLists: [],
      pairedCarListsPage: 1,
      selectedCarLists: [],
      plantsOptions: [],
      selectedPlant: { label: this.$t('plant_select.placeholder'), code: null },
      carListCodeFilter: '',
      carListIdFilter: '',
      operationStartDate: '',
      operationEndDate: '',
    }
  },
  mounted() {
    this.setPlantsOptions()
    this.getPairedCarLists(this.pairedCarListsPage)
  },
  methods: {
    setPlantsOptions() {
      this.$http.get('/plants/list_for_select.json').then(response => {
        response.data.plants.forEach((plant) => {
          this.plantsOptions.push({ label: plant.name, code: plant.value })
        })
      })
    },
    optimizeCarLists() {
      if (this.selectedCarLists.length > 0) {
        this.$http.post(
          `/operations_pairings_optimizer/optimize_car_lists`,
          {
            carListIds: this.selectedCarLists,
            optimizationType: this.optimizationType,
          }
        ).then(response => {
          if (response.data['success'])
            succNotify({ title: this.$t('optimization_in_progress') })
        })
      } else {
        errNotify({ title: this.$t('select_car_lists') })
      }
    },
    saveSelectedCarLists(carLists) {
      this.selectedCarLists = carLists
    },
    getPairedCarLists(page) {
      var query = {
        plant_id: this.selectedPlant.code,
      }
      this.$http.get(
        `/operations_pairings_optimizer/paired_car_list_collection?page=${page}`,
        { params: query }
      ).then(response => {
        this.arrivalPage = page
        this.pairedCarLists = response.data
      })
    },
    updateAddonablesSetup(data) {
      this.$http.put(
        `/operations_pairings_optimizer/update_car_list_addonables_setup`,
        {
          carListId: data['carListId'],
          carListAddonablesSetup: data['carListAddonablesSetup'],
        }
      ).then(response => {
        if (response.data['success'])
          succNotify({ title: this.$t('saved') })
      })
    },
    confirmOptimization() {
      this.$confirm(
        {
          message: this.$t('are_you_sure'),
          button: {
            no: this.$t('confirmable.cancel'),
            yes: this.$t('confirmable.confirm'),
          },
          callback: confirm => {
            if(confirm){
              this.optimizeCarLists()
            }
          },
        }
      )
    },
  },
}
</script>

<style lang="sass" scoped>
.inputs
  display: flex
  align-items: center
.car-list-code-filter, .car-list-id-filter, .operation-end-date, .operation-start-date
  width: 125px
</style>
