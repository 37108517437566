import Axios from 'axios'

export default {
  fetch(apiToken, searchQuery, trainDate, successCallback, errorHandling) {
    Axios.get('/v1/trains', {
      params: {
        number: searchQuery,
        date: trainDate,
      },
      headers: {
        'X-AUTH-TOKEN': apiToken,
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          successCallback(response.data)
        } else {
          errorHandling(error)
        }
      })
      .catch(function (error) {
        errorHandling(error)
      })
  },
}
