<template lang='pug'>
//- see mounted to find where the buttons that trigger the open modal are
modal(name='partial-odm-modal' class='vue-modal' v-bind:adaptive='true' @before-close="resetModal")
  form.ui.modal.visible.active.partial-odm-modal(style="height: inherit")
    div(slot='top-right')
      i.close.icon(v-on:click='closeModal')
    .header
      | {{ $t('title') }}

    .content(v-if="selectedShuntingResources.length === 0" style="height: inherit")
      | {{ $t('at_least_one_selected') }}
    template(v-else)
      .content
        .ui.active.inverted.dimmer(v-if='loading')
          .ui.text.loader Loading

        | {{ $t('applying_partial_odm') }}
        | {{ selectedShuntingResources.length }}
        | wagons

      .content(v-if="needNewOdm")
        label(for="new_odm_name")
          | {{ $t('new_odm_name') }}
        input(type="text" id="new_odm_name" name="new_odm_name" v-model="newOdmName")

    .actions
      .ui.cancel.button(v-on:click='closeModal')
        | {{ $t('cancel') }}
      .ui.cancel.button.green(
        v-on:click='applyPartialOdm'
        v-if="selectedShuntingResources.length > 0 && (!needNewOdm || newOdmName.trim().length > 0)"
      )
        | {{ $t('confirm') }}
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { error as errNotify, success as succNotify } from '../utils/notify'

export default {
  name: 'PartialOdmApplication',
  components: {
    vSelect,
  },
  data() {
    return {
      selectedShuntingResources: [],
      loading: false,
      needNewOdm: false,
      newOdmName: '',
    }
  },
  props: {
    checkgroupName: String,
    submitPath: String,
    resourcesCount: Number,
  },
  computed: {
    ...mapGetters([
    ]),
  },
  mounted() {
    const buttonContainer = Array.from(document.querySelectorAll('#apply-partial-planned-shunting-button')).at(-1)
    buttonContainer.style.display = 'block'
    buttonContainer.addEventListener('click', () => this.openModal())
  },
  methods: {
    openModal() {
      const domQuery = `#resources_checkers input[type='checkbox'][name^='${this.checkgroupName}']`

      const checkers = Array.from(document.querySelectorAll(domQuery))
      this.selectedShuntingResources = checkers
        .filter(ch => ch.checked)
        .map(ch => ch.dataset.resourceId)

      if (this.resourcesCount > this.selectedShuntingResources.length) {
        this.needNewOdm = true
      }

      this.$modal.show('partial-odm-modal')
    },
    closeModal() {
      this.$modal.hide('partial-odm-modal')
    },
    resetModal() {
      this.selectedShuntingResources = []
      this.loading = false
      this.needNewOdm = false
      this.newOdmName = ''
    },
    applyPartialOdm() {
      this.loading = true
      $.ajax({
        url: this.submitPath,
        data: JSON.stringify({
          new_odm_name: this.newOdmName,
          planned_shunting_resource_ids: this.selectedShuntingResources,
        }),
        contentType: 'application/json',
        headers: {
          Accept: 'application/json',
        },
        method: 'post',
      }).always((result) => {
        this.loading = false
        if (result.success) {
          succNotify({ title: result.results })
          window.location.replace(result.redirect_path)
        } else {
          errNotify({ title: result.results })
        }
      })
    },
  },
}
</script>
