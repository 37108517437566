<template lang='pug'>
  .week-days-selector
    .fields
      .field.required(v-bind:class='weekDaysCssClass')
        label.required {{ $t('labels.week_days') }}
        .ui.pointing.red.basic.label(v-if='weekDaysHaveValidationError')
          | {{ weekDaysValidationError }}
    .equal.width.inline.fields
      .field.week-day-checkbox(v-for='day in days')
        label.boolean(v-bind:for='weekDayId(day)')
          | {{ weekDaylabel(day) }}
        input(
          value='0'
          type='hidden'
          v-bind:name='weekDayName(day)'
        )
        input.boolean(
          type='checkbox'
          value='1'
          v-bind:id='weekDayId(day)'
          v-model='$data[day]'
          v-bind:name='weekDayName(day)'
          v-on:change='updateWeekDays'
        )
</template>

<script>
export default {
  props: {
    model: String,
    fieldName: String,
    weekDays: Object,
    errors: Object,
  },

  data() {
    return {
      monday: this.weekDays.monday,
      tuesday: this.weekDays.tuesday,
      wednesday: this.weekDays.wednesday,
      thursday: this.weekDays.thursday,
      friday: this.weekDays.friday,
      saturday: this.weekDays.saturday,
      sunday: this.weekDays.sunday,
    }
  },

  computed: {
    days() {
      return [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
      ]
    },
    weekDaysHaveValidationError() {
      return this.errors !== undefined &&
        this.errors[this.fieldName] !== undefined &&
        this.errors[this.fieldName].length > 0
    },
    weekDaysValidationError() {
      if (this.weekDaysHaveValidationError) {
        return this.errors[this.fieldName].join(', ')
      }
    },
    weekDaysCssClass() {
      if (this.weekDaysHaveValidationError) {
        return 'error'
      }
    },
  },

  methods: {
    weekDayName(day) {
      return this.model + '[' + this.fieldName + '][' + day + ']'
    },
    weekDayId(day) {
      return 'week_days_' + day
    },
    weekDaylabel(day) {
      return this.$t('days.' + day)
    },
    updateWeekDays() {
      this.$emit('updateWeekDays', {
        monday: this.monday,
        tuesday: this.tuesday,
        wednesday: this.wednesday,
        thursday: this.thursday,
        friday: this.friday,
        saturday: this.saturday,
        sunday: this.sunday,
      })
    },
  },
}
</script>

<style lang='sass'>
  .ui.form .week-days-selector
    margin-bottom: 15px
    .fields
      margin-bottom: 4px
  .ui.form .inline.fields .field.week-day-checkbox
    label
      font-weight: normal
    > input
      display: block
      margin-top: 5px
</style>
