import Vue from 'vue'
import VueConfirmDialog from '../vue-confirm-dialog'
import VueI18n from 'vue-i18n'
import VModal from 'vue-js-modal'
import store from '../store'
import VueMasonry from 'vue-masonry-css'

import Plant from '../plant'
import PlantDashboard from '../plant-dashboard'
import OperationAddonableGates from '../operation-addonable-gates/OperationAddonableGates'
import InvoiceAddon from '../invoice-addon/InvoiceAddon'
import MaintenanceFooter from '../layouts/MaintenanceFooter'
import OperationsOptimizer from '../operations-optimizer/OperationsOptimizer'
import OperationsPairing from '../operations-pairing/OperationsPairing'
import OperationsPairingsOptimizer from '../operations-pairings-optimizer/OperationsPairingsOptimizer'
import OperationsPairingsOptimizations from '../operations-pairings-optimizer/OperationsPairingsOptimizations'
import OperationsPlansOptimizer from '../operations-plans-optimizer/OperationsPlansOptimizer'
import TrainsTimeline from '../trains-timeline/TrainsTimeline'
import LongPeriodCalendar from '../long-period-calendar/LongPeriodCalendar'
import OptimizedShifts from '../optimized-shifts'
import PartialOdmApplication from '../operations-planned-shunting/partial-odm-application'
import CarListActions from '../plant/car-list-actions'
import DaysSelector from '../days-selector'
import CarListPublishButton from '../plant/car-list-publish-button'
import CarListUploadButton from '../plant/car-list-upload-button'
import DropdownHighlighter from '../modules/dropdown_highlighter'
import { notice, info, success, error } from '../utils/notify'
import { getPlantStorage } from '../plant/utils/localStorage'
import 'vue2-datepicker/locale/it'
import 'vue2-datepicker/locale/en'
import 'vue2-datepicker/locale/fr'
import DayjsPlugin from '@/plugins/dayjs'
import Toast from 'vue-toastification'
import { options } from '@/plugins/vue_toastification'
import AxiosPlugin from '@/plugins/axios'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
// import with webpack and not with sprockets to solve icons error
//   - sprockets don't compile fonts/images/assets referenced in yarn packages' css
//   - if assets are copied in /app/assets to let sprockets find them, generated digest prevents corrent loading
import 'semantic-ui-css/semantic.css'
import '@/assets/stylesheets/application.css.sass'
import '@/modules/import_images.js'

Vue.use(VueI18n)
Vue.use(VModal)

Vue.use(VueConfirmDialog)
Vue.use(VueMasonry)
Vue.component('VueConfirmDialog', VueConfirmDialog.default)
Vue.use(DayjsPlugin)
Vue.use(AxiosPlugin)
Vue.use(FloatingVue)

// share gloabl properties with vuex store
store.$dayjs = Vue.prototype.$dayjs
store.$http = Vue.prototype.$http

// TODO: generalize vue components init
// - DRY
// - handle i18n and store presence or not
// - load all translations for all components?

const defaultPropsParser = (props) => (props)

const componentInit = function(elId, component, options = {}) {
  const propsParser = options.propsParser || defaultPropsParser
  let el = document.getElementById(elId)
  if (el == null) { return }
  let id = '#' + el.getAttribute('id')
  let props = propsParser(JSON.parse(el.getAttribute('data-props')))
  let locale = el.getAttribute('data-locale')
  let messages = {}
  messages[locale] = JSON.parse(el.getAttribute('data-i18n'))

  Vue.prototype.$dayjs.locale(locale)

  let i18n = new VueI18n({
    locale: locale,
    messages,
  })

  return new Vue({
    store,
    i18n,
    render: h => h(component, { props }),
  }).$mount(id)
}

const daysSelectorComponentInit = function() {
  componentInit('days-selector-vue-wrapper', DaysSelector)
}

const plantComponentInit = function() {
  const urlParams = new URLSearchParams(window.location.search)
  const paramsOperationId = urlParams.get('operation')
  const propsParser = (props) => {
    const { currentOperation, operations, plantId } = props
    const lastOperationId = paramsOperationId || getPlantStorage(plantId).lastOperationId

    let selectedOperation = currentOperation

    if (!currentOperation && lastOperationId) {
      selectedOperation = operations.find((op) => (op.id === lastOperationId))
    }

    return {
      ...props,
      currentOperation: selectedOperation,
    }
  }

  componentInit('plant-vue-wrapper', Plant, { propsParser })
}

const plantDashboardComponentInit = function() {
  componentInit('plant-dashboard-vue-wrapper', PlantDashboard)
}

const operationAddonableGatesComponentInit = function() {
  componentInit('operation-addonable-gates-vue-wrapper', OperationAddonableGates)
}
const operationInvoiceAddonComponentInit = function() {
  componentInit('invoice-addon-vue-wrapper', InvoiceAddon)
}
const maintenanceFooterComponentInit = function() {
  componentInit('maintenance-footer-vue-wrapper', MaintenanceFooter)
}

const operationsOptimizerComponentInit = function() {
  componentInit('operations-optimizer-vue-wrapper', OperationsOptimizer)
}

const operationsPairingComponentInit = function() {
  componentInit('operations-pairing-vue-wrapper', OperationsPairing)
}

const OperationsPairingsOptimizerComponentInit = function() {
  componentInit('operations-pairings-optimizer-vue-wrapper', OperationsPairingsOptimizer)
}

const OperationsPairingsOptimizationsComponentInit = function() {
  componentInit('operations-pairings-optimizations-vue-wrapper', OperationsPairingsOptimizations)
}

const operationsPlansComponentInit = function() {
  componentInit('operations-plans-optimizer-vue-wrapper', OperationsPlansOptimizer)
}

const trainsTimelineComponentInit = function() {
  componentInit('trains-timeline-vue-wrapper', TrainsTimeline)
}

const longPeriodCalendarComponentInit = function() {
  componentInit('long-period-calendar-vue-wrapper', LongPeriodCalendar)
}

const optimizedShiftsComponentInit = function() {
  componentInit('optimized-shifts-vue-wrapper', OptimizedShifts)
}

const partialOdmApplicationComponentInit = function() {
  componentInit('partial-odm-application-vue-wrapper', PartialOdmApplication)
}

const carListActionsComponentInit = function() {
  if (window.Manovre.instances.carListActions) {
    window.Manovre.instances.carListActions.$destroy()
    window.Manovre.instances.carListActions = null
  }
  window.Manovre.instances.carListActions = componentInit('car-list-actions-vue-wrapper', CarListActions)
}

const carListPublishButtonComponentInit = function() {
  if (window.Manovre.instances.carListPublishButton) {
    window.Manovre.instances.carListPublishButton.$destroy()
    window.Manovre.instances.carListPublishButton = null
  }
  window.Manovre.instances.carListPublishButton = componentInit('car-list-publish-button-vue-wrapper', CarListPublishButton)
}

const carListUploadButtonComponentInit = function() {
  if (window.Manovre.instances.carListUploadButton) {
    window.Manovre.instances.carListUploadButton.$destroy()
    window.Manovre.instances.carListUploadButton = null
  }
  window.Manovre.instances.carListUploadButton = componentInit('car-list-upload-button-vue-wrapper', CarListUploadButton)
}

document.addEventListener('DOMContentLoaded', () => {
  Vue.use(Toast, options) // needs body to be loaded

  window.Manovre.instances = {}

  daysSelectorComponentInit()
  plantComponentInit()
  plantDashboardComponentInit()
  operationAddonableGatesComponentInit()
  operationInvoiceAddonComponentInit()
  maintenanceFooterComponentInit()
  operationsOptimizerComponentInit()
  operationsPairingComponentInit()
  OperationsPairingsOptimizerComponentInit()
  OperationsPairingsOptimizationsComponentInit()
  operationsPlansComponentInit()
  trainsTimelineComponentInit()
  longPeriodCalendarComponentInit()
  optimizedShiftsComponentInit()
  carListActionsComponentInit()
  carListPublishButtonComponentInit()
  carListUploadButtonComponentInit()
  partialOdmApplicationComponentInit()

  window.Manovre = window.Manovre || {}
  window.Manovre.notify = { notice, info, success, error }
  window.Manovre.components = {
    daysSelectorComponentInit,
    plantComponentInit,
    plantDashboardComponentInit,
    operationAddonableGatesComponentInit,
    operationsOptimizerComponentInit,
    operationsPairingComponentInit,
    OperationsPairingsOptimizerComponentInit,
    operationsPlansComponentInit,
    optimizedShiftsComponentInit,
    carListActionsComponentInit,
    carListPublishButtonComponentInit,
    carListUploadButtonComponentInit,
    partialOdmApplicationComponentInit,
  }

  window.Manovre.DropdownHighlighter = DropdownHighlighter
})

