<template lang='pug'>
  modal(name='rat-confirmator-modal' class='vue-modal' width='900px' height='auto' v-bind:adaptive='true' v-on:closed='onClosed')
    .ui.modal.visible.active.railroad-car-lists-merge-modal(style="overflow: auto; max-height: 100vh;")
      div(slot='top-right')
        i.close.icon(v-on:click='closeModal')

      .header
        | {{ $t('rat_modal.title') }}

      .content
        table.ui.celled.table
          thead
            tr
              th {{ $t('rat_modal.code') }}
              th {{ $t('rat_modal.rat_completed') }}
              th {{ $t('rat_modal.rat_completed_at') }}
          tbody
            tr(v-for='rat in rats')
              td
                | {{ rat.code }}
              td
                .ui.checkbox
                  input(type='checkbox' :id='rat.id' :checked='!!rat.rat_completed_at' v-on:click='toggleRat(rat.id)')
                  label
                    | {{ $t('rat_modal.rat_completed') }}
              td
                .ui.form.field
                  DateTimePicker(:date='rat.rat_completed_at' :id='rat.id' :disabled='!rat.rat_completed_at' v-on:dateChanged='onDateChanged')

      .actions
        .ui.cancel.button(v-on:click='closeModal')
          | {{ $t('cancel') }}
        .ui.primary.button(v-on:click='submitModal')
          | {{ $t('confirm') }}

</template>

<script>
import { mapState, mapGetters } from 'vuex'
import DateTimePicker from '../header/date-time-picker'
import { error as errNotify, success as succNotify } from '../../../utils/notify'
import vSelect from 'vue-select'
import moment from 'moment'
import _ from 'lodash'

export default {
  components: {
    vSelect,
    DateTimePicker,
  },

  data() {
    return {
      rats: [],
    }
  },

  computed: {
    ...mapState({
      carList: 'ratConfirmatorModal',
    }),
  },

  created() {
    moment.locale(this.$i18n.locale)
  },

  mounted() {
    this.$modal.show('rat-confirmator-modal')

    $.ajax({
      url: `/car-lists/${this.carList.id}/car-positions/rat_confirmations`,
      dataType: 'json',
    }).done((data) => {
      this.rats = data.results
    })
  },

  methods: {
    closeModal() {
      this.$modal.hide('rat-confirmator-modal')
    },
    submitModal() {
      const data = {}
      this.rats.forEach(
        (rat) => {
          data[rat.id] = rat.rat_completed_at
        }
      )

      $.ajax({
        url: `/car-lists/${this.carList.id}/car-positions/update_rat_confirmation`,
        data: { rats_completed: data, _method: 'post' },
        dataType: 'script',
        method: 'post',
      }).always(() => {
        succNotify({ title: this.$t('success') })
        this.$modal.hide('rat-confirmator-modal')
      })
    },
    onClosed() {
      this.$emit('closed')
      this.$store.dispatch('hideRatConfirmatorModal')
    },
    onDateChanged(date, id) {
      let current_rat = this.rats.find(r => r.id === id)

      if (current_rat.rat_completed_at) {
        current_rat.rat_completed_at  = new Date(date).toISOString()
      }
    },
    toggleRat(id) {
      let current_rat = this.rats.find(r => r.id === id)

      current_rat.rat_completed_at = current_rat.rat_completed_at ? null : current_rat.originally_completed_date || (new Date).toISOString()
    },
  },
}
</script>
