<template lang="pug">
.row-header(:style="cssVars")
  .row-header__title {{ title }}
  .row-header__status-icon
    slot(name="statusIcon")
</template>

<script>
export default {
  name: 'PureRowHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
      default: 40,
    },
    minHeight: {
      type: Number,
      default: 40,
    },
    width: {
      type: Number,
      default: 200,
    },
  },
  computed: {
    cssVars() {
      return {
        '--height': `${Math.max(this.minHeight, this.height)}px`,
        '--width': `${this.width}px`,
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.row-header
  display: flex
  align-items: center
  padding: 0px 15px
  min-width: 200px
  border: 1px solid #bbb
  min-height: var(--height)
  width: var(--width)
  background: white

  .row-header__status-icon
    margin-left: auto
</style>
