<template lang='pug'>
  div.operation-actions(v-if='hasCurrentOperation')
    div.ui.small.labeled.icon.dropdown.button
      i.dropdown.icon
      | {{ $t("actions") }}

      div.menu
        a.item(v-on:click='redirectToOperationShow')
          i.info.icon
          | {{ $t('details') }}

        a.item(v-on:click='redirectToOperationPdf')
          i.print.icon
          | {{ $t('print') }}

        a.item(v-bind:href='releasePath' data-modal='true')
          i.lock.icon
          | {{ $t('release') }}

</template>

<script>
import { mapState } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },

  computed: {
    ...mapState([
      'currentOperation',
    ]),
    operationId() {
      if (!this.currentOperation) { return null }

      return this.currentOperation.id
    },
    releasePath() {
      return `/operations/${this.operationId}/release/new`
    },
    hasCurrentOperation() {
      return !!this.currentOperationId
    },
  },

  mounted() {
    new PNotify({
      title: this.$t('select_operation_confirm_title'),
      text: this.$t('select_operation_confirm_message'),
      type: 'success',
      delay: 2000,
      animate_speed: 'fast',
      history: false,
    })
  },

  methods: {
    redirectToOperationShow() {
      window.location.href = `/operations/${this.currentOperationId}`
    },
    redirectToOperationPdf() {
      window.location.href = `/operations/${this.currentOperationId}.pdf`
    },
  },
}
</script>
<style lang='sass'>
  .operation-actions
    display: inline-block
    width: 180px
</style>
