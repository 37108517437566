<template lang='pug'>
  Datepicker(
    v-bind:language='datePickerLocale'
    v-bind:inline='true'
    v-bind:disabled='true'
    v-bind:monday-first='true'
    v-bind:full-month-name='true'
    v-bind:disabledDates='disabledDays'
    v-bind:highlighted='highlightedDays'
    v-bind:open-date='rangeStart'
    v-on:selected='dayClicked'
    calendar-class='days-selector-picker'
    format='dd/MM/yyyy'
  )
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'

export default {
  components: {
    Datepicker,
  },

  props: {
    rangeStart: Date,
    rangeEnd: Date,
    weekDays: Object,
    datePickerLocale: Object,
    excludedDays: Array,
  },

  computed: {
    highlightedDays() {
      let highlighted = []
      let from = moment(this.rangeStart)
      let to = moment(this.rangeEnd)

      while (from <= to) {
        if (!this.excludedDays.includes(from.format())) {
          highlighted.push(moment(from).toDate())
        }
        from = from.clone().add(1, 'd')
      }

      return {
        dates: highlighted,
        includeDisabled: false,
      }
    },
    disabledDays() {
      let disabledByWeekDays = []
      let from = moment(this.rangeStart)
      let to = moment(this.rangeEnd)
      let dayOfTheWeekIndex

      while (from <= to) {
        dayOfTheWeekIndex = moment(from).isoWeekday()
        if ((dayOfTheWeekIndex === 1 && !this.weekDays.monday) ||
          (dayOfTheWeekIndex === 2 && !this.weekDays.tuesday) ||
          (dayOfTheWeekIndex === 3 && !this.weekDays.wednesday) ||
          (dayOfTheWeekIndex === 4 && !this.weekDays.thursday) ||
          (dayOfTheWeekIndex === 5 && !this.weekDays.friday) ||
          (dayOfTheWeekIndex === 6 && !this.weekDays.saturday) ||
          (dayOfTheWeekIndex === 7 && !this.weekDays.sunday)) {

          disabledByWeekDays.push(moment(from).toDate())
        }
        from = from.clone().add(1, 'd')
      }
      return {
        to: this.rangeStart,
        from: this.rangeEnd,
        dates: disabledByWeekDays,
      }
    },
  },

  methods: {
    dayClicked(day) {
      let formattedDay = moment(day).format()
      let selectedDays = Vue.util.extend([], this.excludedDays)

      if (selectedDays.includes(formattedDay)) {
        selectedDays = selectedDays.filter(function(day) {
          return day !== formattedDay
        })
      } else {
        selectedDays.push(formattedDay)
      }
      selectedDays = selectedDays.sort()
      this.$emit('submitExcludedDays', selectedDays)
    },
  },
}
</script>

<style lang='sass'>
  .vdp-datepicker__calendar.days-selector-picker
    .cell.day,
    .cell.day.selected
      background: #fdd86c
    .cell.day.highlighted,
    .cell.day.highlighted.selected
      background: #cae5ed
    .cell.day.blank,
    .cell.day.disabled
      background: none
</style>
