<template lang='pug'>
  div(v-bind:class='cssClass')
    div(v-if='isLoading')
      .ui.active.inverted.dimmer
        .ui.text.loader Loading
    div.addonables-collection-form__content(v-html='formContent')
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
  },

  computed: {
    ...mapState({
      formContent: 'addonablesCollectionFormContent',
      isLoading: 'addonablesCollectionFormIsLoading',
      addonablesFormIsShown: 'addonablesFormIsShown',
    }),
    cssClass() {
      let cssClass = 'addonables-collection-form'
      if (this.addonablesFormIsShown) {
        cssClass += ' addonables-collection-form--collapsed'
      }
      return cssClass
    },
  },

  methods: {
    handleEditLinkClick(e) {
      e.preventDefault()
      e.stopPropagation()
      if(e.currentTarget.dataset.done == 'false' || confirm(this.$t('addonable_actions.edit_confirmation'))){
        const operationId = $(e.currentTarget).data('operation-id')
        const addonableId = $(e.currentTarget).data('addonable-id')
        this.$store.dispatch('loadAddonablesForm', { operationId, addonableId })
      }
    },
    handleFormSubmit(e) {
      e.preventDefault()
      e.stopPropagation()

      const form = '#addonables-collection-form'
      const data = $(form).serialize()
      this.$store.commit('addonablesCollectionFormLoading', true)

      $.ajax({
        url: $(form).attr('action'),
        data: data,
        method: 'post',
      }).done((result) => {
        if (result.status === 'ok') { return window.location.href = result.url }

        this.$store.commit('addonablesCollectionFormContent', result.content)
        this.$store.commit('addonablesCollectionFormLoading', false)
      })
    },
  },

  mounted() {
    this.$nextTick().then(() => {
      $(document).off('submit', '#addonables-collection-form')
      $(document).off('click', '.addonable-edit-link')
      $(document).on('click', '.addonable-edit-link', this.handleEditLinkClick)
      $(document).on('submit', '#addonables-collection-form', this.handleFormSubmit)

      var self = this
      Manovre.ws.on('movements.created', (payload) => {
        // no need to reload if another plant has been updated
        if (gon.plant_id != payload.plant_id) return

        payload.railroads.forEach(function(railroad) {
          railroad.car_lists.forEach(function(car_list) {
            self.$store.dispatch('setAndUpdateAddonableCarList', car_list)
          })
        })
      })

    })
  },

  updated() {
    this.$nextTick().then(() => {
      Manovre.initializeUI()
    })
  },

  beforeDestroy() {
    $(document).off('submit', '#addonables-collection-form')
    $(document).off('click', '.addonable-edit-link')
  },
}
</script>
