<template lang="pug">


  div(v-bind:class='cssClass' v-bind:data-update-token='plantToken')
    vue-confirm-dialog

    // TODO: Delete the reset-button component
    .ui.dimmer(:class="{'active': loading}")
      .ui.large.loader
    .plant-header
      .ui.grid
        .two.wide.column
          h2 {{ plantNameFromStore }}
        .three.wide.column
          DatePicker
        .four.wide.column
          SelectOperation
        .two.wide.column
          button.ui.button.primary.small(v-if='showLogishiftAppButton' v-on:click='openLogishiftApp' style="float: right;")
            | {{ "LogiShift - PTJ" }}
        .one.wide.column
          HazardButton(
            :hazardMessages='hazardMessages'
          )
        .four.wide.column
          CollapseRailroadGroupButton(v-if='this.tenant == "sncfr"')

    .carSearchContainer

      .ui.divider

      .ui.grid.second-line-buttons
        .eight.wide.column
          .ui.button.primary.basic(v-if="settings.enable_collapseable_filters", @click='toggleFormVisibility') {{ $t('filters', { count: filterCount }) }}
        .eight.wide.column.text-right
          a.ui.basic.button.blue(v-if='this.tenant != "sncfr" && isolatedBrakeActive' v-bind:href='this.isolatedBrakeUrl' data-modal="true")
            | {{ $t('isolated_brake') }}
          a.ui.basic.button.blue(v-if='this.tenant != "sncfr"' v-bind:href='this.bracketsUrl' data-modal="true")
            | {{ $t('plant_brackets_availability') }}: {{ plantBracketsAvailableCountFromStore }}/{{ plantBracketsCountFromStore }}
      div(v-show='isFormVisible')
        .ui.warning.message(v-if="noCarsFoundFromImport")
          | {{ $t('no_cars_found_from_import') }}
        .ui.grid
          .two.wide.computer.four.wide.tablet.eleven.wide.mobile.column
            | {{ $t('cars_search') }}
          .three.wide.computer.four.wide.tablet.eleven.wide.mobile.column
            .ui.input.mini
              input(
                v-model='searchedCarCode'
                v-bind:placeholder='codeSearchPlaceholder'
                @keydown="updateAdvancedCarSearchButtonVisibility"
              )

          .three.wide.computer.four.wide.tablet.eleven.wide.mobile.column
            <div @paste="handleCarCodesSearchPaste">
              vSelect(
                  class='option-select'
                  v-model='searchedFullCarCodes'
                  label="code"
                  v-bind:options='plantCarsOptions'
                  v-bind:placeholder='fullCarCodePlaceholder'
                  v-bind:searchable='true'
                  :multiple='true'
                  @keydown="updateAdvancedCarSearchButtonVisibility"
                )
                template(v-slot:no-options="{ search, searching }")
                  em {{ $t('no_results') }}
            </div>
          .three.wide.computer.four.wide.tablet.eleven.wide.mobile.column(v-if='this.tenant == "sncfr"')
            vSelect(
              class='option-select'
              v-model='searchedCarTypology'
              label="code"
              v-bind:options='carCustomIcons'
              v-bind:placeholder='typologySearchPlaceholder'
              v-bind:searchable='true'
              @input="updateAdvancedCarSearchButtonVisibility"
            )
              template(v-slot:no-options="{ search, searching }")
                em {{ $t('no_results') }}
          .three.wide.computer.four.wide.tablet.eleven.wide.mobile.column(v-if='this.tenant == "sncfr"')
            vSelect(
              class='option-select'
              v-model='searchedMaintenanceStatus'
              label="code"
              v-bind:placeholder='statusMaintenanceSearchPlaceholder'
              v-bind:options='maintenanceStatuses'
              v-bind:searchable='true'
              @input="updateAdvancedCarSearchButtonVisibility"
            )
              template(v-slot:no-options="{ search, searching }")
                em {{ $t('no_results') }}

          .three.wide.computer.four.wide.tablet.eleven.wide.mobile.column(
            v-if='!carSearchResultsFoundFromStore && this.tenant == "sncfr"'
          )
            a(
              class="ui mini button"
              v-bind:href='searchCar()'
            )
              | {{ $t('advanced_search') }}
          .five.wide.computer.four.wide.tablet.eleven.wide.mobile.column(
            v-if="!isReadOnlyPlant"
          )
            PublishCarListButton.d-inline-block
            OsvImportButton.d-inline-block(
              v-if='this.tenant == "sncfr"'
              :railroadGroups='railroadGroups'
              :plantId='plantId'
            )
            a(
              v-else
              class="button ui button small teal"
              v-bind:href='importCarListXls()'
            )
              | {{ $t('osv_import_modal.title') }}
    FlashMessage(v-if='showFlashMessage')

    .ui.divider

    .ui.grid.second-line-buttons
      .eight.wide.column
        PlannedShuntingButton.d-inline-block

    .ui.divider

    .container
      .modal_container
      .ui.grid
        #plant-container(v-bind:class='railroadGroupCssClass')
          RailroadGroup(
            v-for='railroadGroup in railroadGroupsFromStore'
            v-bind:key='railroadGroup.id'
            v-bind:railroadGroup='railroadGroup'
            v-bind:searchedCarCode='searchedCarCode'
            v-bind:searchedFullCarCodes='searchedFullCarCodes'
            v-bind:searchedCarTypology='searchedCarTypology'
            v-bind:searchedMaintenanceStatus='searchedMaintenanceStatus'
            v-bind:readOnlyPlant='isReadOnlyPlant'
          )
        .six.wide.column.screen-height-scrollable#addonables-sidebar-wrapper(v-if='willRenderAddonablesSidebar')
          .plant-component__addonables#addonables-sidebar
            AddonablesRenderer
    CarListEditModal(v-if='editingCarList')
    CarListMergeModal(v-if='mergingCarList')
    CarListReleaseModal(v-if='releasingCarList')
    RailroadCarListsMergeModal(v-if='isMergingRailroadCarLists')
    CarListSplitAtModal(v-if='splittingCarList')
    RatConfirmatorModal(v-if='ratConfirmatorModal')
    CarListRemoverModal(v-if='removingCarFromList')
    EditRailroadNoteModal(v-if='editRailroadNote')
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { queryStringToJSON } from './utils/querystring-to-json'
import vSelect from 'vue-select'

import DatePicker from './components/header/date-picker'
import PlannedShuntingButton from './components/header/planned-shunting-button'
import OsvImportButton from './components/header/osv-import-button'
import PublishCarListButton from './components/header/plant-car-list-publishing-modal-button'
import HazardButton from './components/header/hazard-button'
import SelectOperation from './components/header/select-operation'
import FlashMessage from './components/header/flash-message'
import RailroadGroup from './components/railroad-group'
import CarListEditModal from './components/modals/car-list-edit'
import CarListReleaseModal from './components/modals/car-list-release'
import CarListMergeModal from './components/modals/car-list-merge-modal'
import RailroadCarListsMergeModal from './components/modals/railroad-car-lists-merge-modal'
import CarListSplitAtModal from './components/modals/car-list-split-at-modal'
import RatConfirmatorModal from './components/modals/rat-confirmator-modal'
import CarListRemoverModal from './components/modals/car-remover-modal'
import EditRailroadNoteModal from './components/modals/railroad-note-editor-modal'
import AddonablesRenderer from './components/addonables/renderer'
import CollapseRailroadGroupButton from './components/header/collapse-railroad-group-button.vue'

export default {
  components: {
    DatePicker,
    SelectOperation,
    FlashMessage,
    RailroadGroup,
    CarListEditModal,
    CarListReleaseModal,
    CarListMergeModal,
    RailroadCarListsMergeModal,
    AddonablesRenderer,
    PlannedShuntingButton,
    OsvImportButton,
    PublishCarListButton,
    CarListSplitAtModal,
    RatConfirmatorModal,
    CarListRemoverModal,
    EditRailroadNoteModal,
    vSelect,
    HazardButton,
    CollapseRailroadGroupButton,
  },

  props: {
    plantId: Number,
    plantName: String,
    plantUpdateToken: String,
    railroadGroups: Array,
    operations: Array,
    currentOperation: Object,
    currentAddonable: Object,
    otherPlants: Array,
    manualTrainSelect: Boolean,
    releaseToAnotherPlant: Boolean,
    apiToken: String,
    currentDate: String,
    currentOperationType: String,
    plantBracketsCount: Number,
    plantBracketsAvailableCount: Number,
    readOnlyPlant: Boolean,
    settings: Object,
    currentUser: Object,
    tenant: String,
    bracketsUrl: String,
    isolatedBrakeUrl: String,
    carListId: Number,
    plantInsertFromLabels: Object,
    canToggleRailroadActive: Boolean,
    permissionToMergeRailroadCarLists: Boolean,
    canWriteRailroad: Boolean,
    canWriteCarPosition: Boolean,
    carTypologies: Array,
    enableTypologySelect: Boolean,
    carCustomIcons: Array,
    maintenanceStatuses: Array,
    hazardMessages: Array,
    isolatedBrakeActive: Boolean,
    plannedShuntingResourcesInProgressPlantUrl: String,
    fromPlannedShuntingResources: Boolean,
  },

  data: function () {
    return {
      searchedMaintenanceStatus: null,
      searchedCarTypology: null,
      searchedCarCode: null,
      searchedFullCarCodes: [],
      isFormVisible: !this.settings.enable_collapseable_filters,
      noCarsFoundFromImport: false,
      movementsCreationDone: false,
      plannedShuntingResourcesInProgress: this.fromPlannedShuntingResources || false,
    }
  },

  computed: {
    ...mapGetters([
      'isOperationSelected',
      'willRenderAddonablesSidebar',
      'carLists',
      'isMergingRailroadCarLists',
      'plantCars',
    ]),
    ...mapState({
      plantNameFromStore: 'plantName',
      railroadGroupsFromStore: 'railroadGroups',
      editingCarList: 'editingCarList',
      splittingCarList: 'splittingCarList',
      ratConfirmatorModal: 'ratConfirmatorModal',
      removingCarFromList: 'removingCarFromList',
      editRailroadNote: 'editRailroadNote',
      mergingCarList: 'mergingCarList',
      releasingCarList: 'releasingCarList',
      dragging: 'dragging',
      plantBracketsCountFromStore: 'plantBracketsCount',
      plantBracketsAvailableCountFromStore: 'plantBracketsAvailableCount',
      plantToken: 'plantUpdateToken',
      carSearchResultsFoundFromStore: 'carSearchResultsFound',
      loading: 'loading',
    }),
    cssClass() {
      let cssClass = 'plant-component'
      if (this.dragging) {
        cssClass += ' dragging'
      }
      return cssClass
    },
    railroadGroupCssClass() {
      return this.willRenderAddonablesSidebar
        ? 'ten wide column screen-height-scrollable'
        : 'sixteen wide column'
    },
    showFlashMessage() {
      return this.tenant !== 'sncfr'
    },
    statusMaintenanceSearchPlaceholder() {
      return this.$t('cars.status_maintenance')
    },
    typologySearchPlaceholder() {
      return this.$t('cars.typology')
    },
    fullCarCodePlaceholder() {
      return this.$t('cars.code')
    },
    codeSearchPlaceholder() {
      return this.$t('cars.code') + '/' + this.$t('cars.ctr')
    },
    showLogishiftAppButton() {
      return (
        /Mobi|Android/i.test(navigator.userAgent) && this.tenant == 'sncfr'
      )
    },
    plantCarsOptions() {
      return this.plantCars.map((car) => ({ id: car.id, code: car.code }))
    },
    filterCount() {
      const filters = [
        this.searchedCarCode,
        this.searchedFullCarCodes,
        this.searchedCarTypology,
        this.searchedMaintenanceStatus,
      ]

      return filters.filter((filter) => {
        if (Array.isArray(filter)) {
          return filter.length > 0
        }
        return filter
      }).length
    },
    isReadOnlyPlant() {
      return this.readOnlyPlant || this.plannedShuntingResourcesInProgress
    },
  },

  methods: {
    ...mapActions(['highlightCarList']),
    searchCar() {
      const searchedCarTypologyCode =
        this.searchedCarTypology && this.searchedCarTypology.code
          ? this.searchedCarTypology.code
          : ''
      return (
        '/cars/search_position?' +
        encodeURI(
          `q[car_sncf_wagon_type_cont]=${searchedCarTypologyCode}&q[resource_code_cont]=${
            this.searchedCarCode || ''
          }`
        )
      )
    },
    importCarListXls() {
      return '/car-lists/xls'
    },
    updateAdvancedCarSearchButtonVisibility() {
      this.$store.commit('setCarSearchResultsFound', false)
    },
    openLogishiftApp() {
      window.location.href = 'https://logishift.binarysystem.eu'
    },
    toggleFormVisibility() {
      this.isFormVisible = !this.isFormVisible
    },
    handleCarCodesSearchPaste(event) {
      event.preventDefault()

      const pastedData = event.clipboardData.getData('text')
      const codes = pastedData.split('\n').map((code) => code.trim())
      const validSelectedOptions = this.plantCarsOptions.filter((option) =>
        codes.includes(option.code)
      )
      this.searchedFullCarCodes = validSelectedOptions
      this.noCarsFoundFromImport = validSelectedOptions.length === 0

      this.updateAdvancedCarSearchButtonVisibility()
    },
  },

  created() {
    const initialState = {
      plantId: this.plantId,
      plantName: this.plantName,
      plantUpdateToken: this.plantUpdateToken,
      railroadGroups: this.railroadGroups,
      operations: this.operations,
      isOperationsDropdownShown: !!this.currentOperation,
      currentOperation: this.currentOperation,
      currentAddonable: this.currentAddonable,
      otherPlants: this.otherPlants,
      manualTrainSelect: this.manualTrainSelect,
      releaseToAnotherPlant: this.releaseToAnotherPlant,
      apiToken: this.apiToken,
      currentDate: this.currentDate,
      currentOperationType: this.currentOperationType,
      originalDate: this.currentDate,
      plantBracketsCount: this.plantBracketsCount,
      plantBracketsAvailableCount: this.plantBracketsAvailableCount,
      settings: this.settings,
      tenant: this.tenant,
      bracketsUrl: this.bracketsUrl,
      isolatedBrakeUrl: this.isolatedBrakeUrl,
      currentUser: this.currentUser,
      plantInsertFromLabels: this.plantInsertFromLabels,
      canToggleRailroadActive: this.canToggleRailroadActive,
      permissionToMergeRailroadCarLists: this.permissionToMergeRailroadCarLists,
      canWriteRailroad: this.canWriteRailroad,
      canWriteCarPosition: this.canWriteCarPosition,
      insufficientRailRoadCapacityWarning: this.$t('railroad_capacity_exceded'),
    }

    this.$store.dispatch('loadInitialState', initialState)
  },
  mounted() {
    this.$nextTick().then(() => {
      const queryStringObj = queryStringToJSON(window.location.search)
      this.searchedCarCode = queryStringObj?.search_car_code

      const carList = this.carLists.find(
        (cl) => this.carListId && cl.id === this.carListId.toString()
      )

      this.highlightCarList(carList)
      Manovre.ws.on('movements.created', (payload) => {
        const payloadData = JSON.parse(payload)
        // no need to reload if another plant has been updated
        if (this.plantId != payloadData.plant_id) return
        this.movementsCreationDone = true
        this.plannedShuntingResourcesInProgress = false
        this.$store.dispatch('refreshRailroads', payloadData.railroads)
        this.$store.dispatch('refreshplantUpdateToken', payloadData.plantUpdateToken)
        this.$store.commit('endDragging')
        this.$store.dispatch('reloadAddonablesForm')
      })
      Manovre.ws.on('movements.error', (payload) => {
        const payloadData = JSON.parse(payload)
        // no need to reload if another plant has been updated
        if (this.plantId != payloadData.plant_id) return
        this.movementsCreationDone = true
        this.plannedShuntingResourcesInProgress = false

        console.error('pushModifiedRailroads', payloadData.error)
        this.$store.dispatch('refreshRailroads', payloadData.railroads)
        this.$store.commit('endDragging')
        new PNotify({
          title: payloadData.message.title,
          text: payloadData.message.text,
          hide: false,
          type: 'error',
          animate_speed: 'fast',
          history: false,
          delay: 10000,
        })
      })

      var self = this
      $.ajax({
        type: 'GET',
        url: this.plannedShuntingResourcesInProgressPlantUrl,
        success: function (data) {
          if ((!self.movementsCreationDone && self.fromPlannedShuntingResources) || (!self.movementsCreationDone && data.planned_shunting_resources_in_progress_show_alert)) {
            self.plannedShuntingResourcesInProgress = true
            new PNotify({
              title: data.planned_shunting_resources_in_progress_title,
              text: data.planned_shunting_resources_in_progress_text,
              type: 'warning',
              hide: false,
              animate_speed: 'fast',
              history: false,
            })
          }
        },
      })
    })
  },
}
</script>

<style lang="sass">
.screen-height-scrollable
  max-height: 80vh
  padding-bottom: 300px
  overflow: auto
// override semantic ui rules without using important
.ui.grid > .column:not(.row).screen-height-scrollable
  padding-bottom: 300px
</style>
