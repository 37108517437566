<template lang="pug">
div(id="footer-app" v-if="publishing || justPublished")
  .ui.center.aligned.darkorange(v-if="publishing")
    i.icon.warning.sign
      | {{ ongoingMaintenance }}
  .ui.center.aligned.lightgreen(v-if="justPublished")
    i.icon.refresh
      | {{ applicationRestarting }}
</template>


<script>
export default {
  name: 'MaintenanceFooter',

  data() {
    return {
      publishing: false,
      lastPublishedAt: null,
      restartingSeconds: 30,
      currentTime: new Date(),
    }
  },
  props: {
    year: {
      type: Number,
    },
    appVersion: {
      type: String,
    },
    ongoingMaintenance: {
      type: String,
    },
    applicationRestarting: {
      type: String,
    },
    applicationName: {
      type: String,
    },
  },
  mounted: function() {
    var self = this
    this.$nextTick(function() {
      self.pingStatus()
    })
  },
  computed: {
    currentLocale() {
      return gon.locale
    },
    justPublished: function() {
      if (!this.lastPublishedAt)
        return false
      var diffInSeconds = (this.currentTime - this.lastPublishedAt) / 1000
      return !this.publishing && diffInSeconds < this.restartingSeconds
    }
  },
  methods: {
    pingStatus: function() {
      var self = this
      this.currentTime = new Date()
      $.post('https://europe-west1-binarysystem-1309.cloudfunctions.net/deploy-informations', { full_product_name: this.applicationName }).then(function(data) {
        try {
          if (!data.error) {
            if (data.status == 'deployed') {
              self.lastPublishedAt = new Date(data.time)
              self.publishing = false
            } else if (data.status == 'deploying') {
              self.lastPublishedAt = null
              self.publishing = true
            }
          }
          _.delay(self.pingStatus, 30000)
        } catch (error) {
          console.error(error)
          _.delay(self.pingStatus, 30000)
        }
      })
    },
  },
}
</script>

<style lang="sass" scoped>
#footer-app
  position: fixed
  left: 0
  bottom: 0
  width: 100%
  height: 35px
  padding-top: 7px
  background-color: gray
  font-size: 11pt

  .ui.center.aligned
    width: auto
    text-align: center
    font-weight: bold
  .icon
    width: auto
  .darkorange
    color: darkorange
  .lightgreen
    color: lightgreen
  </style>
