<template lang='pug'>
  .car(v-bind:style='carStyle' v-bind:class='foundBySearchedParams')
    i.icons(:class="iconsClass")
      InlineSvg.sncf-icon(
        v-if="this.tenant === 'sncfr'"
        :src="this.getIcon()"
        @error='svgLoadError($event)'
        :class='setInlineSvgClass'
        :style='styleObjectss'
        :transformSource='transformSvg'
      )
      <img v-if="this.tenant !== 'sncfr' && this.car.car_custom_icon" :src="this.car.car_custom_icon" :class="setInlineSvgClass" :style="styleObjectss" alt="Car Icon" />
      .special-mark(v-if="car.car_custom_icon === 'special_family' && (this.car.resource_type !== 'locomotive')" :style="iconTextStyle")
        | ?
      i.icon(
        v-if="this.tenant !== 'sncfr' && !this.car.car_custom_icon"
        v-bind:class='carCssClasses'
        v-bind:style='iconStyle'
      )
      i.icon.bottom.right.corner.info.circle.orange.isolated-brake-icon(v-if="car.isolated_brake")
      .car-id(v-if='canShowCarInfos') {{ carId }}
      .clamp(v-if='isClamped')
</template>

<script>
import { mapState } from 'vuex'
import InlineSvg from 'vue-inline-svg'
import DEFAULT_ICON from '@images/custom_square_icon.svg'

const ICONS = Object.freeze({
  'square': DEFAULT_ICON,
  'special_family': DEFAULT_ICON,
  'generic_sncfr_locomotive': require(`@images/generic_sncfr_locomotive.svg`),
  'c12_family': require(`@images/c12_family_icon.svg`),
  'draisines': require(`@images/draisines_icon.svg`),
  'f28_family': require(`@images/f28_family_icon.svg`),
  'g54_family': require(`@images/g54_family_icon.svg`),
  'h52_family': require(`@images/h52_family_icon.svg`),
  'k39_family': require(`@images/k39_family_icon.svg`),
  'w85_family': require(`@images/w85_family_icon.svg`),
  'y_family': require(`@images/y_family_icon.svg`),
})

const SNCF_CUL_JAUNE_FAMILY = 'g54_family'

const TEMPLATES = {
  LINEAR_GRADIENT: (id, stops) => {
    return `<linearGradient xmlns="http://www.w3.org/2000/svg" id="${id}" gradientTransform="rotate(0)">
        ${stops}
      </linearGradient>`
  },
  GRADIENT_STOP: (stopMeasure, color) => {
    return `<stop offset="${stopMeasure}" stop-color="${color}"></stop>`
  },
}
export default {
  props: {
    car: Object,
    carList: Object,
    searchedCarCode: String,
    searchedFullCarCodes: Array,
    searchedCarTypology: Object,
    searchedMaintenanceStatus: Object,
  },
  data() {
    return {
      styleObjectss: {
        width: '20px',
        height: '20px',
      },
      gradientId: 'myGradient',
    }
  },
  components: {
    InlineSvg,
  },
  mounted() {
    this.setIconGradient()
  },
  computed: {
    ...mapState({
      tenant: 'tenant',
      currentUser: 'currentUser',
    }),
    iconsClass() {
      if (this.tenant !== 'sncfr')
        return null

      if (this.car.immobile_stuff_container)
        return 'cul-jaune'

      if (this.car.anonymous)
        return 'anonymous'

      return null
    },
    carId() {
      const carInfo = [
        this.tenant == 'sncfr' && this.car.resource_type == 'car' ? this.car.code : null,
        this.tenant != 'sncfr' ? this.car.code.slice(-4) : null,
        this.tenant == 'sncfr' && this.car.resource_type == 'locomotive' ? this.car.serial : null,
        this.car.ctr ? `${this.$t('car_ctr')}: ${this.car.ctr}` : null,
        this.car.lot_names && this.tenant != 'sncfr' ? `${this.$t('car_lot')}: ${this.car.lot_names}` : null,
        this.carList && this.carList.notes && this.tenant == 'sncfr' ? `${this.$t('car_list_notes')}: ${this.carList.notes}` : null,
        this.car.sncf_wagon_type && this.tenant == 'sncfr' && this.car.resource_type == 'car' ? `${this.$t('sncf_wagon_type')}: ${this.car.sncf_wagon_type}` : null,
        this.car.sncf_wagon_type && this.tenant == 'sncfr' && this.car.resource_type == 'locomotive' ? `${this.$t('sncf_locomotive_series')}: ${this.car.sncf_wagon_type}` : null,
        this.car.immobile_stuff_container_code && this.tenant == 'sncfr' ? `${this.$t('immobile_stuff_container_code')}: ${this.car.immobile_stuff_container_code}` : null,
        this.car.arrival_station && this.tenant == 'sncfr' ? `${this.$t('cars.arrival_station')}: ${this.car.arrival_station.name}` : null,
        this.car.comments && this.tenant == 'sncfr' ? `${this.$t('comments')}: ${this.car.comments}` : null,
        this.car.car_fault_codes && this.tenant != 'sncfr' ? `${this.$t('cars.car_faults')}: ${this.car.car_fault_codes}` : null,
        this.car.onu_codes && this.tenant != 'sncfr' ? `${this.$t('onu_codes')}: ${this.car.onu_codes}` : null,
        this.car.goods_car_property && this.tenant != 'sncfr' ? `${this.$t('goods_car_property')}: ${this.car.goods_car_property}` : null,
      ].filter(Boolean)

      return carInfo.join('\n')
    },
    canShowCarInfos() {
      return this.currentUser.internal || // internal user can see all cars
        this.car.railway_company_id === null ||
        (this.currentUser.external && // external user can see cars from his company
          (this.currentUser.railway_company_id === this.car.railway_company_id // cars from his railway company
            || this.car.mto_id === this.currentUser.company_id)) // cars from his MTO
    },
    isClamped() {
      const { left_bracket, right_bracket } = this.car

      return !!(left_bracket || right_bracket)
    },
    foundBySearchedParams() {
      if (!((Array.isArray(this.searchedFullCarCodes) && this.searchedFullCarCodes.length) || this.searchedCarCode || this.searchedCarTypology || this.searchedMaintenanceStatus)) {
        return null // not searching at all
      }

      const codes = this.searchedFullCarCodes.map(o => o.code)
      const foundByFullCode = this.searchedFullCarCodes.length &&
        (codes.includes(this.car.code) || codes.includes(this.car.ctr))

      const foundByCode = !!this.searchedCarCode &&
        this.searchedCarCode.length > 1 &&
        (
          (
            this.car.code &&
            this.car.code.includes(this.searchedCarCode)
          ) || (
            this.car.ctr &&
            this.car.ctr.includes(this.searchedCarCode)
          )
        )

      const foundByTypology = !!this.searchedCarTypology &&
        this.car.sncf_wagon_type &&
        this.searchedCarTypology.code &&
        (
          this.car.sncf_wagon_type === this.searchedCarTypology.code ||
          this.car.sncf_wagon_type.startsWith(this.searchedCarTypology.code)
        )

      const foundByMaintenanceStatus = !!this.searchedMaintenanceStatus &&
        this.car.maintenance_status_from_verform_api &&
        this.searchedMaintenanceStatus.id &&
        this.car.maintenance_status_from_verform_api === this.searchedMaintenanceStatus.id

      if (
        // not searching by X or searching and found by X
        (!this.searchedFullCarCodes.length || foundByFullCode) &&
        (!this.searchedCarCode || foundByCode) &&
        (!this.searchedCarTypology || foundByTypology) &&
        (!this.searchedMaintenanceStatus || foundByMaintenanceStatus)
      ) {
        this.$store.commit('setCarSearchResultsFound', true)
        return 'found-light'
      }

      return null
    },
    carCssClasses() {
      let classes = ''

      if (this.car.resource_type === 'locomotive') {
        classes = 'train'

        if (this.isClamped) {
          classes += ' clamped-locomotive'
        }

        return classes
      }
      if (this.car.car_fault_codes?.length > 0) {
        if (this.car.car_fault_codes.includes('NA')) {
          classes = 'plus square'
        } else  {
          classes = 'minus square'
        }
      } else {
        classes = 'square'
      }

      if (this.car.empty) {
        classes += ' outline'
      }

      return classes
    },
    carStyle() {
      let styles = {backgroundColor: 'white'}

      if (this.car.color && this.car.color !== '') {
        styles.backgroundColor = this.car.color
      }

      if (this.car.railway_company_id !== this.currentUser.railway_company_id && this.currentUser.external) {
        styles['opacity'] = 0.4
      }

      return styles
    },
    iconStyle() {
      if (!this.car.colors_from_load) { return }

      let grad = ''

      if (this.car.colors_from_load.length == 1){
        grad = this.car.colors_from_load[0] + ', ' + this.car.colors_from_load[0]
      } else if (this.car.colors_from_load.length == 2){
        grad = this.car.colors_from_load[0] + ' 50%' + ', ' + this.car.colors_from_load[1] + ' 50%'
      } else if (this.car.colors_from_load.length == 3){
        grad = this.car.colors_from_load[0] + ' 33%' + ', ' +
          this.car.colors_from_load[1] + ' 33%'  + ', ' +
          this.car.colors_from_load[1] + ' 66%' + ', ' +
          this.car.colors_from_load[2] + ' 66%'
      }

      return {
        background: 'linear-gradient(to right, ' + grad + ')',
        '-webkit-text-fill-color': 'transparent',
        cursor: 'pointer',
        '-webkit-background-clip': 'text',
      }
    },
    setInlineSvgClass (){
      return {
        'empty': this.car.empty,
        ...this.anomaliesCssClassesHash,
      }
    },
    anomaliesCssClassesHash() {
      if (this.car.immobile_stuff_container) {
        return {'icon-cul-jaune': true}
      }

      if  (!this.car.color_from_verform_api) { return {'icon-status-no-border': true}}

      const classesHash = {}
      classesHash[`icon-status-${this.car.color_from_verform_api}`] = true

      return classesHash
    },
    iconTextStyle() {
      return { color: this.car.empty ? 'black' : 'white' }
    },
  },

  methods: {
    updateAdvancedCarSearchButtonVisibility() {
      this.$store.commit('setCarSearchResultsFound', true)
    },
    svgLoadError(event) {
      console.error(event)
    },
    setIconFill (){
      this.styleObjectss = Object.assign({}, this.styleObjectss)
    },
    setIconGradient (){
      let color = ''
      if (this.car.anonymous){
        color = 'grey'
      } else {
        color = `url('#${this.gradientId}')`
      }
      this.styleObjectss = Object.assign({}, this.styleObjectss, {fill: color})

    },
    colorsToGradient(colors){
      const stripeWidth = 100 / colors.length
      const stops = colors.map((color, index) => {
        return [
          TEMPLATES.GRADIENT_STOP(`${stripeWidth * index}%`, color),
          TEMPLATES.GRADIENT_STOP(`${stripeWidth * (index + 1)}%`, color),
        ]
      }).flat()
      return TEMPLATES.LINEAR_GRADIENT('myGradient', stops.join(''))
    },
    transformSvg (svg){
      const linearGradient = this.colorsToGradient(this.car.colors_from_load)
      if(this.car.resource_type === 'locomotive' &&  this.car.id === this.carList.cars[0].id){
        this.$set(this.styleObjectss, 'transform', 'scaleX(-1)')
      }
      svg.insertAdjacentHTML('afterbegin', linearGradient)
      return svg
    },
    getIcon() {
      if (this.tenant === 'sncfr' && this.car.immobile_stuff_container) {
        return ICONS[SNCF_CUL_JAUNE_FAMILY]
      }
      if (this.car.car_custom_icon){
        return ICONS[this.car.car_custom_icon]
      }
      if (this.car.resource_type === 'locomotive' && !this.car.car_custom_icon){
        return ICONS['generic_sncfr_locomotive']
      }
      return DEFAULT_ICON
    },
  },
}
</script>

<style lang='sass'>
  .car
    position: relative

  .special-mark
    position: absolute
    display: flex
    align-items: center
    justify-content: center
    top: 1px
    left: 0px
    height: 100%
    width: 100%
    font-size: 70%
    font-weight: 800
    color: black

  .sncf-icon.empty
    #inside
      fill: transparent
  .sncf-icon.icon-status-yellow
    #border
      fill: goldenrod
  .sncf-icon.icon-status-fuchsia
    #border
      fill: fuchsia
  .sncf-icon.icon-status-blue
    #border
      fill: blue
  .sncf-icon.icon-status-red
    #border
      fill: red
  .sncf-icon.icon-status-no-border
    #border
      fill: transparent
  .sncf-icon.icon-cul-jaune
    #border
      fill: #FBBD08
    #inside
      fill: #FBBD08
    #outside
      fill: transparent
  .car
    i.icons
      display: flex
      align-items: center
      justify-content: center
      height: var(--car-height)
      width: var(--car-height)

      .corner.icon.isolated-brake-icon
        font-size: 0.5em

      .icon.train
        margin: 0px

      &.cul-jaune::after
        content: 'CJ'
        font-family: monospace
        font-size: 60%
        font-weight: bold
        position: absolute
        left: 50%
        top: 50%
        transform: translate(-50%, -35%)

      &.anonymous::after
        content: '?'
        font-family: monospace
        font-size: 60%
        font-weight: bold
        position: absolute
        left: 50%
        top: 50%
        transform: translate(-60%, -50%)
</style>
