import Vue from 'vue'
import toOptimize from './to_optimize'
import toSave from './to_save'
import dayjs from 'dayjs'

import {
  SHOW_OPTIMIZED_TIMES,
  HIDE_OPTIMIZED_TIMES,

  SET_OPERATIONS_DATA,
  SET_CURRENT_PAGE_OPERATION_IDS,
  SET_CURRENT_SORT,

  SET_OPTIMIZED_TIMES_DATA,
  SET_CURRENT_PAGE_OPTIMIZED_TIME_IDS,

  SET_OPTIMIZED_TIME_PERIODICITY_ACTIVITY_TIME,

  SET_DATE_RANGE,
} from './mutation_types'

import {
  SET_PAGINATION_INFO,
  ENABLE_PAGINATION_LINKS,
  DISABLE_PAGINATION_LINKS,
} from '../../pagination/store/mutation_types'

const state = {
  ...toOptimize.state,
  ...toSave.state,

  showOptimizedTimes: false,
  fromDate: null,
  toDate: null,
}

const getters = {
  ...toOptimize.getters,
  ...toSave.getters,
}

const actions = {
  ...toOptimize.actions,
  ...toSave.actions,

  callIndex ({ state, commit, rootState}, { page, sort, direction }) {
    if (state.showOptimizedTimes) { commit(HIDE_OPTIMIZED_TIMES) }
    commit(`pagination/${DISABLE_PAGINATION_LINKS}`, null, { root: true })

    return this.$http.get('/operations_optimizer', {
      params: {
        page: page || rootState.pagination.currentPage,
        from_date: state.fromDate,
        to_date: state.toDate,
        sort: sort && sort.split(' ').map(k => `${k} ${direction || 'desc'}`),
      },
    }).then(response => {
      commit(SET_OPERATIONS_DATA, response.data.operations)
      commit(SET_CURRENT_PAGE_OPERATION_IDS, response.data.operations)
      const responseSortKeys = response.data.sort.map(x => x.split(' ')[0]).join(' ')
      const responseSortDirection = response.data.sort[0].split(' ')[1]
      commit(SET_CURRENT_SORT, { sort: responseSortKeys, direction: responseSortDirection})
      commit(`pagination/${SET_PAGINATION_INFO}`, response.data.pagination, { root: true })
      commit(`pagination/${ENABLE_PAGINATION_LINKS}`, null, { root: true })
    }).catch(err => {
      commit(`pagination/${ENABLE_PAGINATION_LINKS}`, null, { root: true })
    })
  },
  showLastOptimized ({ commit, rootState}, page) {
    commit(SHOW_OPTIMIZED_TIMES)
    commit(`pagination/${DISABLE_PAGINATION_LINKS}`, null, { root: true })

    return this.$http.get('/operations_optimizer', {
      params: {
        get_last_optimized: true,
        page: page || rootState.pagination.currentPage,
      },
    }).then(function (response) {
      commit(SET_OPTIMIZED_TIMES_DATA, response.data.optimizedTimes)
      commit(SET_CURRENT_PAGE_OPTIMIZED_TIME_IDS, response.data.optimizedTimes)
      commit(`pagination/${SET_PAGINATION_INFO}`, response.data.pagination, { root: true })
      commit(`pagination/${ENABLE_PAGINATION_LINKS}`, null, { root: true })
    })
  },

  setOptimizedTimePeriodicityActivityTime ({ commit }, { field, optimizedTimeId, datetime }) {
    commit(SET_OPTIMIZED_TIME_PERIODICITY_ACTIVITY_TIME, { field, optimizedTimeId, datetime })
  },

  setDateRange ({ commit }, { fromDate, toDate }) {
    commit(SET_DATE_RANGE, { fromDate, toDate })
  },
}

const mutations = {
  ...toOptimize.mutations,
  ...toSave.mutations,

  [SHOW_OPTIMIZED_TIMES] (state) {
    state.showOptimizedTimes = true
  },
  [HIDE_OPTIMIZED_TIMES] (state) {
    state.showOptimizedTimes = false
  },


  [SET_OPTIMIZED_TIME_PERIODICITY_ACTIVITY_TIME] (state, { field, optimizedTimeId, datetime }) {
    const optimizedTime = state.optimizedTimesData.find(ot => ot.id === optimizedTimeId)
    if (!optimizedTime.periodicity) {
      optimizedTime.periodicity = {}
    }
    const serializedDatetime = datetime ? dayjs(datetime).format('YYYY-MM-DDTHH:mm:ss.000+0000') : null
    Vue.set(optimizedTime.periodicity, field, serializedDatetime)
  },

  [SET_DATE_RANGE] (state, { fromDate, toDate }) {
    state.fromDate = fromDate
    state.toDate = toDate
  },
}

export const operationsOptimizerModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
