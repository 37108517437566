<template lang="pug">
.train-timeline-row-header-group(v-if="railroads", :style="cssVars")
  PureRowGroupHeader(
    v-if="rowGroupHeaderEnabled",
    :name="railroadGroup.name",
    :width="width",
    :height="rowGroupHeaderHeight"
  )
  TrainsTimelineRowHeader(
    v-for="railroad in railroads",
    :key="railroad.id",
    :railroad="railroad",
    :width="width"
  )
</template>

<script>
import TrainsTimelineRowHeader from './TrainsTimelineRowHeader'
import PureRowGroupHeader from '@/trains-timeline/PureRowGroupHeader'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'TrainsTimelineRowHeaderGroup',
  components: {
    TrainsTimelineRowHeader,
    PureRowGroupHeader,
  },
  props: {
    railroadGroup: {
      type: Object,
      default: undefined,
    },
    width: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState('trainsTimeline', [
      'rowGroupHeaderEnabled',
      'rowGroupHeaderHeight',
    ]),
    ...mapGetters('trainsTimeline', [
      'getRailroadsByIds',
    ]),
    cssVars() {
      return {
        '--width': `${this.width}px`,
      }
    },
    railroads() {
      return this.getRailroadsByIds(this.railroadGroup.railroadIds)
    },
  },

}
</script>

<style lang="sass" scoped>
@import '@styles/components/trains_timeline/train_timeline_row_group.sass'
</style>
