<template lang="pug">
.pagination(v-show="pages > 1")
  .ui.buttons
    button.ui.icon.button.gray(
      @click="handlePreviousPageClick()",
      :disabled="!prev"
    )
      i.icon.chevron.left

    button.ui.icon.button(
      v-if="firstPageOutOfReach",
      @click="handlePageClick(1)"
    )
      | {{ 1 }}

    button.ui.icon.button.gray(v-if="firstPageOutOfReach", :disabled="true")
      i.icon.ellipsis.horizontal

    button.ui.icon.button(
      v-for="index in nearbyPages",
      :key="index",
      :class="page === index ? 'blue' : 'gray'",
      @click="handlePageClick(index)"
    )
      | {{ index }}

    button.ui.icon.button.gray(v-if="lastPageOutOfReach", :disabled="true")
      i.icon.ellipsis.horizontal

    button.ui.icon.button(
      v-if="lastPageOutOfReach",
      @click="handlePageClick(pages)"
    )
      | {{ this.pages }}

    button.ui.icon.button.gray(
      @click="handleNextPageClick()",
      :disabled="!next"
    )
      i.icon.chevron.right
</template>

<script>
import { mapState, mapActions } from 'vuex'
const MAX_ITEMS_COUNT = 3

export default {
  computed: {
    ...mapState('pagination', [
      'paginationInfo',
    ]),
    page() {
      return this.paginationInfo && this.paginationInfo.page || 1
    },
    pages() {
      return this.paginationInfo && this.paginationInfo.pages || 1
    },
    prev() {
      return this.paginationInfo && this.paginationInfo.prev || null
    },
    next() {
      return this.paginationInfo && this.paginationInfo.next || null
    },
    nearbyPages() {
      let minNearby = this.page - Math.floor(MAX_ITEMS_COUNT / 2)
      let maxNearby = minNearby + MAX_ITEMS_COUNT - 1
      const leftLeftover = minNearby > 0 ? 0 : Math.abs(minNearby) + 1
      const rightLeftover = maxNearby < this.pages ? 0 : maxNearby - this.pages
      minNearby += leftLeftover - rightLeftover
      const nearby =  Array.from({length: MAX_ITEMS_COUNT}, (_, i) => i + minNearby )
      return nearby.filter(n => n > 0 && n <= this.pages)
    },
    firstPageOutOfReach() {
      return this.nearbyPages[0] > 1
    },
    lastPageOutOfReach() {
      return this.nearbyPages[MAX_ITEMS_COUNT - 1] < this.pages
    },
  },
  methods: {
    ...mapActions('pagination', [
      'nextPage',
      'previousPage',
      'setPage',
    ]),
    handleNextPageClick() {
      this.nextPage()
      this.$emit('pagination:page:changed')
    },
    handlePreviousPageClick() {
      this.previousPage()
      this.$emit('pagination:page:changed')
    },
    handlePageClick(page) {
      this.setPage(page)
      this.$emit('pagination:page:changed')
    },
  },
}
</script>

<style lang="sass" scoped>
.pagination
  margin-top: 13px
</style>
