import Axios from 'axios'
import qs from 'qs'

Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
Axios.defaults.paramsSerializer = params => {
  // this is needed to format nested objects in GET JSON params
  // to be correctly parsed by rails
  // (see https://github.com/axios/axios/issues/738,
  //      https://edgeguides.rubyonrails.org/action_controller_overview.html#hash-and-array-parameters)
  return qs.stringify(params, {
    // using 'brackets' to match rails array params format
    // (see https://edgeguides.rubyonrails.org/action_controller_overview.html#hash-and-array-parameters)
    arrayFormat: 'brackets',
  })
}

const tokenDom = document.querySelector('meta[name=csrf-token]')
if (tokenDom) {
  const csrfToken = tokenDom.content
  Axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
}

export default Axios
