<template lang='pug'>
  div(v-bind:class='cssClass')
    .ui.small.icon.buttons
      button.ui.yellow.button(v-if='canManipulate' v-on:click='edit')
        i.write.icon
      button.ui.button.disabled(v-if='cannotManipulate')
        i.write.icon

    CarListEditModal(v-if='editingCarList' v-on:closed='onClosed')
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CarListEditModal from './components/modals/car-list-edit'

export default {
  components: {
    CarListEditModal,
  },

  props: {
    apiToken: String,
    carList: Object,
    nestedPlantView: Boolean,
    currentOperation: Object,
    currentAddonable: Object,
    plantId: Number,
    plantBracketsCount: Number,
    plantBracketsAvailableCount: Number,
    plantName: String,
    railroadGroups: Array,
    operations: Array,
    otherPlants: Array,
    manualTrainSelect: Boolean,
    releaseToAnotherPlant: Boolean,
  },

  data() {
    return {
      carListSelectedId: '',
    }
  },

  computed: {
    ...mapGetters([
      'manipulationDisabled',
    ]),
    ...mapState({
      editingCarList: 'editingCarList',
      releasingCarList: 'releasingCarList',
      plantBracketsCountFromStore: 'plantBracketsCount',
      plantBracketsAvailableCountFromStore: 'plantBracketsAvailableCount',
    }),
    cssClass() {
      let classes = 'car-list-actions'

      if (!this.nestedPlantView) {
        classes += ' plant-component'
      }

      return classes
    },
    canManipulate() {
      return !this.manipulationDisabled && this.carListSelectedId.length > 0
    },
    cannotManipulate() {
      return !this.canManipulate
    },
    shouldRenderEditModal() {
      return !this.nestedPlantView
    },
  },

  methods: {
    railroadId() {
      const domId = 'operation_addonable_from_railroad_id'
      const el = document.getElementById(domId)

      return el.options[el.selectedIndex].value
    },

    carListId() {
      const domId = 'operation_addonable_car_list_id'
      const el = document.getElementById(domId)

      return el.options[el.selectedIndex].value
    },

    edit(e) {
      e.preventDefault()

      if (!this.railroadId() || !this.carListId()) { return }

      this.$store.dispatch('fetchAndEditCarList', {
        railroadId: this.railroadId(),
        carListId: this.carListId(),
      })
    },

    onClosed() {
      // Force full form refresh
      $('#operation_addonable_form').trigger('reload')
      //$('#operation_addonable_from_railroad_id').trigger('change');
    },

    handleFromRailroadChange() {
      this.carListSelectedId = this.carListId()
    },
  },

  created() {
    if (this.nestedPlantView) { return }

    const initialState = {
      operations: [this.currentOperation],
      currentOperation: this.currentOperation,
      currentAddonable: this.currentAddonable,
      currentCarList: this.carList,
      apiToken: this.apiToken,
      plantId: this.plantId,
      plantBracketsCount: this.plantBracketsCount,
      plantBracketsAvailableCount: this.plantBracketsAvailableCount,
      railroad: this.railroad,
      plantName: this.plantName,
      railroadGroups: this.railroadGroups,
      otherPlants: this.otherPlants,
      manualTrainSelect: this.manualTrainSelect,
      releaseToAnotherPlant: this.releaseToAnotherPlant,
    }

    this.$store.dispatch('loadInitialState', initialState)
  },


  mounted() {
    this.$nextTick().then(() => {
      this.carListSelectedId = this.carListId()
      $(document).off('change', '#operation_addonable_car_list_id')
      $(document).on('change', '#operation_addonable_car_list_id',
                     this.handleFromRailroadChange)
    })
  },

  beforeDestroy() {
    $(document).off('change', '#operation_addonable_car_list_id')
  },
}
</script>

<style lang='sass'>
</style>

