<template lang="pug">
.train-timeline-row.train-timeline-row--header(v-if="railroad" :style="cssVars")
  PureRowHeader(
    :title="railroad.name",
    :height="adaptedRowHeight",
    :min-height="rowHeight",
    :width="width"
  )
    template(slot="statusIcon", v-if="hasOverlap")
      i.icon.exclamation.triangle.orange.large(v-tooltip="'È presente una sovrapposizione'")
</template>

<script>
import PureRowHeader from '@/trains-timeline/PureRowHeader'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'TrainsTimelineRowHeader',
  components: {
    PureRowHeader,
  },
  props: {
    railroad: {
      type: Object,
      default: () => {},
    },
    width: {
      type: Number,
      default: 200,
    },
  },
  computed: {
    ...mapState('trainsTimeline', [
      'rowHeight',
      'currentOptimizationMethod',
    ]),
    ...mapGetters('trainsTimeline', [
      'subRowHeight',
      'getSubRowsCount',
    ]),
    adaptedRowHeight() {
      return (this.rowHeight - this.subRowHeight) + this.subRowHeight * this.getSubRowsCount(this.railroad.id)
    },
    hasOverlap() {
      return this.getSubRowsCount(this.railroad.id) > 1 && this.currentOptimizationMethod !== 'all'
    },
    cssVars() {
      return {
        '--width': `${this.width}px`,
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import '@styles/components/trains_timeline/train_timeline_row.sass'

.train-timeline-row--header
  width: var(--width)
</style>
