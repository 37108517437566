<template lang='pug'>
  div(v-if='isShown' v-bind:class='cssClass')
    div(v-if='isLoading')
      .ui.active.inverted.dimmer
        .ui.text.loader Loading
    div.addonables-edit-form__content(v-html='formContent' ref="addonables_edit_form")
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { error as errNotify, success as succNotify } from '../../../utils/notify'

const formId = '#operation_addonable_form'
const reuseCarListId = '#operation_addonable_reuse_car_list'

export default {
  components: {
  },

  computed: {
    ...mapGetters([
      'getCarList',
    ]),
    ...mapState({
      formContent: 'addonablesFormContent',
      isLoading: 'addonablesFormIsLoading',
      isShown: 'addonablesFormIsShown',
      initialAttributes: 'addonablesFormInitialState',
      dirtyAttributes: 'addonablesFormDirtyState',
    }),
    cssClass() {
      const cssClass = 'addonables-edit-form'
      return cssClass
    },
  },

  methods: {
    handleReuseCarListClick(e) {
      e.preventDefault()
      e.stopPropagation()

      const $a = $(reuseCarListId)
      const uuid = $a.data('car-list-uuid')
      const carList = this.getCarList(uuid)

      this.$store.dispatch('setAndUpdateAddonableCarList', carList)
    },

    handleFormSubmit(e) {
      e.preventDefault()
      e.stopPropagation()

      const data = $(formId).serialize()
      this.$store.commit('addonablesFormLoading', true)

      $.ajax({
        url: $(formId).attr('action'),
        data: data,
        method: 'post',
      }).done((result) => {
        if (result.status === 'ok') { return window.location.href = result.url }

        errNotify({ title: result.error_message })
        this.$store.commit('addonablesFormContent', result.content)
        this.$store.commit('addonablesFormLoading', false)
      })
    },
    serializeFormAttributes() {
      const data = $(formId).serializeArray()
      return data
    },
    handleInputChange(e) {
      const attrs = this.serializeFormAttributes()
      this.$store.commit('trackAddonablesFormDirtyState', attrs)
    },
    restoreDirtyAttributes() {
      const dirtyAttrs = this.$store.state.addonablesFormDirtyState || []

      this.unsubscribeDirtyAttributesListeners()

      dirtyAttrs.forEach(({ name, value }) => {
        const $input = $(`[name="${name}"]`)

        switch (name) {
        case 'operation_addonable[done]':
          $input.prop('checked', value === '0' ? false : true)
          break

        case 'operation_addonable[starts_at]':
        case 'operation_addonable[ceases_at]':
        case 'operation_addonable[delay_reason_note]':
          $input.val(value)
          break

        case 'operation_addonable[operation_team_id]':
        case 'operation_addonable[gate_id]':
        case 'operation_addonable[delay_code_id]':
          $input.val(value)
          $input.trigger('change') // requred by semantic UI
          break
        }
      })

      this.subscribeDirtyAttributesListeners()
    },
    subscribeDirtyAttributesListeners() {
      $(document).on('change', `${formId} input`, this.handleInputChange)
      $(document).on('change', `${formId} select`, this.handleInputChange)
      $(document).on('change', `${formId} textarea`, this.handleInputChange)
    },
    unsubscribeDirtyAttributesListeners() {
      $(document).off('change', `${formId} input`, this.handleInputChange)
      $(document).off('change', `${formId} select`, this.handleInputChange)
      $(document).off('change', `${formId} textarea`, this.handleInputChange)
    },
  },

  mounted() {
    this.$nextTick().then(() => {
      $(document).on('submit', formId, this.handleFormSubmit)
      $(document).on('click', reuseCarListId, this.handleReuseCarListClick)
      this.subscribeDirtyAttributesListeners()
    })
  },

  updated() {
    this.$nextTick().then(() => {
      Manovre.initializeUI()
      Manovre.initOperationAddonableForm()

      if (!this.isLoading) {
        const attrs = this.serializeFormAttributes()
        const dirtyAttrs = this.$store.state.addonablesFormDirtyState || []

        this.$store.commit('trackAddonablesFormInitialState', attrs)
        if (dirtyAttrs.length > 0) { this.restoreDirtyAttributes() }

        // The problems of mixing vue and jquery
        if (this.$refs.addonables_edit_form) {
          this.$refs.addonables_edit_form
            .addEventListener('change', () => {
              // when a different carList is selected manually from the addonablesForm on the right
              // change the current carlist accordingly
              const currentCarListSelect = this.$refs.addonables_edit_form.querySelector('select#operation_addonable_car_list_id')
              this.$store.dispatch('setCarList', { id: currentCarListSelect.value })
            })
        }
      }
    })
  },

  beforeDestroy() {
    $(document).off('submit', formId)
    $(document).off('click', reuseCarListId, this.handleReuseCarListClick)
    this.unsubscribeDirtyAttributesListeners()
  },
}
</script>
