import Axios from 'axios'
import {
  error as errNotify,
  success as succNotify,
} from '../../utils/notify'

const handleAxiosError = (error, cb) => {
  const data = error.response.data

  if (data.message) {
    errNotify(data.message)
  }
  cb(error, data.railroads)

  return Promise.reject(error, data.railroads)
}

const handleAxiosSuccess = (response, cb) => {
  const { data } = response
  const { message, plant_update_token } = data

  if (message) {
    succNotify(message)
  }

  cb({ ...data, plantUpdateToken: plant_update_token })
}

const makeSuccessHandler = ({ onSuccess, onFailure }) => (response) => {
  if (response.status === 200) {
    return handleAxiosSuccess(response, onSuccess)
  }

  return handleAxiosError(error, onFailure)
}

const makeFailureHandler = ({ onFailure }) => (error) => {
  return handleAxiosError(error, onFailure)
}

const axiosOptions = ({ apiToken }) => ({
  headers: {
    'X-AUTH-TOKEN': apiToken,
  },
})

export default {
  create(apiToken, railroads, { operationId, useOperationTitle = false, ...params }, onSuccess, onFailure) {
    return Axios.post('/v1/movements', {
      modified_railroads: railroads,
      operation_id: operationId,
      addonable_id: params.addonableId,
      use_operation_title: useOperationTitle,
    }, {
      headers: { 'X-AUTH-TOKEN': apiToken },
    })
  },

  invert(apiToken, railroadId, railroadUpdateToken, carListUuid, operationId,
    successCallback, errorHandling) {
    return Axios.put('/v1/movements/invert', {
      movement_params: {
        railroad_id: railroadId,
        railroad_update_token: railroadUpdateToken,
        car_list_uuid: carListUuid,
        operation_id: operationId,
      },
    }, {
      headers: { 'X-AUTH-TOKEN': apiToken },
    })
      .then(function (response) {
        if (response.status === 200) {
          handleAxiosSuccess(response, successCallback)
        } else {
          return handleAxiosError(error, errorHandling)
        }

        return response.data
      })
      .catch(function (error) {
        return handleAxiosError(error, errorHandling)
      })
  },

  merge(apiToken, railroadId, railroadUpdateToken, operationId, leftCarListUuid,
    rightCarListUuid, params, onSuccess, onFailure) {
    const { carListCode, addonableId, trainId } = params

    return Axios.put('/v1/movements/merge', {
      movement_params: {
        railroad_id: railroadId,
        railroad_update_token: railroadUpdateToken,
        operation_id: operationId,
        car_list_a_uuid: leftCarListUuid,
        car_list_b_uuid: rightCarListUuid,
        car_list_code: carListCode,
        addonable_id: addonableId,
        train_id: trainId,
      },
    }, { headers: { 'X-AUTH-TOKEN': apiToken } })
      .then(makeSuccessHandler({ onSuccess, onFailure }))
      .catch(makeFailureHandler({ onFailure }))
  },

  mergeAll(params = {}, onSuccess, onFailure) {
    const {
      carListCode,
      addonableId,
      trainId,
      apiToken,
      railroadId,
      railroadUpdateToken,
      operationId,
    } = params

    return Axios.put('/v1/movements/merge_all', {
      movement_params: {
        railroad_id: railroadId,
        railroad_update_token: railroadUpdateToken,
        operation_id: operationId,
        car_list_code: carListCode,
        addonable_id: addonableId,
        train_id: trainId,
      },
    }, { headers: { 'X-AUTH-TOKEN': apiToken } })
      .then(makeSuccessHandler({ onSuccess, onFailure }))
      .catch(makeFailureHandler({ onFailure }))
  },

  processing(
    apiToken, railroadId, railroadUpdateToken, carListUuid, processingValue,
    onSuccess, onFailure) {
    return Axios.put('/v1/movements/processing', {
      movement_params: {
        railroad_id: railroadId,
        railroad_update_token: railroadUpdateToken,
        car_list_uuid: carListUuid,
        processing_value: processingValue,
      },
    }, axiosOptions({ apiToken }))
      .then(makeSuccessHandler({ onSuccess, onFailure }))
      .catch(makeFailureHandler({ onFailure }))
  },

  clamps(
    apiToken, railroadId, railroadUpdateToken, carListUuid,
    clampsValue, leftBracketId, rightBracketId, leftCarPositionId, rightCarPositionId, operationId,
    { addonableId, ...params },
    onSuccess, onFailure) {
    return Axios.put('/v1/movements/clamps', {
      movement_params: {
        railroad_id:           railroadId,
        railroad_update_token: railroadUpdateToken,
        car_list_uuid:         carListUuid,
        clamps_value:          clampsValue,
        left_bracket_id:       leftBracketId,
        right_bracket_id:      rightBracketId,
        left_car_position_id:  leftCarPositionId,
        right_car_position_id: rightCarPositionId,
        addonable_id:          addonableId,
        operation_id:          operationId,
      },
    }, axiosOptions({ apiToken }))
      .then(makeSuccessHandler({ onSuccess, onFailure }))
      .catch(makeFailureHandler({ onFailure }))
  },

  clampsLocomotive(params = {}, { onSuccess, onFailure }) {
    const {
      apiToken,
      railroadId,
      railroadUpdateToken,
      operationId,
      addonableId,
      carListUuid,
      carPositionId,
      clampsValue,
      leftBracketId,
      rightBracketId,
      leftCarPositionId,
      rightCarPositionId,
    } = params

    return Axios.put('/v1/movements/clamps-locomotive', {
      movement_params: {
        railroad_id: railroadId,
        railroad_update_token: railroadUpdateToken,
        operation_id: operationId,
        addonable_id: addonableId,
        car_list_uuid: carListUuid,
        car_position_id: carPositionId,
        clamps_value: clampsValue,
        left_bracket_id: leftBracketId,
        right_bracket_id: rightBracketId,
        left_car_position_id: leftCarPositionId,
        right_car_position_id: rightCarPositionId,
      },
    }, axiosOptions({ apiToken }))
      .then(makeSuccessHandler({ onSuccess, onFailure }))
      .catch(makeFailureHandler({ onFailure }))
  },

  split(
    apiToken, railroadId, railroadUpdateToken, operationId, carListUuid,
    splitIndex, splitAgain, params = {},
    onSuccess, onFailure) {
    return Axios.put('/v1/movements/split', {
      movement_params: {
        railroad_id: railroadId,
        railroad_update_token: railroadUpdateToken,
        operation_id: operationId,
        car_list_uuid: carListUuid,
        car_position_index: splitIndex,
        split_again: splitAgain,
        car_lists_codes: params.car_lists_codes,
        car_lists_notes: params.car_lists_notes,
        addonable_id: params.addonableId,
      },
    }, axiosOptions({ apiToken }))
      .then(function (response) {
        if (response.status === 200) {
        // handleAxiosSuccess(response, successCallback)
        // Do not use handleAxiosSuccess function because of the custom behaviour
        // of split API
          onSuccess(response.data)
        } else {
          return handleAxiosError(error, onFailure)
        }
      })
      .catch(function (error) {
        return handleAxiosError(error, onFailure)
      })
  },

  popResourceAt({
    apiToken,
    railroadId,
    railroadUpdateToken,
    operationId,
    carListUuid,
    splitIndex,
    carListsCodes = [],
    carListsNotes = [],
    onSuccess, onFailure,
    ...params
  }) {
    return Axios.put('/v1/movements/pop_resource_at', {
      movement_params: {
        railroad_id: railroadId,
        railroad_update_token: railroadUpdateToken,
        operation_id: operationId,
        car_list_uuid: carListUuid,
        car_position_index: splitIndex,
        car_lists_codes: carListsCodes,
        car_lists_notes: carListsNotes,
        addonable_id: params.addonableId,
      },
    }, axiosOptions({ apiToken }))
      .then(makeSuccessHandler({ onSuccess, onFailure }))
      .catch(makeFailureHandler({ onFailure }))
  },

  splitAll(
    apiToken, railroadId, railroadUpdateToken, operationId, carListUuid,
    { addonableId, ...params },
    successCallback, errorHandling) {
    return Axios.put('/v1/movements/split_all', {
      movement_params: {
        railroad_id: railroadId,
        railroad_update_token: railroadUpdateToken,
        operation_id: operationId,
        car_list_uuid: carListUuid,
        addonable_id: addonableId,
      },
    }, {
      headers: { 'X-AUTH-TOKEN': apiToken },
    })
      .then(function (response) {
        if (response.status === 200) {
          handleAxiosSuccess(response, successCallback)
        } else {
          return handleAxiosError(error, errorHandling)
        }
      })
      .catch(function (error) {
        return handleAxiosError(error, errorHandling)
      })
  },

  release(apiToken, railroadId, railroadUpdateToken, operationId, carListUuid,
    trainId, manualTrainNumber, notes,
    { trainDate, trainTime, plantId, ...params },
    successCallback, errorHandling) {
    return Axios.put('/v1/movements/release', {
      movement_params: {
        railroad_id: railroadId,
        railroad_update_token: railroadUpdateToken,
        operation_id: operationId,
        car_list_uuid: carListUuid,
        train_id: trainId,
        manual_train_number: manualTrainNumber,
        train_date: trainDate,
        train_time: trainTime,
        plant_id: plantId,
        notes: notes,
      },
    }, {
      headers: { 'X-AUTH-TOKEN': apiToken },
    })
      .then(function (response) {
        if (response.status === 200) {
          handleAxiosSuccess(response, successCallback)
        } else {
          return handleAxiosError(error, errorHandling)
        }
      })
      .catch(function (error) {
        return handleAxiosError(error, errorHandling)
      })
  },
}
